import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { fetchFailedPayments } from "../../Api/VideoMetrics";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface FailedPayment {
  CustomerID: string;
  ChargeId: string;
  Status: string;
  Amount: number;
  Currency: string;
  PaymentDate: string;
  SubscriptionId: string;
  Email: string;
  PeriodStart: string;
  PeriodEnd: string;
}

const FailedPayments: React.FC = () => {
  const [data, setData] = useState<FailedPayment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const authCtx = useContext(UserAuthenticationContext);
  const access_token = authCtx.userData.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadFailedPayments = async () => {
      try {
        const result = await fetchFailedPayments(access_token, abortController);
        setData(result);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load data", err);
          setError("Failed to load data");
        }
      } finally {
        setLoading(false);
      }
    };

    loadFailedPayments();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    // Convert the full data array to a worksheet.
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet.
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FailedPayments");
    // Write the workbook to an array buffer.
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Create a Blob from the buffer and trigger a download.
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "FailedPayments.xlsx");
  };

  const columns = [
    { title: "Customer ID", dataIndex: "CustomerID", key: "CustomerID" },
    { title: "Charge ID", dataIndex: "ChargeId", key: "ChargeId" },
    { title: "Status", dataIndex: "Status", key: "Status" },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text: number) => `£${text.toFixed(2)}`,
    },
    { title: "Currency", dataIndex: "Currency", key: "Currency" },
    {
      title: "Payment Date",
      dataIndex: "PaymentDate",
      key: "PaymentDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    { title: "Subscription ID", dataIndex: "SubscriptionId", key: "SubscriptionId" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    {
      title: "Period Start",
      dataIndex: "PeriodStart",
      key: "PeriodStart",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Period End",
      dataIndex: "PeriodEnd",
      key: "PeriodEnd",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#e6e0f8", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ margin: 0, color: "#333", display: "inline-block", marginRight: "10px" }}>
            Failed Payments
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>
            Total: {data.length}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="CustomerID"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default FailedPayments;
