import React from "react";

interface VideoLoadingOverlayProps {
    hideLoader: boolean;
}

const VideoLoadingOverlay: React.FC<VideoLoadingOverlayProps> = ({ hideLoader }) => {
    return (
        <div
            className={`${hideLoader ? 'is-hidden' : 'is-not-hidden'} video-player__loading-overlay u-full_cover_absolute is-sixteen-nine-aspect-ratio loader--side-to-side`}
        >

        </div>
    );
};

export default VideoLoadingOverlay;
