import {TvPlatform} from "../Models/Enums/TvPlatforms";

export function GetTvPlatformName(platform: TvPlatform) {
    switch (platform) {
        case TvPlatform.AndroidTv:
            return "Android TV";
        case TvPlatform.FireStick:
            return "Firestick";
        case TvPlatform.Roku:
            return "Roku";
        case TvPlatform.SamsungTv:
            return "Samsung TV";
        default:
            return "TV";
    }
}
