import styled from "styled-components";
import { AxiosError } from "axios";

// Api
import { AddRating, DeleteRating } from "../../Api/Rating";

// Models
import { IRatingDTO } from "../../Models/DTOs/IRatingDTO";
import { RatingButtonType } from "../../Models/Enums/RatingButtonType";

// Assets
import IconThumbsUp from "../../Assets/SVGs/Icons/UI/ThumbsUp";
import IconThumbsDown from "../../Assets/SVGs/Icons/UI/ThumbsDown";

const Button = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
        margin: 0 16px 0 0;
    }
`;

const
    buttonStyles = `
        background: transparent;
        border: 0;
        margin: 0 6px 0 0;
        width: 20px;
        height: 20px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
                `,
    ThumbUp = styled.div`${ buttonStyles }`,
    ThumbDown = styled.div`${ buttonStyles }`;

function RatingBtn(data: {
    UserLiked: boolean;
    UserDisliked: boolean;
    Type: RatingButtonType;
    activeColour: string;
    IconSize: number;
    aspNetUserId: string;
}) {
    if (data.Type === RatingButtonType.Like) {
        return (
            <ThumbUp style={{cursor: data.aspNetUserId !== undefined ? 'pointer' : 'default'}}>
                <IconThumbsUp iconFill={ data.UserLiked && data.Type === RatingButtonType.Like ? data.activeColour : "#999" } />
            </ThumbUp>
        );
    }

    return (
        <ThumbDown style={{cursor: data.aspNetUserId !== undefined ? 'pointer' : 'default'}}>
            <IconThumbsDown iconFill={ data.UserDisliked && data.Type === RatingButtonType.Dislike ? data.activeColour : "#999" } />
        </ThumbDown>
    );
}

function LikeDislikeButton(props: {
    videoId: number;
    aspNetUserId: string;
    IconSize: number;
    Rating: IRatingDTO;
    isDisabled: boolean;
    Type: RatingButtonType;
    RefreshRating: () => void;
    testId?: string,
    children?: React.ReactNode
}) {
    const controller = new AbortController();
    const activeColour =
        props.Type === RatingButtonType.Like ? "#57fb72" : "#ff4747";

    async function PostRating() {
        if (props.aspNetUserId !== undefined) {
            //If user already likes or dislikes rating will be removed
            if (
                (props.Type === RatingButtonType.Like && props.Rating.UserLiked) ||
                (props.Type === RatingButtonType.Dislike &&
                    props.Rating.UserDisliked)
            ) {
                await DeleteContentRating();
                return;
            }

            await AddContentRating(
                props.Type === RatingButtonType.Like ? true : false
            );
        }
    }

    async function AddContentRating(isLike: boolean) {
        if (props.aspNetUserId !== undefined) {
            let result = await AddRating(props.videoId, props.aspNetUserId, isLike, controller);

            if (!result || result instanceof AxiosError) {
                return;
            }

            switch(props.Type){
                case RatingButtonType.Like:
                    props.Rating.LikeCount++;

                    if(props.Rating.UserDisliked){
                        props.Rating.DislikeCount--
                    }
                    break;
                case RatingButtonType.Dislike:
                    props.Rating.DislikeCount++

                    if(props.Rating.UserLiked){
                        props.Rating.LikeCount--
                    }
                    break;
            }

            props.Rating.UserLiked = props.Type === RatingButtonType.Like ? true : false;
            props.Rating.UserDisliked = props.Type === RatingButtonType.Dislike ? true : false;

            setTimeout(() => {
                props.RefreshRating();
            }, 500);
        }
    }

    async function DeleteContentRating() {
        if (props.aspNetUserId !== undefined) {
            let result = await DeleteRating(
                props.videoId,
                props.aspNetUserId,
                controller
            );

            if (!result || result instanceof AxiosError) {
                return;
            }

            switch(props.Type){
                case RatingButtonType.Like:
                    props.Rating.LikeCount--;
                    break;
                case RatingButtonType.Dislike:
                    props.Rating.DislikeCount--;
                    break;
            }

            props.Rating.UserLiked = false;
            props.Rating.UserDisliked = false;

            setTimeout(() => {
                props.RefreshRating();
            }, 500);
        }
    }

    return (
        <Button onClick={PostRating} disabled={props.isDisabled} data-testid={props.testId}>
            <RatingBtn
                IconSize={props.IconSize}
                Type={props.Type}
                UserDisliked={props.Rating.UserDisliked}
                UserLiked={props.Rating.UserLiked}
                activeColour={activeColour}
                aspNetUserId={props.aspNetUserId}
            />

            { props.children }
        </Button>
    );
}

export default LikeDislikeButton;
