import React from "react";

const IconX = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="300.251"
            viewBox="0 0 300 300"
        >
            <path
                style={{fill: props?.iconFill}}
                d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59H300M36.01 19.54h40.65l187.13 262.13h-40.66"></path>
        </svg>
    )
};

export default IconX;
