import {ContentAccessType} from "../Enums/ContentAccessType";
import {ContentType} from "../Enums/ContentType";
import IckonicLive from '../../Assets/Images/Browse/ComginSoon/Ickonic Live_Poster_811x1080.jpg'
import MessengersS14 from "../../Assets/Images/Browse/ComginSoon/Messengers S14.jpg";
import Persona from "../../Assets/Images/Browse/ComginSoon/Persona Non Grata.jpg";
import TheRide from "../../Assets/Images/Browse/ComginSoon/The Ride - Cycle To The Isle.jpg";
import WhyTheMiddleEast from "../../Assets/Images/Browse/ComginSoon/Why The Middle East.jpg";

const comingSoonItems = [
    // {
    //     Id: 4,
    //     Title: "Loading...",
    //     Text: "",
    //     Season: 0,
    //     Episode: 0,
    //     CategoryId: 0,
    //     Thumbnail: "",
    //     PortraitThumbnail: Persona,
    //     BackgroundImage: "",
    //     AccessType: ContentAccessType.Unlimited,
    //     ContentType: ContentType.Film,
    //     Created: "",
    //     ReleaseDate: "",
    //     ReleaseDateEarlyAccess: "",
    //     IsOriginal: false,
    //     IsFreeView: false,
    //     IsFreeViewApp: false,
    //     IsVisible: true,
    //     IsFavourite: false,
    //     Rating: 0,
    //     IsBookable: false,
    //     IsClickeable: false,
    //     LatestEpisode: undefined,
    //     VideoLinkHLS: "",
    //     VideoLinkDash: "",
    //     TrailerLinkHLS: "",
    //     TrailerLinkDash: "",
    //     Link: "#", // Fallback link during loading
    //     OptionalLabel: '29th March'
    // },
    {
        Id: 4,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: MessengersS14,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: '7th April'
    },
    {
        Id: 2,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: IckonicLive,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: '20th April'
    },
    {
        Id: 5,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: TheRide,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: '29th April'
    },
    {
        Id: 6,
        Title: "Loading...",
        Text: "",
        Season: 0,
        Episode: 0,
        CategoryId: 0,
        Thumbnail: "",
        PortraitThumbnail: WhyTheMiddleEast,
        BackgroundImage: "",
        AccessType: ContentAccessType.Unlimited,
        ContentType: ContentType.Film,
        Created: "",
        ReleaseDate: "",
        ReleaseDateEarlyAccess: "",
        IsOriginal: false,
        IsFreeView: false,
        IsFreeViewApp: false,
        IsVisible: true,
        IsFavourite: false,
        Rating: 0,
        IsBookable: false,
        IsClickeable: false,
        LatestEpisode: undefined,
        VideoLinkHLS: "",
        VideoLinkDash: "",
        TrailerLinkHLS: "",
        TrailerLinkDash: "",
        Link: "#", // Fallback link during loading
        OptionalLabel: '11th May'
    }
]

export default comingSoonItems;
