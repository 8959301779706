import { AxiosError } from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ForgotPassword } from "../../../Api/Account";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import WhiteButton from "../Buttons/WhiteButton";
import InputWithLabel from "../Inputs/InputWithLabel";
import Spinner from "../Spinner";

import {ContainsAt} from "../../../Helpers/Strings";

const Container = styled.form`
    width: 100%;
    margin: 1rem 0 0 0;
`;

function ForgotPasswordForm(props: {
    formSuccess: boolean;
    setFormSuccess: (value: boolean) => void;
}) {
    const emailInput = useRef<HTMLInputElement | null>(null);
    const [emailError, setEmailError] = useState<string>();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    function GetEmailValidState(text: string): InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("Email cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("Not a valid Email");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    }

    function GetEmailKeyUpState(text: string): InputIsValid {
        if (emailError === "" || emailError === undefined || emailError === null) {
            return InputIsValid.Valid;
        }
        return GetEmailValidState(text);
    }

    function emailReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetEmailKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetEmailValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Value: event.target.value,
            Type: InputState.User_Input,
        } as IInputDTO);
    }

    function validateEmailHandler() {
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    const emailIsValid = emailState.IsValid === InputIsValid.Valid;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(emailIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [emailIsValid]);

    async function CallForgotPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);

        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        if (!formIsValid || emailState.Type === InputState.Not_Set) {
            setLoading(false);
            return;
        }

        const result = await ForgotPassword(emailState.Value);
        let success;

        if(result instanceof AxiosError){
            success = false;
            props.setFormSuccess(false);
        }
        else{
            success = result;
            props.setFormSuccess(result);
        }

        if (success) {
            toast.success("Your request has been sent. You should recieve an E-mail shortly");
        } else {
            toast.error(
                "An error has occured. Please try again or send an email directly to support@ickonic.com."
            );
        }

        setLoading(false);
    }

    return (
        <Container onSubmit={CallForgotPassword}>
            {loading ? (
                <Spinner fontSize={50} />
            ) : (
                <>
                    <InputWithLabel
                        ref={emailInput}
                        label={"Email Address"}
                        placeholder={"you@example.com"}
                        value={emailState.Value}
                        isValid={emailIsValid || emailState.IsValid === InputIsValid.NotSet}
                        onChange={emailChangeHandler}
                        onBlur={validateEmailHandler}
                        errorMessage={emailError}
                        required={true}
                        type={"text"}
                    />

                    <WhiteButton disabled={!formIsValid || props.formSuccess}>
                        Reset Password
                    </WhiteButton>
                </>
            )}
        </Container>
    );
}

export default ForgotPasswordForm;
