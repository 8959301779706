import styled from "styled-components";
import Heading from "../UI/Text/Heading";
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";
import Carousel from "./Carousel";
import { SwiperSlide } from "swiper/react";
import CarouselItem from "./CarouselItem";
import useWindowDimensions from "../../Helpers/WindowDimensions";
import { HeadingType } from "../../Models/Enums/HeadingType";
import NavLinkSmallInlineGreyButton from "../UI/Buttons/NavLinkSmallInlineGreyButton";

const CarouselObject = styled.div`
    display: flex;
    flex-direction: column;
    margin: calc(48rem/16) 0;
    &:first-child {
        margin-top: calc(8rem/16);
    }
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: calc(16rem/16);
    h3 {
        margin: 0;
        max-width: calc(100% - (103rem/16));
    }
`;

export default function GenericCarousel({
    carousel,
    arrows,
    autoPlay,
    bullets,
    loop,
    viewMoreText,
    viewMoreLocation,
    AmountToShow
}: {
    carousel: ICarouselDTO;
    arrows?: boolean;
    autoPlay?: boolean;
    bullets?: boolean;
    loop?: boolean;
    viewMoreText?: string;
    viewMoreLocation?: string
    AmountToShow: number,
}) {
    const { width } = useWindowDimensions();

    return (
        <CarouselObject key={ "c" + carousel.Title }>
            <div>
                <TitleContainer>
                    <Heading type={ HeadingType.H3 }>
                        { carousel.Title }
                    </Heading>

                    { viewMoreLocation && viewMoreText ? (
                        <NavLinkSmallInlineGreyButton to={ viewMoreLocation }>{ viewMoreText }</NavLinkSmallInlineGreyButton>
                    ) : null }
                </TitleContainer>

                <Carousel
                    arrows={ arrows }
                    autoPlay={ autoPlay }
                    bullets={ bullets}
                    sliderPerView={ width > 1115 ? AmountToShow : width > 800 ? 2 : 1 }
                    spaceBetween={ 16 }
                    loop={ loop }
                >
                    { carousel.Content.map((content, index) => {
                        return (
                            <SwiperSlide key={ index.toString() + content.Id }>
                                <CarouselItem Content={ content } disableOverlay={ true } />
                            </SwiperSlide>
                        );
                    }) }
                </Carousel>
            </div>
        </CarouselObject>
    );
}
