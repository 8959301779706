import {TFunction} from "i18next";

/**
 * Simple email address validation that checks for "@"
 */
export const emailValidation = (emailAddress: string, t: TFunction): string => {
    if (!emailAddress) {
        return t('error-email-required');
    }
    if (!emailAddress.includes('@')) {
        return t('error-email-at-symbol');
    }
    const [localPart, domain] = emailAddress.split('@');
    if (!localPart || !domain) {
        return t('error-email-format');
    }
    if (!domain.includes('.') || domain.endsWith('.')) {
        return t('error-email-domain');
    }
    const domainParts = domain.split('.');
    if (domainParts.length < 2 || domainParts.some(part => part.length === 0)) {
        return t('error-email-domain-format');
    }
    // Add more specific checks as needed
    return "valid";
}
export const passwordValidation = (password: string, t: TFunction): string => {
    if (!password) {
        return t('error-pw-required');
    }

    if (password.length < 8) {
        return t('error-pw-length');
    }

    if (!/[A-Z]/.test(password)) {
        return t('error-pw-uppercase');
    }

    if (!/[a-z]/.test(password)) {
        return t('error-pw-lowercase');
    }

    if (!/[0-9]/.test(password)) {
        return t('error-pw-number');
    }

    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
        return t('error-pw-special-char');
    }

    return "valid";
};
export const nameValidation = (name: string, t: TFunction): string => {
    if (!name || name.trim().length === 0) {
        return t('error-name-required');
    }

    if (!name || name.trim().length < 2) {
        return t('error-name-part-length');
    }

    return "valid";
};

export const mustHaveValueValidation = (value: string): string => {
    if (!value || value.trim().length === 0) {
        return "Must have a value";
    }

    if (!value || value.trim().length < 2) {
        return "Must be at least one word";
    }

    return "valid";
};

