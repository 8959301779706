import React from "react";

const IconFullScreenRevert = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M15 2h2v5h7v2h-9zm9 13v2h-7v5h-2v-7zM9 22H7v-5H0v-2h9zM0 9V7h7V2h2v7z"> </path>
        </svg>
    )
};

export default IconFullScreenRevert;
