import React from "react";
import InputTextArea from "../../Inputs/InputTextArea";
import ButtonPill from "../../Buttons/ButtonPill";
import IconSpinLoader from "../../../Assets/SVGs/Icons/Loading/SpinLoader";

interface StepProvideFeedbackProps {
    feedback: string;
    setFeedback: (s: string) => void;
    checkValidity: () => void;
    feedbackValid: boolean;
    errorText: string;
    handleCancelMembership: () => Promise<void>;
    isCancelApiLoading: boolean;
}

const StepProvideFeedback_CancelOrPause = (
    {
        feedback,
        setFeedback,
        checkValidity,
        feedbackValid,
        errorText,
        handleCancelMembership,
        isCancelApiLoading
    }: StepProvideFeedbackProps) =>
{
    const
        setFeedbackValue = (newValue: string) => {
            setFeedback(newValue);
            checkValidity();
        },
        checkCanCancelMembership = () => {
            if (feedbackValid) {
                handleCancelMembership();
            }
        };

    return (
        <div className="cancel-or-pause__step-provide-feedback">
            <div className="step-provide-feedback__inner">
                <div className="cancel-or-pause__heading-wrapper">
                    <h1>
                        Help us Improve
                    </h1>
                </div>

                <div className="cancel-or-pause__divider" />

                <div className="cancel-or-pause__content-wrapper">
                    <p>
                        How could we have improved your Ickonic experience? All feedback would be greatly appreciated.
                    </p>

                    <InputTextArea
                        name="Provide Feedback"
                        value={feedback}
                        placeholder="Give us some feedback"
                        onChange={(e) => setFeedbackValue(e.target.value)}
                        onFocus={() => {}}
                        onBlur={() => checkValidity()}
                        hasError={errorText !== 'valid'}
                        errorMessage={errorText}
                    />

                    <p className="step-provide-feedback__next-step">
                        <ButtonPill
                            onClick={() => checkCanCancelMembership()}
                            label={isCancelApiLoading ? "" : "Cancel Auto-Renew"}
                            className={`${feedbackValid ? 'is-valid' : 'is-not-valid'} ${isCancelApiLoading ? "is-loading" : "is-not-loading"}`}
                            link=""
                            icon={isCancelApiLoading ? IconSpinLoader() : null}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default StepProvideFeedback_CancelOrPause;
