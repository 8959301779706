import styled from "styled-components";
import { useEffect, useState, useContext } from 'react';

// Models
import { IRelatedVideoInfo } from "../../../Models/DTOs/IRelatedVideoInfo";

// Api
import { GetRelatedVideosInfo } from "../../../Api/Video";

// Components
import ButtonPill from "../../Buttons/ButtonPill";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

// Assets
import IconArrowWithTail from "../../../Assets/SVGs/Icons/Arrows/ArrowWithTail";
import IconList from "../../../Assets/SVGs/Icons/List/List";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

const Container = styled.div`
    gap: calc(16rem/16);
    width: 100%;
    .ickonic-button {
        font-weight: normal;
        background: #2e2e2e;
        width: 100%;
        margin: 0 0 calc(16rem/16) 0;
        padding: 6px 16px;
        height: 40px;
        &:hover,
        &:focus,
        &:active {
            background: #404040;
        }
    }

    @media screen and (min-width: calc(358em/16)) {
        display: inline-flex;
        margin: 0 0 calc(16rem/16) 0;
        .ickonic-button {
            margin: 0;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        width: auto;
        .ickonic-button {
            width: auto;
            &:first-child {
                margin-left: 1rem;
            }
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        justify-content: flex-end;
    }
`;

function VideoRedirects(props: {
    videoId: number,
}) {
    const controller = new AbortController();
    const [redirects, setRedirects] = useState<IRelatedVideoInfo | null>();
    const authCtx = useContext(UserAuthenticationContext);

    useEffect(() => {
        async function loadData() {
            const result = await GetRelatedVideosInfo(props.videoId, authCtx.userData.CurrentCountryCode, controller);
            //console.log(result);
            setRedirects(result);
        }

        if (props.videoId !== undefined && authCtx.userData) {
            loadData();
        }
    }, [props.videoId]);

    if (redirects === null) {
        return null;
    }

    return (
        <Container>

            {/** Next Episode Button **/}
            { redirects?.NextEpisode !== null && redirects?.NextEpisode !== undefined ? (
                <ButtonPill
                    link={RoutePaths.Watch(redirects?.NextEpisode as number)}
                    className=""
                    icon={IconArrowWithTail()}
                    label="Next Episode"
                />
            ) : null}

            {/** 'Go To Series' Button'' **/}
            { redirects?.Series !== null && redirects?.Series !== undefined ? (
                <ButtonPill
                    link={RoutePaths.SeriesEpisodes(redirects?.Series as number)}
                    className=""
                    icon={IconList()}
                    label="All Episodes"
                />
            ) : null }

            {/*{ redirects?.SurpriseMe !== null ?*/}
            {/*    (<>*/}
            {/*        <NavLinkGreyButton to={RoutePaths.Watch(redirects?.SurpriseMe as number)}>*/}
            {/*            <IconWrapper>*/}
            {/*                <IconSurprise />*/}
            {/*            </IconWrapper>*/}

            {/*            Surprise  Me*/}
            {/*        </NavLinkGreyButton>*/}
            {/*    </>)*/}
            {/*    : null }*/}
        </Container>
    )
}

export default VideoRedirects;
