import React from "react";
import { NavLink } from "react-router-dom";

// Enums
import {ContentType} from "../../Models/Enums/ContentType";

// Models
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";

// Components
import ButtonPill from "../Buttons/ButtonPill";
import ArrowSmall from "../../Assets/SVGs/Icons/Arrows/ArrowSmall";

// Constants

import {GetContentRedirect} from "../../Helpers/Content";

type CarouselCardsWithContentProps = {
    item: ICarouselContentDTO;
    itemWidth: number;
    isLoading: boolean;
};

const CarouselCardsWithContent: React.FC<CarouselCardsWithContentProps> =(
    {
        item,
        itemWidth,
        isLoading
    }
) => {
    const
        earlyAccessAvailable = item.ReleaseDateEarlyAccess !== undefined && new Date(`${item.ReleaseDateEarlyAccess}Z`) < new Date() && item.Text === "Film",
        redirect = item.IsClickeable || earlyAccessAvailable ? GetContentRedirect(item.ContentType, item.Id) : null;

    return (
        <div
            className="carousel-cards-with-content__item u-relative_hidden"
            style={{width: `${itemWidth}px`}}
        >
            {item.IsFreeView &&
                <div className="carousel-cards-with-content__item-free-view free-view-content-item-label"><span>Watch Free</span>
                </div>}

            <NavLink
                to={redirect ? redirect : "#"}
                className="carousel-cards-with-content__item-image is-sixteen-nine-aspect-ratio">
                {isLoading ?
                    (
                        <div className="loader--side-to-side"/>
                    )
                    :
                    (
                        <div
                            className="u-full_cover_absolute"
                            style={{
                                background: `url("${item.Thumbnail ?? ""}") center/cover no-repeat`,
                            }}
                        />
                    )
                }

                <div className="carousel-cards-with-content__item-image-gradient"/>
            </NavLink>

            <div className="carousel-cards-with-content__item-content">
                <h3>
                    {item.Title}
                </h3>

                <p>
                    {ContentType[item.ContentType]}
                </p>

                <ButtonPill
                    label="Watch Now"
                    className=""
                    link={redirect ? redirect : "#"}
                    icon={ArrowSmall({iconFill: 'black'})}
                />
            </div>
        </div>
    );
};

export default CarouselCardsWithContent;
