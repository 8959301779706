import React, {useContext} from "react";

// Components
import ButtonPill from "../Buttons/ButtonPill";

// Context
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";

type NotificationBarProps = {
    isDarkMode: boolean;
};

const NotificationBar: React.FC<NotificationBarProps> = ({ isDarkMode }) => {
    const {notificationBar} = useContext(GlobalInterfaceContext);
    return (
        <div
            className={`global__notification-bar u-relative_hidden ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
        >
            {notificationBar && notificationBar.Id && (
                <div className="notification-bar__content">
                    <p>
                        {notificationBar.Heading}
                    </p>

                    { notificationBar.ButtonLink && notificationBar.ButtonText && (
                        <ButtonPill
                            label={notificationBar.ButtonText}
                            className=""
                            link={notificationBar.ButtonLink}
                        />
                    ) }
                </div>
            )}

            <div className="u-full_cover_absolute" />
        </div>
    );
};

export default NotificationBar;
