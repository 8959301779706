import React, {useContext, useEffect, useRef, useState} from "react";

// Custom Hooks
import useFetchCategoryContent from "../../Hooks/Content/Watch/useFetchCategoryContent";
import useFetchJustReleased from "../../Hooks/Content/Watch/useFetchJustReleased";
import LandscapeVideoItem from "../Content/LandscapeVideoItem";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import {createPlaceholderICarouselContentDTO} from "../../Models/DTOs/ICarouselContentDTO";

interface VideoRelatedVideosProps {
    videoId: number;
    videoCategoryId: number | null | undefined;
    additionalCategories?: number[]; // Made optional to account for undefined state
    parentContentStillLoading: boolean;
}

const VideoRelatedVideos: React.FC<VideoRelatedVideosProps> = ({ videoId, videoCategoryId, additionalCategories = [], parentContentStillLoading }) => {

    // User authentication data
    const
        authCtx = useContext(UserAuthenticationContext),
        userId = authCtx.userData.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6",
        countryCode = authCtx.userData.CountryCode?.trim() || "GB",

        [contentItemWidth, setContentItemWidth] = useState<number>(184),

        containerRef = useRef<HTMLDivElement | null>(null),

        // Determine the effective category ID based on props
        effectiveCategoryId =
            (videoCategoryId !== null && videoCategoryId !== undefined)
                ? videoCategoryId
                : (!parentContentStillLoading && additionalCategories.length > 0
                        ? additionalCategories[0]
                        : null
                ),

        // Determine if we should fetch category content
        shouldFetchCategory = effectiveCategoryId !== null,

        // Fetch category content if applicable
        {
            categoryContent,
            contentLoading: categoryLoading,
            contentError: categoryError
        } = useFetchCategoryContent(
            shouldFetchCategory ? effectiveCategoryId : null, // Pass null to indicate no fetch
            countryCode,
            userId,
            12, // Assuming limit
            0,  // Assuming offset
            false // Assuming some flag, adjust as needed
        ),

        // Determine if we should fetch just released content
        shouldFetchJustReleased =
            !parentContentStillLoading &&
            videoCategoryId == null &&
            additionalCategories.length === 0,

        // Fetch just released content if applicable
        {
            justReleased,
            justReleasedLoading,
            justReleasedError
        } = useFetchJustReleased(
            shouldFetchJustReleased ? userId : null,
            shouldFetchJustReleased ? countryCode : "GB"
        ),

        // Determine loading and error states
        isLoading = categoryLoading || justReleasedLoading || parentContentStillLoading,
        hasError = categoryError || justReleasedError,

        dummyContent = createPlaceholderICarouselContentDTO(6),

        filteredCategoryContent = categoryContent?.filter(video => video.Id !== videoId),
        filteredJustReleasedContent = justReleased?.Content?.filter(video => video.Id !== videoId);


    // Calculate correct video content width based on available real-estate
    useEffect(() => {
        if (!containerRef.current) return;

        const updateWidth = () => {
            const width = containerRef.current?.clientWidth;
            if (width) {
                setContentItemWidth(width);
            }
        };

        // Initial calculation
        updateWidth();

        if (typeof ResizeObserver !== 'undefined') {
            const resizeObserver = new ResizeObserver(() => {
                updateWidth();
            });
            resizeObserver.observe(containerRef.current);

            // Cleanup
            return () => {
                resizeObserver.disconnect();
            };
        } else {
            // Fallback for old browsers: use window resize event
            window.addEventListener('resize', updateWidth);

            // Cleanup
            return () => {
                window.removeEventListener('resize', updateWidth);
            };
        }
    }, []);

    return (
        <div ref={containerRef} className="watch-video__related-videos">
            <div>
                <h2>Discover more</h2>

                {/* Render category-related videos */}
                {!isLoading && shouldFetchCategory && filteredCategoryContent && filteredCategoryContent.length > 0 && filteredCategoryContent.map((video) => (
                    <LandscapeVideoItem
                        key={video.Id} // Ensure each child has a unique key
                        item={video}
                        userData={authCtx.userData}
                        authTokenExists={authCtx.doesAuthTokenExist()}
                        itemWidth={contentItemWidth}
                        isLoading={isLoading}
                    />
                ))}

                {/* Render just released content */}
                {!isLoading && shouldFetchJustReleased && filteredJustReleasedContent && filteredJustReleasedContent.length > 0 && filteredJustReleasedContent.map((video) => (
                    <LandscapeVideoItem
                        key={video.Id} // Ensure each child has a unique key
                        item={video}
                        userData={authCtx.userData}
                        authTokenExists={authCtx.doesAuthTokenExist()}
                        itemWidth={contentItemWidth}
                        isLoading={isLoading}
                    />
                ))}

                {/* Fallback if no content is available */}
                {!isLoading && !shouldFetchCategory && !shouldFetchJustReleased && !isLoading && (
                    <h2>No Related Videos Available</h2>
                )}

                {!isLoading && hasError && (
                    <h2>Error loading related videos. Please try again later.</h2>
                )}

                { isLoading && dummyContent.map((video) => (
                    <LandscapeVideoItem
                        key={video.Id} // Ensure each child has a unique key
                        item={video}
                        userData={authCtx.userData}
                        authTokenExists={authCtx.doesAuthTokenExist()}
                        itemWidth={contentItemWidth}
                        isLoading={isLoading}
                    />
                ))}
            </div>
        </div>
    );
};

export default VideoRelatedVideos;
