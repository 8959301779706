import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import ForgotPasswordForm from "../../../Components/UI/Forms/ForgotPasswordForm";
import Heading from "../../../Components/UI/Text/Heading";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import SuccessText from "../../../Components/UI/Text/SuccessText";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import Card from "../../../Components/UI/Card";
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../Constants/RoutePaths";
import {SetTitle} from "../../../Helpers/PageMetadata";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    background: ${ Colours.SecondaryDarker };

    > div {
        margin: 75px 0 35px 0;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        background: ${ Colours.Secondary };
        padding: 0;
        @media screen and (min-height: calc(1000em/16)) {
            padding: 0 0 5vh 0;
        }
    }
`;

const FormContainer = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    hr {
        border-color: #7d7d7d;
        margin: 0;
    }

    h1 {
        font-size: 2rem;
        margin: 0;
    }

    > p {
        font-size: 11pt;
        margin: 0;
        line-height: 1.5;
    }
`;

function ForgotPasswordScreen() {
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();

    useEffect(() => {
        // If user is authenticated, redirect to a protected route
        if (authCtx.userData && authCtx.userData.AspNetUserId && authCtx.userData.AspNetUserId.length > 0) {
            navigate(RoutePaths.Browse); // Or whatever route is appropriate for authenticated users
        }
    }, [authCtx.userData, navigate]);

    function SetFormSuccess(value: boolean) {
        setFormSuccess(value);
    }

    return (
        <Container>
            <Card>
                <FormContainer>
                    <Heading>Forgot your password?</Heading>
                    <PrimaryText>
                        Enter your email address and we will email password reset instructions.
                    </PrimaryText>
                    <PrimaryText>
                        The email should arrive within 15 minutes.
                    </PrimaryText>
                    <ForgotPasswordForm formSuccess={formSuccess} setFormSuccess={SetFormSuccess} />
                    {formSuccess ? (
                        <SuccessText>
                            Please check your inbox to reset your password. If it doesn't appear check
                            your spam folder.
                        </SuccessText>
                    ) : null}
                </FormContainer>
            </Card>
        </Container>
    );
}

export default ForgotPasswordScreen;

export async function Loader() {
    // Without UserSnapshot, we do minimal work here.
    // Just set the title and return null. Authentication checks are now in the component.
    SetTitle("Forgot Password");
    return null;
}
