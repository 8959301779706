import axios, { AxiosError } from "axios";
import ICommentAndPersonDTO from "../Models/DTOs/ICommentAndPersonDTO";
import IVideoCommentDTO from "../Models/DTOs/IVideoCommentDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.videoComment;

// Updated GetComments Function
export async function GetComments(
    videoId: number,
    abortController: AbortController
): Promise<ICommentAndPersonDTO[]> {
    const fetchUrl = `${BACKEND_URL}${videoId}`
    try {
        const response = await axios.get<ICommentAndPersonDTO[]>(
            fetchUrl,
            { signal: abortController.signal }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("AxiosError fetching comments:", error.message);
            // Optionally, you can inspect error.response for more details
        } else {
            console.error("Unexpected error fetching comments:", error);
        }
        // Return an empty array in case of any error
        return [];
    }
}

export async function AddComment(
    VideoComment: IVideoCommentDTO,
    videoId: number,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "Add/" + videoId, VideoComment, {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as number;
        })
        .catch((error: AxiosError) => {
            console.log("add comment error: " );
            return error;
        });

    return responce;
}

export async function DeleteComment(
    commentId: number,
    abortController: AbortController
) {
    const responce = await axios
        .delete(BACKEND_URL + "Delete/" + commentId, {
            signal: abortController.signal,
        })
        .then((responce) => {
            return responce.data as number;
        })
        .catch((error: AxiosError) => {
            console.log("delete comment error: ");
            return error;
        });

    return responce;
}
