// Article - Header
import { Link } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { BiSolidCommentDetail } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";

import { Colours } from "Constants/Colours";
import { Fonts } from "Constants/Fonts";
import IArticleDTO from "Models/DTOs/IArticleDTO";
import { HeadingType } from "Models/Enums/HeadingType";
import { ContentType } from "Models/Enums/ContentType";

import IconTime from "../../../Assets/SVGs/Icons/TimeAndDate/Time";
import Heading from "Components/UI/Text/Heading";
import ImageLazyLoad from "Components/UI/ImageLazyLoad";
import { CategoryTerm } from "Components/UI/Article/CategoryTerm";
import { SocialsMedia } from "Components/UI/SocialsMedia";

type Props = {
    article: IArticleDTO | undefined;
    ReadTime: number | undefined;
    DateToString: string | undefined;
    commentsRef?: React.RefObject<HTMLDivElement>;
};

const Header = styled.div`
    padding: 60px 20px 40px;

    @media screen and (max-width: 1024px) {
      padding: 50px 20px;
    }

    @media screen and (max-width: 768px) {
      padding: 30px 20px;
    }

    @media screen and (max-width: 576px) {
      padding: 20px;
    }

    .article-header {
      &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-width: 950px;
        width: 100%;

        .article-header__column {
          width: 70%;
          text-align: center;
          margin-bottom: 48px;

          @media screen and (max-width: 768px) {
            margin-bottom: 38px;
            width: 100%;
          }

          @media screen and (max-width: 576px) {
            margin-bottom: 30px;
          }

          .article-header__categories {
            margin-bottom: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
          }

          h1,
          h3 {
            color: ${Colours.TextBlack};
            font-family: ${Fonts.Rubik};
            letter-spacing: 0;
          }

          h1 {
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            margin: 0;
            margin-bottom: 16px;

            @media screen and (max-width: 768px) {
              font-size: 34px;
              line-height: 42px;
              margin-bottom: 12px;
            }

            @media screen and (max-width: 576px) {
              font-size: 28px;
              line-height: 34px;
            }
          }

          h3 {
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            margin: 0;

            @media screen and (max-width: 768px) {
              font-size: 18px;
            }

            @media screen and (max-width: 576px) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }

      &__featured-image {
        width: 100%;
        position: relative;

        .article-header__read-time {
          display: flex;
          align-items: center;
          gap: 8px;
          position: absolute;
          margin: 0;
          top: 10px;
          right: 10px;
          border-radius: 12px;
          font-size: 12px;
          line-height: 18px;
          padding: 8px 16px;
          background: ${Colours.SecondaryDarker};
          color: ${Colours.TextBright};

          svg {
            width: 16px;
            height: 16px;
            * {
              fill: ${Colours.TextBright};
            }
          }
        }
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 24px;
        padding: 0 20px;

        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
        }

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 576px) {
          margin-bottom: 16px;
        }

        &-text {
          font-family: ${Fonts.Rubik};
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: ${Colours.TextBlack};
          margin: 0;
          flex-shrink: 0;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        &-link {
          color: ${Colours.IckonicLighterPink};
          text-decoration: none;
        }

        &-comments {
          display: flex;
          align-items: center;
          gap: 12px;
          color: ${Colours.IckonicLighterPink};
          border: none;
          background: none;
          cursor: pointer;
        }
      }
    }
  `,
    Comment = styled(BiSolidCommentDetail)`
    color: ${Colours.TextBright};
    fill: ${Colours.IckonicLighterPink};
    width: 20px;
    height: 20px;
  `,
    Dot = styled(GoDotFill)`
    fill: ${Colours.LighterGrey};
    width: 10px;
    height: 10px;
    border-radius: 50%;
  `;

const ArticleHeader = ({
                           article,
                           DateToString,
                           ReadTime,
                           commentsRef,
                       }: Props) => {
    if (article === undefined) {
        return null;
    }

    const handle = {
        scrollToComments: () => {
            commentsRef?.current?.scrollIntoView({ behavior: "smooth" });
        },
    };

    return (
        <Header>
            <div className="article-header__inner">
                {/* Article Header Details */}
                <div className="article-header__column">
                    {article?.Categories && article?.Categories?.length > 0 && (
                        <div className="article-header__categories">
                            {article?.Categories?.map((category) => (
                                <CategoryTerm category={category} key={category?.Id} />
                            ))}
                        </div>
                    )}

                    <Heading>{parse(DOMPurify.sanitize(article?.Title))}</Heading>

                    <Heading type={HeadingType.H3}>
                        {parse(DOMPurify.sanitize(article?.SubTitle))}
                    </Heading>
                </div>

                {/* Articile Info */}
                <div className="article-header__info">
                    <p className="article-header__info-text">
                        By: {article?.Author?.Name}
                    </p>

                    <Dot />
                    <p className="article-header__info-text">{DateToString}</p>

                    {/* Static site link to our website */}
                    <Dot />
                    <Link
                        to="https://www.ickonic.com/"
                        className="article-header__info-text article-header__info-link"
                    >
                        ickonic.com
                    </Link>

                    <Dot />
                    <SocialsMedia
                        isShare={false}
                        title={article?.Title}
                        contentType={ContentType.Article}
                        articleUrlTitle={article?.UrlTitle}
                    />

                    {/*<Dot />*/}
                    {/* Static comment count */}
                    {/*<button*/}
                    {/*    className="article-header__info-comments article-header__info-text"*/}
                    {/*    onClick={handle.scrollToComments}*/}
                    {/*>*/}
                    {/*    <Comment /> 400 Comments*/}
                    {/*</button>*/}
                </div>

                {/* Article Featured Image */}
                <div className="article-header__featured-image">
                    <ImageLazyLoad source={article?.Header}>
                        <p className="article-header__read-time">
                            {IconTime()}
                            {ReadTime} minute read
                        </p>
                    </ImageLazyLoad>
                </div>
            </div>
        </Header>
    );
};

export default ArticleHeader;
