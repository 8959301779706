import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import lscache from "lscache";

// Contexts
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { VideoPlayerSettingsContext } from "../../../Context/VideoPlayerSettingsContext";

// Models
import ISeriesDTO from "../../../Models/DTOs/ISeriesDTO";

// Hooks
import useFetchSeriesDetails from "../../../Hooks/Content/Watch/useFetchSeriesDetails";

// Components
import VideoPlayer from "../../../Components/VideoPlayer/VideoPlayer";
import ButtonPill from "../../../Components/Buttons/ButtonPill";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";
import VideoLikeDislike from "../../../Components/VideoInfo/VideoLikeDislike";
import ShareButtons from "../../../Components/Menus/ShareButtons";
import {ContentType} from "../../../Models/Enums/ContentType";
import VideoRedirects from "../../../Components/UI/Navigation/VideoRedirects";

const TrailerSeries = () => {
    // Parse the URL param into a number.
    const
        { id } = useParams<string>(),
        seriesId = id ? parseInt(id) : undefined,

        // Context
        { isTheatreMode, setIsTheatreMode } = useContext(VideoPlayerSettingsContext),

        // Series object state
        [seriesData, setSeriesData] = useState<ISeriesDTO>({} as ISeriesDTO),

        // Fetch series details (using refresh value 0 for now)
        { series, loading, error } = useFetchSeriesDetails(seriesId, 0);

    useEffect(() => {
        if (series) {
            // Update the series data if it's new
            if (seriesData.Id === undefined || series.Id !== seriesData.Id) {
                setSeriesData(series);
            }
        }
    }, [series]);

    return (
        <div className="page--watch-video page--watch-trailer">
            <div className={`page__wrapper ${isTheatreMode ? 'is-theatre-mode' : 'is-not-theatre-mode'}`}>
                <VideoPlayer
                    key={seriesData.Id}
                    src={seriesData.TrailerLink}
                    autoPlay={true}
                    playbackAllowed={true}
                    isTheatreMode={isTheatreMode}
                    setIsTheatreMode={setIsTheatreMode}
                    isCommentInputFocused={false}
                />

                <div className="watch-video__primary-info watch-trailer__primary-info">
                    <div className="primary-info__top-row">
                        <div className="primary-info__title-and-date">
                            <h1 className={loading ? "loader--side-to-side" : "is-loaded"}>
                                {!loading && series.Title} Trailer
                            </h1>
                        </div>

                    </div>
                </div>


                {seriesId && (
                    <div className="watch__button">
                        <ButtonPill
                            label="View Series"
                            className=""
                            link={RoutePaths.SeriesEpisodes(seriesId)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
        ;
};

export default TrailerSeries;
