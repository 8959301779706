import { AxiosError } from "axios";
import { useContext, useEffect, useRef } from "react";
import { Params, useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetAffiliate } from "../Api/Affiliate";
import { LocalStorageKeys } from "../Constants/LocalStorageKeys";
import { RoutePaths } from "../Constants/RoutePaths";
import IAffiliateDTO from "../Models/DTOs/IAffiliateDTO";
import { SubscriptionStatus } from "../Models/Enums/SubscriptionStatus";

// New Auth Context
import { UserAuthenticationContext } from "../Context/UserAuthenticationContext";

// LocalContext unchanged if still needed
import LocalContext from "../Store/local-context";
import {AddToPersistentStorage} from "../Helpers/LocalStorage";
import {HoursToMinutes} from "../Helpers/DateAndTime";

function AffiliateScreen() {
    const localCtx = useContext(LocalContext);
    const authCtx = useContext(UserAuthenticationContext);
    const { affiliate } = useLoaderData() as { affiliate: IAffiliateDTO };
    const navigate = useNavigate();
    const isLoaded = useRef<boolean>(false);

    useEffect(() => {
        // If authCtx is not yet loaded or no user data, wait
        if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
            return;
        }

        // Avoid running this effect multiple times
        if (isLoaded.current) {
            return;
        }

        // Perform eligibility checks now that we have authCtx.userData
        const userDetails = authCtx.userData;

        const hasSub =
            userDetails.SubscriptionStatus === SubscriptionStatus.Active ||
            userDetails.SubscriptionStatus === SubscriptionStatus.Cancelled ||
            userDetails.SubscriptionStatus === SubscriptionStatus.Trialing;

        const isLoggedInAndNotEligible =
            userDetails.AspNetUserId &&
            userDetails.AspNetUserId.length > 0 &&
            !userDetails.FreeTrialAvailable;

        // If affiliate is not active
        if (!affiliate.IsActive) {
            toast.error("Affiliate does not exist.");
            navigate(RoutePaths.Root);
            isLoaded.current = true;
            return;
        }

        // If already logged in and not eligible or has a subscription, can't redeem
        if (isLoggedInAndNotEligible || hasSub) {
            toast.error("You are not eligible to redeem this affiliate.");
            navigate(RoutePaths.Root);
            isLoaded.current = true;
            return;
        }

        // Otherwise, redeem affiliate
        toast.success("Affiliate successfully redeemed.");
        localCtx.SetAffiliate(affiliate);
        AddToPersistentStorage(LocalStorageKeys.Affiliate, affiliate, HoursToMinutes(1));
        navigate(RoutePaths.Root);

        isLoaded.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.userData]);

    // Does not need a style. Feedback is provided via toasts
    return <></>;
}

export default AffiliateScreen;

export async function Loader({ params }: { params: Readonly<Params<string>> }) {
    const controller = new AbortController();
    const link = params.link;

    if (!link || link.length <= 0) {
        toast.error("Affiliate does not exist.");
        return { affiliate: { IsActive: false } as IAffiliateDTO };
    }

    const affiliate = await GetAffiliate(link, controller);

    if (affiliate instanceof AxiosError) {
        // Throwing will likely show a default error page or can be handled by a catch boundary
        throw affiliate.response;
    }

    if (!affiliate || affiliate.Id <= 0) {
        toast.error("Affiliate does not exist.");
        return { affiliate: { IsActive: false } as IAffiliateDTO };
    }

    // Just return the affiliate, let the component handle user checks now
    return { affiliate };
}
