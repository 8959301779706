import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import { fetchLifetimeMembers } from "../../Api/VideoMetrics";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface LifetimeMember {
  SubscriptionStartDate: string;
  ID: number;
  AspNetUserID: string;
  BillingAmount: number;
  StripeCustomerID: string;
  StripePaymentID: string;
  HasStripeSubscriptionRecord: boolean;
  HasAspNetUsersRecord: boolean;
  HasAspNetUsersEmail: string;
}

const LifetimeMembers: React.FC = () => {
  const [data, setData] = useState<LifetimeMember[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const authCtx = useContext(UserAuthenticationContext);
  const access_token = authCtx.userData.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadLifetimeMembers = async () => {
      try {
        const result = await fetchLifetimeMembers(access_token, abortController);
        // Process the email field for display purposes.
        const processedData = result.map((member) => ({
          ...member,
          ShortEmail:
            member.HasAspNetUsersEmail.length > 20
              ? `${member.HasAspNetUsersEmail.substring(0, 20)}...`
              : member.HasAspNetUsersEmail,
        }));

        setData(processedData);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load data", err);
          setError("Failed to load data");
        }
      } finally {
        setLoading(false);
      }
    };

    loadLifetimeMembers();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    // Convert the full data array to a worksheet.
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet.
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LifetimeMembers");
    // Write the workbook to an array buffer.
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Create a Blob from the buffer and trigger a download.
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "LifetimeMembers.xlsx");
  };

  const columns = [
    {
      title: "Start Date",
      dataIndex: "SubscriptionStartDate",
      key: "SubscriptionStartDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "User ID", dataIndex: "AspNetUserID", key: "AspNetUserID" },
    {
      title: "Amount",
      dataIndex: "BillingAmount",
      key: "BillingAmount",
      render: (text: number) => `£${text.toFixed(2)}`,
    },
    { title: "Cust. ID", dataIndex: "StripeCustomerID", key: "StripeCustomerID" },
    { title: "Pay. ID", dataIndex: "StripePaymentID", key: "StripePaymentID" },
    { title: "Email", dataIndex: "ShortEmail", key: "Email" },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#FFD700", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              color: "#DAA520", // Different gold color for header text
              display: "inline-block",
              marginRight: "10px",
            }}
          >
            Lifetime Members
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>
            ({data.length} Current Members)
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="ID"
        rowClassName={(_, index) =>
          index % 2 === 0 ? "gold-row" : "grey-gold-row"
        }
        style={{
          backgroundColor: "#FFD700",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default LifetimeMembers;
