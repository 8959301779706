import moment from "moment";

export function DateAddDays(days: number): Date {
    let date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function DifferenceBetweenDates(high: Date, low: Date) {
    let timeDiff = Math.abs(high.getTime() - low.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return diffDays;
}

export function addDays(date: Date, days: number) {
    let newData = new Date();

    newData.setDate(date.getDate() + days);
    return newData;
}

export function IsEligibleForSevenExtraDays(start: Date, startEndDifference: number) {
    let currentDate = new Date();
    let nextSevenDays = addDays(start, 7);
    let nextFourDays = addDays(start, 4);

    if (currentDate < nextSevenDays && currentDate >= nextFourDays && startEndDifference <= 7) {
        return true;
    }

    return false;
}

export function HoursToMinutes(hours: number): number {
    return hours * 60;
}

export function convertUTCDateToLocalDate(date: Date): string {
    let stillUtc = moment.utc(date).toDate();
    let local = moment(stillUtc).local().format("hh:mm A DD/MM/yyyy");
    return local;
}
