export function IsDev() {
    if (!process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "development") {

        // development build code
        return true;

    } else {

        // production build code
        return false;
    }
}

