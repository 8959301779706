import { ReactComponent as Minds } from '../../Assets/Images/Logos/logo-minds.svg';
import { title } from "process";

// Models
import { ContentType } from "../../Models/Enums/ContentType";

// Helpers

// Assets
import IconCopyLink from "../../Assets/SVGs/Icons/UI/CopyLink";
import IconEmailSharpCorners from "../../Assets/SVGs/Icons/SocialAndShare/EmailSharpCorners";
import IconShare from "../../Assets/SVGs/Icons/SocialAndShare/Share";
import ButtonPill from "../Buttons/ButtonPill";
import {useEffect, useRef, useState} from "react";
import IconClose from "../../Assets/SVGs/Icons/UI/Close";
import IconX from "../../Assets/SVGs/Icons/SocialAndShare/X-twitter";
import IconFacebook from "../../Assets/SVGs/Icons/SocialAndShare/Facebook";
import IconReddit from "../../Assets/SVGs/Icons/SocialAndShare/Reddit";
import IconLinkedin from "../../Assets/SVGs/Icons/SocialAndShare/Linkedin";
import {toast} from "react-toastify";
import {GetArticleShareUrl} from "../../Helpers/Articles";
import {ContentTypeToString} from "../../Helpers/Content";

function ShareButtons(props: { displayText: boolean; title: string, contentType: ContentType | string, articleUrlTitle?: string}) {

    // let contentType : string;
    const
        contentType = typeof props.contentType === "string" ? props.contentType : ContentTypeToString(props.contentType),
        popupRef = useRef<HTMLDivElement>(null),
        [isPopUpActive, setIsPopUpActive] = useState(false),
        shareUrl =
            (props.contentType === ContentType.Article && props.articleUrlTitle !== null) && props.articleUrlTitle !== undefined ?
                GetArticleShareUrl(props.articleUrlTitle)
                :
                window.location.href,

        handleClose = () => {
            setIsPopUpActive(false);
        };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        if (isPopUpActive) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isPopUpActive]);

    return (
        <div className="content-share-buttons">
            <ButtonPill
                label="Share"
                className=""
                link=""
                icon={IconShare()}
                onClick={() => setIsPopUpActive(true)}
            />

            <div className={`share-buttons__popup ${isPopUpActive ? "is-active" : "is-not-active"}`}>
                <div className="share-buttons__popup-close" onClick={() => handleClose()}>
                    {IconClose()}
                </div>

                <div className="share-buttons__popup-inner" ref={popupRef}>
                    <div className="share-buttons__share-options">

                        {/** Facebook **/}
                        <div id="fb-share" className="share-buttons__button">
                            <a
                                href={"https://www.facebook.com/sharer/sharer.php?u=" + shareUrl + "&t=" + title}
                                title="Share on Facebook"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="u-full_cover_absolute"
                            >
                                {IconFacebook()}
                            </a>

                            {/*<label>Facebook</label>*/}
                        </div>

                        {/** X **/}
                        <div id="x-share" className="share-buttons__button">
                            <a
                                href={
                                    "https://twitter.com/intent/tweet?u=" +
                                    shareUrl +
                                    "&text=check out this " + contentType + " by @ickonic on " +
                                    shareUrl
                                }
                                title="Share on Twitter"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="u-full_cover_absolute"
                            >
                                {IconX()}
                            </a>

                            {/*<label>X</label>*/}
                        </div>

                        {/** Reddit **/}
                        <div id="reddit-share" className="share-buttons__button">
                            <a
                                href={"https://reddit.com/submit?url=" + shareUrl + "&title=" + props.title}
                                title="Share on reddit"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="u-full_cover_absolute"
                            >
                                {IconReddit()}
                            </a>

                            {/*<label>Reddit</label>*/}
                        </div>

                        {/** Minds **/}
                        <div id="minds-share" className="share-buttons__button">
                            <a
                                href={
                                    "https://www.minds.com/newsfeed/subscriptions/latest?intentUrl=Check out this content by @ickonicmediagroup at" +
                                    shareUrl
                                }
                                title="Share on Minds"
                                target="_blank"
                                rel="noreferrer nofollow"
                                style={{padding: '4px'}}
                                className="u-full_cover_absolute"
                            >
                                <Minds/>
                            </a>

                            {/*<label>Minds</label>*/}
                        </div>

                        {/** LinkedIn **/}
                        <div id="in-share" className="share-buttons__button">
                            <a
                                href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl}
                                title="Share on LinkedIn"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="u-full_cover_absolute"
                            >
                                {IconLinkedin()}
                            </a>

                            {/*<label>Linked In</label>*/}
                        </div>

                        {/** Email **/}
                        <div id="email-share" className="share-buttons__button">
                            <a
                                href={
                                    "mailto:?subject=" +
                                    props.title +
                                    "&body=Check out " +
                                    props.title +
                                    " from Ickonic at " +
                                    shareUrl
                                }
                                title="Share in an E-mail"
                                target="_blank"
                                rel="noreferrer nofollow"
                                className="u-full_cover_absolute"
                            >
                                {IconEmailSharpCorners()}
                            </a>

                            {/*<label>Email</label>*/}
                        </div>

                        {/** Copy Link **/}
                        <div className="share-buttons__copy-link">
                            <p className="u-relative_hidden">{shareUrl}</p>

                            <ButtonPill
                                label="Copy"
                                className=""
                                link=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    toast("The URL has been copied in your clipboard.");
                                    navigator.clipboard.writeText(shareUrl);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareButtons;
