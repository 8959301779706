import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

// Constants
import { RoutePaths } from "../../../Constants/RoutePaths";

// Components
import CarouselLandscape from "../../Carousels/CarouselLandscape";

// Hooks
import useFetchLatestArticles from "../../../Hooks/Content/Read/useFetchLatestArticles";

// Assets
import IconArrowDown from "../../../Assets/SVGs/Icons/Arrows/ArrowDown";
import IconLoaderCircle from "../../../Assets/SVGs/Icons/Loading/LoaderCircle";

// Models
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import IArticleCategoryListDTO from "../../../Models/DTOs/IArticleCategoryListDTO";
import ButtonPill from "../../Buttons/ButtonPill";

interface CarouselData {
    heading: string;
    carouselItems: ICarouselContentDTO[];
}

interface ArticlesGridProps {
    heading?: string;
    carousels?: CarouselData[];
    articleGroups?: IArticleCategoryListDTO[];
    disableLoadMore: boolean;
    loadMoreUpdate?: () => void;
    isLoading: boolean;
}

const ArticlesGrid: React.FC<ArticlesGridProps> = (
    {
        heading,
        carousels,
        articleGroups,
        disableLoadMore,
        loadMoreUpdate,
        isLoading
    }
) => {
    return (
        <div className="layout--articles-grid">
            {heading && <h2>{heading}</h2>}

            {articleGroups && articleGroups.length > 0 ?
                articleGroups.map((group, groupIndex) => (
                    <React.Fragment key={`group-${groupIndex}`}>
                        {group.Title && group.Title !== "" && (
                            <div className="articles-grid__cat-title-wrapper">
                                <h3 className="articles-grid__category-title">{group.Title}</h3>
                                <ButtonPill label="Read More" className="" link={RoutePaths.ArticleCategory(group.CategoryId)} />
                            </div>
                        )}

                        <div className={`articles-grid__group ${carousels && carousels.length > 0 ? 'has-video-carousels' : 'has-no-video-carousels'}`}>
                            {group.Articles.map((article: ICarouselContentDTO) => (
                                <div key={article.Id} className="articles-grid__article">
                                    <div className="articles-grid__thumbnail">
                                        <Link
                                            to={RoutePaths.ReadArticle(article.Id)}
                                            style={{
                                                background: `url("${article.Thumbnail}") center/cover no-repeat`,
                                            }}
                                            className="u-full_cover_absolute"
                                        />
                                    </div>
                                    <div className="articles-grid__content">
                                        <Link to={RoutePaths.ReadArticle(article.Id)}>
                                            {article.Text}
                                        </Link>
                                        <p>
                                            <Link to={RoutePaths.ReadArticle(article.Id)}>
                                                {article.Authors?.[0] ? `By ${article.Authors[0].Name}` : ""}{" "}
                                                | {format(new Date(`${article.ReleaseDate}`), "MMM d")}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {carousels &&
                            groupIndex !== articleGroups.length - 1 &&
                            groupIndex < carousels.length && (
                                <div className="articles__video-carousel">
                                    <CarouselLandscape
                                        isDarkMode={false}
                                        heading={carousels[groupIndex].heading}
                                        carouselItems={carousels[groupIndex].carouselItems}
                                    />
                                </div>
                            )}
                    </React.Fragment>
                ))
                :
                (
                    <>
                        {Array.from({ length: 3 }, (_, index: number) => (
                            <div className="articles-grid__group" key={index}>
                                {Array.from({ length: 10 }, (_, i: number) => (
                                    <div key={i} className="articles-grid__article loader--side-to-side" />
                                ))}
                            </div>
                        ))}
                    </>
                )}

            {!disableLoadMore && (
                <div className="articles-grid__load-more">
                    <button onClick={loadMoreUpdate} disabled={isLoading}>
                        {isLoading ? "Loading..." : "Load More"}{" "}
                        {isLoading
                            ? IconLoaderCircle({className: "is-loading"})
                            : IconArrowDown()}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ArticlesGrid;
