import axios, { AxiosError } from "axios";
import config from "../Constants/Config";
import { ICancelSubscriptionDTO } from "../Models/DTOs/ICancelSubscriptionDTO";
import { ICancelPauseSubscriptionDTO } from "../Models/DTOs/ICancelPauseSubscriptionDTO";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.stripe;

export async function NewCancelSubscription(
    data: ICancelSubscriptionDTO,
    access_token: string,
    abortController: AbortController
): Promise<string> {
    try {
        const response = await axios.post(BACKEND_URL + "NewCancelSubscription", data, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token
            }
        });

        return response.data as string;
    } catch (error: AxiosError | any) {
        console.log("NewCancelSubscription Error: ", error);
        throw error;
    }
}

export async function NewPauseSubscription(data: ICancelPauseSubscriptionDTO, access_token: string, abortController: AbortController) {

    const response = await axios.post(BACKEND_URL + "PauseSubscription", data, {
        signal: abortController.signal,
        headers: {
            Authorization: "Bearer " + access_token
        }
    }).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("NewPauseSubscription ");
        return error;
    });

    return response;
}

// Define the correct return type as a Promise of an array
export async function GetCancellationReasons(): Promise<{ plKey: string; plTitle: string }[]> {
    try {
        const fullUrl = `${BACKEND_URL}/GetCancellationReasons`;
        const response = await axios.get<{ plKey: string; plTitle: string }[]>(fullUrl);
        return response.data; // Return the array of cancellation reasons
    } catch (error: AxiosError | any) {
        console.error("Error fetching cancellation reasons:", error.response?.data || error.message);
        return []; // Return an empty array on error
    }
}

export async function GetDevices(): Promise<{ plKey: string; plTitle: string }[]> {
    try {
        const fullUrl = `${BACKEND_URL}/GetDevices`;
        const response = await axios.get<{ plKey: string; plTitle: string }[]>(fullUrl);
        return response.data;
    } catch (error: AxiosError | any) {
        console.error("Error fetching devices:", error.response?.data || error.message);
        return [];
    }
}

export async function GetBrowsers(): Promise<{ plKey: string; plTitle: string }[]> {
    try {
        const fullUrl = `${BACKEND_URL}/GetBrowsers`;
        const response = await axios.get<{ plKey: string; plTitle: string }[]>(fullUrl);
        return response.data;
    } catch (error: AxiosError | any) {
        console.error("Error fetching browsers:", error.response?.data || error.message);
        return [];
    }
}

export async function GetTechnicalIssues(): Promise<{ plKey: string; plTitle: string }[]> {
    try {
        const fullUrl = `${BACKEND_URL}/GetTechnicalIssues`;
        const response = await axios.get<{ plKey: string; plTitle: string }[]>(fullUrl);
        return response.data;
    } catch (error: AxiosError | any) {
        console.error("Error fetching browsers:", error.response?.data || error.message);
        return [];
    }
}
