export const IconEye = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                style={{fill: props?.iconFill}}
                fillRule="nonzero"
                d="M11.998 5C7.92 5 4.256 8.093 2.145 11.483a1 1 0 00-.001 1.034C4.256 15.907 7.92 19 11.998 19c4.143 0 7.796-3.09 9.864-6.493a1 1 0 000-1.014C19.794 8.09 16.141 5 11.998 5zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zM12 8c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"
            ></path>
        </svg>
    )
};

export default IconEye;
