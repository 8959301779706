import {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import lscache from "lscache";

// Global state
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Assets
import {IckonicLogo} from "../../Assets/Images/Logos/Logos";
import PlayButtonFractalLines from "../../Assets/SVGs/PlayButtonFractalLines";
import IconQuestionFilled from "../../Assets/SVGs/Icons/AlertAndInfo/QuestionFilled";

// Components
import SinglePlanInfo from "../../Components/Layouts/SinglePlanInfo";
import ButtonPill from "../../Components/Buttons/ButtonPill";

// Constants
import {RoutePaths} from "../../Constants/RoutePaths";
import {LocalStorageKeys} from "../../Constants/LocalStorageKeys";
import {Colours} from "../../Constants/Colours";
import {Ease} from "../../Constants/EasingCurves";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";

const PageSignUpSuccess = styled.div`
    min-height: 100vh;
    background: #0e0e0e;
    .sign-up-success__loader {
        display: inline-block;
        width: 100%;
        transform: translateY(calc(5rem/16));
    }

    .page__header {
        height: calc(55rem / 16);
        width: 100%;
        box-shadow: 0 0 calc(10rem/16) 0 rgba(0,0,0,0.7);
        position: relative;
        z-index: 1;
        .ickonic-logo--primary {
            margin: 0 auto;
            display: block;
            width: 100%;
            height: 100%;
            padding: calc(12rem / 16);

            * {
                fill: white;
            }
        }
    }

    .page__heading {
        padding: 2rem 1rem;
        background: #0E0E0EFF;
        &:after {
            content: '';
            position: absolute;
            height: calc(5rem/16);
            width: 100%;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
        }

        svg {
            position: absolute;
            width: 200%;
            height: auto;
            top: -50vw;
            left: -50%;
            path {
                stroke: rgba(120,120,120,0.05);
            }
        }

        h1 {
            font-weight: bold;
            max-width: calc(650rem/16);
            margin: 0 auto;
            font-family: "Outfit", Helvetica sans-serif;
            text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) #000;
            color: white;

            // Loader
            .u-relative_hidden {
                max-width: calc(90rem/16);
                height: calc(38rem/16);
            }
        }
    }

    .page__inner {
        padding: 1rem;
        margin: 0 auto;
        max-width: calc(700rem/16);
        & > p {
            font-family: "WorkSans", Helvetica sans-serif;
            & > span {
                margin: 0 0 0 calc(7rem/16);
            }
        }

        p {
            color: #E6E6E6FF;
            font-size: calc(16rem/16);

            // Loader
            .u-relative_hidden {
                height: calc(25rem/16);
                max-width: calc(200rem/16);
            }

            .page__text-highlight {
                padding: calc(2rem/16) calc(8rem/16) calc(5rem/16) calc(8rem/16);
                background: #0E0E0EFF;
                box-shadow: inset 0 0 0 calc(2rem/16) rgba(183,96,180,0.5);
                border-radius: calc(6rem/16);
            }
        }

        > h1, > h2, > p {
            margin: 0 0 2rem 0;
        }

        .page__help-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
                height: calc(30rem/16);
                width: calc(30rem/16);
                * {
                    fill: #303030FF;
                }
            }

            span {
                margin: 0;
                width: calc(100% - (46rem/16));
                a {
                    color: #e0e0e0;
                    text-decoration: underline;
                    &:hover,
                    &:focus,
                    &:active {
                        color: ${Colours.IckonicPinkHighlight};
                    }
                }
            }
        }

        .page__button-wrapper {
            position: relative;
        }

        .ickonic-button {
            text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.3);
            color: white;
            margin: 0;
            width: 100%;
            padding: calc(5rem/16) calc(25rem/16);
            &:first-of-type {
                animation: boxShadowGlowPulsePink 2s ${Ease.Smooth} infinite;
                background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
                &:hover,
                &:focus,
                &:active {
                    background: linear-gradient(90deg, #b760b4 0%, rgb(232,187,39) 100%);
                }
            }

            &:last-of-type {
                position: absolute;
                background: #B760B4FF;
                opacity: 0;
                transition: opacity .25s ${Ease.Smooth};
                &:hover,
                &:focus,
                &:active {
                    background: ${Colours.IckonicPinkHighlight};
                }
            }

            &:hover,
            &:focus,
            &:active {
                &:last-of-type {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        .page__heading {
            padding: 4rem 2rem 2rem 2rem;
        }

        .page__inner {
            padding: 2rem;
        }
    }
`;

const SignUpSuccess = () => {
    const
        userAuthenticationContext = useContext(UserAuthenticationContext),
        [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (userAuthenticationContext.userData.Email) {
            setIsLoading(false);
        }
    }, [userAuthenticationContext.userData]);

    return (
        <PageSignUpSuccess className={`page page--sign-up-success page--no-nav ${isLoading ? 'is-loading' : 'is-not-loading'}`}>
            <div className="page__header">
                <NavLink to='/'>
                    {IckonicLogo()}
                </NavLink>
            </div>

            <div className="page__heading u-relative_hidden has-light-mode">
                <h1>
                    Welcome to the Ickonic
                    Family, {isLoading ?
                    <span className="u-relative_hidden sign-up-success__loader">
                        <span className="u-full_cover_absolute loader--side-to-side"/>
                    </span>
                    :
                    `${(userAuthenticationContext.userData?.Person?.FirstName || "First Name")}!`}
                </h1>

                {PlayButtonFractalLines()}
            </div>

            <div className="page__inner">
                <p>
                    A confirmation email has been sent to:
                    {
                        isLoading ?
                            <span className="u-relative_hidden sign-up-success__loader">
                            <span className="u-full_cover_absolute loader--side-to-side"/>
                        </span>
                            :
                            <span className="page__text-highlight">{(userAuthenticationContext.userData.Email || "you@example.com")}</span>
                    }
                </p>

                <SinglePlanInfo
                    userData={userAuthenticationContext.userData}
                />

                <p className="page__help-text">
                    {IconQuestionFilled()}
                    <span>If you have any questions, visit our <NavLink to={RoutePaths.HelpCentre}>Help Centre</NavLink> to contact customer support or check out our FAQs</span>
                </p>

                <div className="page__button-wrapper">
                    <ButtonPill label="Start Watching" className="" link={RoutePaths.Browse}/>
                    <ButtonPill label="Start Watching" className="u-full_cover_absolute" link={RoutePaths.Browse}/>
                </div>
            </div>
        </PageSignUpSuccess>
    );
};

export default SignUpSuccess;

export async function Loader() {
    lscache.flushExpired();
    let tokenJson = lscache.get(LocalStorageKeys.AccessToken);

    if (tokenJson === null) {
        //const fullUrl = `${window.location.origin}${RoutePaths.Browse}`;
        //window.location.href = fullUrl;
    }

    return null;
};
