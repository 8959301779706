import React from "react";

const IconArrowCarrot = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                style={{fill: props?.iconFill}}
                d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
            > </path>
        </svg>
    )
};

export default IconArrowCarrot;
