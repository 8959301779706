import React, { useEffect, useState, useCallback, useContext } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    LabelList,
} from "recharts";
import { TransformedReportData } from "./ReportingMenu";
import { fetchDailyVideoWatchDetail } from "../../Api/VideoMetrics"; // ✅ Import API function
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { format, parseISO } from "date-fns";

const TOP_COUNTRY_COLORS: string[] = [
    "#8884d8",
    "#ff7300",
    "#00c49f",
    "#ffbb28",
    "#ff8042",
    "#6a0dad",
    "#20c997",
    "#ff1493",
    "#007bff",
    "#e83e8c",
];

const COUNTRY_NAMES: { [key: string]: string } = {
    AU: "Australia",
    CA: "Canada",
    CH: "Switzerland",
    DE: "Germany",
    GB: "United Kingdom",
    NL: "Netherlands",
    SE: "Sweden",
    US: "United States",
};

interface TotalViewsByDateRangeProps {
    reportData: TransformedReportData[];
}

const TotalViewsByDateRange: React.FC<TotalViewsByDateRangeProps> = ({
    reportData,
}) => {
    const [formattedData, setFormattedData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [countryKeys, setCountryKeys] = useState<string[]>([]);
    const [countryColors, setCountryColors] = useState<{
        [key: string]: string;
    }>({});
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [videoDetails, setVideoDetails] = useState<any[]>([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const authCtx = useContext(UserAuthenticationContext);
    const access_token = authCtx.userData.Access_Token || "";

    useEffect(() => {
        console.log("📊 Received reportData:", reportData);

        if (!reportData || reportData.length === 0) {
            setError("No data available for the selected date range.");
            setLoading(false);
            return;
        }

        const countryTotals: { [key: string]: number } = {};
        const processedData: any[] = [];

        reportData.forEach(({ Date: dateString, ...countryEntries }) => {
            const parsedDate = new Date(dateString);
            if (isNaN(parsedDate.getTime())) {
                console.error("❌ Invalid Date Format:", dateString);
                return;
            }

            const formattedDate = format(parsedDate, "yyyy-MM-dd");

            // ✅ Sum all country views to calculate TotalViews
            const totalViews = Object.values(countryEntries)
                .filter((value): value is number => typeof value === "number") // ✅ Ensure only numbers
                .reduce((sum, value) => sum + (value as number), 0); // ✅ Explicit type assertion

            const formattedEntry: any = {
                Date: formattedDate,
                TotalViews: totalViews,
            };

            Object.entries(countryEntries).forEach(([countryCode, count]) => {
                if (typeof count !== "number") return;

                const countryName = COUNTRY_NAMES[countryCode] || countryCode;
                formattedEntry[countryName] = count;

                countryTotals[countryName] =
                    (countryTotals[countryName] || 0) + count;
            });

            processedData.push(formattedEntry);
        });

        console.log(
            "✅ Final Processed Data with Correct TotalViews:",
            processedData
        );

        const sortedCountries = Object.entries(countryTotals)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 8)
            .map(([country]) => country);

        console.log("✅ Sorted Top 8 Countries:", sortedCountries);

        const dynamicCountryColors: { [key: string]: string } = {};
        sortedCountries.forEach((country, index) => {
            dynamicCountryColors[country] =
                index < TOP_COUNTRY_COLORS.length
                    ? TOP_COUNTRY_COLORS[index]
                    : "#999";
        });

        setCountryKeys(sortedCountries);
        setCountryColors(dynamicCountryColors);
        setFormattedData(processedData);
        setLoading(false);
    }, [reportData]);

    /** ✅ Click handler for fetching video details */
    const handleBarClick = useCallback(
        async (barData: any) => {
            if (!barData || !barData.Date) return;

            console.log("📊 Clicked Date:", barData.Date);
            setSelectedDate(barData.Date);
            setLoadingDetails(true);

            const abortController = new AbortController();

            try {
                // ✅ Ensure correct function call with required parameters
                const details = await fetchDailyVideoWatchDetail(
                    barData.Date,
                    access_token, // ✅ Ensure accessToken is available
                    abortController
                );

                setVideoDetails(details);
            } catch (error) {
                console.error("❌ Failed to fetch video details:", error);
            } finally {
                setLoadingDetails(false);
            }
        },
        [access_token]
    ); // ✅ Ensure dependencies are correctly included

    if (loading) return <p>Loading report...</p>;
    if (error)
        return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;

    return (
        <div
            style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
            }}
        >
            <h2 style={{ textAlign: "center", color: "#333" }}>
                Total Views by Date Range
            </h2>

            <ResponsiveContainer width="100%" height={450}>
                <BarChart
                    data={formattedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis
                        dataKey="Date"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        tickFormatter={(dateString) => {
                            try {
                                return format(
                                    parseISO(dateString),
                                    "dd-MM-yyyy"
                                );
                            } catch (error) {
                                return dateString; // Fallback
                            }
                        }}
                    />

                    <YAxis
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        domain={[0, "dataMax + 100"]}
                        allowDecimals={false}
                    />
                    <Tooltip
                        formatter={(value, name) => [`${value} Views`, name]}
                    />
                    <Legend
                        verticalAlign="top"
                        wrapperStyle={{ fontSize: "14px" }}
                    />

                    {/* ✅ Show Total Views Above Each Bar */}
                    <Bar
                        dataKey="TotalViews"
                        fill="transparent"
                        stackId="total"
                    >
                        <LabelList
                            dataKey="TotalViews"
                            position="top"
                            fill="#333"
                            fontSize={14}
                            fontWeight="bold"
                            formatter={(value: number) =>
                                `${value.toLocaleString()} Views`
                            }
                        />
                    </Bar>

                    {/* ✅ Render stacked bars per country */}
                    {countryKeys.map((country) => (
                        <Bar
                            key={country}
                            dataKey={country}
                            stackId="a"
                            fill={countryColors[country]}
                            name={country}
                            cursor="pointer"
                            onClick={(event) => handleBarClick(event)}
                        >
                            <LabelList
                                dataKey={country}
                                position="inside"
                                fill="#fff"
                                fontSize={12}
                                fontWeight="bold"
                            />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>

            {/* ✅ Detailed Table Section */}
            {selectedDate && (
                <div
                    style={{
                        marginTop: "20px",
                        padding: "20px",
                        backgroundColor: "#fff", // solid background to cover lower elements
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        overflowX: "auto", // allow horizontal scrolling if needed
                        position: "relative", // create a new stacking context
                        zIndex: 1000, // high enough to appear above footer icons
                    }}
                >
                    <h3 style={{ textAlign: "center", color: "#333" }}>
                        Detailed View for{" "}
                        {new Date(selectedDate).toLocaleDateString("en-GB")}
                    </h3>

                    {loadingDetails ? (
                        <p style={{ textAlign: "center", fontStyle: "italic" }}>
                            Loading details...
                        </p>
                    ) : (
                        <table
                            style={{
                                width: "100%",
                                marginTop: "10px",
                                borderCollapse: "collapse",
                                border: "1px solid #ddd",
                                backgroundColor: "#fff",
                                minWidth: "600px",
                            }}
                        >
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "#8884d8",
                                        color: "#fff",
                                        textAlign: "left",
                                    }}
                                >
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Video Title
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Views
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoDetails.map((detail, index) => (
                                    <tr
                                        key={index}
                                        style={{
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? "#f8f9fa"
                                                    : "#fff",
                                            color: "#333",
                                            transition: "background 0.3s",
                                        }}
                                    >
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.Title}
                                        </td>
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.Qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default TotalViewsByDateRange;