import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    Customized,
    Rectangle,
    LabelList,
} from "recharts";

const TOP_COUNTRY_COLORS = [
    "#8884d8",
    "#ff7300",
    "#00c49f",
    "#ffbb28",
    "#ff8042",
    "#6a0dad",
    "#20c997",
    "#ff1493",
    "#007bff",
    "#e83e8c",
];

const DATE_BACKGROUND_COLORS = [
    "rgba(255, 0, 0, 0.2)", // Light Red
    "rgba(0, 255, 0, 0.2)", // Light Green
    "rgba(0, 0, 255, 0.2)", // Light Blue
];

// 1) Define the interface matching your API parameters
interface ZeroViewsRecord {
    ViewDate: string; // e.g. "2025-02-11"
    Country: string; // e.g. "USA"
    Qty: number; // e.g. 0 or any integer
}

// 2) Define props for this component
interface ZeroViewsProps {
    zeroBreakdownData: ZeroViewsRecord[];
}

const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    // Set a minimum height in pixels
    const minHeight = 10;
    // If the calculated height is less than minHeight, use minHeight
    const adjustedHeight = height < minHeight ? minHeight : height;
    // Adjust y so that the bar's bottom remains fixed
    const adjustedY = height < minHeight ? y - (minHeight - height) : y;

    return (
        <rect
            x={x}
            y={adjustedY}
            width={width}
            height={adjustedHeight}
            fill={fill}
        />
    );
};

const ZeroView: React.FC<ZeroViewsProps> = ({ zeroBreakdownData }) => {
    // 3) Check for no data
    if (!zeroBreakdownData || zeroBreakdownData.length === 0) {
        return (
            <p style={{ color: "red", textAlign: "center" }}>
                No data available for the selected date range.
            </p>
        );
    }

    // 4) Flatten data into a structure Recharts can use
    //    We'll rename `ViewDate` -> `Date` and `Qty` -> `ZeroViews` for clarity
    const flattenedData = zeroBreakdownData.map(
        ({ ViewDate, Country, Qty }) => ({
            Date: ViewDate,
            Country,
            ZeroViews: Qty,
        })
    );

    // 5) Group countries by Date => { "2025-02-11": ["USA","Canada"], ... }
    const dateGroups: Record<string, string[]> = {};
    for (const row of flattenedData) {
        if (!dateGroups[row.Date]) {
            dateGroups[row.Date] = [];
        }
        dateGroups[row.Date].push(row.Country);
    }

    // 6) Find the maximum for Y-axis domain
    const maxViews = Math.max(...flattenedData.map((d) => d.ZeroViews));

    const CustomTooltip = ({ active, payload }: any) => {
      if (active && payload && payload.length > 0) {
          const tooltipData = payload[0]?.payload; // Extracts the bar data
  
          // Ensure the data exists before rendering the tooltip
          if (!tooltipData) return null;
  
          return (
              <div
                  style={{
                      backgroundColor: "white",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                  }}
              >
                  <p style={{ margin: 0, fontWeight: "bold", color: "#333" }}>
                      {`Date: ${tooltipData.Date || "N/A"}`}
                  </p>
                  <p style={{ margin: 0, color: "#666" }}>
                      {`Country: ${tooltipData.Country || "N/A"}`}
                  </p>
                  <p style={{ margin: 0, color: "#8884d8", fontWeight: "bold" }}>
                      {`Zero Views: ${tooltipData.ZeroViews || 0}`}
                  </p>
              </div>
          );
      }
      return null;
  };

    return (
        <div
            style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
            }}
        >
            <h2 style={{ textAlign: "center", color: "#333" }}>
                Zero Views by Country
            </h2>

            <ResponsiveContainer width="100%" height={450}>
                <BarChart
                    data={flattenedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap="20%"
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />

                    {/* 7) Draw background rectangles for each Date */}
                    <Customized
    component={(props) => {
        const { xAxisMap, yAxisMap } = props as any;
        if (!xAxisMap || !xAxisMap[0]) return null;

        const xScale = xAxisMap[0].scale;
        const bandWidth = xScale.bandwidth?.() || 0;
        const chartHeight = yAxisMap?.[0]?.height || 0;
        const chartY = yAxisMap?.[0]?.y || 0;

        const rects: JSX.Element[] = [];
        const uniqueDates = [...new Set(flattenedData.map((d) => d.Date))];

        uniqueDates.forEach((date, i) => {
            // Find all bars corresponding to the date
            const barsForDate = flattenedData.filter((d) => d.Date === date);
            
            // Get all X positions of bars for this date
            const xPositions = barsForDate
                .map((b) => xScale(b.Country)) // Get x position of each country bar
                .filter((val) => val !== undefined) as number[];

            if (!xPositions.length) return;

            const minX = Math.min(...xPositions);
            const maxX = Math.max(...xPositions) + bandWidth;

            rects.push(
                <Rectangle
                    key={date}
                    x={minX}
                    y={chartY}
                    width={maxX - minX}
                    height={chartHeight}
                    fill={DATE_BACKGROUND_COLORS[i % DATE_BACKGROUND_COLORS.length]}
                    opacity={0.3}
                />
            );
        });

        return <>{rects}</>;
    }}
/>


                    <XAxis
                        dataKey="Country"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        angle={-75}
                        textAnchor="end"
                        interval={0}
                        height={120}
                    />

                    {/* 8) Use linear domain so 0 is visible */}
                    <YAxis domain={[0, maxViews + 10]} />
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />

                    <Legend
                        verticalAlign="top"
                        wrapperStyle={{ fontSize: "14px" }}
                    />

                    {/* 9) Draw bars using the "ZeroViews" field */}
                    <Bar
                        dataKey="ZeroViews"
                        name="Zero Views"
                        fill="#8884d8"
                        shape={CustomBar}
                        cursor="pointer"
                    >
                        <LabelList
                            position="top"
                            fill="#333"
                            fontSize={12}
                            fontWeight="bold"
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ZeroView;