import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { GetFeaturedContent, GetSeries } from "../../../Api/Carousel";
import CarouselItem from "../../../Components/Carousels/CarouselItem";
import FeaturedCarousel from "../../../Components/Carousels/FeaturedCarousel";
import ListContainer from "../../../Components/UI/List/ListContainer";
import FeaturedSliderLoader from "../../../Components/UI/PageLoaders/FeaturedSliderLoader";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import { ContentType } from "../../../Models/Enums/ContentType";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import VideoSingleLoader from "../../../Components/UI/PageLoaders/VideoSingleLoader";
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../Constants/RoutePaths";
import {SetTitle} from "../../../Helpers/PageMetadata";
import {AuthValidSubOnly} from "../../../Helpers/Routing";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const take = 30;

function SeriesScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();

    const [featuredData, setFeaturedData] = useState<ICarouselContentDTO[] | null>(null);
    const [initialSeries, setInitialSeries] = useState<ICarouselContentDTO[] | null>(null);
    const [series, setSeries] = useState<ICarouselContentDTO[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        async function fetchInitialData() {
            setLoadingInitial(true);

            const controller = new AbortController();
            const userId = authCtx.userData.AspNetUserId;
            const countryCode = authCtx.userData.CurrentCountryCode;

            // Fetch featured content
            const featuredResult = await GetFeaturedContent(countryCode, userId, controller);
            let featuredRes: ICarouselContentDTO[] = [];
            if (featuredResult && !(featuredResult instanceof AxiosError)) {
                featuredRes = featuredResult;
            }

            // Fetch initial series
            const seriesResult = await GetSeries(countryCode, userId, take, 0);
            let seriesRes: ICarouselContentDTO[] = [];
            if (seriesResult && !(seriesResult instanceof AxiosError)) {
                seriesRes = seriesResult;
            }

            SetTitle("Series");

            setFeaturedData(featuredRes);
            setInitialSeries(seriesRes);
            setLoadingInitial(false);
        }

        fetchInitialData();
    }, [authCtx.userData, navigate]);

    async function loadMoreSeries(skip: number) {
        if (!authCtx.userData) {
            return;
        }

        const result = await GetSeries(
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip
        );

        if (!result || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setSeries((prev) => [...prev, ...result]);
    }

    if (loadingInitial) {
        return (
            <>
                <FeaturedSliderLoader />
                <Container>
                    <ListContainer>
                        {[...Array(take - 5)].map((_, i) => (
                            <VideoSingleLoader paddingTop="8" key={i} />
                        ))}
                    </ListContainer>
                </Container>
            </>
        );
    }

    const mergedLists = initialSeries ? [...initialSeries, ...series] : [];

    return (
        <>
            {featuredData && (
                <FeaturedCarousel
                    content={featuredData}
                    allowedContent={[ContentType.Series]}
                />
            )}

            <Container>
                <InfiniteScroll
                    dataLength={mergedLists.length}
                    hasMore={hasMore}
                    next={() => loadMoreSeries(mergedLists.length)}
                    loader={
                        <ListContainer>
                            {[...Array(5)].map((_, i) => (
                                <VideoSingleLoader paddingTop="8" key={i} />
                            ))}
                        </ListContainer>
                    }
                >
                    <ListContainer>
                        {mergedLists.map((content, index) => (
                            <CarouselItem
                                key={index.toString() + content.Id}
                                Content={content}
                                disableOverlay={true}
                            />
                        ))}
                    </ListContainer>
                </InfiniteScroll>
            </Container>
        </>
    );
}

export default SeriesScreen;
