import React from "react";

const IconListCheckmarks = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                style={{fill: props?.iconFill}}
                fillRule="nonzero"
                d="M22 17.75a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h9.5a.75.75 0 00.75-.75zM2.251 16.373l2.172 1.936a.754.754 0 001.059-.053l3.677-4.02a.751.751 0 00-.556-1.257.757.757 0 00-.558.244l-3.174 3.47-1.616-1.44a.753.753 0 00-1.004 1.12zM18 13.75a.75.75 0 00-.75-.75h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 00.75-.75zM2.251 8.394l2.172 1.936a.754.754 0 001.059-.053l3.677-4.02A.752.752 0 008.603 5a.757.757 0 00-.558.244L4.871 8.715l-1.616-1.44A.754.754 0 002 7.835c0 .206.084.411.251.559zM20 9.75a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h7.5a.75.75 0 00.75-.75zm2-4a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h9.5a.75.75 0 00.75-.75z"
            ></path>
        </svg>
    )
};

export default IconListCheckmarks;
