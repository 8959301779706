import React from "react";

const IconVolumeMuted = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M19 7.358V23l-8-5v-.785zm3-6.094L20.452 0l-3.446 4.247L11 8v3.646L9 14.11V8H5v10h.843L2 22.736 3.548 24z"> </path>
        </svg>
    )
};

export default IconVolumeMuted;
