import { useEffect, useState } from "react";
import ICarouselDTO from "../../../Models/DTOs/ICarouselDTO";
import { GetLatest } from "../../../Api/Carousel";

function useFetchJustReleased(userId: string | null, countryCode: string) {
    const [justReleased, setJustReleased] = useState<ICarouselDTO | null>(null);
    const [justReleasedLoading, setJustReleasedLoading] = useState<boolean>(false);
    const [justReleasedError, setJustReleasedError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;

        if (userId !== null) {
            setJustReleasedLoading(true);

            (async () => {
                try {
                    const data = await GetLatest(countryCode, userId);
                    if (!isCancelled) {
                        // Filter duplicates from the Content array based on the unique Id
                        const uniqueContent = data.Content.filter(
                            (item, index, self) =>
                                index === self.findIndex((t) => t.Id === item.Id)
                        );
                        // Replace the Content array with the deduplicated version
                        const dedupedData = { ...data, Content: uniqueContent };
                        //console.log("Deduplicated data:", dedupedData);
                        setJustReleased(dedupedData);
                    }
                } catch (err) {
                    if (!isCancelled) {
                        setJustReleasedError("Failed to fetch just released.");
                        console.error(err);
                    }
                } finally {
                    if (!isCancelled) {
                        setJustReleasedLoading(false);
                    }
                }
            })();
        }

        return () => {
            isCancelled = true;
        };
    }, [userId, countryCode]);

    return { justReleased, justReleasedLoading, justReleasedError };
}

export default useFetchJustReleased;
