import { AxiosError } from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ResetPassword } from "../../../Api/Account";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import WhiteButton from "../Buttons/WhiteButton";
import InputWithIcon from "../Inputs/InputWithIcon";
import InputWithLabel from "../Inputs/InputWithLabel";
import Spinner from "../Spinner";

import {ContainsAt, ContainsSpecialChars, ContainsUpperCase} from "../../../Helpers/Strings";

const Container = styled.form`
    width: 100%;
    button {
        margin: 24px 0 16px 0;
        width: 100%;
    }
`;

function ResetPasswordForm(props: {
    code: string;
    success: boolean;
    setSuccess: (value: boolean) => void;
}) {
    const emailInput = useRef<HTMLInputElement | null>(null);
    const passwordInput = useRef<HTMLInputElement | null>(null);
    const confirmPasswordInput = useRef<HTMLInputElement | null>(null);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [seePassword, setSeePassword] = useState<boolean>(false);
    const [seeConfirmPassword, setConfirmSeePassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

    const controller = new AbortController();

    function TogglePassword() {
        setSeePassword(!seePassword);
    }

    function ToggleConfirmPassword() {
        setConfirmSeePassword(!seeConfirmPassword);
    }

    function GetEmailValidState(text: string): InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("Email cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("Not a valid email.");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    }

    function GetConfirmValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setConfirmPasswordError("Confirm password cannot be empty");
            return InputIsValid.Invalid;
        }

        if (text !== passwordState.Value) {
            setConfirmPasswordError("Passwords do not match");
            return InputIsValid.Invalid;
        }

        setConfirmPasswordError(undefined);
        return InputIsValid.Valid;
    }

    function GetPasswordValidState(text: string) {
        if (text.length <= 0 || text.trim().length <= 0) {
            setPasswordError("You must enter a value");
            return InputIsValid.Invalid;
        }

        if (text.length < 8 || text.trim().length < 8) {
            setPasswordError("New password must be at least 8 characters");
            return InputIsValid.Invalid;
        }

        if (!ContainsUpperCase(text)) {
            setPasswordError("New password must contain at least 1 uppercase character");
            return InputIsValid.Invalid;
        }

        if (!ContainsSpecialChars(text)) {
            setPasswordError("New password must contain a special character");
            return InputIsValid.Invalid;
        }

        setPasswordError(undefined);
        return InputIsValid.Valid;
    }

    function GetEmailKeyUpState(text: string): InputIsValid {
        if (emailError === "" || emailError === undefined || emailError === null) {
            return InputIsValid.Valid;
        }
        return GetEmailValidState(text);
    }

    function emailReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetEmailKeyUpState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetEmailValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function passwordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetPasswordValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetPasswordValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    function confirmPasswordReducer(state: IInputDTO, action: IInputDTO) {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: GetConfirmValidState(action.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: GetConfirmValidState(state.Value),
                } as IInputDTO;
            case InputState.Not_Set:
                return {
                    Value: action.Value,
                    IsValid: InputIsValid.NotSet,
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    }

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [confirmPasswordState, dispatchConfirmPassword] = useReducer(confirmPasswordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Value: event.target.value,
            Type: InputState.User_Input,
        } as IInputDTO);
    }

    function validateEmailHandler() {
        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function passwordChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchPassword({
            Value: event.target.value,
            Type: InputState.User_Input,
        } as IInputDTO);
    }

    function validatePasswordHandler() {
        dispatchPassword({
            Value: passwordState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    function confirmPasswordChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatchConfirmPassword({
            Value: event.target.value,
            Type: InputState.User_Input,
        } as IInputDTO);
    }

    function validateConfirmPasswordHandler() {
        dispatchConfirmPassword({
            Value: confirmPasswordState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);
    }

    const emailIsValid = emailState.IsValid === InputIsValid.Valid;
    const passwordIsValid = passwordState.IsValid === InputIsValid.Valid;
    const confirmPasswordIsValid = confirmPasswordState.IsValid === InputIsValid.Valid;

    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(emailIsValid && passwordIsValid && confirmPasswordIsValid);
        }, 500);

        return function CleanUp() {
            clearTimeout(identifier);
        };
    }, [emailIsValid, passwordIsValid, confirmPasswordIsValid]);

    async function CallResetPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);

        dispatchEmail({
            Value: emailState.Value,
            Type: InputState.Input_Blur,
        } as IInputDTO);

        if (!formIsValid || emailState.Type === InputState.Not_Set) {
            setLoading(false);
            return;
        }

        const result = await ResetPassword(
            emailState.Value,
            props.code,
            passwordState.Value,
            controller
        );

        let success;

        if(result instanceof AxiosError){
            success = false;
            props.setSuccess(false);
        }
        else{
            success = result;
            props.setSuccess(result);
        }

        if (success) {
            toast.success("Your password has successfully been changed! Please login to continue.");
        } else {
            toast.error(
                "An error has occurred. Please try again or send an email directly to support@ickonic.com."
            );
        }

        setLoading(false);
    }

    return (
        <Container onSubmit={CallResetPassword}>
            {loading ? (
                <Spinner fontSize={50} />
            ) : (
                <>
                    <InputWithLabel
                        ref={emailInput}
                        label={"Email Address"}
                        placeholder={"you@example.com"}
                        value={emailState.Value}
                        isValid={emailIsValid || emailState.IsValid === InputIsValid.NotSet}
                        onChange={emailChangeHandler}
                        onBlur={validateEmailHandler}
                        errorMessage={emailError}
                        required={true}
                        type={"text"}
                    />

                    <InputWithIcon
                        label={"Password"}
                        ref={passwordInput}
                        placeholder={"****"}
                        type={seePassword ? "text" : "password"}
                        isValid={passwordIsValid || passwordState.IsValid === InputIsValid.NotSet}
                        value={passwordState.Value}
                        onChange={passwordChangeHandler}
                        onBlur={validatePasswordHandler}
                        onIconClick={TogglePassword}
                        errorMessage={passwordError}
                    >
                        {seePassword ? <FaEye /> : <FaEyeSlash />}
                    </InputWithIcon>

                    <InputWithIcon
                        ref={confirmPasswordInput}
                        label={"Confirm Password"}
                        placeholder={"Retype your new password"}
                        value={confirmPasswordState.Value}
                        type={seeConfirmPassword ? "text" : "password"}
                        isValid={
                            confirmPasswordIsValid ||
                            confirmPasswordState.IsValid === InputIsValid.NotSet
                        }
                        onChange={confirmPasswordChangeHandler}
                        onBlur={validateConfirmPasswordHandler}
                        onIconClick={ToggleConfirmPassword}
                        errorMessage={confirmPasswordError}
                    >
                        {confirmPasswordError ? <FaEye /> : <FaEyeSlash />}
                    </InputWithIcon>

                    <WhiteButton disabled={!formIsValid || props.success}>Reset Password</WhiteButton>
                </>
            )}
        </Container>
    );
}

export default ResetPasswordForm;
