import React, { useState, useEffect, useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  LabelList,
} from "recharts";
import { fetchDeviceStatsData, DeviceStatsData } from "../../Api/VideoMetrics";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

const renderCustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <text
      x={x}
      y={y + 10} 
      textAnchor="end"
      fill="#666"
      transform={`rotate(-40, ${x}, ${y})`}
      style={{ fontSize: 12, fontWeight: "bold" }}
    >
      {payload.value}
    </text>
  );
};

const DeviceStats: React.FC = () => {
  const [data, setData] = useState<DeviceStatsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  const { userData } = useContext(UserAuthenticationContext);
  const accessToken = userData?.Access_Token || ""; 

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const response = await fetchDeviceStatsData(accessToken, abortController);
        console.log("Device Stats API Response:", response);
        if (Array.isArray(response)) {
          setData(response);
        } else {
          console.error("Unexpected response format:", response);
          setData([]);
        }
      } catch (err: any) {
        console.error("Error fetching device stats:", err);
        setError(err?.message || "Failed to load device stats data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, [accessToken]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;
  if (!data || data.length === 0) return <p>No data available</p>;

  // Normalize the API data so that we have the expected keys
  const normalizedData = data.map((item) => ({
    deviceType: (item as any)["DeviceType"] || item.deviceType || "Unknown",
    operatingSystem: (item as any)["OperatingSystem"] || item.operatingSystem || "Unknown",
    TotalViews: item.TotalViews,
    PercentageOfTotal: item.PercentageOfTotal,
  }));

  const transformedData = normalizedData.map((item) => ({
    label: `${item.deviceType} (${item.operatingSystem})`,
    TotalViews: item.TotalViews,
    PercentageOfTotal: item.PercentageOfTotal,
  }));

  console.log("Transformed Data:", transformedData);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        backgroundColor: "#f8f9fa",
        borderRadius: "10px",
      }}
    >
      <h2 style={{ textAlign: "center", color: "#333" }}>Device Stats Report</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={transformedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 120 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
          <XAxis
            dataKey="label"
            tick={renderCustomizedXAxisTick}
            interval={0}
            tickMargin={10} // reduced tick margin to bring labels closer
          />
          <YAxis tick={{ fontSize: 12, fontWeight: "bold" }} />
          <Tooltip formatter={(value) =>
            typeof value === "number" ? value.toLocaleString() : value
          } />
          <Legend verticalAlign="top" align="center" layout="horizontal" />
          <Bar dataKey="TotalViews" fill="#8884d8" name="Total Views">
            <LabelList dataKey="TotalViews" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DeviceStats;
