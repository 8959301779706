// Category Term
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Colours } from "../../../Constants/Colours";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { Ease } from "Constants/EasingCurves";
import { Fonts } from "Constants/Fonts";

import IArticleCategoryDTO from "Models/DTOs/IArticleCategoryDTO";

type Props = {
    category: IArticleCategoryDTO;
    clickable?: boolean;
};

const Term = styled(NavLink)`
    padding: 6px 16px;
    text-decoration: none;
    background: ${Colours.TextBlack};
    transition: background 0.25s ${Ease.Smooth};
    border-radius: 100px;
    display: inline-block;
    font-family: ${Fonts.Rubik};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${Colours.TextBright};
    border: 1px solid ${Colours.Tertiary};

    @media screen and (max-width: 576px) {
        font-size: 12px;
    }

    &:hover,
    &:focus {
        background: ${Colours.IckonicPink};
    }
`;

export const CategoryTerm = ({ category, clickable = true }: Props) => {
    return (
        <Term to={clickable ? RoutePaths.ArticleCategory(category?.Id) : ""}>
            {category?.Title}
        </Term>
    );
};
