import React, {useEffect} from "react";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import { IUserDetails } from "../../Models/Interfaces/IUserDetails";

// Components
import ButtonPill from "../Buttons/ButtonPill";
import EmailFreeView from "./EmailFreeView";

// Helpers
import { IsActive } from "../../Helpers/Account";

// Constants
import { RoutePaths } from "../../Constants/RoutePaths";

interface VideoAccessOverlayProps {
    video: IVideoDTO;
    userData: IUserDetails;
    doesTokenExist: boolean;
    playbackAllowed: boolean;
    onAccessGranted: () => void;
}

const VideoAccessOverlay: React.FC<VideoAccessOverlayProps> = (
    {
        video,
        userData,
        doesTokenExist,
        playbackAllowed,
        onAccessGranted,
    }
) => {
    let content = null;
    const
        now = new Date(),
        datetimeEarlyAccess = video.DatetimeEarlyAccess ? new Date(video.DatetimeEarlyAccess + "Z") : null,
        datetimeRelease = video.Datetime ? new Date(video.Datetime + "Z") : null,

        // Helper to format date as "29/3, 5pm"
        formatDate = (date: Date): string => {
            const day = date.getDate();
            const month = date.getMonth() + 1; // zero-indexed
            let hours = date.getHours();
            const period = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12 || 12;
            return `${day}/${month}, ${hours}${period}`;
        };

    // Makes sure freeview content is viewable by logged in users
    // (even without subscriptions)
    useEffect(() => {
        if (video.IsFreeView && (playbackAllowed || doesTokenExist)) {
            onAccessGranted();
        }
    }, [video, playbackAllowed, doesTokenExist]);

    if (video && video.Id) {
        // ----- Logged In Users -----
        if (doesTokenExist) {
            // Check subscription status
            if (userData.AspNetUserId && !IsActive(userData)) {
                // Inactive subscription: show re-subscribe UI.
                content = (
                    <div className="access-overlay__content">
                        <h2>Don't Miss Out!</h2>
                        <p>There is a problem with your account.</p>
                        <ButtonPill className="" label="Manage Subscription" link={RoutePaths.Account} />
                    </div>
                );
            } else {
                // Active subscription.
                // Lifetime users (AccessLevel >= 6)
                if (userData.AccessLevel >= 6) {
                    // Check if early access date exists.
                    if (datetimeEarlyAccess) {
                        // Early access window not active yet:
                        if (datetimeEarlyAccess > now) {
                            content = (
                                <div className="access-overlay__content">
                                    <h2>Coming Soon</h2>
                                    <p>
                                        This video will be available on{" "}
                                        <strong>{formatDate(datetimeEarlyAccess)}</strong>.
                                    </p>
                                </div>
                            );
                        } else if (datetimeEarlyAccess < now) {
                            // EA window active: grant access.
                            onAccessGranted();
                            return null;
                        }
                    } else {
                        // No EA date, fallback to release date.
                        if (datetimeRelease && datetimeRelease > now) {
                            content = (
                                <div className="access-overlay__content">
                                    <h2>Coming Soon</h2>
                                    <p>
                                        This video will be available on{" "}
                                        <strong>{formatDate(datetimeRelease)}</strong>.
                                    </p>
                                </div>
                            );
                        } else if (datetimeRelease && datetimeRelease < now) {
                            onAccessGranted();
                            return null;
                        }
                    }
                }
                // Non-lifetime users (AccessLevel < 6)
                else {
                    if (datetimeRelease) {
                        if (datetimeRelease > now) {
                            content = (
                                <div className="access-overlay__content">
                                    <h2>Coming Soon</h2>
                                    <p>
                                        This video will be available on{" "}
                                        <strong>{formatDate(datetimeRelease)}</strong>.
                                    </p>
                                </div>
                            );
                        } else {
                            onAccessGranted();
                            return null;
                        }
                    } else {
                        // Fallback if no release date is provided.
                        onAccessGranted();
                        return null;
                    }
                }
            }
        }

        // ----- Logged Out Users -----
        else {
            if (video.IsFreeView && !playbackAllowed) {
                // Show FreeView email collection.
                content = (
                    <div className="access-overlay__content">
                        <h2>This Video is Free!</h2>
                        <p>Get instant access using your email address:</p>
                        <EmailFreeView onAccessGranted={onAccessGranted} />
                    </div>
                );
            } else {
                // Normal subscribe UI.
                content = (
                    <div className="access-overlay__content">
                        <h2>Sign Up to Watch</h2>
                        <p>Unlock This Video for only £1.99!</p>
                        <ButtonPill className="" label="Get Started" link={RoutePaths.SignUp[0]} />
                    </div>
                );
            }
        }
    }

    return (
        <div className="watch-video__access-overlay">
            <div className="access-overlay__inner">{content}</div>
        </div>
    );
};

export default VideoAccessOverlay;
