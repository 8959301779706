import styled from "styled-components";
import { Colours } from "../../Constants/Colours";
import IPlanDTO from "../../Models/DTOs/IPlanDTO";
import Heading from "./Text/Heading";
import PrimaryText from "./Text/PrimaryText";
import {Fonts} from "../../Constants/Fonts";
import {Ease} from "../../Constants/EasingCurves";
import {SymbolFromCurrency} from "../../Helpers/Currency";
import {GetPlanDisplayedPrice, GetPlanTitle, GetPlanUniqueIdentifier} from "../../Helpers/PlanInformation";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    background: ${Colours.Secondary};
    cursor: pointer;
    position: relative;
    transition-duration: 100ms;
    transition-property: border;
    transition-timing-function: ease-in-out;
    border-radius: 12px;
    border: 2px solid ${Colours.Transparent};
    box-sizing: border-box;
    transition: background .45s ${ Ease.Smooth }, box-shadow .45s ${ Ease.Smooth }, border .45s ${ Ease.Smooth };
    &.plan-highlight {
        border-color: ${Colours.IckonicPink};
    }

    &.is-game-changer {
        box-shadow: inset 0 0 0 2px ${ Colours.Accent };
    }

    :hover{
        background: ${Colours.SecondaryLighter};
        box-shadow: inset 0 0 0 2px ${ Colours.IckonicPink } !important;
        > span {
            background: ${ Colours.IckonicPink };
        }
    }

    h1 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 18pt;
        font-weight: bold;
        text-transform: uppercase;
    }

    > span {
        position: absolute;
        top: 0;
        left: calc(50% - 68px);
        background: ${ Colours.Accent };
        padding: 5px 20px;
        font-weight: bold;
        font-size: 12pt;
        color: ${ Colours.SecondaryDarker };
        line-height: 1;
        text-transform: uppercase;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        transition: background .45s ${ Ease.Smooth };
    }

    > h1,
    > p{
        text-align: center;
    }
`;

const PriceContainer = styled.div`
    text-align: center;

    p {
        color: ${Colours.Text};
        font-family: ${ Fonts.Secondary };
        font-weight: normal;
        font-size: 35pt;
        text-transform: lowercase;
        font-weight: 600;
        line-height: 1.2;
        margin: 0 0 1rem 0;
        span {
            font-size: 14pt;
            font-weight: normal;
            color: ${ Colours.IckonicPinkHighlight }
        }
    }

    p {
        &:first-child {
            span {
                display: block;
            }
        }

        &:last-child {
            font-size: 16pt;
        }
    }

    &.is-truth-seeker {

    }
`;

const PlanTextContainer = styled.div`
    //text-align: center;
    p {
        margin-top: 11px;
        margin-bottom: 11px;
        font-size: 15px;
    }
`;

function Plan(props: {
    plan: IPlanDTO,
    freeDays?: number,
    onClick: (plan: IPlanDTO) => void,
}) {
    return (
        <Container onClick={() => props.onClick(props.plan)} className={ props.plan.Title === 'Truth Seeker' ? 'is-truth-seeker' : 'is-game-changer' }>
            <Heading>{GetPlanTitle(props.plan.Type)}</Heading>

            <PriceContainer className={ props.plan.Title === 'Truth Seeker' ? 'is-truth-seeker' : 'is-game-changer' }>
                { props.plan.Title === 'Truth Seeker' ?
                    (
                        <PrimaryText>
                            <sup>£</sup>1.99 <span>for your first month</span>
                        </PrimaryText>
                    )
                    :
                    (
                        <PrimaryText>
                            <sup>£</sup>60 <span>for your first year</span>
                        </PrimaryText>
                    )
                }

                <PrimaryText testId={"planPrice"}>
                    {"Renews at "}
                    {
                        props.plan.Title === 'Truth Seeker' ?
                            GetPlanDisplayedPrice(props.plan.Price, props.plan.Type)
                            :
                            (
                                <>
                                    <sup>{ SymbolFromCurrency(props.plan.Currency) }</sup>{ props.plan.Price } <span>/ year</span>
                                </>
                            )
                    }
                </PrimaryText>
            </PriceContainer>

            { GetPlanUniqueIdentifier(props.plan.Type) }

            <PlanTextContainer>
                {props.plan.Text.map((text, index) => {
                    return text !== "Best value" ? <PrimaryText key={index}>{text}</PrimaryText> : null;
                })}
            </PlanTextContainer>
        </Container>
    );
}

export default Plan;
