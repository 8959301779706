import React from 'react';
import { IckonicLogoIcon } from "../../Assets/SVGs/Logos";

interface VideoPlayPauseProps {
    isPlaying: boolean;
    onMouseDown: (e: React.MouseEvent) => void;
    showVideoPlayerOtherControls: boolean;
    isMobile: boolean;
    hideControls: () => void;
    showControlsAndResetTimer: () => void;
    skipBack: () => void;
    skipForward: () => void;
}

const BigCenteredControls: React.FC<VideoPlayPauseProps> = ({
    isPlaying,
    onMouseDown,
    showVideoPlayerOtherControls,
    isMobile,
    hideControls,
    showControlsAndResetTimer,
    skipBack,
    skipForward
}) => {
    const handleOnTouchStart = () => {
        if (!showVideoPlayerOtherControls && isPlaying) {
            showControlsAndResetTimer();
            return;
        }

        if (showVideoPlayerOtherControls && isPlaying) {
            hideControls();
        }
    }

    return (
        <div
            className={`video-player__big-centered is-big-centered u-full_cover_absolute ${
                showVideoPlayerOtherControls || !isPlaying ? 'is-not-hidden' : 'is-hidden'
            }`}
            aria-label={isPlaying ? "Pause video" : "Play video"}
        >
            {!isMobile ? (
                <div
                    className="u-full_cover_absolute"
                    onMouseDown={(e) => {
                        e.stopPropagation(); // Prevents parent onPointerDown from firing
                        onMouseDown(e);
                    }}
                    role="button"
                />
            ) : (
                <div
                    className="u-full_cover_absolute"
                    onTouchStart={handleOnTouchStart}
                    role="button"
                />
            )}

            <div
                className="big-centered__inner"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    onMouseDown(e);
                }}
            >
                {isPlaying ? (
                    /* Pause Icon */
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="white"
                    >
                        <rect x="5" y="4" width="4" height="16"/>
                        <rect x="15" y="4" width="4" height="16"/>
                    </svg>
                ) : (
                    /* Play Icon */
                    IckonicLogoIcon()
                )}
            </div>

            <div className="big-centered__skip-controls">
                <button
                    type="button"
                    className="ickonic-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        skipBack();
                    }}
                    aria-label="Skip back 10 seconds"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 22 24">
                        <path d="m3 4 6 4V0z"></path>
                        <path
                            d="M15 2.77V0L9 4l6 4V4.95c2.96 1.47 5 4.53 5 8.05 0 4.96-4.04 9-9 9s-9-4.04-9-9c0-1.25.26-2.43.72-3.51l-1.7-1.08C.38 9.81.01 11.36.01 13c0 6.08 4.92 11 11 11s11-4.92 11-11c0-4.66-2.91-8.63-7-10.23Z"></path>
                        <path
                            d="M6.16 11.72v-1.18h2.63v1.18zm1.38 5.85v-7.03h1.35v7.03zM12.92 17.68c-.55 0-1.05-.15-1.5-.46-.45-.3-.8-.73-1.07-1.27q-.39-.81-.39-1.92c0-1.11.13-1.36.39-1.9s.61-.96 1.05-1.26.94-.45 1.49-.45 1.06.15 1.51.45.8.72 1.06 1.26.38 1.18.38 1.91-.13 1.37-.38 1.92c-.26.54-.61.97-1.05 1.27s-.94.45-1.48.45Zm-.02-1.25c.32 0 .6-.09.83-.27s.42-.44.54-.79.2-.79.2-1.32-.07-.96-.2-1.31-.31-.62-.55-.79-.51-.26-.83-.26-.59.09-.82.26-.42.44-.54.78c-.13.35-.19.79-.19 1.31s.06.97.19 1.33.31.62.54.8.51.27.83.27Z"></path>
                    </svg>
                </button>

                <button
                    type="button"
                    className="ickonic-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        skipForward();
                    }}
                    aria-label="Skip forward 10 seconds"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 22 24">
                        <path d="m19 4-6-4v8z"></path>
                        <path
                            d="m20.99 8.41-1.7 1.08c.46 1.08.72 2.27.72 3.51 0 4.96-4.04 9-9 9S2 17.96 2 13c0-3.53 2.04-6.58 5-8.05V8l6-4-6-4v2.77C2.91 4.37 0 8.34 0 13c0 6.08 4.92 11 11 11s11-4.92 11-11c0-1.64-.37-3.19-1.01-4.59"></path>
                        <path
                            d="M6.23 11.77v-1.18h2.63v1.18zm1.38 5.85v-7.03h1.35v7.03zM12.99 17.73c-.55 0-1.05-.15-1.5-.46-.45-.3-.8-.73-1.07-1.27q-.39-.81-.39-1.92c0-1.11.13-1.36.39-1.9s.61-.96 1.05-1.26.94-.45 1.49-.45 1.06.15 1.51.45.8.72 1.06 1.26.38 1.18.38 1.91-.13 1.37-.38 1.92c-.26.54-.61.97-1.05 1.27s-.94.45-1.48.45Zm-.02-1.25c.32 0 .6-.09.83-.27s.42-.44.54-.79.2-.79.2-1.32-.07-.96-.2-1.31-.31-.62-.55-.79-.51-.26-.83-.26-.59.09-.82.26-.42.44-.54.78c-.13.35-.19.79-.19 1.31s.06.97.19 1.33.31.62.54.8.51.27.83.27Z"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default BigCenteredControls;
