// src/components/ProtectedRoute.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserAuthenticationContext } from "../Context/UserAuthenticationContext";
import { ALLOWED_REPORTING_USERS } from "../reportsconfig";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { userData } = useContext(UserAuthenticationContext);
  const userId = userData?.AspNetUserId;

  // If there's no user or the user is not in the allowed list, redirect.
  if (!userId || !ALLOWED_REPORTING_USERS.includes(userId)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
