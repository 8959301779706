import React from "react";

const IconVolume = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="m6 7 8-5v20l-8-5zM0 17h4V7H0zM20.264 3.736l-1.497 1.497C20.614 7.016 21.75 9.39 21.75 12s-1.135 4.984-2.983 6.766l1.498 1.498C22.57 18.111 24 15.209 24 12s-1.43-6.11-3.736-8.264M19.775 12c0-2.084-.915-3.967-2.384-5.391l-1.503 1.503c1.011 1.049 1.637 2.401 1.637 3.888s-.623 2.841-1.634 3.891l1.503 1.503c1.468-1.424 2.381-3.309 2.381-5.394"> </path>
        </svg>
    )
};

export default IconVolume;
