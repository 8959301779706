export function mergeRefs<T>(...refs: Array<React.Ref<T> | undefined>) {
    return (node: T) => {
        refs.forEach(ref => {
            if (typeof ref === "function") {
                ref(node);
            } else if (ref != null) {
                (ref as React.MutableRefObject<T | null>).current = node;
            }
        });
    };
}
