export function debounce(func: (...args: any[]) => void, delay: number) {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func(...args), delay);
    };
}

// Throttling allows the first click immediately, then ignores (or queues) others until the delay ends.
export function throttle<T extends (...args: any[]) => void>(
    func: T,
    limit: number
): T {
    let inThrottle = false;
    return function (this: any, ...args: Parameters<T>) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;
            }, limit);
        }
    } as T;
}
