import axios, { AxiosError } from "axios";
import ICarouselContentDTO from "../Models/DTOs/ICarouselContentDTO";
import ICarouselDTO from "../Models/DTOs/ICarouselDTO";
import { ContentType } from "../Models/Enums/ContentType";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.carousel;

export async function GetFeaturedContent(countryCode: string, aspNetUserId: string, abortController: AbortController) {

    return await axios.get(BACKEND_URL + "/GetFeaturedContent/" + countryCode + "/web/" + aspNetUserId, { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error: AxiosError) => {
        console.log("Carousel GetFeaturedContent");
        return error;
    });
}

export async function GetLatest(countryCode: string, aspNetUserId: string) {
    return await axios.post(`${BACKEND_URL}/GetLatest`, {
        excludeDailyDownload: true,
        os: 'web',
        aspNetUserId: aspNetUserId,
        countryCode: countryCode
    }).then((result) => {
        //console.log(result);
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("Carousel GetLatest error");
        throw error;
    });
}

export async function GetLatestDailyDownload(countryCode: string, aspNetUserId: string) {
    return await axios.post(`${BACKEND_URL}/GetDailyDownload`, {
        os: 'web',
        countryCode: countryCode,
        aspNetUserId: aspNetUserId
    }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("Carousel GetLatest error");
        return error;
    });
}

export async function GetTopRatedCarousel(countryCode: string, aspNetUserId: string) {
    return await axios.post(`${BACKEND_URL}/GetTopRatedCarousel`, {
        aspNetUserId: aspNetUserId,
        countryCode: countryCode,
        os: 'web'
    }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("Carousel GetTopRatedCarousel error");
        throw error;
    });
}

export async function GetNewAndCurrentAffairs(
    aspNetUserId: string
): Promise<ICarouselDTO> {
    try {
        const response = await axios.post<ICarouselDTO>(
            `${BACKEND_URL}/GetNewAndCurrentAffairs`,
            {
                os: 'web',
                aspNetUserId
            }
        );
        return response.data;
    } catch (error) {
        // Log error (and optionally inspect if it's an AxiosError)
        console.error("Carousel GetNewAndCurrentAffairs error:", error);

        // Re-throw so the calling code can handle it in its own try/catch block
        throw error;
    }
}

export async function GetCustomCarousels(country: string, aspNetUserId: string) {
    return await axios.post(`${BACKEND_URL}/GetCustomCarousels`, {
        aspNetUserId: aspNetUserId,
        countryCode: country,
        os: 'web'
    }).then((result) => {
        return result.data as ICarouselDTO[];
    }).catch((error: AxiosError) => {
        console.log("Custom Carousel error");
        throw error;
    });
};

export async function GetCategories(aspNetUserId: string) {
    return await axios.post(`${BACKEND_URL}/GetCategories`, {
        aspNetUserId: aspNetUserId,
        os: 'web'
    }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("GetCategories error");
        return error;
    });
};

export async function GetFilms(country: string, aspNetUserId: string, take: number, skip: number) {
    return await axios.post(`${BACKEND_URL}/GetFilms`, {
        os: 'web',
        aspNetUserId: aspNetUserId,
        take: take,
        skip: skip,
        countryCode: country
    }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetFilms error ");
        throw error;
    });
};

export async function GetSeries(country: string, aspNetUserId: string, take: number, skip: number) {
    return await axios.post(`${BACKEND_URL}/GetSeries`, {
        aspNetUserId: aspNetUserId,
        os: 'web',
        countryCode: country,
        take: take,
        skip: skip
    }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetSeries error");
        throw error;
    });
};

export async function GetCategoryContent(categoryId: number, country: string, aspNetUserId: string, take: number, skip: number, abortController: AbortController) {

    const requestUrl = BACKEND_URL + "GetCategoryContent/" + categoryId + "/"
        + aspNetUserId + "/" + take + "/" + skip + "/" + country + "/web";

    //console.log(requestUrl);
    return await axios.get(requestUrl, { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error) => {
        console.log("GetCategoryContent error: " + error);
        throw error;
    });
};

export async function GetCategorySeries(categoryId: number, aspNetUserId: string, take: number, skip: number, episodeTake: number) {
    return await axios.post(`${BACKEND_URL}/GetCategorySeries`,
        {
            os: 'web',
            aspNetUserId: aspNetUserId,
            categoryId: categoryId,
            take: take,
            skip: skip,
            episodeTake: episodeTake
        }).then((result) => {
        return result.data as ICarouselDTO[];
    }).catch((error) => {
        console.log("GetCategorySeries error: " + error);
        return null;
    });
};

export async function GetCategoryFilmsContent(categoryId: number, country: string, aspNetUserId: string, take: number, skip: number, abortController: AbortController) {

    return await axios.get(BACKEND_URL + "GetCategoryFilmsContent/" + categoryId + "/"
        + aspNetUserId + "/" + take + "/" + skip + "/" + country + "/web", { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error) => {
        console.log("GetCategoryFilms error: " + error);
        return null;
    });
};

export async function GetCategoryFilmsCarousel(categoryId: number, country: string, aspNetUserId: string, take: number, skip: number) {
    return await axios.post(`${BACKEND_URL}/GetCategoryFilmsCarousel`, {
        categoryId: categoryId,
        os: 'web',
        countryCode: country,
        aspNetUserId: aspNetUserId,
        take: take,
        skip: skip
    }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error) => {
        console.log("GetCategoryFilms error: " + error);
        return null;
    });
};

export async function GetEpisodeBySeason(seriesId: number, country: string, abortController: AbortController, futureVidoes: boolean, seasonNumber?: number, sortOrder?: string) {
    const season = seasonNumber ? "/" + seasonNumber : "";
    const sort = sortOrder ? sortOrder : 'a';
    const url = BACKEND_URL + "GetEpisodesBySeason/" + seriesId + "/" + country + "/web" + season + "/" + futureVidoes + "/{" + sort + "}";
    //console.log(url);
    return await axios.get(url, { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("GetEpisodesBySeason error");
        return error;
    });
};

export async function GetEpisodesByYear(seriesId: number, country: string, aspNetUserId: string, take: number, skip: number, iteration: number, abortController: AbortController, sortOrder?: string) {
    const sort = sortOrder ? sortOrder : 'a'
    const url = BACKEND_URL + "GetEpisodesByYear/" + seriesId + "/" + take + "/" + skip + "/" + iteration + "/" + country + "/web" + "/{" + sort + "}";
    //console.log(url);
    return await axios.get(url, { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("GetEpisodesByYear error");
        throw error;
    });
};

export async function GetLatestArticles(take: number, skip: number, abortController: AbortController) {

    return await axios.get(BACKEND_URL + `GetLatestArticles/${take}/${skip}`, { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselContentDTO[];
    }).catch((error: AxiosError) => {
        console.log("GetLatestArticles error");
        throw error;
    });
};

export async function GetRelatedContent(aspNetUserId: string, contentId: number, type: ContentType, countryCode: string, abortController: AbortController) {

    return await axios.get(BACKEND_URL + "GetRelatedContent/" + aspNetUserId + "/" + contentId + "/" + type + "/" + countryCode + "/web", { signal: abortController.signal }).then((result) => {
        return result.data as ICarouselDTO;
    }).catch((error: AxiosError) => {
        console.log("GetRelatedContent error");
        return error;
    });
};

