import { useState, useEffect } from "react";
import { GetVideoRating } from "../../Api/Rating";
import {IRatingDTO} from "../../Models/DTOs/IRatingDTO";

function useFetchVideoRating(
    videoId: number,
    userId: string | null,
    refresh: number
) {
    const [rating, setRating] = useState<IRatingDTO>({} as IRatingDTO);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        const controller = new AbortController;

        if (videoId && userId) {
            setLoading(true);

            (async () => {
                try {
                    // If you only need to fetch one piece of data,
                    // using Promise.all isn't necessary.
                    const ratingData = await GetVideoRating(videoId, userId, controller);
                    if (!isCancelled) {
                        setRating(ratingData);
                    }
                } catch (err) {
                    if (!isCancelled) {
                        setError("Failed to fetch video rating.");
                        console.error(err);
                    }
                } finally {
                    if (!isCancelled) {
                        setLoading(false);
                    }
                }
            })();
        }

        return () => {
            isCancelled = true;
        };
    }, [videoId, userId, refresh]);

    return { rating, loading, error };
}

export default useFetchVideoRating;
