import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { getUnpaidSubs } from "../../Api/VideoMetrics";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface UnpaidSub {
  CustomerId: string;
  SubscriptionId: string;
  Email: string | null;
  StartDate: string;
  CurrentPeriodStart: string;
  CurrentPeriodEnd: string;
  BillingCycle: string;
  Amount: number;
  Country: string;
  Status: string;
}

const UnpaidSubs: React.FC = () => {
  const [data, setData] = useState<UnpaidSub[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const authCtx = useContext(UserAuthenticationContext);
  const access_token = authCtx.userData.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadUnpaidSubs = async () => {
      try {
        const result = await getUnpaidSubs(access_token, abortController);
        setData(result);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load data", err);
          setError("Failed to load data");
        }
      } finally {
        setLoading(false);
      }
    };

    loadUnpaidSubs();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UnpaidSubs");
    // Write the workbook to an array buffer
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "UnpaidSubscriptions.xlsx");
  };

  const columns = [
    { title: "Customer ID", dataIndex: "CustomerId", key: "CustomerId" },
    { title: "Subscription ID", dataIndex: "SubscriptionId", key: "SubscriptionId" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Current Period Start",
      dataIndex: "CurrentPeriodStart",
      key: "CurrentPeriodStart",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Current Period End",
      dataIndex: "CurrentPeriodEnd",
      key: "CurrentPeriodEnd",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    { title: "Billing Cycle", dataIndex: "BillingCycle", key: "BillingCycle" },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text: number) => `£${text.toFixed(2)}`,
    },
    { title: "Country", dataIndex: "Country", key: "Country" },
    { title: "Status", dataIndex: "Status", key: "Status" },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#e6e0f8", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ textAlign: "left", color: "#333", margin: 0, display: "inline-block", marginRight: "10px" }}>
            Unpaid Subscriptions
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>
            Total: {data.length}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="CustomerId"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default UnpaidSubs;
