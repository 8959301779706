import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// Components
import NavLinkPinkTextButton from "../UI/Buttons/NavLinkPinkTextButton";

// Api
import EmailFreeViewAPI from "../../Api/EmailFreeView";

// Constants
import { RoutePaths } from "../../Constants/RoutePaths";

// Assets
import IconCheckMark from "../../Assets/SVGs/Icons/YesOrNo/CheckMark";
import IconSpinLoader from "../../Assets/SVGs/Icons/Loading/SpinLoader";
import InputField from "../Inputs/InputField";

// Helpers
import { ContainsAt } from "../../Helpers/Strings";

interface EmailFreeViewProps {
    handleClosePopUp?: () => void,
    isPopUpOpen?: boolean,
    onAccessGranted: () => void;
}

const EmailFreeView: React.FC<EmailFreeViewProps> = ({
                                                         handleClosePopUp,
                                                         isPopUpOpen,
                                                         onAccessGranted
                                                     }) => {
    const
        cookieName = 'emilFreeview',
        [email, setEmail] = useState(''),
        [validEmail, setValidEmail] = useState(false),
        [showForm, setShowForm] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [success, setSuccess] = useState(false),
        [emailError, setEmailError] = useState<string | undefined>(),
        controller = new AbortController(),

        handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },

        validateEmail = (text: string, allowErrors: boolean) => {
            if (text.trim().length === 0) {
                allowErrors && setEmailError("E-mail cannot be empty");
                setValidEmail(false);
                return false;
            }

            if (!ContainsAt(text)) {
                allowErrors && setEmailError("Not a valid email address");
                setValidEmail(false);
                return false;
            }

            setEmailError(undefined);
            setValidEmail(true);
            return true;
        },

        submitEmail = () => {
            if (validateEmail(email, true)) {
                setIsLoading(true);
                EmailFreeViewAPI(controller, email)
                    .then((response) => {
                        if (response) {
                            setEmail('');
                            Cookies.set(cookieName, '');
                            setSuccess(true);
                            onAccessGranted();
                        }
                        setIsLoading(false);
                    });
            }
        },

        handleBlur = () => {
            validateEmail(email, true);
        },

        // This function handles click on the submit button.
        handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            submitEmail();
        },

        // NEW: Handle form submission (Enter key triggers this)
        handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            submitEmail();
        };

    useEffect(() => {
        if (Cookies.get(cookieName) === undefined) {
            setShowForm(true);
        }
    }, []);

    useEffect(() => {
        if (Cookies.get(cookieName) !== undefined && handleClosePopUp !== undefined) {
            handleClosePopUp();
        }
    }, [isPopUpOpen, handleClosePopUp]);

    useEffect(() => {
        setTimeout(() => {
            if (success && handleClosePopUp) {
                handleClosePopUp();
            }
        }, 1500);
    }, [success, handleClosePopUp]);

    useEffect(() => {
        validateEmail(email, false);
    }, [email]);

    return (
        <form className="watch-video__email-free-view" onSubmit={handleFormSubmit}>
            <p className="email-free-view__error-message">
                {emailError}
            </p>

            <InputField
                type="email"
                name="email"
                hasError={email !== "" && !validEmail}
                value={email}
                onChange={handleChange}
                onFocus={() => {}}
                onBlur={handleBlur}
                className=""
                label="Email"
                errorMessage=""
            />

            <button
                className={`ickonic-button ${success ? 'has-success' : 'is-waiting'} ${validEmail ? "is-valid" : "is-not-valid"}`}
                onClick={handleClick}
                disabled={!validEmail}
            >
                {success ? (
                    <div className="button__icon icon--success">{IconCheckMark()}</div>
                ) : isLoading ? (
                    <div className="button__icon icon--loading">{IconSpinLoader()}</div>
                ) : (
                    'Submit'
                )}
            </button>

            <p>
                By clicking submit you're agreeing to allow us to contact you with offers and new releases. Please see our{' '}
                <NavLinkPinkTextButton to={RoutePaths.TermsOfUse}>
                    terms and conditions
                </NavLinkPinkTextButton>{' '}
                for more information.
            </p>
        </form>
    );
};

export default EmailFreeView;
