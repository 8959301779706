import React from "react";
import IconArrowCarrot from "../../Assets/SVGs/Icons/Arrows/ArrowCarrot";

type ButtonsPrevNextProps = {
    onPrev: () => void;
    onNext: () => void;
    currentIndex: number;
    maxIndex?: number;
    parentClass?: string;
    ariaLabel?: string;
};

const ButtonsPrevNext: React.FC<ButtonsPrevNextProps> = ({
    onPrev,
    onNext,
    currentIndex,
    maxIndex,
    parentClass = "",
    ariaLabel
}) => {
    return (
        <div className={`button--prev-next ${parentClass}`}>
            <button
                className="prev-next__prev prev-next__button"
                onClick={onPrev}
                disabled={maxIndex !== undefined && currentIndex === 0}
                aria-label={ariaLabel || "Previous"}
            >
                {IconArrowCarrot()}
            </button>

            <button
                className="prev-next__next prev-next__button"
                onClick={onNext}
                disabled={maxIndex !== undefined && currentIndex === maxIndex}
                aria-label={ariaLabel || "Next"}
            >
                {IconArrowCarrot()}
            </button>
        </div>
    );
};

export default ButtonsPrevNext;
