import React, { useState, useEffect, useContext } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  fetchOnboarded,
  fetchCategoryDistribution,
  fetchContentDistribution,
  fetchHDYHDistribution,
} from "../../Api/VideoMetrics";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];

const cardStyle: React.CSSProperties = {
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  margin: "8px",
  padding: "10px",
  width: "22%",
  minHeight: "400px", // Increased minimum height for extra vertical space
};

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  marginBottom: "8px",
  fontSize: "1rem",
  color: "#333",
  borderBottom: "1px solid #eee",
  paddingBottom: "6px",
};

const progressBarContainerStyle: React.CSSProperties = {
  width: "100%",
  backgroundColor: "#ccc",
  borderRadius: "5px",
  overflow: "hidden",
  margin: "20px 0",
};

const progressBarStyle = (progress: number): React.CSSProperties => ({
  width: `${progress}%`,
  backgroundColor: "#0088FE",
  height: "20px",
  transition: "width 0.5s ease-in-out",
});

const Onboarding: React.FC = () => {
  const authCtx = useContext(UserAuthenticationContext);
  const accessToken = authCtx.userData.Access_Token || "";

  const [onboardedData, setOnboardedData] = useState<any[]>([]);
  const [categoryDistributionData, setCategoryDistributionData] = useState<any[]>([]);
  const [contentDistributionData, setContentDistributionData] = useState<any[]>([]);
  const [hdyhDistributionData, setHdyhDistributionData] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();

    const loadData = async () => {
      try {
        // Fetch Onboarded Data
        const onboarded = await fetchOnboarded(accessToken, abortController);
        const processedOnboarded = onboarded.map((item: any) => ({
          ...item,
          Qty: Number(item.Qty),
        }));
        setOnboardedData(processedOnboarded);
        setProgress((prev) => prev + 25);

        // Fetch Category Distribution Data
        const categoryDistribution = await fetchCategoryDistribution(accessToken, abortController);
        setCategoryDistributionData(categoryDistribution);
        setProgress((prev) => prev + 25);

        // Fetch Content Distribution Data
        const contentDistribution = await fetchContentDistribution(accessToken, abortController);
        setContentDistributionData(contentDistribution);
        setProgress((prev) => prev + 25);

        // Fetch HDYH Distribution Data
        const hdyhDistribution = await fetchHDYHDistribution(accessToken, abortController);
        setHdyhDistributionData(hdyhDistribution);
        setProgress((prev) => prev + 25);
      } catch (err: any) {
        console.error("Error fetching OnBoarding report data:", err);
        setError("Failed to load report data.");
      } finally {
        setLoading(false);
      }
    };

    loadData();
    return () => abortController.abort();
  }, [accessToken]);

  if (loading) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2 style={{ color: "#333" }}>Loading OnBoarding Report...</h2>
        <div style={progressBarContainerStyle}>
          <div style={progressBarStyle(progress)} />
        </div>
        <p>{progress}%</p>
        <p style={{ fontSize: "0.9rem", color: "#555" }}>
          Please note: This report may take up to 75 seconds to build.
        </p>
      </div>
    );
  }
  
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "10px" }}>
      <h2 style={{ textAlign: "center", color: "#333" }}>OnBoarding Report</h2>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {/* Onboarded Pie Chart */}
        <div style={cardStyle}>
          <div style={headerStyle}>Onboarded</div>
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={onboardedData}
                dataKey="Qty"
                nameKey="Result"
                cx="50%"
                cy="50%"
                outerRadius={70}
                label
              >
                {onboardedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Category Distribution Pie Chart */}
        <div style={cardStyle}>
          <div style={headerStyle}>Category Distribution</div>
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={categoryDistributionData}
                dataKey="Qty"
                nameKey="Category"
                cx="50%"
                cy="50%"
                outerRadius={70}
                label
              >
                {categoryDistributionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Content Distribution Pie Chart */}
        <div style={cardStyle}>
          <div style={headerStyle}>Content Distribution</div>
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={contentDistributionData}
                dataKey="Qty"
                nameKey="Content"
                cx="50%"
                cy="50%"
                outerRadius={70}
                label
              >
                {contentDistributionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* HDYH Distribution Pie Chart */}
        <div style={cardStyle}>
          <div style={headerStyle}>How Did You Hear Distribution</div>
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={hdyhDistributionData}
                dataKey="Qty"
                nameKey="HowDidYouHear"
                cx="50%"
                cy="50%"
                outerRadius={70}
                label
              >
                {hdyhDistributionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;