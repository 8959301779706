import { useState, useEffect } from "react";
import { GetSeries } from "../../../Api/Series";
import ISeriesDTO from "../../../Models/DTOs/ISeriesDTO";

function useFetchSeriesDetails(
    seriesId: number | undefined,
    refresh: number
) {
    const [series, setSeries] = useState<ISeriesDTO>({} as ISeriesDTO);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        const controller = new AbortController();

        if (seriesId) {
            setLoading(true);

            (async () => {
                try {
                    const seriesData = await GetSeries(seriesId, controller);
                    if (!isCancelled) {
                        setSeries(seriesData);
                    }
                } catch (err) {
                    if (!isCancelled) {
                        setError("Failed to fetch series details.");
                        console.error(err);
                    }
                } finally {
                    if (!isCancelled) {
                        setLoading(false);
                    }
                }
            })();
        }

        return () => {
            isCancelled = true;
            controller.abort();
        };
    }, [seriesId, refresh]);

    return { series, loading, error };
}

export default useFetchSeriesDetails;
