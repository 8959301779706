import React from "react";

interface StepCancelSuccessProps {
    cancellationMessage: string | null;
    cancelErrorMessage: string | null;
}

const StepCancelSuccess_CancelOrPause = (
    {
        cancellationMessage,
        cancelErrorMessage
    }: StepCancelSuccessProps) =>
{
    return (
        <div className="cancel-or-pause__cancel-success">
            <div className="step-provide-feedback__inner">
                <div className="cancel-or-pause__heading-wrapper">
                    <h1>
                        Subscription Status
                    </h1>
                </div>

                <div className="cancel-or-pause__divider"/>

                <div className="cancel-or-pause__content-wrapper">
                    <p className={`${cancelErrorMessage ? 'has-error' : 'has-no-error'}`}>
                        {
                            cancelErrorMessage ?
                                cancelErrorMessage
                                :
                                (
                                    cancellationMessage ? cancellationMessage : "Your subscription has been successfully cancelled."
                                )
                        }
                    </p>
                </div>
            </div>
        </div>
    );
}

export default StepCancelSuccess_CancelOrPause;
