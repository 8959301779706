import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { fetchSiteTrackingToDateForTracking } from "../../Api/VideoMetrics";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface SiteTracking {
  UserEmail: string;
  AspNetUserID: string;
  SubscriptionStatus: string;
  LoginAttempts: number;
  FirstLogin: string;
  LastLogin: string;
  EndDate?: string;
  LoginStatus: string;
}

const SiteTrackingReport: React.FC = () => {
  const [data, setData] = useState<SiteTracking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(UserAuthenticationContext);
  const access_token = userData?.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadSiteTracking = async () => {
      try {
        const result = await fetchSiteTrackingToDateForTracking(access_token, abortController);
        setData(result);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load site tracking data", err);
          setError("Failed to load data");
        }
      } finally {
        setLoading(false);
      }
    };

    loadSiteTracking();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SiteTracking");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "SiteTracking.xlsx");
  };

  const columns = [
    { title: "User Email", dataIndex: "UserEmail", key: "UserEmail" },
    { title: "AspNet User ID", dataIndex: "AspNetUserID", key: "AspNetUserID" },
    { title: "Subscription Status", dataIndex: "SubscriptionStatus", key: "SubscriptionStatus" },
    { title: "Login Attempts", dataIndex: "LoginAttempts", key: "LoginAttempts" },
    {
      title: "First Login",
      dataIndex: "FirstLogin",
      key: "FirstLogin",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Last Login",
      dataIndex: "LastLogin",
      key: "LastLogin",
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
      render: (text: string) => (text ? new Date(text).toLocaleDateString() : "N/A"),
    },
    { title: "Login Status", dataIndex: "LoginStatus", key: "LoginStatus" },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#e6e0f8", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              color: "#333",
              display: "inline-block",
              marginRight: "10px",
            }}
          >
            Site Tracking Report
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>Total: {data.length}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="AspNetUserID"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default SiteTrackingReport;