import {Location} from "react-router-dom";
import {addCookieWithExpiry, GetCookieValue, hasCookie} from "./Cookies";
import {DateAddDays} from "./DateAndTime";
import {toast} from "react-toastify";

export function ShowWelcomeToast(
    firstName: string | undefined,
    location: Location
) {
    let message;
    const dateString = GetCookieValue("registered");
    const registered =
        dateString === "" ? DateAddDays(-999) : new Date(Date.parse(dateString));
    if (
        location.pathname.toLowerCase().includes("browse") &&
        registered < DateAddDays(-0.25)
    ) {
        firstName === null || firstName === undefined || firstName === ""
            ? (message = "Welcome back!")
            : (message = "Welcome back, " + firstName + "!");
        if (!hasCookie("welcome_back_toast")) {
            toast(message, {
                position: "top-center",
            });
            addCookieWithExpiry("welcome_back_toast", "true", 6);
        }
    }
}
