import React, { useEffect, useState } from "react";
import Player from "video.js/dist/types/player";

interface VideoTimeDisplayProps {
    player: Player | null; // Allow null if the player hasn't been set yet
    isMobile: boolean;
    isVolumeHoveredOver: boolean;
}

const VideoTimeDisplay: React.FC<VideoTimeDisplayProps> = ({ player, isMobile, isVolumeHoveredOver }) => {
    const
        [currentTime, setCurrentTime] = useState(0),
        [duration, setDuration] = useState(0);

    useEffect(() => {
        if (!player) return;

        const
            handleTimeUpdate = () => {
            setCurrentTime(player.currentTime() ?? 0);
        },
            handleLoadedMetadata = () => {
            setDuration(player.duration() ?? 0);
        };

        // Listen for time updates and metadata loading
        player.on("timeupdate", handleTimeUpdate);
        player.on("loadedmetadata", handleLoadedMetadata);

        // Clean up
        return () => {
            player.off("timeupdate", handleTimeUpdate);
            player.off("loadedmetadata", handleLoadedMetadata);
        };
    }, [player]);

    // Utility to convert seconds to HH:MM:SS or MM:SS
    const formatTime = (timeInSeconds: number) => {
        const
            hours = Math.floor(timeInSeconds / 3600),
            minutes = Math.floor((timeInSeconds % 3600) / 60),
            seconds = Math.floor(timeInSeconds % 60);

        if (hours > 0) {
            return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        } else {
            return `${minutes}:${seconds.toString().padStart(2, "0")}`;
        }
    };

    return (
        <div className={`video-controls__info video-controls--time-display ${isMobile ? 'is-mobile' : 'is-not-mobile'} ${isMobile || isVolumeHoveredOver ? 'volume-is-hovered' : 'volume-not-hovered'}`}>
            <p>
                <span>{!player ? "--:--" : formatTime(currentTime)}</span>
                {" / "}
                <span>{!player ? "--:--" : formatTime(duration)}</span>
            </p>
        </div>
    );
};

export default VideoTimeDisplay;
