import React from "react";

const IconBrowser = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                style={{fill: props?.iconFill}}
                d="M21 4c0-.478-.379-1-1-1H4c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-1.5 5.505V19.5h-15V9.505zm0-1.5h-15V4.5h15zm-2.25-2.5a.75.75 0 110 1.5.75.75 0 010-1.5zm-3 0a.75.75 0 110 1.5.75.75 0 010-1.5z"
            ></path>
        </svg>
    )
};

export default IconBrowser;
