import React from "react";

// Assets
import IconFullScreen from "../../Assets/SVGs/Icons/VideoPlayer/FullScreen";
import IconFullScreenRevert from "../../Assets/SVGs/Icons/VideoPlayer/FullScreenRevert";

interface VideoFullscreenProps {
    fullScreenRef: React.RefObject<HTMLDivElement>;
    isFullscreen: boolean;
    setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
    showControls: boolean;
}

const VideoFullscreen: React.FC<VideoFullscreenProps> = ({ fullScreenRef, isFullscreen, setIsFullscreen, showControls }) => {
    const toggleFullscreen = () => {
        if (!fullScreenRef.current) return;

        console.log(`is currently fullscreen? ${isFullscreen}`);

        if (isFullscreen) {
            document.exitFullscreen()
                .then(() => setIsFullscreen(false))
                .catch((err) => console.error("Error exiting fullscreen:", err));
        } else {
            fullScreenRef.current.requestFullscreen()
                .then(() => setIsFullscreen(true))
                .catch((err) => console.error("Error entering fullscreen:", err));
        }
    };

    return (
        <button
            className="video-controls__button video-controls--fullscreen"
            onClick={toggleFullscreen}
            aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
            { isFullscreen ? IconFullScreenRevert() : IconFullScreen() }
        </button>
    );
};

export default VideoFullscreen;
