import axios, { AxiosError } from "axios";

const BACKEND_URL = process.env.REACT_APP_API_URL + "/slider/notification/1";

export async function GetNotificationBar() {
    const response = await axios.get(BACKEND_URL).then((result) => {
        //console.log("GetNotificationBar");
        //console.log(result.data);
        return result.data;
    }).catch((error: AxiosError) => {
        console.log("error GetNotificationBar");
        throw error;
    });

    return response
};
