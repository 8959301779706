import React, { useEffect, useState, useCallback, useContext } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    LabelList,
} from "recharts";
import {
    fetchVideoMetricsData,
    fetchDailyVideoWatchDetail,
} from "../../Api/VideoMetrics";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { format, parseISO } from "date-fns";

const TOP_COUNTRY_COLORS: string[] = [
    "#8884d8", // Blue
    "#ff7300", // Orange
    "#00c49f", // Green
    "#ffbb28", // Yellow
    "#ff8042", // Red
    "#6a0dad", // Purple
    "#20c997", // Teal
    "#ff1493", // Pink
];

const TotalViewsReport: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [details, setDetails] = useState<any[]>([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const authCtx = useContext(UserAuthenticationContext);

    const access_token = authCtx.userData.Access_Token || "";
    const [countryKeys, setCountryKeys] = useState<string[]>([]);
    const [countryColors, setCountryColors] = useState<{
        [key: string]: string;
    }>({});

    const COUNTRY_NAMES: { [key: string]: string } = {
        AF: "Afghanistan",
        AL: "Albania",
        DZ: "Algeria",
        AD: "Andorra",
        AO: "Angola",
        AR: "Argentina",
        AM: "Armenia",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BH: "Bahrain",
        BD: "Bangladesh",
        BY: "Belarus",
        BE: "Belgium",
        BJ: "Benin",
        BO: "Bolivia",
        BA: "Bosnia and Herzegovina",
        BR: "Brazil",
        BG: "Bulgaria",
        CA: "Canada",
        CL: "Chile",
        CN: "China",
        CO: "Colombia",
        HR: "Croatia",
        CU: "Cuba",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DO: "Dominican Republic",
        EG: "Egypt",
        EE: "Estonia",
        FI: "Finland",
        FR: "France",
        GE: "Georgia",
        DE: "Germany",
        GR: "Greece",
        HK: "Hong Kong",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran",
        IQ: "Iraq",
        IE: "Ireland",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KR: "South Korea",
        KW: "Kuwait",
        LV: "Latvia",
        LB: "Lebanon",
        LT: "Lithuania",
        LU: "Luxembourg",
        MY: "Malaysia",
        MX: "Mexico",
        MA: "Morocco",
        NP: "Nepal",
        NL: "Netherlands",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NG: "Nigeria",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PA: "Panama",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PL: "Poland",
        PT: "Portugal",
        QA: "Qatar",
        RO: "Romania",
        RU: "Russia",
        SA: "Saudi Arabia",
        RS: "Serbia",
        SG: "Singapore",
        SK: "Slovakia",
        SI: "Slovenia",
        ZA: "South Africa",
        ES: "Spain",
        LK: "Sri Lanka",
        SE: "Sweden",
        CH: "Switzerland",
        TW: "Taiwan",
        TH: "Thailand",
        TR: "Turkey",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UY: "Uruguay",
        VE: "Venezuela",
        VN: "Vietnam",
        YE: "Yemen",
        ZW: "Zimbabwe",
    };

    useEffect(() => {
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                const response = await fetchVideoMetricsData(
                    access_token,
                    abortController
                );
                console.log("Raw API Response:", response);

                // ✅ Format date & calculate total views per country
                const countryTotals: { [key: string]: number } = {};
                const formattedData = response.map((entry) => {
                    let totalViews = 0;

                    Object.keys(entry).forEach((key) => {
                        if (key !== "Date") {
                            totalViews += entry[key] || 0;
                            countryTotals[key] =
                                (countryTotals[key] || 0) + entry[key];
                        }
                    });

                    return {
                        ...entry,
                        Date: entry.Date.split("T")[0], // Remove time
                        TotalViews: totalViews, // Store daily total views
                    };
                });

                // ✅ Sort countries from highest to lowest total views
                const sortedCountries = Object.entries(countryTotals)
                    .sort(([, a], [, b]) => b - a) // Sort descending
                    .map(([country]) => country);

                console.log(
                    "Sorted Countries by Total Views:",
                    sortedCountries
                );

                // Assign colors dynamically to top 8 countries
                const dynamicCountryColors: { [key: string]: string } = {};
                sortedCountries.forEach((country, index) => {
                    dynamicCountryColors[country] =
                        index < TOP_COUNTRY_COLORS.length
                            ? TOP_COUNTRY_COLORS[index]
                            : "#999"; // Default color if extra
                });

                setCountryKeys(sortedCountries);
                setCountryColors(dynamicCountryColors);
                setData(formattedData);
            } catch (err) {
                setError("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        return () => abortController.abort();
    }, [access_token]);

    /** ✅ Click handler using Bar Data */
    const handleBarClick = useCallback(
        async (barData: any) => {
            if (!barData || !barData.Date) return;

            console.log("Clicked Date:", barData.Date);
            setSelectedDate(barData.Date);
            setLoadingDetails(true);
            const abortController = new AbortController();

            try {
                const detailData = await fetchDailyVideoWatchDetail(
                    barData.Date,
                    access_token,
                    abortController
                );
                setDetails(detailData);
            } catch (err) {
                setError("Failed to load details");
            } finally {
                setLoadingDetails(false);
            }
        },
        [access_token]
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: "red" }}>{error}</p>;

    return (
        <div
            style={{
                width: "100%",
                height: "auto",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
            }}
        >
            <h2 style={{ textAlign: "center", color: "#333" }}>
                Top 8 Countries - Videos Watched (Last 7 Days)
            </h2>

            <ResponsiveContainer width="100%" height={450}>
                <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis
                        dataKey="Date"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        tickFormatter={(dateString) => {
                            try {
                                return format(
                                    parseISO(dateString),
                                    "dd-MM-yyyy"
                                );
                            } catch (error) {
                                return dateString; // Fallback
                            }
                        }}
                    />

                    <YAxis tick={{ fontSize: 12, fontWeight: "bold" }} />
                    <Tooltip
                        cursor={{ fill: "rgba(136,132,216,0.2)" }}
                        formatter={(value: number, name: string, item) => {
                            // Ensure payload exists
                            if (!item || !Array.isArray(item.payload))
                                return [
                                    `${value} Views`,
                                    COUNTRY_NAMES[name] || name,
                                ];

                            // ✅ Extract & sort tooltip data dynamically
                            const sortedEntries = item.payload
                                .map((entry) => ({
                                    country:
                                        COUNTRY_NAMES[entry.name as string] ||
                                        entry.name, // Translate country code
                                    views:
                                        typeof entry.value === "number"
                                            ? entry.value
                                            : 0, // Ensure numeric values
                                }))
                                .sort((a, b) => b.views - a.views); // Sort highest → lowest

                            // ✅ Return sorted entries in correct format
                            return sortedEntries.map((entry) => [
                                `${entry.views} Views`,
                                entry.country,
                            ]);
                        }}
                    />

                    <Legend
                        verticalAlign="top"
                        wrapperStyle={{ fontSize: "14px" }}
                    />

                    {/* ✅ Show Total Views Above Each Bar */}
                    <Bar
                        dataKey="TotalViews"
                        fill="transparent"
                        stackId="total"
                        pointerEvents="none"
                    >
                        <LabelList
                            dataKey="TotalViews"
                            position="top"
                            fill="#333"
                            fontSize={14}
                            fontWeight="bold"
                            formatter={(value: number) => `${value} Views`}
                        />
                    </Bar>

                    {/* ✅ Render stacked bars per country in sorted order */}
                    {countryKeys.map((country) => (
                        <Bar
                            key={country}
                            dataKey={country}
                            stackId="a"
                            fill={countryColors[country]}
                            name={COUNTRY_NAMES[country] || country}
                            cursor="pointer"
                            onClick={(event) => handleBarClick(event)}
                        >
                            <LabelList
                                dataKey={country}
                                position="inside"
                                fill="#fff"
                                fontSize={12}
                                fontWeight="bold"
                            />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>

            {/* ✅ Detailed Table Section */}
            {selectedDate && (
                <div
                    style={{
                        marginTop: "20px",
                        padding: "20px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        overflowX: "auto",
                        position: "relative", // Make it a positioned element
                        zIndex: 10, // Ensure it's above lower layers (e.g., footer icons)
                    }}
                >
                    <h3 style={{ textAlign: "center", color: "#333" }}>
                        Detailed View for{" "}
                        {selectedDate
                            ? new Date(selectedDate).toLocaleDateString("en-GB")
                            : ""}
                    </h3>

                    {loadingDetails ? (
                        <p style={{ textAlign: "center", fontStyle: "italic" }}>
                            Loading details...
                        </p>
                    ) : (
                        <table
                            style={{
                                width: "100%",
                                marginTop: "10px",
                                borderCollapse: "collapse",
                                border: "1px solid #ddd",
                                backgroundColor: "#fff",
                                minWidth: "600px",
                            }}
                        >
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "#8884d8",
                                        color: "#fff",
                                        textAlign: "left",
                                    }}
                                >
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Video Title
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Views
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((detail, index) => (
                                    <tr
                                        key={index}
                                        style={{
                                            backgroundColor:
                                                index % 2 === 0
                                                    ? "#f8f9fa"
                                                    : "#fff",
                                            color: "#333",
                                            transition: "background 0.3s",
                                        }}
                                    >
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.Title}
                                        </td>
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.Qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default TotalViewsReport;