import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    Customized,
} from "recharts";

interface VideoViewsByCountry {
    VDate: string;
    CountryName: string;
    TotalViews: number;
}

interface TotalViewsByCountryProps {
    countryBreakdownData: VideoViewsByCountry[];
}

const TotalViewsByCountry: React.FC<TotalViewsByCountryProps> = ({ countryBreakdownData }) => {
    if (!countryBreakdownData || countryBreakdownData.length === 0) {
        return <p style={{ color: "red", textAlign: "center" }}>No data available for the selected date range.</p>;
    }

    // Flatten data with VDate
    const flattenedData = countryBreakdownData.map(({ VDate, CountryName, TotalViews }) => ({
      VDate: String(VDate),
      Country: CountryName.trim(), // Trim spaces to ensure correct matching
      TotalViews,
  }));
  

    // Group countries by VDate
    const dateGroups: Record<string, string[]> = {};
    for (const row of flattenedData) {
        if (!dateGroups[row.VDate]) {
            dateGroups[row.VDate] = [];
        }
        dateGroups[row.VDate].push(row.Country);
    }

    // Determine Y-axis max value
    const maxViews = Math.max(...flattenedData.map((d) => d.TotalViews));

    return (
        <div style={{ width: "100%", padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "10px" }}>
            <h2 style={{ textAlign: "center", color: "#333" }}>Total Views by Country</h2>

            <ResponsiveContainer width="100%" height={450}>
                <BarChart data={flattenedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} barCategoryGap="20%">
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />

                    <Customized
                        component={(props) => {
                            console.log("Customized component is rendering!");
                            const { xAxisMap, yAxisMap, data } = props as any;
                            if (!xAxisMap || !xAxisMap[0] || !data) {
                                console.log("Customized component did NOT get valid props!");
                                return null;
                            }

                            const xScale = xAxisMap[0].scale;
                            const bandWidth = xScale.bandwidth?.() || 0;
                            const chartHeight = yAxisMap?.[0]?.height || 0;
                            const chartY = yAxisMap?.[0]?.y || 0;

                            const uniqueDates: string[] = Array.from(new Set(data.map((d: any) => String(d.VDate))));
                            console.log("Unique VDates detected:", uniqueDates);

                            const separators: JSX.Element[] = [];

                            for (let i = 1; i < uniqueDates.length; i++) {
                                const prevDate = uniqueDates[i - 1];
                                const currDate = uniqueDates[i];

                                const prevCountries = data
                                    .filter((d: any) => String(d.VDate) === prevDate)
                                    .map((d: any) => d.Country);
                                const currCountries = data
                                    .filter((d: any) => String(d.VDate) === currDate)
                                    .map((d: any) => d.Country);

                                console.log(`Prev Date: ${prevDate}, Countries:`, prevCountries);
                                console.log(`Curr Date: ${currDate}, Countries:`, currCountries);

                                // Extract X positions
                                const prevXPositions = prevCountries
                                    .map((c: string) => xScale(c) as number | undefined)
                                    .filter((x: number | undefined): x is number => x !== undefined);

                                const currXPositions = currCountries
                                    .map((c: string) => xScale(c) as number | undefined)
                                    .filter((x: number | undefined): x is number => x !== undefined);

                                if (prevXPositions.length === 0 || currXPositions.length === 0) {
                                    console.log(`Skipping separator for ${currDate} - No valid X positions`);
                                    continue;
                                }

                                const prevMaxX = Math.max(...prevXPositions) + bandWidth;
                                const currMinX = Math.min(...currXPositions);

                                console.log(`Separator for ${currDate} → prevMaxX: ${prevMaxX}, currMinX: ${currMinX}`);

                                if (!isNaN(prevMaxX) && !isNaN(currMinX) && currMinX > prevMaxX) {
                                    const separatorX = (prevMaxX + currMinX) / 2;
                                    console.log(`Drawing separator at X=${separatorX}`);

                                    separators.push(
                                        <line
                                            key={`separator-${currDate}`}
                                            x1={separatorX}
                                            y1={chartY}
                                            x2={separatorX}
                                            y2={chartY + chartHeight}
                                            stroke="red"
                                            strokeWidth={2}
                                            strokeDasharray="5 5"
                                        />
                                    );
                                }
                            }

                            console.log("Total separators created:", separators.length);
                            return <>{separators}</>;
                        }}
                    />

                    <XAxis
                        dataKey="Country"
                        type="category"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        angle={-75}
                        textAnchor="end"
                        interval={0}
                        height={120}
                    />

                    <YAxis scale="log" domain={[1, maxViews + 10]} />

                    <Tooltip
                        formatter={(value, name, props) => [`${value} Views`, `${props.payload?.Country}`]}
                    />

                    <Legend verticalAlign="top" wrapperStyle={{ fontSize: "14px" }} />

                    <Bar dataKey="TotalViews" name="Total Views" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

// ✅ Fix: Ensure the file is recognized as a module
export default TotalViewsByCountry;
