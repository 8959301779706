import styled from "styled-components";
import ICommentAndPersonDTO from "../Models/DTOs/ICommentAndPersonDTO";
import CommentInput from "./UI/Comments/CommentInput";
import CommentItem from "./UI/Comments/CommentItem";
import PrimaryText from "./UI/Text/PrimaryText";

const CommentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 32px 0;
    width: 60%;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`;


function VideoComments(props: {
    videoId: number;
    comments: ICommentAndPersonDTO[];
    UpdateComments: (videoId: number) => void;
    disable?: boolean;
    disableText?: string;
}) {
    const
        displayUnsubscribePlaceholder = props.disable ?? false,
        disableText = props.disableText ?? "Sign up to write a comment.",
        placeholder = displayUnsubscribePlaceholder ? disableText : "Write a comment here...",
        RenderComment = (comments: ICommentAndPersonDTO[]) => {
            let result = comments.map((item) => {

                // TODO: Fix count for nested replies i.e. count for a reply of replies
                let childrenCount = props.comments.filter(
                    (x) =>
                        x.Comment.MasterCommentId === item.Comment.Id ||
                        x.Comment.ReplyId === item.Comment.Id
                ).length;

                // Sorts children by date
                let directChildren = props.comments
                    .filter((x) => x.Comment.ReplyId === item.Comment.Id)
                    .sort(function (a, b) {
                        return Date.parse(a.Comment.DatePosted) - Date.parse(b.Comment.DatePosted);
                    });

                return (
                    <CommentItem
                        key={ item.Comment.Id }
                        CommentPerson={ item }
                        count={ childrenCount }
                        videoId={ props.videoId }
                        UpdateComments={ props.UpdateComments }
                        disableInputs={ props.disable }
                    >
                        { RenderComment(directChildren) }
                    </CommentItem>
                );
            });

            return result;
        };

    return (
        <CommentsContainer>
            <PrimaryText>
                { props.comments.length.toString() } Comments
            </PrimaryText>

            <CommentInput
                videoId={ props.videoId }
                placeholder={ placeholder }
                UpdateComments={ props.UpdateComments }
                disableInput={ props.disable }
            />

            { RenderComment(
                props.comments.filter(
                    (x) => x.Comment.MasterCommentId === null && x.Comment.ReplyId === null
                )
            ) }
        </CommentsContainer>
    );
}

export default VideoComments;
