import React from "react";

const IconFacebook = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path
                style={{fill: props?.iconFill}}
                d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615z"></path>
        </svg>
    )
};

export default IconFacebook;
