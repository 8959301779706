import React, { useEffect, useState, useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  LabelList,
} from "recharts";
import {
  fetchThirtyDayCancellations,
  fetchCancellationReasonAndContent,
} from "../../Api/VideoMetrics";
import { format, parseISO } from "date-fns";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { Button } from "antd";

interface CancellationReason {
  Reason: string;
  Content: string;
}

const ThirtyDayCancellations: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cancelReportDays, setCancelReportDays] = useState<number>(30);
  const [inputError, setInputError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [cancellationDetails, setCancellationDetails] = useState<CancellationReason[]>([]);
  const [detailLoading, setDetailLoading] = useState<boolean>(false);

  const authCtx = useContext(UserAuthenticationContext);
  const access_token = authCtx.userData.Access_Token || "";

  // -------------------------
  // Data Loading for Main Chart
  // -------------------------
  useEffect(() => {
    const loadData = async () => {
      if (cancelReportDays < 1 || cancelReportDays > 365) {
        setInputError("Please select a value between 1 and 365 days.");
        return;
      }
      setInputError(null);
      setLoading(true);
      try {
        const response = await fetchThirtyDayCancellations(cancelReportDays, access_token);
        setData(response);
      } catch (err) {
        console.error("Error fetching cancellations:", err);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [cancelReportDays, access_token]);

  // -------------------------
  // Export Main Chart Data as CSV
  // -------------------------
  const exportMainCSV = () => {
    if (data.length === 0) return;
    const keys = Object.keys(data[0]);
    let csv = keys.join(",") + "\n";
    data.forEach((row) => {
      const line = keys
        .map((key) => {
          const cell = row[key] !== null && row[key] !== undefined ? row[key].toString() : "";
          return `"${cell.replace(/"/g, '""')}"`;
        })
        .join(",");
      csv += line + "\n";
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `ThirtyDayCancellations_${cancelReportDays}Days_${new Date().toISOString()}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // -------------------------
  // Export Cancellation Details as CSV (with detailed header)
  // -------------------------
  const exportDetailsCSV = () => {
    if (cancellationDetails.length === 0) return;
    const totalCancellations = cancellationDetails.length;
    const headerInfo = `Cancellation Details Report for ${selectedDate || "N/A"}, Total Cancellations: ${totalCancellations}\n\n`;
    const headers = ["Reason", "Content"];
    let csv = headerInfo + headers.join(",") + "\n";
    cancellationDetails.forEach((detail) => {
      const line = headers
        .map((key) => {
          const cell = detail[key as keyof CancellationReason] || "";
          return `"${cell.toString().replace(/"/g, '""')}"`;
        })
        .join(",");
      csv += line + "\n";
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `CancellationDetails_${selectedDate || "details"}_${new Date().toISOString()}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // -------------------------
  // Handle Bar Click Event to Load Details
  // -------------------------
  const handleBarClick = async (barData: any) => {
    try {
      if (!barData?.Period) return;
      const formattedDate = format(parseISO(barData.Period), "dd-MM-yyyy");
      setSelectedDate(formattedDate);
      setDetailLoading(true);
      const details = await fetchCancellationReasonAndContent(
        formattedDate,
        access_token,
        new AbortController()
      );
      setCancellationDetails(details);
    } catch (err) {
      console.error("Error fetching cancellation details:", err);
    } finally {
      setDetailLoading(false);
    }
  };

  if (error) return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;

  return (
    <div className="date-range-report-container">
      {/* Report Header with Title on Left and Export Buttons on Right */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        <h2 className="report-title" style={{ margin: 0 }}>
          {cancelReportDays}-Day Cancellations
        </h2>
        <div style={{ display: "flex", gap: "20px" }}>
          <Button type="primary" onClick={exportMainCSV}>
            Export Main Report
          </Button>
          <Button
            type="primary"
            onClick={exportDetailsCSV}
            disabled={!selectedDate || cancellationDetails.length === 0}
          >
            Export Cancellation Details
          </Button>
        </div>
      </div>

      {/* Top Bar with Days Input */}
      <div className={`top-bar ${inputError ? "top-bar-error" : ""}`}>
        <div className="date-range-picker">
          <div className="date-picker-container">
            <label className="date-picker-label">Select Days:</label>
            <input
              type="number"
              min="1"
              max="365"
              value={cancelReportDays}
              onChange={(e) => {
                const value = Number(e.target.value);
                setCancelReportDays(value > 0 ? value : 1);
              }}
              className="custom-date-input"
            />
            {inputError && <p className="inline-error">{inputError}</p>}
          </div>
        </div>
      </div>

      {/* Main Chart */}
      {loading ? (
        <p>Loading report...</p>
      ) : (
        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis
              dataKey="Period"
              tick={{ fontSize: 12, fontWeight: "bold", fill: "#000" }}
              tickFormatter={(dateString) => {
                try {
                  if (!dateString) return "";
                  const parsedDate = parseISO(dateString);
                  return format(parsedDate, "dd-MM-yyyy");
                } catch (error) {
                  console.error("Invalid date format:", dateString, error);
                  return dateString;
                }
              }}
            />
            <YAxis tick={{ fontSize: 12, fontWeight: "bold", fill: "#000" }} allowDecimals={false} />
            <Tooltip formatter={(value) => [`${value} Cancellations`, "Cancellations"]} />
            <Legend verticalAlign="top" wrapperStyle={{ fontSize: "14px" }} />
            <Bar dataKey="Cancelled" fill="#ff7300" onClick={handleBarClick}>
              <LabelList
                dataKey="Cancelled"
                position="top"
                fill="#333"
                fontSize={14}
                fontWeight="bold"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}

      {/* Cancellation Details Section */}
      {selectedDate &&
        (() => {
          // Process cancellation details for display
          const reasonCounts: { [key: string]: number } = {};
          const groupedComments: { [key: string]: string[] } = {};
          cancellationDetails.forEach((detail) => {
            reasonCounts[detail.Reason] = (reasonCounts[detail.Reason] || 0) + 1;
            if (!groupedComments[detail.Reason]) {
              groupedComments[detail.Reason] = [];
            }
            groupedComments[detail.Reason].push(detail.Content || "No comment");
          });
          const totalCancellations = cancellationDetails.length;
          const reasonColors: { [key: string]: string } = {
            "Too Expensive": "#ff7300",
            "Technical Issues": "#6a5acd",
            Other: "#00c49f",
            UnsatisfiedWithContent: "#ff8042",
            "Didn't Use": "#ffbb28",
            "Prefer Competitor": "#20c997",
          };
          const groupedData = Object.keys(groupedComments).map((reason) => ({
            reason,
            comments: groupedComments[reason],
          }));
          return (
            <div
              style={{
                marginTop: "20px",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.15)",
                width: "100%",
                maxWidth: "none",
                overflowX: "auto",
                position: "relative",
                zIndex: 1000,
                border: "1px solid #ddd",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: "#333",
                  marginBottom: "15px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#6a5acd",
                    color: "#fff",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    fontSize: "18px",
                  }}
                >
                  Cancellation Details for {selectedDate}
                </span>
              </h3>
              {detailLoading ? (
                <p style={{ textAlign: "center", fontStyle: "italic", color: "#6a5acd" }}>
                  Loading details...
                </p>
              ) : totalCancellations > 0 ? (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    {Object.entries(reasonCounts).map(([reason, count]) => {
                      const reasonPercentage = ((count / totalCancellations) * 100).toFixed(1);
                      const barColor = reasonColors[reason] || "#888";
                      return (
                        <div
                          key={reason}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "8px",
                            gap: "8px",
                          }}
                        >
                          <div style={{ flex: "0 0 120px", fontWeight: "600", color: "#333" }}>
                            {reason}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              backgroundColor: "#eee",
                              borderRadius: "4px",
                              height: "8px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: `${reasonPercentage}%`,
                                backgroundColor: barColor,
                                borderRadius: "4px",
                                height: "8px",
                              }}
                            ></div>
                          </div>
                          <span style={{ flex: "0 0 50px", textAlign: "right", fontSize: "12px", color: "#666" }}>
                            {reasonPercentage}%
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ width: "100%", overflowX: "auto" }}>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid #ddd",
                        backgroundColor: "#fff",
                        textAlign: "left",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#6a5acd",
                            color: "#fff",
                            textAlign: "left",
                          }}
                        >
                          <th
                            style={{
                              padding: "12px",
                              borderBottom: "2px solid #ddd",
                              minWidth: "250px",
                              whiteSpace: "nowrap",
                              fontWeight: "bold",
                            }}
                          >
                            Reason
                          </th>
                          <th
                            style={{
                              padding: "12px",
                              borderBottom: "2px solid #ddd",
                              width: "70%",
                              fontWeight: "bold",
                            }}
                          >
                            Comments
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedData.map((group, index) => {
                          const rowColor = index % 2 === 0 ? "#f9f9f9" : "#ececec";
                          return (
                            <tr
                              key={group.reason}
                              style={{
                                backgroundColor: rowColor,
                                color: "#333",
                                transition: "background 0.3s",
                                cursor: "pointer",
                              }}
                              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#ddd1ff")}
                              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = rowColor)}
                            >
                              <td
                                style={{
                                  padding: "12px",
                                  borderBottom: "1px solid #ddd",
                                  fontWeight: "600",
                                  color: "#333",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {group.reason}
                              </td>
                              <td
                                style={{
                                  padding: "12px",
                                  borderBottom: "1px solid #ddd",
                                  width: "70%",
                                }}
                              >
                                {group.comments.map((comment, cIndex) => (
                                  <div
                                    key={cIndex}
                                    style={{
                                      marginBottom: "6px",
                                      fontStyle: comment === "No comment" ? "italic" : "normal",
                                      color: comment === "No comment" ? "#888" : "#333",
                                    }}
                                  >
                                    - {comment}
                                  </div>
                                ))}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <p style={{ textAlign: "center", fontStyle: "italic", color: "#6a5acd" }}>
                  No cancellation details found for this date.
                </p>
              )}
            </div>
          );
        })()}
    </div>
  );
};

export default ThirtyDayCancellations;