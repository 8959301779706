import { useEffect, useState } from "react";

// Api
import { GetFilms } from "../../../Api/Carousel";

// Models
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";

function useFetchFilms(
    countryCode: string,
    userId: string,
    take: number,
    skip: number,
    shouldAppendData: boolean
) {
    const
        [films, setFilms] = useState<ICarouselContentDTO[]>([]),
        [filmsLoading, setFilmsLoading] = useState<boolean>(false),
        [filmsError, setFilmsError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        setFilmsLoading(true);

        (async () => {
            try {
                const data = await GetFilms(countryCode, userId, take, skip);

                if (!isCancelled && data !== null) {
                    if (shouldAppendData) {
                        setFilms((prev) => [...prev, ...data]);
                    } else {
                        setFilms(data);
                    }
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setFilmsError("Failed to fetch films.");
                }
            } finally {
                if (!isCancelled) {
                    setFilmsLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
        };
        // Re-run whenever userId, countryCode, take, or skip changes
    }, [take, skip]);

    return { films, filmsLoading, filmsError };
}

export default useFetchFilms;
