import React from "react";

const IconPenWriting = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                style={{fill: props?.iconFill}}
                d="M19 20.25a.772.772 0 00-.75-.75H3.75c-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-7.403-3.398l9.124-9.125A.982.982 0 0021 7.043a.92.92 0 00-.28-.662l-3.115-3.104a.95.95 0 00-1.344 0L7.118 12.38c-.569 1.763-1.555 4.823-1.626 5.081a.867.867 0 00-.029.224c0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342l2.137 2.137-3.168 1.046zm.955-1.166l7.651-7.616 2.335 2.327-7.637 7.638z"
            > </path>
        </svg>
    )
};

export default IconPenWriting;
