import React from "react";

const IconList = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={ props?.iconFill }
                fillRule="nonzero"
                d="M3.3 15.4a1.301 1.301 0 0 1 0 2.6 1.301 1.301 0 0 1 0-2.6M6 17.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H6.75a.75.75 0 0 1-.75-.75M3.3 10.7a1.301 1.301 0 0 1 0 2.6 1.301 1.301 0 0 1 0-2.6M6 12a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12M3.3 6a1.301 1.301 0 0 1 0 2.6 1.301 1.301 0 0 1 0-2.6m2.7.75A.75.75 0 0 1 6.75 6h14.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 6.75"
            > </path>
        </svg>
    )
};

export default IconList;
