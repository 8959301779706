export function SymbolFromCurrency(currency?: string) {
    const gbp = "\u00A3";

    if (currency === null || currency === undefined || currency.length <= 0) {
        // GBP
        return gbp;
    }

    switch (currency.toUpperCase()) {
        case "USD":
            return "\u0024";
        case "NZD":
            return "NZ \u0024";
        case "AUD":
            return "AU \u0024";
        case "CAD":
            return "C \u0024";
        case "EUR":
            return "\u20AC";
        case "JPY":
            return "\u00A5";
        case "SEK":
            return "SEK";
        default:
            // GBP
            return gbp;
    }
}
