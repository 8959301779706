import BadWords from "../Assets/BadWords.json";

export function ContainsBadWords(text: string) {
    if (text.length <= 0) {
        return false;
    }

    const badWords = BadWords as string[];
    const contains = badWords.some((x) => text.split(/\s+/).includes(x));

    return contains;
}

export function IsCommentValid(text: string) {
    const regex = new RegExp(
        "^((?!(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:\\/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+).)([A-Za-z0-9!\\-.,;_#£&$%?\\u00C0-\\u00D6\\u00D8-\\u00f6\\u00f8-\\u00ff\\s]*)$",
        "gm"
    );

    return regex.test(text);
}
