import React, { useEffect, useState, useRef } from 'react';
import Player from "video.js/dist/types/player";

interface VideoProgressBarProps {
    player?: Player;
}

const VideoProgressBar: React.FC<VideoProgressBarProps> = ({ player }) => {
    const
        [progress, setProgress] = useState(0),
        [buffered, setBuffered] = useState(0),
        containerRef = useRef<HTMLDivElement | null>(null),
        isDraggingRef = useRef<boolean>(false),

        // Used to throttle scrubbing / dragging across the progress bar
        lastSeekTimeRef = useRef<number>(0);

    /**
     * 1) Update 'progress' when the player's current time changes
     */
    useEffect(() => {
        if (!player) return;

        const handleTimeUpdate = () => {
            // Don't override if we're actively dragging
            if (isDraggingRef.current) return;

            const
                current = player.currentTime() ?? 0,
                duration = player.duration() || 0,
                newProgress = duration ? (current / duration) * 100 : 0;

            setProgress(newProgress);
        };

        player.on("timeupdate", handleTimeUpdate);

        return () => {
            player.off("timeupdate", handleTimeUpdate);
        };
    }, [player]);

    /**
     * 2) Update 'buffered' when the player reports progress on loading
     */
    useEffect(() => {
        if (!player) return;

        const handleProgress = () => {
            const duration = player.duration();
            if (!duration || duration === 0) {
                setBuffered(0);
                return;
            }
            const bufferedRanges = player.buffered(); // TimeRanges
            let maxBufferedEnd = 0;

            // Find furthest buffered range
            for (let i = 0; i < bufferedRanges.length; i++) {
                const end = bufferedRanges.end(i);
                if (end > maxBufferedEnd) {
                    maxBufferedEnd = end;
                }
            }

            const bufferedPercent = (maxBufferedEnd / duration) * 100;
            setBuffered(bufferedPercent);
        };

        player.on("progress", handleProgress);
        handleProgress(); // in case it's already buffered some

        return () => {
            player.off("progress", handleProgress);
        };
    }, [player]);

    /**
     * 3) Global pointermove/pointerup for dragging (scrubbing)
     */
    useEffect(() => {
        const handleGlobalPointerMove = (e: PointerEvent) => {
            if (!player || !containerRef.current || !isDraggingRef.current) return;

            // Throttle: only allow a seek if it’s been >= 50 ms
            const now = Date.now();
            if (now - lastSeekTimeRef.current < 50) {
                return;
            }
            lastSeekTimeRef.current = now;

            const
                { left, width } = containerRef.current.getBoundingClientRect(),
                clampedX = Math.max(left, Math.min(e.clientX, left + width)),
                ratio = (clampedX - left) / width;

            setProgress(ratio * 100);

            const duration = player.duration() || 0;
            player.currentTime(duration * ratio);
        };

        const handleGlobalPointerUp = () => {
            isDraggingRef.current = false;
        };

        // Listen at the window level so pointer drags still work if user
        // moves pointer out of the progress bar
        window.addEventListener("pointermove", handleGlobalPointerMove);
        window.addEventListener("pointerup", handleGlobalPointerUp);

        return () => {
            window.removeEventListener("pointermove", handleGlobalPointerMove);
            window.removeEventListener("pointerup", handleGlobalPointerUp);
        };
    }, [player]);

    /**
     * 4) onPointerDown on the bar
     *    - If user taps/clicks the bar (not the thumb), jump to that position
     */
    const handleBarPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!player || !containerRef.current) return;

        // If user clicked the thumb, do nothing here.
        if ((e.target as HTMLElement).classList.contains("progress-bar__thumb")) {
            return;
        }

        // Otherwise, it's a bar click => jump immediately
        const
            { left, width } = containerRef.current.getBoundingClientRect(),
            ratio = (e.clientX - left) / width;

        setProgress(ratio * 100);

        player.currentTime((player.duration() || 0) * ratio);
    };

    /**
     * 5) onPointerDown on the thumb
     *    - Start dragging
     */
    const handleThumbPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!player) return;

        // Capture future pointer events so we keep receiving them,
        // even if the user drags outside the thumb
        e.currentTarget.setPointerCapture(e.pointerId);

        isDraggingRef.current = true;
    };

    return (
        <div
            className="video-player__progress-bar"
            ref={containerRef}
            onPointerDown={handleBarPointerDown}
            style={{
                // KEY: disable default scrolling/zooming behavior on touch devices
                touchAction: "none",
                cursor: "pointer"
            }}
        >
            <div className="progress-bar__inner">
                {/* The buffered portion (behind the "played" portion) */}
                <div
                    className="progress-bar__buffered"
                    style={{
                        width: `${buffered}%`,
                    }}
                />

                {/* The played (watched) portion */}
                <div
                    className="progress-bar__progress"
                    style={{
                        width: `${progress}%`,
                    }}
                >
                    {/* The draggable thumb */}
                    <div
                        className="progress-bar__thumb"
                        onPointerDown={handleThumbPointerDown}
                        style={{
                            // Make sure the thumb can be grabbed
                            cursor: "grab",
                            // Also disable default touch actions on the thumb itself
                            touchAction: "none"
                        }}
                    >
                        <div className="progress-bar__thumb-nail" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoProgressBar;
