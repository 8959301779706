import React from "react";

const IconDiamond = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            fill="none"
            viewBox="0 0 17 16"
        >
            {props?.defs ? props.defs : null}
            <path
                style={{fill: props?.iconFill}}
                fillRule="evenodd"
                d="M5 2a.5.5 0 0 0-.376.17l-3.5 4a.5.5 0 0 0 .01.671l6.997 7.497a.5.5 0 0 0 .143.108.5.5 0 0 0 .595-.108l6.997-7.497a.5.5 0 0 0 .01-.67l-3.5-4A.5.5 0 0 0 12 2H5m2.5 1H5.227L2.602 6H5.25zM5.161 7h-2.51l4.394 4.708zm4.794 4.708L14.35 7h-2.51zM10.761 7 8.5 12.654 6.239 7zM10.5 6h-4l2-2.667zm1.25 0L9.5 3h2.273l2.625 3z"
                clipRule="evenodd"
            ></path>
            <path
                style={{fill: props?.iconFill}}
                fillRule="evenodd"
                d="M5 2a.5.5 0 0 0-.376.17l-3.5 4a.5.5 0 0 0 .01.671l6.997 7.497a.5.5 0 0 0 .143.108.5.5 0 0 0 .595-.108l6.997-7.497a.5.5 0 0 0 .01-.67l-3.5-4A.5.5 0 0 0 12 2H5m2.5 1H5.227L2.602 6H5.25zM5.161 7h-2.51l4.394 4.708zm4.794 4.708L14.35 7h-2.51zM10.761 7 8.5 12.654 6.239 7zM10.5 6h-4l2-2.667zm1.25 0L9.5 3h2.273l2.625 3z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
};

export default IconDiamond;
