import { useState, useEffect } from "react";

// API
import { GetRandomPartner } from "../../Api/Partner";

// Models
import IPartnerDTO from "../../Models/DTOs/IPartnerDTO";

function useFetchRandomPartner(trigger: boolean) {
    const [partner, setPartner] = useState<IPartnerDTO | null>(null);
    const [partnerLoading, setPartnerLoading] = useState<boolean>(false);
    const [partnerError, setPartnerError] = useState<string>("");

    useEffect(() => {
        if (!trigger) return;

        let isCancelled = false;
        const abortController = new AbortController();

        setPartnerLoading(true);

        (async () => {
            try {
                const data = await GetRandomPartner(abortController);
                if (!isCancelled && data) {
                    setPartner(data as IPartnerDTO);
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setPartnerError("Failed to fetch partner.");
                }
            } finally {
                if (!isCancelled) {
                    setPartnerLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [trigger]);

    return { partner, partnerLoading, partnerError };
}

export default useFetchRandomPartner;
