import {PagesWithoutFooter} from "../Constants/PagesWithoutFooter";
import {PagesWithoutPopups} from "../Constants/PagesWithoutPopups";
import {PagesWithoutNavigation} from "../Constants/PagesWithoutNavigation";

export function ShouldHideNavbar(path: string) {
    return PagesWithoutNavigation.includes(path.toLowerCase());
}

export function ShouldHideFooter(path: string) {
    return PagesWithoutFooter.includes(path.toLowerCase());
}

export function NoPopupPage(path: string) {
    return PagesWithoutPopups.includes(path.toLowerCase());
}
