import { AxiosError } from "axios";
import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UpgradeSubscription } from "../../../Api/Stripe";
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { PlanPeriod } from "../../../Models/Enums/PlanPeriod";
import { SubscriptionStatus } from "../../../Models/Enums/SubscriptionStatus";
import GreyButton from "../Buttons/GreyButton";
import PinkButton from "../Buttons/PinkButton";
import Input from "../Inputs/Input";
import Heading from "../Text/Heading";
import ErrorText from "../Text/ErrorText";
import PrimaryText from "../Text/PrimaryText";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";
import {DeviceLimit, TrimPlanTitle} from "../../../Helpers/PlanInformation";
import {SymbolFromCurrency} from "../../../Helpers/Currency";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;

    > * {
        flex-basis: 50%;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    float: right;

    button:first-child {
        flex-basis: 30%;
    }
    button:last-child {
        flex-basis: 65%;
    }
`;

const Split = styled.div``;

const Form = styled.form`
    > p{
        margin: 10px 0;
    }
`;

function PlanSummary(props: {
    selectedPlan: IPlanDTO;
    setSelectedPlan: (plan?: IPlanDTO) => void;
    setSelectedPaymentMethod: (paymentMethod?: string) => void;
    setDisplay: (value: boolean) => void;
    isUpgrade: boolean,
    skipRecap: boolean,
    setShowedRecap: (value: boolean) => void,
    setSuccess?: (value: boolean) => void
}) {
    const authCtx = useContext(UserAuthenticationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const controller = new AbortController();

    function ResetPlan() {
        props.setSelectedPlan(undefined);
    }

    async function SubmitForm() {
        setIsLoading(true);

        const result = await UpgradeSubscription(props.selectedPlan.PlanId, authCtx.userData.AspNetUserId, props.selectedPlan.Type, controller);

        let success;

        if(result instanceof AxiosError){
            success = false;
        }
        else{
            success = result;
        }

        if(props.setSuccess !== undefined){
            props.setSuccess(success);
        }

        if(!success){
            props.setSelectedPaymentMethod(undefined);
            setErrorMessage("Subscription failed to upgrade. Please contact support");
            setIsLoading(false);
            props.setShowedRecap(true);
            return;
        }

        authCtx.refreshUserData(authCtx.userData.Access_Token);

        props.setSelectedPlan(undefined);
        props.setSelectedPaymentMethod(undefined);
        setErrorMessage(undefined);
        setIsLoading(false);
        props.setDisplay(false);
        toast.success("Your subscription has been sucessfully updated");
    }

    async function OnSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await SubmitForm();
    }

    useEffect(() => {
        async function autoComplete() {
            await SubmitForm();
        }

        if (props.skipRecap) {
            autoComplete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (props.skipRecap) {
        return (
            <PrimaryText>Loading...</PrimaryText>
        );
    }

    return (
        <Form onSubmit={OnSubmit}>
            <Container>
                <Split>
                    <Heading type={HeadingType.H3}>
                        Subscription level:
                    </Heading>
                    <Input
                        isValid={true}
                        onChange={() => {}}
                        onBlur={() => {}}
                        type={"text"}
                        value={TrimPlanTitle(props.selectedPlan.Title)}
                        disabled={true}
                    />

                    <Heading type={HeadingType.H3}>
                        Billing frequency:
                    </Heading>
                    <Input
                        isValid={true}
                        onChange={() => {}}
                        onBlur={() => {}}
                        type={"text"}
                        value={PlanPeriod[props.selectedPlan.Period]}
                        disabled={true}
                    />
                </Split>

                <Split>
                    <Heading type={HeadingType.H3}>Device limit:</Heading>
                    <Input
                        isValid={true}
                        onChange={() => {}}
                        onBlur={() => {}}
                        type={"text"}
                        value={DeviceLimit(
                            TrimPlanTitle(props.selectedPlan.Title)
                        ).toString()}
                        disabled={true}
                    />
                    <Heading type={HeadingType.H3}>
                        Billing amount:
                    </Heading>
                    <Input
                        isValid={true}
                        onChange={() => {}}
                        onBlur={() => {}}
                        type={"text"}
                        value={
                            SymbolFromCurrency(props.selectedPlan.Currency) +
                            props.selectedPlan.Price
                        }
                        disabled={true}
                    />
                </Split>
            </Container>
            {authCtx.userData.SubscriptionStatus !==
            SubscriptionStatus.Trialing ? (
                <PrimaryText>
                    You will now be charged instantaneously. The charge will
                    include the full upgraded subscription price and a pro-rated
                    refund based on the length of time remaining on your current
                    subscription plan
                </PrimaryText>
            ) : undefined}

            {errorMessage !== undefined ? (
                <ErrorText>{errorMessage}</ErrorText>
            ) : null}
            <ButtonContainer>
                <GreyButton
                    disabled={isLoading}
                    onClick={ResetPlan}
                    type={"button"}
                >
                    Change Plan
                </GreyButton>
                <PinkButton disabled={isLoading} type={"submit"}>
                    Purchase Upgrade
                </PinkButton>
            </ButtonContainer>
        </Form>
    );
}

export default PlanSummary;
