import React from "react";

const IconTheatreMode = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } fillRule="nonzero" d="M22 5c0-.478-.379-1-1-1H3c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-6.5 13.5h-12v-10h12zm1.5 0V8c0-.478-.379-1-1-1H3.5V5.5h17v13zm-6.518-4.943V14.8a.75.75 0 0 0 1.5 0v-3.05a.75.75 0 0 0-.75-.75H8.199a.75.75 0 0 0 0 1.5h1.219L6.22 15.698a.75.75 0 0 0 1.06 1.061z"> </path>
        </svg>
    )
};

export default IconTheatreMode;
