import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CardElement } from '@stripe/react-stripe-js';

interface CreditCardInputProps {
    name: string;
    value: string;
    label: string;
    onFocus: (name: string) => void;
    onBlur: (name: string) => void;
    isDarkMode: boolean;
    hasError?: boolean;
    errorMessage?: string;
    required?: boolean;
    setCardComplete: (complete: boolean) => void;
    updateZipCode: (newZip: string) => void;
}

const InputsStripe = forwardRef<HTMLInputElement, CreditCardInputProps>(({
    name,
    value,
    label,
    onFocus,
    onBlur,
    isDarkMode,
    hasError,
    errorMessage,
    required,
    setCardComplete,
    updateZipCode
}, ref) => {
    const [focusedInput, setFocusedInput] = useState<string>("");
    const { t } = useTranslation();

    const modeSpecificStyles = {
        base: {
            color: isDarkMode ? 'white' : 'black',
            '::placeholder': {
                color: '#888888'
            }
        },
    };

    // CardElement options include postal code by default unless you hide it.
    const cardOptions = {
        style: modeSpecificStyles,
        hidePostalCode: false,
    };

    const handleCardChange = (event: any) => {
        // Set the overall complete status of the CardElement.
        setCardComplete(event.complete);
        // Update zip code in parent if available.
        if (event.value && event.value.postalCode) {
            updateZipCode(event.value.postalCode);
        } else {
            updateZipCode("");
        }
    };

    const handleFocus = () => {
        setFocusedInput(name);
        onFocus(name);
    };

    const handleBlur = () => {
        setFocusedInput(name);
        onBlur(name);
    };

    return (
        <div
            className={`form__input-wrapper form__credit-card ${hasError ? 'has-error' : 'has-no-error'} ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'} ${focusedInput ? "is-focused" : "is-not-focused"} ${value !== '' ? 'has-value' : 'has-no-value'}`}
            style={{display: 'block'}}
        >
            <CardElement
                options={cardOptions}
                onChange={handleCardChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    );
});

export default InputsStripe;
