import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback
} from "react";
import { createRoot } from "react-dom/client";
import { isMobile } from "react-device-detect";
import Player from "video.js/dist/types/player";
import "videojs-contrib-quality-levels";

// Assets
import IconGear from "../../Assets/SVGs/Icons/UI/Gear";
import IconFilters from "../../Assets/SVGs/Icons/UI/Filters";
import IconArrowBack from "../../Assets/SVGs/Icons/UI/ArrowBack";
import IconCheckMarkHollow from "../../Assets/SVGs/Icons/YesOrNo/CheckMarkHollow";
import IconPlaybackSpeed from "../../Assets/SVGs/Icons/VideoPlayer/PlaybackSpeed";

// Helpers
import {throttle} from "../../Helpers/Performance";

interface VideoSettingsProps {
    player: Player;
    isOpen: boolean;
    onToggleOpen?: (value: boolean) => void;
}

interface QualityOption {
    label: string;
    index: number;
    height?: number;
}

const VideoSettings: React.FC<VideoSettingsProps> = ({ player, isOpen, onToggleOpen }) => {
    // Local state for quality and speed settings
    const [qualityOptions, setQualityOptions] = useState<QualityOption[]>([]);
    const [selectedQuality, setSelectedQuality] = useState("auto");
    const [activeSubMenu, setActiveSubMenu] = useState<string>("");
    const [containerHeight, setContainerHeight] = useState<string>("auto");
    const [selectedSpeed, setSelectedSpeed] = useState<number>(1);
    const playbackSpeedOptions = [0.5, 0.75, 1, 1.25, 1.5, 2];

    // Ref for the popup content container
    const containerRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    // Refs used for mobile portal
    const portalContainerRef = useRef<HTMLElement | null>(null);
    const popupRootRef = useRef<ReturnType<typeof createRoot> | null>(null);

    const rowClass = "settings-popup__row";
    const subMenuClass = "settings-popup__slide-in";

    // Toggle open/close of the settings popup
    const handleOpenSettingsPopup = () => {
        if (isOpen) {
            setActiveSubMenu("");
            setContainerHeight("");
        }
        onToggleOpen?.(!isOpen);
    };

    // Quality selection logic
    const selectQualityCore = useCallback(
        (chosenIndex: string, chosenLabel: string) => {
            setSelectedQuality(chosenLabel);
            const qualityLevels = (player as any).qualityLevels();
            if (chosenIndex === "auto") {
                for (let i = 0; i < qualityLevels.length; i++) {
                    qualityLevels[i].enabled = true;
                }
            } else {
                const indexToEnable = parseInt(chosenIndex, 10);
                for (let i = 0; i < qualityLevels.length; i++) {
                    qualityLevels[i].enabled = i === indexToEnable;
                }
            }
            setActiveSubMenu("");
        },
        [player]
    );

    const throttledSelectQuality = useMemo(() => {
        return throttle(selectQualityCore, 800);
    }, [selectQualityCore]);

    const handleSelectSpeed = useCallback(
        (speed: number) => {
            setSelectedSpeed(speed);
            player.playbackRate(speed);
            setActiveSubMenu("");
        },
        [player]
    );

    const handleSelectQuality = (chosenIndex: string, chosenLabel: string) => {
        throttledSelectQuality(chosenIndex, chosenLabel);
    };

    // Update quality options whenever quality levels change
    useEffect(() => {
        if (!player) return;
        const qualityLevels = (player as any).qualityLevels();

        const updateQualityOptions = () => {
            const newOptions: QualityOption[] = [];
            for (let i = 0; i < qualityLevels.length; i++) {
                const level = qualityLevels[i];
                const label = level.height
                    ? `${level.height}p`
                    : `${Math.round(level.bitrate / 1000)} kbps`;
                newOptions.push({ label, index: i, height: level.height });
            }
            newOptions.sort((a, b) => {
                if (a.height && b.height) return a.height - b.height;
                if (!a.height) return 1;
                if (!b.height) return -1;
                return 0;
            });
            setQualityOptions(newOptions);
        };

        const handleAddQualityLevel = () => {
            updateQualityOptions();
        };

        updateQualityOptions();
        qualityLevels.on("addqualitylevel", handleAddQualityLevel);
        return () => {
            qualityLevels.off("addqualitylevel", handleAddQualityLevel);
        };
    }, [player]);

    // Define a callback to measure the container's height
    const measureHeight = useCallback(() => {
        if (!containerRef.current) return;
        // First, try to find an active submenu
        const activeDiv = containerRef.current.querySelector(
            `.${subMenuClass}.is-active`
        );
        if (activeDiv && activeDiv instanceof HTMLElement) {
            setContainerHeight(`${activeDiv.scrollHeight}`);
        } else {
            // Otherwise, default to the main menu's height.
            const mainMenu = containerRef.current.querySelector(`.${rowClass}`);
            if (mainMenu && mainMenu instanceof HTMLElement) {
                setContainerHeight(`${mainMenu.scrollHeight * 2}`);
            }
        }
    }, [subMenuClass, rowClass]);

    // Attach a ResizeObserver to the popup container so that any content change re-measures the height.
    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            measureHeight();
        });
        resizeObserver.observe(containerRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [measureHeight]);

    // Also re-measure when activeSubMenu, isOpen, or qualityOptions change.
    useEffect(() => {
        // Use a slight delay to ensure the DOM updates before measuring.
        const timeoutId = setTimeout(() => {
            measureHeight();
        }, 50);
        return () => clearTimeout(timeoutId);
    }, [activeSubMenu, isOpen, qualityOptions, measureHeight]);

    // Reset active submenu when popup is closed.
    useEffect(() => {
        if (!isOpen) {
            setActiveSubMenu("");
        }
    }, [isOpen]);

    // On mobile, create a portal container outside the video player.
    useEffect(() => {
        if (isMobile) {
            const portalContainer = document.createElement("div");
            portalContainer.className = "video-settings-portal-container";
            document.body.appendChild(portalContainer);
            portalContainerRef.current = portalContainer;
            popupRootRef.current = createRoot(portalContainer);
        }
        return () => {
            if (portalContainerRef.current) {
                if (popupRootRef.current) {
                    popupRootRef.current.unmount();
                }
                document.body.removeChild(portalContainerRef.current);
            }
        };
    }, []);

    // Update portal container classes when isOpen changes.
    useEffect(() => {
        if (isMobile && portalContainerRef.current) {
            if (isOpen) {
                portalContainerRef.current.classList.add("is-open");
                portalContainerRef.current.classList.remove("is-closed");
            } else {
                portalContainerRef.current.classList.add("is-closed");
                portalContainerRef.current.classList.remove("is-open");
            }
        }

        const handleOutsideClick = (event: MouseEvent) => {
            if (
                containerRef.current && !containerRef.current.contains(event.target as Node) &&
                buttonRef.current && !buttonRef.current.contains(event.target as Node)
            ) {
                console.log('click outside');
                onToggleOpen?.(!isOpen);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    // Define the popup content as JSX.
    const popupContent = (
        <div
            ref={containerRef}
            className={`video-controls__settings-popup ${
                isOpen ? "is-open" : "is-closed"
            }`}
            style={{ height: `${containerHeight}px` }}
        >
            <div
                className={`${rowClass} ${
                    activeSubMenu !== "" ? "is-hidden" : "is-visible"
                }`}
                onClick={() => setActiveSubMenu("speed")}
            >
                <p>
                    {IconPlaybackSpeed({ iconFill: "white" })} <span>Speed</span>
                </p>
                <p>{selectedSpeed}x</p>
            </div>

            <div
                className={`${rowClass} ${
                    activeSubMenu !== "" ? "is-hidden" : "is-visible"
                }`}
                onClick={() => setActiveSubMenu("quality")}
            >
                <p>
                    {IconFilters({ iconFill: "white" })} <span>Quality</span>
                </p>
                <p>{selectedQuality}</p>
            </div>

            <div
                className={`${subMenuClass} settings-popup__quality ${
                    activeSubMenu === "quality" ? "is-active" : "is-not-active"
                }`}
            >
                <div
                    className={`settings-popup__submenu-item ${
                        selectedQuality === "auto" ? "is-active" : ""
                    }`}
                    onClick={() => handleSelectQuality("auto", "auto")}
                >
                    Auto{" "}
                    {selectedQuality === "auto" ? IconCheckMarkHollow({ className: "is-active-icon" }) : null}
                </div>

                {qualityOptions.map((opt) => (
                    <div
                        key={opt.index}
                        className={`settings-popup__submenu-item ${
                            selectedQuality === String(opt.label) ? "is-active" : ""
                        }`}
                        onClick={() => handleSelectQuality(String(opt.index), opt.label)}
                    >
                        {opt.label}{" "}
                        {selectedQuality === String(opt.label)
                            ? IconCheckMarkHollow({ className: "is-active-icon" })
                            : null}
                    </div>
                ))}

                <div
                    className="settings-popup__submenu-item"
                    onClick={() => setActiveSubMenu("")}
                >
                    {IconArrowBack({ iconFill: "white" })}
                </div>
            </div>

            <div
                className={`${subMenuClass} settings-popup__playback-speed ${
                    activeSubMenu === "speed" ? "is-active" : "is-not-active"
                }`}
            >
                {playbackSpeedOptions.map((speed) => (
                    <div
                        key={speed}
                        className={`settings-popup__submenu-item ${
                            selectedSpeed === speed ? "is-active" : ""
                        }`}
                        onClick={() => handleSelectSpeed(speed)}
                    >
                        {speed}x{" "}
                        {selectedSpeed === speed ? IconCheckMarkHollow({ className: "is-active-icon" }) : null}
                    </div>
                ))}
                <div
                    className="settings-popup__submenu-item"
                    onClick={() => setActiveSubMenu("")}
                >
                    {IconArrowBack({ iconFill: "white" })}
                </div>
            </div>
        </div>
    );

    // On mobile, render the popup via our portal using createRoot.
    useEffect(() => {
        if (isMobile && popupRootRef.current) {
            popupRootRef.current.render(popupContent);
        }
    }, [
        isMobile,
        popupContent,
        isOpen,
        containerHeight,
        activeSubMenu,
        qualityOptions,
        selectedQuality,
        selectedSpeed,
    ]);

    return (
        <>
            {/* On desktop, render inline; on mobile, the popup is rendered via the portal */}
            {!isMobile && popupContent}
            <button
                ref={buttonRef}
                onClick={handleOpenSettingsPopup}
                className="video-controls__button video-controls__settings"
            >
                {IconGear()}
            </button>
        </>
    );
};

export default VideoSettings;
