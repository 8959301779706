import React, { useRef, useEffect, useState, useCallback, useContext } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import 'videojs-contrib-quality-levels';
import "video.js/dist/video-js.css";
import {isMobile} from "react-device-detect";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import {IPlayerPeakInfo} from "../../Models/IPlayerPeakInfo";

// Components
import VideoProgressBar from "./VideoProgressBar";
import BigCenteredControls from "./BigCenteredControls";
import VideoLoadingOverlay from "./VideoLoadingOverlay";
import VideoFullscreen from "./VideoFullScreen";
import VideoSettings from "./VideoSettings";
import VideoTheatreMode from "./VideoTheatreMode";
import VideoPlayPause from "./VideoPlayPause";
import VideoTimeDisplay from "./VideoTimeDisplay";
import VideoVolume from "./VideoVolume";

// Context
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Hooks
import useTelemetry from "../../Hooks/VideoInfo/useTelemetry";

interface VideoPlayerProps {
    video?: IVideoDTO;
    src?: string;
    autoPlay: boolean;
    playbackAllowed: boolean;
    isTheatreMode?: boolean,
    setIsTheatreMode?: React.Dispatch<React.SetStateAction<boolean>>,
    peak?: IPlayerPeakInfo | null,
    poster?: string,
    isCommentInputFocused?: boolean;
    useTrailerLink?: boolean;
}

// Wait time before hiding controls if no user input provided
const INACTIVITY_TIMEOUT = 3000; // ms

const VideoPlayer: React.FC<VideoPlayerProps> = (
    {
        video,
        src,
        autoPlay,
        playbackAllowed,
        isTheatreMode,
        setIsTheatreMode,
        peak,
        isCommentInputFocused,
        useTrailerLink
    }
) => {
    const

        /** =============================
         * Refs
         ============================= */
        containerRef = useRef<HTMLDivElement | null>(null),
        videoRef = useRef<HTMLVideoElement | null>(null),
        fullScreenRef = useRef<HTMLDivElement | null>(null),
        playerRef = useRef<Player | null>(null),
        isCommentInputFocusedRef = useRef(isCommentInputFocused),

        // tracks the timeout before hiding controls if user provides no further input
        inactivityTimerRef = useRef<number | null>(null),

        /** =============================
         * Context
         ============================= */
        { hasUserInteracted } = useContext(GlobalInterfaceContext),
        { userData } = useContext(UserAuthenticationContext),

        /** =============================
         * State
         ============================= */
        // True when video.js has fully initialized
        [videoPlayerReady, setVideoPlayerReady] = useState(false),

        // State that shows / hides player controls (Play/pause has its own, slightly modified rules)
        [showControls, setShowControls] = useState(true),

        // Tracks if video is playing or not
        [isPlaying, setIsPlaying] = useState(false),

        // Tracks if video uses fullscreen or not
        [isFullscreen, setIsFullscreen] = useState(false),
        [containerHeight, setContainerHeight] = useState<number | undefined>(undefined),
        [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false),
        [isVolumeHoveredOver, setIsVolumeHoveredOver] = useState(false),

        /** =============================
         * Functions
         ============================= */
        // Hides player controls
        hideControls = useCallback(() => {
            if (playerRef.current && !playerRef.current.paused()) {
                setShowControls(false);
                setTimeout(() => {
                    setIsSettingsMenuOpen(false);
                }, 750);
            }
        }, []),

        togglePlay = useCallback(() => {
            const player = playerRef.current;
            if (!player) return;

            if (player.paused()) {
                player.play();
                setIsPlaying(true);
            } else {
                player.pause();
                setIsPlaying(false);
            }
        }, []),

        // Restarts the inactivity timer if user provides input over the player
        resetInactivityTimer = useCallback(() => {
            if (inactivityTimerRef.current) {
                window.clearTimeout(inactivityTimerRef.current);
            }

            inactivityTimerRef.current = window.setTimeout(() => {
                hideControls();
            }, INACTIVITY_TIMEOUT);
        }, [hideControls]),

        // Calls the timer reset function and sets the show/hide state
        showControlsAndResetTimer = useCallback(() => {
            //console.log('showControlsAndResetTimer');
            setShowControls(true);
            resetInactivityTimer();
        }, [resetInactivityTimer]),

        toggleSettingsMenu = (newIsOpen: boolean) => {
            if (showControls) {
                if (isMobile) {
                    if (!newIsOpen && playerRef.current?.paused()) {
                        showControlsAndResetTimer();
                    }

                    if ((hasUserInteracted && newIsOpen && !playerRef.current?.paused()) || (!newIsOpen && playerRef.current?.paused())) {
                        togglePlay();
                    }
                }

                setIsSettingsMenuOpen(newIsOpen);
            }
        },

        /** =============================
         * Input Handlers
         ============================= */
            // Pointer events to show controls + reset timer
            // (Removing onPointerLeave that forcibly hides controls)
        handlePointerMove = () => {
            showControlsAndResetTimer();
        },

        handlePointerTogglePlay = (e: React.MouseEvent) => {
            if (e.button === 0) {
                togglePlay();
                showControlsAndResetTimer();
            }
        },

        handleOnTouchStart = () => {
            if (!showControls && isPlaying) {
                showControlsAndResetTimer();
                return;
            }

            if (showControls && isPlaying) {
                hideControls();
            }
        },

        skipBack10Seconds = useCallback(() => {
            const player = playerRef.current;
            if (player) {
                const currentTime = player.currentTime();

                if (currentTime) {
                    // Ensure the new time is not negative
                    player.currentTime(Math.max(currentTime - 10, 0));
                    showControlsAndResetTimer();
                }
            }
        }, []),

        skipForward10Seconds = useCallback(() => {
            const player = playerRef.current;
            if (player) {
                const currentTime = player.currentTime();
                const duration = player.duration();

                if (currentTime && duration) {
                    // Ensure the new time does not exceed the video duration
                    player.currentTime(Math.min(currentTime + 10, duration));
                    showControlsAndResetTimer();
                }
            }
        }, []);

    /** =============================
     * Initialize the player
     ============================= */
    useEffect(() => {
        if (!videoRef.current) return;
        const
            videoLink = useTrailerLink ? video?.TrailerLink : video?.Link,
            shouldAutoplay = hasUserInteracted && autoPlay,
            source = src !== undefined ? src : (video && video.Id ? videoLink : undefined);

        // console.log(`useTrailerLink ? ${useTrailerLink}`);
        // console.log(`video?.TrailerLink: ${video?.TrailerLink}`);
        // console.log(`source: ${source}`);

        if (!playerRef.current && source && playbackAllowed) {

            const options = {
                autoplay: shouldAutoplay,
                controls: false,
                responsive: true,
                fluid: true,
                sources: [{ src: source, type: "application/x-mpegURL" }]
            };

            playerRef.current = videojs(videoRef.current, options, () => {
                //console.log("Video player ready!");
                setVideoPlayerReady(true);

                if (shouldAutoplay) {
                    setIsPlaying(true);
                    showControlsAndResetTimer();
                }

                // set default focus to container for space-bar play-pause support
                if (containerRef && containerRef.current) {
                    containerRef.current.focus();
                }
            });
        }

        // Cleanup player and state on unmount
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
                setVideoPlayerReady(false);
            }
        };
    }, [video, autoPlay]);

    /** =============================
     * If the video is paused, show controls
     ============================= */
    useEffect(() => {
        if (playerRef.current && playerRef.current.paused()) {
            showControlsAndResetTimer();
        }
    }, [playerRef, showControlsAndResetTimer]);

    /** =============================
     * Listen for "ended" event to set isPlaying to false
     ============================= */
    useEffect(() => {
        if (videoPlayerReady) {
            const player = playerRef.current;
            if (!player) return;

            const handleEnded = () => {
                setIsPlaying(false);
                showControlsAndResetTimer();
            };

            player.on("ended", handleEnded);

            return () => {
                player.off("ended", handleEnded);
            };
        }
    }, [videoPlayerReady, showControlsAndResetTimer, playerRef]);

    useEffect(() => {
        isCommentInputFocusedRef.current = isCommentInputFocused;
    }, [isCommentInputFocused]);

    /** =============================
     * Global keydown handler for spacebar and back / fwd skip
     ============================= */
    useEffect(() => {
        const handleGlobalKeyDown = (e: KeyboardEvent) => {
            // Check if an input is focused, so that we don’t interfere with form input.
            const activeElement = document.activeElement;
            const isInputFocused =
                activeElement &&
                (
                    activeElement.tagName === "INPUT" ||
                    activeElement.tagName === "TEXTAREA" ||
                    activeElement.getAttribute("contenteditable") === "true"
                );

            if (isInputFocused) {
                return; // Skip if any input is focused.
            }

            // Optionally, check for your comment input ref too.
            if (isCommentInputFocusedRef.current) return;

            if (e.key === " " || e.code === "Space") {
                e.preventDefault();
                togglePlay();
                showControlsAndResetTimer();
            } else if (e.key === "ArrowLeft") {
                // Skip back 10 seconds on left arrow key press
                e.preventDefault();
                skipBack10Seconds();
                showControlsAndResetTimer();
            } else if (e.key === "ArrowRight") {
                // Skip forward 10 seconds on right arrow key press
                e.preventDefault();
                skipForward10Seconds();
                showControlsAndResetTimer();
            }
        };

        window.addEventListener("keydown", handleGlobalKeyDown);
        return () => {
            window.removeEventListener("keydown", handleGlobalKeyDown);
        };
    }, [togglePlay, showControlsAndResetTimer, skipBack10Seconds, skipForward10Seconds]);

    /** =============================
     * Listen for fullscreen exit in case the user doesn't press the ui element
     ============================= */
    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFull =
                document.fullscreenElement === fullScreenRef.current ||
                (document as any).webkitFullscreenElement === fullScreenRef.current ||
                (document as any).msFullscreenElement === fullScreenRef.current;

            setIsFullscreen(isFull);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);

        return () => {
            // Cleanup inactivity timer on unmount
            if (inactivityTimerRef.current) {
                window.clearTimeout(inactivityTimerRef.current);
            }

            // Cleanup fullscreen event listeners
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, []);

    /** =============================
     * Listen for window resize to re-calculate proper 16:9 height
     ============================= */
    useEffect(() => {
        if (!fullScreenRef.current) return;

        const updateHeight = () => {
            const width = fullScreenRef.current?.clientWidth;
            if (width) {
                setContainerHeight((width * 9) / 16);
            }
        };

        // Initial calculation
        updateHeight();

        // Check if ResizeObserver is available
        if (typeof ResizeObserver !== 'undefined') {
            const resizeObserver = new ResizeObserver(() => {
                updateHeight();
            });
            resizeObserver.observe(fullScreenRef.current);

            // Cleanup
            return () => {
                resizeObserver.disconnect();
            };
        } else {
            // Fallback for old browsers: use window resize event
            window.addEventListener('resize', updateHeight);
            // Cleanup
            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, []);


    /** =============================
     * Telemetry Integration
     *
     * The onPlay callback below sets up a delayed 30‑second interval that
     * sends telemetry data (using UpdateVideoStat) while the video is playing.
     * It also ensures that StartVideoSession is called only once on the first play.
     *
     * This code uses player events to trigger telemetry and cleans up the
     * interval on unmount.
     ============================= */
    useTelemetry(playerRef.current, {
        accessToken: userData.AspNetUserId,
        video,
        peak
    });

    return (
        <div
            className={ `video-player ${isTheatreMode ? 'is-theatre-mode' : 'is-not-theatre-mode' }` }
            ref={containerRef}
            style={{ position: "relative" }}
            onPointerMove={() => handlePointerMove()}

            // Needed for space-bar play / pause support
            tabIndex={0}
        >
            <div
                ref={fullScreenRef}
                style={{ width: "100%", height: containerHeight }}
                className={`video-player__inner u-relative_hidden ${isFullscreen ? 'is-full-screen' : 'is-not-full-screen'}`}
            >
                {!isMobile ? (
                    <div
                        className={`video-player__cover-overlay u-full_cover_absolute ${showControls ? 'is-hidden' : 'is-visible'}`}
                        onMouseDown={(e) => {
                            e.stopPropagation(); // Prevents parent onPointerDown from firing
                            handlePointerTogglePlay(e);
                        }}
                        role="button"
                    />
                ) : (
                    <div
                        className={`video-player__cover-overlay u-full_cover_absolute ${showControls ? 'is-hidden' : 'is-visible'}`}
                        onTouchStart={handleOnTouchStart}
                        role="button"
                    />
                )}

                <video
                    ref={videoRef}
                    className="video-js ickonic-video-player"
                />

                {playerRef.current && (
                    <>
                        <BigCenteredControls
                            isPlaying={isPlaying}
                            onMouseDown={handlePointerTogglePlay}
                            showVideoPlayerOtherControls={showControls}
                            hideControls={hideControls}
                            showControlsAndResetTimer={() => showControlsAndResetTimer()}
                            isMobile={isMobile}
                            skipBack={skipBack10Seconds}
                            skipForward={skipForward10Seconds}
                        />

                        {videoPlayerReady && (
                            <div
                                className={`video-player__video-controls ${showControls ? 'is-not-hidden' : 'is-hidden'}`}
                            >
                                <div className="video-controls__shelf">
                                    <div className="video-controls__left-side">
                                        <VideoPlayPause
                                            isPlaying={isPlaying}
                                            onTogglePlay={togglePlay}
                                            showControls={showControls}
                                        />

                                        <VideoVolume
                                            isHovered={isVolumeHoveredOver}
                                            setIsHovered={setIsVolumeHoveredOver}
                                            player={playerRef.current}
                                        />

                                        <VideoTimeDisplay
                                            player={playerRef.current}
                                            isMobile={isMobile}
                                            isVolumeHoveredOver={isVolumeHoveredOver}
                                        />
                                    </div>

                                    <VideoSettings
                                        player={playerRef.current}
                                        isOpen={isSettingsMenuOpen}
                                        onToggleOpen={toggleSettingsMenu}
                                    />

                                    { setIsTheatreMode !== undefined && isTheatreMode !== undefined && (
                                        <VideoTheatreMode
                                            isTheatreMode={isTheatreMode}
                                            setIsTheatreMode={setIsTheatreMode}
                                            showControls={showControls}
                                        />
                                    )}

                                    <VideoFullscreen
                                        fullScreenRef={fullScreenRef}
                                        isFullscreen={isFullscreen}
                                        setIsFullscreen={setIsFullscreen}
                                        showControls={showControls}
                                    />
                                </div>

                                <VideoProgressBar
                                    player={playerRef.current}
                                />
                            </div>
                        )}
                    </>
                )}

                <VideoLoadingOverlay
                    hideLoader={videoPlayerReady}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;
