import config from "../Constants/Config";

export function GetArticleShareUrl(urlTitle: string) {
    // If urlTitle is already a full URL, return it
    if (urlTitle.startsWith("http://") || urlTitle.startsWith("https://")) {
        return urlTitle;
    }

    const BACKEND_URL = config.articleShare;
    return BACKEND_URL + urlTitle;
}

const irregularPlurals: Record<string, string> = {
    child: "children",
    person: "people",
    man: "men",
    woman: "women",
};

const pluralize = (word: string): string => {
    const lowerWord = word?.toLowerCase();

    if (irregularPlurals[lowerWord]) {
        return irregularPlurals[lowerWord];
    }

    if (lowerWord?.endsWith("y") && !/[aeiou]y$/.test(lowerWord)) {
        return word.slice(0, -1) + "ies";
    }

    if (/s$|sh$|ch$|x$|z$/.test(lowerWord)) {
        return `${word}es`;
    }

    return `${word}s`;
};

export const getPlural = (count: number, title: string): string => {
    const pluralTitle = count === 1 ? title : pluralize(title);
    return `${count} ${pluralTitle}`;
};
