import React, { useEffect, useRef } from 'react';

// Assets
import IconClose from "../../Assets/SVGs/Icons/UI/Close";

const PopUp = (props: {

    // Allows child components other than what is returned
    // from the API
    children?: React.ReactNode,

    // Determines if 'X' icon is displayed
    canBeDismissed: boolean;

    // Will cover all overlapping elements
    isFullScreen: boolean;

    // Event name that can open the popup. Another component
    // must be configured to dispatch an event with a matching name.
    openEvent?: string;

    // Another method to manually open / close the popup. Better for
    // components that directly render the popup
    isOpen?: boolean;

    zIndex?: number;

    onClose?: () => void;

    isDarkMode?: boolean;

}) => {
    const popUpRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        return () => {
            if (props.isOpen) {
                document.body.classList.add('disable-scroll');
            } else {
                document.body.classList.remove('disable-scroll');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    // Close the dropdown when clicking outside the component
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popUpRef.current &&
                !popUpRef.current.contains(event.target as Node) &&
                props.onClose &&
                props.canBeDismissed
            ) {
                props.onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            style={ {
                position: props.isFullScreen ? 'fixed' : 'absolute',
                zIndex: props.isFullScreen ? (props.zIndex ? props.zIndex : 999) : 1
            } }
            className={ `popup--basic ${ props.isOpen ? 'is-open' : 'is-closed' }` }
        >
            <div className="popup__inner">

                { /** Close popup button ('X') **/ }
                { props.canBeDismissed && (
                    <div className="popup__close"
                        onClick={ props.onClose }
                    >
                        { IconClose() }
                    </div>
                ) }

                { /** Elements passed as children **/ }
                <div className="popup__children" ref={popUpRef}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PopUp;
