import { useEffect, useState } from "react";

// Models
import ICommentAndPersonDTO from '../../Models/DTOs/ICommentAndPersonDTO';

// Api
import {GetComments} from "../../Api/VideoComment";

interface UseFetchVideoCommentsResult {
    comments: ICommentAndPersonDTO[];
    loading: boolean;
    error: string;
}

function useFetchVideoComments(videoId: number | null, refresh: number): UseFetchVideoCommentsResult {
    const [comments, setComments] = useState<ICommentAndPersonDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (videoId === null || videoId === undefined) {
            // If no videoId is provided, reset states
            setComments([]);
            setLoading(false);
            setError("");
            return;
        }

        let isCancelled = false;
        const controller = new AbortController();

        const fetchComments = async () => {
            setLoading(true);
            setError("");

            try {
                const result = await GetComments(videoId, controller);

                if (!isCancelled) {
                    //console.log(result);
                    setComments(result);
                }
            } catch (err) {
                // This block should rarely execute because GetVideoComments handles errors,
                // but it's good practice to have it.
                if (!isCancelled) {
                    setError("An unexpected error occurred while fetching comments.");
                    setComments([]);
                    console.error(err);
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        };

        fetchComments();

        return () => {
            isCancelled = true;
            controller.abort();
        };
    }, [videoId, refresh]);

    return { comments, loading, error };
}

export default useFetchVideoComments;
