import CryptoJS from "crypto-js";

export function Encrypt(text: string, passPhrase: string, iterations = 1000) {
    const keySize = 256;
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    if (
        text === null ||
        text === undefined ||
        text.length <= 0 ||
        passPhrase === null ||
        passPhrase === undefined ||
        passPhrase.length <= 0
    ) {
        return "";
    }

    const key = CryptoJS.PBKDF2(passPhrase, salt, {
        iterations,
        keySize: keySize / 8,
    });

    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const base = window.btoa(salt.toString() + iv.toString() + encrypted.toString());
    return base;
}

export function Decrypt(encryptText: string, passPhrase: string, iterations = 1000) {
    const keySize = 256;

    if (encryptText === null || encryptText === undefined || encryptText.length <= 0) {
        return "";
    }

    const unbased = window.atob(encryptText);
    const salt = CryptoJS.enc.Hex.parse(unbased.substr(0, 32));
    const iv = CryptoJS.enc.Hex.parse(unbased.substr(32, 32));
    const encrypted = unbased.substring(64);

    const key = CryptoJS.PBKDF2(passPhrase, salt, {
        iterations,
        keySize: keySize / 8,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}
