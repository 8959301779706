import React from "react";

const IconApple = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="none"
            viewBox="0 0 33 32"
        >
            {props?.defs ? props.defs : null}
            <path
                style={{fill: props?.iconFill}}
                d="M23.233 27.04c-1.306 1.267-2.733 1.067-4.106.467-1.454-.614-2.787-.64-4.32 0-1.92.826-2.934.586-4.08-.467-6.507-6.707-5.547-16.92 1.84-17.293 1.8.093 3.053.986 4.106 1.066 1.574-.32 3.08-1.24 4.76-1.12 2.014.16 3.534.96 4.534 2.4-4.16 2.494-3.174 7.974.64 9.507-.76 2-1.747 3.987-3.387 5.453zM16.54 9.667C16.34 6.693 18.753 4.24 21.527 4c.386 3.44-3.12 6-4.987 5.667"
            ></path>
        </svg>
    )
};

export default IconApple;
