import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { fetchSubscriptionsWithoutSubscriptionIn } from "../../Api/VideoMetrics";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface SubsNoSubsInProps {
  pageSize?: number;
}

interface SubsNoSubsInDTO {
  CustomerId: string;
  SubscriptionId: string;
  Email: string | null;
  StartDate: string;
  RenewalDate: string;
  ExpiryDate: string;
}

const SubsNoSubsIn: React.FC<SubsNoSubsInProps> = ({ pageSize = 10 }) => {
  const [data, setData] = useState<SubsNoSubsInDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { userData } = useContext(UserAuthenticationContext);
  const access_token = userData?.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadSubscriptions = async () => {
      try {
        const result = await fetchSubscriptionsWithoutSubscriptionIn(
          access_token,
          abortController
        );
        setData(result);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load data", err);
          setError("Failed to load data");
        }
      } finally {
        setLoading(false);
      }
    };

    loadSubscriptions();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    // Convert the entire data array to a worksheet.
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet.
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SubsMissingSubIn");
    // Write the workbook to an array buffer.
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Create a Blob from the buffer and trigger a download.
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "SubscriptionsMissingSubIn.xlsx");
  };

  const columns = [
    { title: "Customer ID", dataIndex: "CustomerId", key: "CustomerId" },
    { title: "Subscription ID", dataIndex: "SubscriptionId", key: "SubscriptionId" },
    { 
      title: "Email", 
      dataIndex: "Email", 
      key: "Email", 
      render: (email: string | null) => email || "N/A" 
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
      render: (text: string) => (text ? new Date(text).toLocaleDateString() : "N/A"),
    },
    {
      title: "Renewal Date",
      dataIndex: "RenewalDate",
      key: "RenewalDate",
      render: (text: string) => (text ? new Date(text).toLocaleDateString() : "N/A"),
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      key: "ExpiryDate",
      render: (text: string) => (text ? new Date(text).toLocaleDateString() : "N/A"),
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#e6e0f8", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ margin: 0, color: "#333", display: "inline-block", marginRight: "10px" }}>
            Subscriptions Missing SubscriptionIn
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>
            Total: {data.length}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page) => setCurrentPage(page)}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="CustomerId"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default SubsNoSubsIn;