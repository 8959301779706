import { useEffect, useState } from "react";

// Api
import { GetCategoryContent } from "../../../Api/Carousel";

// Models
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";

function useFetchCategoryContent(
    categoryId: number | null,
    country: string,
    aspNetUserId: string,
    take: number,
    skip: number,
    shouldAppendData: boolean
) {
    const
        [categoryContent, setCategoryContent] = useState<ICarouselContentDTO[]>([]),
        [contentLoading, setContentLoading] = useState<boolean>(false),
        [contentError, setContentError] = useState<string>("");

    useEffect(() => {
        // Used to cancel the request if the component unmounts
        let isCancelled = false;
        const abortController = new AbortController();

        // console.log(`New cat ID: ${categoryId}`);

        // Start loading
        if (categoryId !== null) {
            setContentLoading(true);

            (async () => {
                try {
                    const data = await GetCategoryContent(
                        categoryId,
                        country,
                        aspNetUserId,
                        take,
                        skip,
                        abortController
                    );

                    if (!isCancelled && data !== null) {
                        // console.log(`New cat data`);
                        // console.log(data);

                        if (shouldAppendData) {
                            setCategoryContent((prev) => [...prev, ...data]);
                        } else {
                            setCategoryContent(data);
                        }
                    }
                } catch (error) {
                    if (!isCancelled) {
                        console.error("GetCategoryContent error:", error);
                        setContentError("Failed to fetch category content.");
                    }
                } finally {
                    if (!isCancelled) {
                        setContentLoading(false);
                    }
                }
            })();
        }

        // Cleanup function when the component unmounts or dependencies change
        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [categoryId, take, skip]);

    return { categoryContent, contentLoading, contentError };
}

export default useFetchCategoryContent;
