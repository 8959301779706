import {TFunction} from "i18next";

export function ContainsNumber(str: string) {
    const specialChars = new RegExp(/\d/);
    return specialChars.test(str);
}

export function IsEven(value: number) {
    return value % 2 === 0;
}

export function IsNumber(value?: string) {
    if (value === undefined || value.length <= 0) {
        return false;
    }

    return !isNaN(+value) && isFinite(+value) && !/e/i.test(value);
}

export function GetPercentageOfNumber(percentage: number, value: number) {
    var percent = (percentage / 100) * value;

    return percent;
}

export function isValidId(value: string) {
    let number = Number(value);
    if (isNaN(number)) {
        return false;
    }

    if (number % 1 === 0) {
        return true;
    }

    return false;
}

export const zipValidation = (name: string, t: TFunction): string => {
    if (!name || name.trim().length === 0) {
        return t('error-zip-required');
    }

    return "valid";
};
