import React from "react";

const IconPenAndPaper = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                style={{fill: props?.iconFill}}
                d="M14.776 18.689l7.012-7.012a.763.763 0 00.217-.532.715.715 0 00-.218-.515l-2.423-2.415a.74.74 0 00-1.045 0l-7.027 6.996c-.442 1.371-1.158 3.586-1.265 3.952-.125.433.199.834.573.834.41 0 .696-.099 4.176-1.308zm-2.258-2.392l1.17 1.171c-.704.232-1.275.418-1.729.566zm.968-1.154l5.356-5.331 1.347 1.342-5.346 5.347zM9 13.75a.772.772 0 00-.75-.75h-5.5c-.394 0-.75.348-.75.75s.356.75.75.75h5.5c.394 0 .75-.348.75-.75zm5-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75z"
            ></path>
        </svg>
    )
};

export default IconPenAndPaper;
