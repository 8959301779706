export default {
    account: "/account/",
    affiliate: "/affiliate/",
    carousel: "/carousel/",
    campaign: "/campaign/",
    email: "/Email",
    favourite: "/favourite/",
    help: "/help/",
    person: "/person/",
    rating: "/rating/",
    search: "/search/",
    searchCount: "/search/GetCount/",
    series: "/Series/",
    stripe: "/stripe/",
    subscription: "/subscription/",
    video: "/video/",
    videoCategories: "/videocategory/",
    videoComment: "/videocomment/",
    videoMetrics: "/videometrics/",
    article: "/article/",
    partner: "/partner/",
    panel: "/panel/",
    popups: "/popups/",
    banners: "/banners/free/",
    author: "/author/",
    helpTopics: "/help/",
    tvAuth: "/rdv/",
    category: "/category/",
    ipDataUrl: "https://api.ipdata.co",
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    ipDataKey: "009e747dec1a5affc1be236045a7d03a89486f6b5aa45ae47f37bf71",
    articleShare: "https://read.ickonic.com/"
}
