import { useEffect, useState } from "react";

// API
import { GetCategory } from "../../../Api/Article";

// Models
import IArticleCategoryLatestDTO from "../../../Models/DTOs/IArticleCategoryLatestDTO";

function useFetchArticleCategoryInfo(categoryId: number, take: number) {
    const
        [ categoryInfo, setCategory ] = useState<IArticleCategoryLatestDTO | null>(null),
        [ loading, setLoading ] = useState<boolean>(false),
        [ error, setError ] = useState<string | null>(null);

    useEffect(() => {
        let isCancelled = false;
        const controller = new AbortController();

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await GetCategory(categoryId, take, controller);
                if (!isCancelled && response) {
                    setCategory(response);
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error("Category fetch failed:", err);
                    setError("Failed to fetch category.");
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isCancelled = true;
            controller.abort();
        };
    }, [categoryId, take]);

    return { categoryInfo, loading, error };
}

export default useFetchArticleCategoryInfo;
