import React from "react";

const IconPlaybackSpeed = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="m15.91 13.34 2.636-4.026-.454-.406-3.673 3.099a2 2 0 0 0-1.894.618 2 2 0 1 0 2.983 2.665 1.99 1.99 0 0 0 .402-1.95M12 2.694V0h4v2.694c-1.439-.243-2.592-.238-4 0m8.851 2.064 1.407-1.407 1.414 1.414-1.321 1.321a12 12 0 0 0-1.5-1.328M2 9h8v2H2zm-2 4h8v2H0zm3 4h7v2H3zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28A7.9 7.9 0 0 0 14 22c4.411 0 8-3.589 8-8s-3.589-8-8-8a7.9 7.9 0 0 0-3.831 1h-3.28C8.7 5.155 11.21 4 14 4c5.523 0 10 4.477 10 10"> </path>
        </svg>
    )
};

export default IconPlaybackSpeed;
