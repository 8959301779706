import React from 'react';
import { components, ClearIndicatorProps } from 'react-select';
import IconClose from "../../../Assets/SVGs/Icons/UI/Close";

// Prevents events from firing that could cause popups'
// handleClickOutside detection to close the popup.
const CustomClearIndicator = (props: ClearIndicatorProps<any>) => {
    const { innerProps } = props;
    return (
        <div
            className="select__custom-clear-indicator"
            {...innerProps}
            onMouseDown={(e) => {
                e.stopPropagation();
                if (innerProps.onMouseDown) {
                    innerProps.onMouseDown(e);
                }
            }}
        >
            {IconClose()}
        </div>
    );
};
export default CustomClearIndicator;
