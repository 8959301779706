export function HowManyDifferentInArray(
    array1: object[] | number[],
    array2: object[] | number[]
): number {
    let differences = 0;

    if (!array1 && array2) {
        return array2.length;
    }
    if (array1 && !array2) {
        return array1.length;
    }
    if (!array1 && !array2) {
        return 0;
    }

    let sorted1 = array1.sort();
    let sorted2 = array2.sort();

    let shortestArray = sorted1;

    let firstArrayIsLonger = sorted1.length > sorted2.length;
    if (firstArrayIsLonger) {
        shortestArray = sorted2;
    }

    for (let i = 0; i < shortestArray.length; i++) {
        if (sorted1[i] !== sorted2[i]) {
            differences++;
        }
    }

    if (array1.length === array2.length) {
        return differences;
    }

    if (firstArrayIsLonger) {
        return differences + (sorted1.length - sorted2.length);
    }

    return differences + (sorted2.length - sorted1.length);
}
