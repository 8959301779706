import {redirect} from "react-router-dom";
import {RoutePaths} from "../Constants/RoutePaths";
import {IUserDetails} from "../Models/Interfaces/IUserDetails";
import {ContentType} from "../Models/Enums/ContentType";
import {TvPlatform} from "../Models/Enums/TvPlatforms";

import {IsAuthenticated, NeedsRenew} from "./Account";

export function GetContentRedirect(contentType: ContentType, id: number) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.Watch(id);
        case ContentType.Series:
            return RoutePaths.SeriesEpisodes(id);
        case ContentType.Event:
            return RoutePaths.Event(id);
        case ContentType.Category:
            return RoutePaths.Category(id);
        default:
            return null;
    }
}

export function GetTrailerRoute(contentId: number, contentType: ContentType) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.WatchTrailer(contentId);
        case ContentType.Series:
            return RoutePaths.SeriesTrailer(contentId);
        default:
            console.log('src/Helpers GetTrailerRoute');
            return RoutePaths.Browse;
    }
}

export function SplitVideoLink(link: string) {
    return link.split("/manifest")[0];
}

export function GetAuthCompleteLink() {
    let returnUrl = "https://" + window.location.hostname + RoutePaths.Renew3DSConfirm;
    if (returnUrl.includes("localhost")) {
        returnUrl = "http://localhost:" + window.location.port + RoutePaths.Renew3DSConfirm;
    }
    return returnUrl;
}

export function RedirectStringToRoutePath(text: string) {
    switch (text) {
        case TvPlatform.AndroidTv:
            return RoutePaths.AndroidTV;
        case TvPlatform.FireStick:
            return RoutePaths.Firestick;
        case TvPlatform.Roku:
            return RoutePaths.Roku;
        case TvPlatform.SamsungTv:
            return RoutePaths.SamsungTV;
        default:
            return RoutePaths.GenericTvAuth;
    }
}

export function AuthValidSubOnly(userDetails: IUserDetails) {

    if (!IsAuthenticated(userDetails)) {
        return redirect(RoutePaths.Login);
    }

    if (NeedsRenew(userDetails)) {
        return redirect(RoutePaths.Renew);
    }

    // Disabling for "open site initiative"
    // if (!IsActive(userDetails)) {
    //     return redirect(RoutePaths.ChoosePlan);
    // }
}
