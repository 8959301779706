import {useContext, useEffect, useState} from "react";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

// Api
import {
    NewCancelSubscription,
    // NewPauseSubscription,
} from "../../../Api/Cancellation";

// Cancel Steps (Components)
import StepSelectReason_CancelOrPause from "./StepSelectReason_CancelOrPause";
import StepUpComingContent_CancelOrPause from "./StepUpComingContent_CancelOrPause";
import StepProvideFeedback_CancelOrPause from "./StepProvideFeedback_CancelOrPause";
import StepTechnicalDifficulties_CancelOrPause from "./StepTechnicalDifficulties_CancelOrPause";
import StepCancelSuccess_CancelOrPause from "./StepCancelSuccess_CancelOrPause";

// Models
import {IYourAccountDTO} from "../../../Models/DTOs/IYourAccountDTO";

// Helpers
import {mustHaveValueValidation} from "../../../Helpers/Inputs";

type CancelOrPauseProps = {
    userData: IYourAccountDTO;
};

const CancelOrPause = ({userData}: CancelOrPauseProps) => {
    const
        authCtx = useContext(UserAuthenticationContext),
        controller = new AbortController(),

        // Step count & API status state
        [step, setStep] = useState<number>(1),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [cancelErrorMessage, setCancelErrorMessage] = useState<string | null>(null),
        [cancellationMessage, setCancellationMessage] = useState<string | null>(null),

        // Select Reason state
        [selectedReason, setSelectedReason] = useState<string | null>(null),
        [reasonError, setReasonError] = useState<string | null>(null),
        [reasonIsValid, setReasonIsValid] = useState(true),

        // Provide Feedback state
        [feedback, setFeedback] = useState<string>(""),
        [feedbackValid, setFeedbackValid] = useState(false),
        [feedbackErrorText, setFeedbackErrorText] = useState("valid"),

        // Technical Issues state
        [selectedIssues, setSelectedIssues] = useState<string[]>([]),
        [selectedDevice, setSelectedDevice] = useState<number>(0),
        [selectedBrowser, setSelectedBrowser] = useState<number>(0),

        validateFeedback = () => {
            const
                validationText = mustHaveValueValidation(feedback),
                isValid = validationText === 'valid';

            setFeedbackErrorText(validationText);
            setFeedbackValid(isValid);
        },

        // Handle membership cancellation
        handleCancelMembership = async () => {
            try {
                console.log('cancel api is loading');
                setIsLoading(true);
                setCancelErrorMessage(null); // Clear previous error message

                const technicalIssuesString = selectedIssues.join(',');

                const data: any = {
                    AspNetUserId: authCtx.userData.AspNetUserId,
                    SubCancelReason: parseInt(selectedReason || "0"),
                    TechnicalIssues: technicalIssuesString,
                    DeviceUsedToView: selectedDevice,
                    BrowserUsedToView: selectedBrowser,
                    Content: feedback,
                };

                console.log("Payload being sent to API:", data);

                const resultMessage = await NewCancelSubscription(
                    data,
                    authCtx.userData.Access_Token,
                    controller
                );

                console.log(resultMessage);

                if (resultMessage) {
                    setCancellationMessage(resultMessage); // Set the returned message
                } else {
                    setCancelErrorMessage(
                        "There was an Error cancelling Your Membership - Please contact support@ickonic.com"
                    );
                }
            } catch (error) {
                console.error("Error cancelling membership:", error);
                setCancelErrorMessage(
                    "There was an Error cancelling Your Membership - Please contact support@ickonic.com"
                );
            } finally {
                setIsLoading(false);
                setStep(8);
            }
        };

    // Listen for custom event to reset cancel state
    useEffect(() => {
        const resetHandler = () => {
            setStep(1);
            setSelectedReason(null);
            setReasonError(null);
            setFeedback("");
            setFeedbackValid(false);
            setFeedbackErrorText("valid");
            setSelectedIssues([]);
            setSelectedDevice(0);
            setSelectedBrowser(0);
            setCancelErrorMessage(null);
            setCancellationMessage(null);
        };

        document.addEventListener("resetCancelSteps", resetHandler);

        return () => {
            document.removeEventListener("resetCancelSteps", resetHandler);
        };
    }, []);

    return (
        <form className="form--cancel-or-pause is-dark-mode" onSubmit={(e) => e.preventDefault()}>

            {/* Select Cancellation Reason */}
            {step === 1 && (
                <StepSelectReason_CancelOrPause
                    userData={userData}
                    setStep={(step) => setStep(step)}
                    selectedReason={selectedReason}
                    setSelectedReason={setSelectedReason}
                    reasonIsValid={reasonIsValid}
                    setReasonIsValid={setReasonIsValid}
                    reasonError={reasonError}
                    setReasonError={setReasonError}
                />
            )}

            {/* Upcoming Content */}
            {step === 10 && (
                <StepUpComingContent_CancelOrPause
                    handleCancelMembership={handleCancelMembership}
                    isCancelApiLoading={isLoading}
                />
            )}

            {/* Technical Difficulties */}
            {step === 11 && (
                <StepTechnicalDifficulties_CancelOrPause
                    selectedIssues={selectedIssues}
                    setSelectedIssues={setSelectedIssues}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                    selectedBrowser={selectedBrowser}
                    setSelectedBrowser={setSelectedBrowser}
                    feedback={feedback}
                    setFeedback={setFeedback}
                    handleCancelMembership={handleCancelMembership}
                    isCancelApiLoading={isLoading}
                />
            )}

            {/* Provide Feedback */}
            {(step === 12 || step === 9) && (
                <StepProvideFeedback_CancelOrPause
                    feedback={feedback}
                    setFeedback={setFeedback}
                    checkValidity={validateFeedback}
                    feedbackValid={feedbackValid}
                    errorText={feedbackErrorText}
                    handleCancelMembership={() => handleCancelMembership()}
                    isCancelApiLoading={isLoading}
                />
            )}

            {/* Step 7 - Pause Membership */}
            {/*{step === 7 && (*/}
            {/*    <StepPauseMembership_CancelOrPause */}
            {/*        feedback={} */}
            {/*        setFeedback={} */}
            {/*        checkValidity={} */}
            {/*        hasError={} */}
            {/*        errorText={}*/}
            {/*    />*/}
            {/*)}*/}

            {/* Step 3 - Special offer */}
            {/*{step === 3 && (*/}
            {/*    <StepSpecialOffer_CancelOrPause*/}
            {/*        feedback={}*/}
            {/*        setFeedback={}*/}
            {/*        checkValidity={}*/}
            {/*        hasError={}*/}
            {/*        errorText={}*/}
            {/*    />*/}
            {/*)}*/}

            {/* Step 9 - Staying with Ickonic */}
            {/*{step === 9 && (*/}
            {/*    <StepStayingWithIckonic_CancelOrPause */}
            {/*        feedback={} */}
            {/*        setFeedback={} */}
            {/*        checkValidity={} */}
            {/*        hasError={} */}
            {/*        errorText={}*/}
            {/*    />*/}
            {/*)}*/}

            {step === 8 && (
                <StepCancelSuccess_CancelOrPause
                    cancellationMessage={cancellationMessage}
                    cancelErrorMessage={cancelErrorMessage}
                />
            )}
        </form>
    );
}

export default CancelOrPause;
