// Artcile - Next article card
import styled from "styled-components";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { NavLink } from "react-router-dom";

import { Colours } from "Constants/Colours";
import { RoutePaths } from "Constants/RoutePaths";
import { Fonts } from "Constants/Fonts";
import { HeadingType } from "Models/Enums/HeadingType";
import IArticleDTO from "Models/DTOs/IArticleDTO";

import Heading from "Components/UI/Text/Heading";
import ImageLazyLoad from "Components/UI/ImageLazyLoad";
import { CategoryTerm } from "Components/UI/Article/CategoryTerm";

type Props = { article: IArticleDTO };

const Container = styled.div`
    background: ${Colours.EditorBg};
    border-radius: 16px;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 1024px) {
        gap: 30px;
        flex-direction: column;
        padding: 20px;
    }

    .next-article__image {
        max-width: 490px;
        width: 100%;

        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }
    }

    .next-article__content {
        &-title {
            font-family: ${Fonts.Rubik};
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            margin: 0;

            @media screen and (max-width: 1024px) {
                font-size: 32px;
                line-height: 42px;
            }

            @media screen and (max-width: 768px) {
                font-size: 26px;
                line-height: 36px;
            }

            @media screen and (max-width: 576px) {
                font-size: 20px;
                line-height: 30px;
            }

            a {
                color: ${Colours.TextBlack};
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        &-header {
            display: grid;
            gap: 16px;
            margin: 12px 0 20px;

            @media screen and (max-width: 1024px) {
                margin: 12px 0;
            }
        }

        &-author {
            font-family: ${Fonts.Rubik};
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: ${Colours.TextBlack};
            margin: 0;

            @media screen and (max-width: 576px) {
                font-size: 14px;
                line-height: 18px;
            }
        }

        &-read-more {
            background: linear-gradient(
                90deg,
                ${Colours.IckonicPink} 0%,
                ${Colours.IckonicLightPink} 100%
            );
            padding: 16px 24px;
            border-radius: 100px;
            text-decoration: none;
            font-family: ${Fonts.Rubik};
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            cursor: pointer;
            transition: all 0.3s ease;
            color: ${Colours.TextBright};
            display: inline-block;

            &:hover {
                background: linear-gradient(
                    135deg,
                    ${Colours.IckonicLightPink},
                    ${Colours.IckonicPink}
                );
            }

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
                padding: 12px 20px;
            }

            @media screen and (max-width: 576px) {
                padding: 10px 18px;
            }
        }
    }
`;

export const NextAricle = ({ article }: Props) => {
    const baseUrl = "https://ickesaimage.blob.core.windows.net";
    const articleFeaturedImage = article?.Header.includes(baseUrl)
        ? article?.Header
        : baseUrl + article?.Header;

    return (
        <Container>
            <div className="next-article__image">
                <ImageLazyLoad source={article?.Header ? articleFeaturedImage : ""} />
            </div>

            <div className="next-article__content">
                <CategoryTerm
                    category={{ Title: "Up Next", Id: 1, Position: 0 }}
                    clickable={false}
                />

                <div className="next-article__content-header">
                    <Heading
                        type={HeadingType.H2}
                        className="next-article__content-title"
                    >
                        <NavLink to={RoutePaths.ReadArticle(article?.UrlTitle)}>
                            {parse(DOMPurify.sanitize(article?.Title))}
                        </NavLink>
                    </Heading>

                    <p className="next-article__content-author">
                        By {article?.Author?.Name}
                    </p>
                </div>

                <NavLink
                    className="next-article__content-read-more"
                    to={RoutePaths.ReadArticle(article?.UrlTitle)}
                >
                    Continue Reading
                </NavLink>
            </div>
        </Container>
    );
};
