// Article - Share Social Media
import { useMemo } from "react";
import {
    FaXTwitter,
    FaFacebookF,
    FaLinkedinIn,
    FaRedditAlien,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Colours } from "Constants/Colours";
import { Fonts } from "Constants/Fonts";
import { ContentType } from "Models/Enums/ContentType";
import { ContentTypeToString } from "../../Helpers/Content";
import { GetArticleShareUrl } from "../../Helpers/Articles";

type Props = {
    isShare: boolean;
    title: string;
    contentType: ContentType | string;
    articleUrlTitle?: string;
};

const SocialsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        align-self: center;
    }

    .social-icon__container {
        display: flex;
        gap: 12px;

        @media screen and (max-width: 576px) {
            gap: 6px;
            flex-wrap: wrap;
        }
    }

    .social-icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${Colours.TextBright}!important;

        > svg {
            width: 12px;
            height: 12px;
        }

        &__share {
            font-family: ${Fonts.Rubik};
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin: 0;

            @media screen and (max-width: 576px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .facebook {
        background-color: ${Colours.Facebook};
    }

    .twitter {
        background-color: ${Colours.XColor};
    }

    .reddit {
        background-color: ${Colours.Reddit};
    }

    .linkedin {
        background-color: ${Colours.Linkedin};
    }
`;

export const SocialsMedia = (
    {
        contentType,
        isShare,
        title,
        articleUrlTitle,
    }: Props) =>
{
    // Share URL
    const shareUrl =
        contentType === ContentType.Article &&
        articleUrlTitle !== null &&
        articleUrlTitle !== undefined
            ? GetArticleShareUrl(articleUrlTitle)
            : window.location.href;

    const type: string =
        typeof contentType === "string"
            ? contentType
            : ContentTypeToString(contentType);

    const socialLinks = useMemo(
        () => [
            {
                href: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=${title}`,
                title: "Share on Facebook",
                className: "facebook social-icon",
                icon: <FaFacebookF />,
            },
            {
                href: `https://twitter.com/intent/tweet?u=${shareUrl}&text=check out this ${type} by @ickonic on ${shareUrl}`,
                title: "Share on Twitter",
                className: "twitter social-icon",
                icon: <FaXTwitter />,
            },
            {
                href: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
                title: "Share on LinkedIn",
                className: "linkedin social-icon",
                icon: <FaLinkedinIn />,
            },
            {
                href: `https://reddit.com/submit?url=${shareUrl}&title=${title}`,
                title: "Share on Reddit",
                className: "reddit social-icon",
                icon: <FaRedditAlien />,
            },
        ],
        [shareUrl, type, title]
    );

    return (
        <SocialsContainer>
            {/*{isShare && <p className="social-icon__share">Share this article :</p>}*/}

            <div className="social-icon__container">
                {socialLinks?.map((social, index) => (
                    <Link
                        key={index}
                        to={social?.href}
                        title={social?.title}
                        target="_blank"
                        rel="noreferrer nofollow"
                        className={social?.className}
                    >
                        {social?.icon}
                    </Link>
                ))}
            </div>
        </SocialsContainer>
    );
};
