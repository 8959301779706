import React, { useState, useContext } from "react";
import {
    FaChartBar,
    FaGlobe,
    FaClock,
    FaCheckCircle,
    FaLayerGroup,
    FaExclamationTriangle,
    FaTimesCircle,
    FaUser,
    FaUserSlash,
    FaClipboardList,
    FaEyeSlash,
    FaMobileAlt,
    FaRocket,
    FaMapMarkerAlt,
    FaUserMinus, // New icon for User Deletions List
} from "react-icons/fa";

import TotalViewsReport from "./TotalViewsReport";
import TotalViewsByDateRange from "./TotalViewsByDateRange";
import TotalViewsByCountry from "./TotalViewsByCountry";
import AverageViewTime from "./AverageViewTime";
import ViewOnCategoryReport from "./ViewOnCategoryReport";
import ThirtyDayCancellations from "./ThirtyDayCancellations";
import VideoCompletionPercentageByDateRange from "./VideoCompletionPercentageByDateRange";
import LifetimeMembers from "./LifetimeMembers";
import FailedPayments from "./FailedPayments";
import UnpaidSubs from "./UnpaidSubs";
import SubsNoUser from "./SubsNoUser";
import SubsNoSubsIn from "./SubsNoSubsIn";
import ZeroView from "./ZeroView";
import DeviceStats from "./DeviceStats";
import DateRangePicker from "./components/DateRangePicker";
import OnBoarding from "./Onboarding"; // Existing OnBoarding report

// New Site Tracking report component
import SiteTrackingReport from "./SiteTracking";

// New Management report component: UserDeletionsList
import UserDeletionsList from "./UserDeletionsList";

// ---- API Functions ----
import {
    fetchVideoMetricsByDateRange,
    fetchVideoCompletionPercentagesByDateRange,
    fetchVideoViewsByCountry,
    fetchZeroViews,
} from "../../Api/VideoMetrics";

import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import {
    ALLOWED_VIDEO_REPORTS_USERS,
    ALLOWED_MANAGEMENT_REPORTS_USERS,
} from "../../reportsconfig";

export interface TransformedReportData {
    Date: string;
    TotalViews: number;
    [countryCode: string]: number | string;
}

interface DateRange {
    startDate?: Date;
    endDate?: Date;
}

// Define your report arrays
const videoReports = [
    { id: "totalViews", name: "Total Views", icon: <FaChartBar /> },
    { id: "totalViewsByDateRange", name: "Views by Date", icon: <FaClock /> },
    { id: "countryBreakdown", name: "By Country", icon: <FaGlobe /> },
    { id: "averageTime", name: "Avg Watch Time", icon: <FaClock /> },
    { id: "completionRate", name: "Completion %", icon: <FaCheckCircle /> },
    { id: "categoryViews", name: "By Category", icon: <FaLayerGroup /> },
    { id: "zeroView", name: "Zero View", icon: <FaEyeSlash /> },
    { id: "deviceStats", name: "Device Stats", icon: <FaMobileAlt /> },
    { id: "onboarding", name: "On Boarding", icon: <FaRocket /> },
    { id: "siteTracking", name: "Site Tracking", icon: <FaMapMarkerAlt /> },
];

const managementReports = [
    {
        id: "stripeFailedPayments",
        name: "Failed Payments",
        icon: <FaExclamationTriangle />,
    },
    {
        id: "thirtyDayCancellations",
        name: "30-Day Cancels",
        icon: <FaTimesCircle />,
    },
    { id: "lifetimeMembers", name: "Lifetime Members", icon: <FaUser /> },
    {
        id: "activeSubsMissingUser",
        name: "Subs: No User",
        icon: <FaUserSlash />,
    },
    {
        id: "activeSubsMissingSubIn",
        name: "Subs: No SubIn",
        icon: <FaUserSlash />,
    },
    {
        id: "subsWithUnpaidStatus",
        name: "Unpaid Subs",
        icon: <FaClipboardList />,
    },
    // New Management Report: User Deletions List
    {
        id: "userDeletionsList",
        name: "User Deletions List",
        icon: <FaUserMinus />,
    },
];

const ReportingMenu: React.FC = () => {
    const [selectedReport, setSelectedReport] = useState("totalViews");
    const [showVideoReports, setShowVideoReports] = useState(true);
    const [showManagementReports, setShowManagementReports] = useState(true);

    const [reportData, setReportData] = useState<TransformedReportData[]>([]);
    const [completionData, setCompletionData] = useState<any[]>([]);
    const [countryBreakdownData, setCountryBreakdownData] = useState<any[]>([]);
    const [zeroViewData, setZeroViewData] = useState<any[]>([]);
    const authCtx = useContext(UserAuthenticationContext);
    const access_token = authCtx.userData.Access_Token || "";

    // Extract the current user's ID
    const currentUserId = authCtx?.userData.AspNetUserId;

    // Determine access for each section based on allowed arrays
    const canViewVideoReports = ALLOWED_VIDEO_REPORTS_USERS.includes(currentUserId);
    const canViewManagementReports = ALLOWED_MANAGEMENT_REPORTS_USERS.includes(currentUserId);

    // -------------------------
    // Fetch Total Views by Date Range
    // -------------------------
    const handleDateChange = async ({ startDate, endDate }: DateRange) => {
        if (!startDate || !endDate) return;

        try {
            const formattedStartDate = startDate.toISOString().split("T")[0];
            const formattedEndDate = endDate.toISOString().split("T")[0];

            const rawData = await fetchVideoMetricsByDateRange(
                formattedStartDate,
                formattedEndDate,
                access_token
            );
            if (!Array.isArray(rawData) || rawData.length === 0) {
                setReportData([]);
                return;
            }
            setReportData(rawData);
        } catch (error) {
            console.error("❌ Failed to fetch video metrics:", error);
        }
    };

    // -------------------------
    // Fetch Completion Rate Data
    // -------------------------
    const handleCompletionRateDateChange = async ({ startDate, endDate }: DateRange) => {
        if (!startDate || !endDate) return;

        try {
            const formattedStartDate = startDate.toISOString().split("T")[0];
            const formattedEndDate = endDate.toISOString().split("T")[0];

            const rawData = await fetchVideoCompletionPercentagesByDateRange(
                formattedStartDate,
                formattedEndDate,
                access_token
            );
            if (!Array.isArray(rawData) || rawData.length === 0) {
                setCompletionData([]);
                return;
            }
            setCompletionData(rawData);
        } catch (error) {
            console.error("❌ Failed to fetch completion metrics:", error);
        }
    };

    // -------------------------
    // Fetch Video Views by Country
    // -------------------------
    const handleCountryBreakdownDateChange = async ({ startDate, endDate }: DateRange) => {
        if (!startDate || !endDate) return;

        try {
            const formattedStartDate = startDate.toISOString().split("T")[0];
            const formattedEndDate = endDate.toISOString().split("T")[0];

            const rawData = await fetchVideoViewsByCountry(
                formattedStartDate,
                formattedEndDate,
                access_token
            );
            if (!Array.isArray(rawData) || rawData.length === 0) {
                setCountryBreakdownData([]);
                return;
            }
            setCountryBreakdownData(rawData);
        } catch (error) {
            console.error("❌ Failed to fetch country breakdown:", error);
        }
    };

    // -------------------------
    // Fetch Zero View Data
    // -------------------------
    const handleZeroViewDateChange = async ({ startDate, endDate }: DateRange) => {
        if (!startDate || !endDate) return;

        try {
            const formattedStartDate = startDate.toISOString().split("T")[0];
            const formattedEndDate = endDate.toISOString().split("T")[0];

            const rawData = await fetchZeroViews(
                formattedStartDate,
                formattedEndDate,
                access_token
            );
            if (!Array.isArray(rawData) || rawData.length === 0) {
                setZeroViewData([]);
                return;
            }
            setZeroViewData(rawData);
        } catch (error) {
            console.error("❌ Failed to fetch zero view data:", error);
        }
    };

    // -------------------------
    // Render the selected report
    // -------------------------
    const renderReport = () => {
        switch (selectedReport) {
            case "totalViews":
                return <TotalViewsReport />;
            case "totalViewsByDateRange":
                return (
                    <>
                        <DateRangePicker onDateChange={handleDateChange} />
                        {reportData.length > 0 ? (
                            <TotalViewsByDateRange reportData={reportData} />
                        ) : (
                            <p>Loading or No Data Available...</p>
                        )}
                    </>
                );
            case "countryBreakdown":
                return (
                    <>
                        <DateRangePicker onDateChange={handleCountryBreakdownDateChange} />
                        {countryBreakdownData.length > 0 ? (
                            <TotalViewsByCountry countryBreakdownData={countryBreakdownData} />
                        ) : (
                            <p>Loading or No Data Available...</p>
                        )}
                    </>
                );
            case "completionRate":
                return (
                    <>
                        <DateRangePicker onDateChange={handleCompletionRateDateChange} />
                        {completionData.length > 0 ? (
                            <VideoCompletionPercentageByDateRange reportData={completionData} />
                        ) : (
                            <p>Loading or No Data Available...</p>
                        )}
                    </>
                );
            case "zeroView":
                return (
                    <>
                        <DateRangePicker onDateChange={handleZeroViewDateChange} />
                        {zeroViewData.length > 0 ? (
                            <ZeroView zeroBreakdownData={zeroViewData} />
                        ) : (
                            <p>Loading or No Data Available...</p>
                        )}
                    </>
                );
            case "deviceStats":
                return <DeviceStats />;
            case "categoryViews":
                return <ViewOnCategoryReport />;
            case "onboarding":
                return <OnBoarding />;
            case "siteTracking":
                return <SiteTrackingReport />;
            case "thirtyDayCancellations":
                return <ThirtyDayCancellations />;
            case "averageTime":
                return <AverageViewTime />;
            case "lifetimeMembers":
                return <LifetimeMembers />;
            case "stripeFailedPayments":
                return <FailedPayments />;
            case "activeSubsMissingUser":
                return <SubsNoUser />;
            case "activeSubsMissingSubIn":
                return <SubsNoSubsIn />;
            case "subsWithUnpaidStatus":
                return <UnpaidSubs />;
            case "userDeletionsList":
                return <UserDeletionsList />;
            default:
                return <h2>Coming Soon...</h2>;
        }
    };

    return (
        <div className="reporting-container">
            <aside className="sidebar" style={{ position: "relative", zIndex: 9999 }}>
                {/* VIDEO REPORTS SECTION */}
                {canViewVideoReports && (
                    <>
                        <h3
                            className="sidebar-section-title"
                            onClick={() => setShowVideoReports(!showVideoReports)}
                            style={{ cursor: "pointer" }}
                        >
                            {showVideoReports ? "▼ " : "► "} 📊 Video
                        </h3>
                        {showVideoReports && (
                            <nav className="report-section">
                                {videoReports.map((report) => (
                                    <button
                                        key={report.id}
                                        className={`sidebar-button ${selectedReport === report.id ? "active" : ""}`}
                                        onClick={() => setSelectedReport(report.id)}
                                    >
                                        {report.icon}{" "}
                                        <span className="button-label">{report.name}</span>
                                    </button>
                                ))}
                            </nav>
                        )}
                    </>
                )}

                {/* MANAGEMENT REPORTS SECTION */}
                {canViewManagementReports && (
                    <>
                        <h3
                            className="sidebar-section-title"
                            onClick={() => setShowManagementReports(!showManagementReports)}
                            style={{ cursor: "pointer" }}
                        >
                            {showManagementReports ? "▼ " : "► "} ⚙️ Management
                        </h3>
                        {showManagementReports && (
                            <nav className="report-section">
                                {managementReports.map((report) => (
                                    <button
                                        key={report.id}
                                        className={`sidebar-button ${selectedReport === report.id ? "active" : ""}`}
                                        onClick={() => setSelectedReport(report.id)}
                                    >
                                        {report.icon}{" "}
                                        <span className="button-label">{report.name}</span>
                                    </button>
                                ))}
                            </nav>
                        )}
                    </>
                )}
            </aside>
            <main className="main-content">{renderReport()}</main>
        </div>
    );
};

export default ReportingMenu;