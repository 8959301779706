import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import moment from "moment";

// Components
import SelectInput from "../../Inputs/SelectInput/SelectInput";

// Api
import {GetCancellationReasons} from "../../../Api/Cancellation";

// Models
import ISelecterDTO from "../../../Models/DTOs/ISelecterDTO";
import {IYourAccountDTO} from "../../../Models/DTOs/IYourAccountDTO";

interface StepSelectReasonProps {
    userData: IYourAccountDTO;
    setStep: (step: number) => void;
    selectedReason: string | null;
    setSelectedReason: Dispatch<SetStateAction<string | null>>;
    reasonError: string | null;
    setReasonError: Dispatch<SetStateAction<string | null>>;
    reasonIsValid: boolean;
    setReasonIsValid: Dispatch<SetStateAction<boolean>>;
}

const  StepSelectReason_CancelOrPause = (
    {
        userData,
        setStep,
        selectedReason,
        setSelectedReason,
        reasonError,
        setReasonError,
        reasonIsValid,
        setReasonIsValid
    } : StepSelectReasonProps) =>
{
    const
        [options, setOptions] = useState<ISelecterDTO[]>([]),

        ReasonOnChange = (selectedOption: ISelecterDTO | null) => {
            if (selectedOption) {
                setReasonIsValid(true);
                setReasonError("");

                console.log(`proceeding to ${selectedOption.value}`);

                // Navigate to appropriate step based on the selected reason
                setStep(parseInt(selectedOption.value));

            } else {
                setReasonIsValid(false);
                setReasonError("Please select a reason for cancelling.");
            }
        };

    useEffect(() => {
        async function fetchCancellationReasons() {
            try {
                const response = await GetCancellationReasons();
                const formattedOptions = response.map((item) => ({
                    value: item.plKey,
                    label: item.plTitle,
                }));

                console.log(formattedOptions);
                setOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetching cancellation reasons:", error);
            }
        }

        fetchCancellationReasons();
    }, []);

    return (
        <div className="cancel-or-pause__step-select-reason">
            <div className="step-select-reason__inner">
                <div className="cancel-or-pause__heading-wrapper">
                    <h1>
                        We're sorry to see you go
                    </h1>

                    <p className="step-select-reason__member-since u-relative_hidden">
                        <span
                            className={`${
                                userData.Subscription?.SubscriptionStartDate
                                    ? "has-loaded"
                                    : "is-loading"
                            }`}
                        >
                            {userData.Subscription?.SubscriptionStartDate ? (
                                <span
                                    className="step-select-reason__member-since-date">Member Since:{" "}{moment(new Date(userData.Subscription.SubscriptionStartDate)).format("MMM YYYY")}</span>
                            ) : (
                                <span className="loader--side-to-side"/>
                            )}
                        </span>
                    </p>
                </div>

                <div className="cancel-or-pause__divider"/>

                <div className="cancel-or-pause__content-wrapper">
                    <p>
                        Please tell us your reason for leaving so we can learn and improve.
                    </p>

                    <SelectInput
                        name="reason"
                        value={selectedReason || ""}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setSelectedReason(selectedValue);
                            if (selectedValue) {
                                const optionObject = options.find(
                                    (option) => option.value === selectedValue
                                );

                                if (optionObject) {
                                    ReasonOnChange(optionObject);
                                }
                            }
                        }}
                        options={options}
                        placeholder="Reason for leaving"
                        hasError={!reasonIsValid}
                        errorMessage={reasonError || ""}
                    />

                    {reasonError && <p>{reasonError}</p>}
                </div>
            </div>
        </div>
    );
}

export default StepSelectReason_CancelOrPause;
