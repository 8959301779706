import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "./CustomInput";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB"; // UK locale
registerLocale("en-GB", enGB);

interface DateRangePickerProps {
  onDateChange: (range: { startDate: Date | undefined; endDate: Date | undefined }) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date || undefined);
    setErrorMessage("");
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date || undefined);
    setErrorMessage("");
  };

  const validateDates = (): boolean => {
    if (!startDate || !endDate) {
      setErrorMessage("Both start and end dates must be selected.");
      return false;
    }

    if (startDate > endDate) {
      setErrorMessage("Start date cannot be after the end date.");
      return false;
    }

    return true;
  };

  const handleGetReportClick = () => {
    if (!validateDates()) return;
    onDateChange({ startDate, endDate });
  };

  return (
    <div className="date-range-report-container">
      <div className={`top-bar ${errorMessage ? "top-bar-error" : ""}`}>
        <div className="date-range-picker">
          <div className="date-picker-container">
            <label className="date-picker-label">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              locale="en-GB"
              customInput={<CustomInput />}
              calendarClassName="custom-calendar"
              placeholderText="DD/MM/YYYY"
            />
          </div>
          <div className="date-picker-container">
            <label className="date-picker-label">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              locale="en-GB"
              customInput={<CustomInput />}
              calendarClassName="custom-calendar"
              placeholderText="DD/MM/YYYY"
            />
          </div>
        </div>

        <div className="report-controls">
          {errorMessage && <p className="inline-error">{errorMessage}</p>}
          <button className="get-report-button" onClick={handleGetReportClick}>
            Get Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
