import { useEffect, useState } from "react";

// Api
import { GetLatestArticles } from "../../../Api/Carousel"; // or wherever GetLatestArticles is exported

// Models
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";

function useFetchLatestArticles(take: number, skip: number, shouldAppendData: boolean) {
    const
        [articles, setArticles] = useState<ICarouselContentDTO[]>([]),
        [articlesLoading, setArticlesLoading] = useState<boolean>(false),
        [articlesError, setArticlesError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        const abortController = new AbortController();

        setArticlesLoading(true);

        (async () => {
            try {
                const data = await GetLatestArticles(take, skip, abortController);
                //console.log(data);
                if (!isCancelled && data !== null) {
                    if (shouldAppendData) {
                        setArticles((prev) => [...prev, ...data]);
                    } else {
                        setArticles(data);
                    }
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setArticlesError("Failed to fetch articles.");
                }
            } finally {
                if (!isCancelled) {
                    setArticlesLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
            abortController.abort();
        };

    }, [take, skip, shouldAppendData]);

    return { articles, articlesLoading, articlesError };
}

export default useFetchLatestArticles;
