import React, { useState, useEffect } from "react";

// Models
import comingSoonItems from "../../../Models/StaticObjects/ComingSoon";

// Components
import PortraitImageItem from "../../Content/PortraitImageItem";
import ButtonPill from "../../Buttons/ButtonPill";
import IconSpinLoader from "../../../Assets/SVGs/Icons/Loading/SpinLoader";

type StepUpComingContentProps = {
    handleCancelMembership: () => Promise<void>;
    isCancelApiLoading: boolean;
};

// Custom hook to get current window width
function useWindowWidth() {
    const [width, setWidth] = useState(
        typeof window !== "undefined" ? window.innerWidth : 0
    );

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
}

function StepUpComingContent_CancelOrPause({ handleCancelMembership, isCancelApiLoading }: StepUpComingContentProps) {
    const
        width = useWindowWidth(),
        isMobile = width < 584,
        itemsToShow = comingSoonItems.slice(0, isMobile ? 4 : 6);

    return (
        <div className="cancel-or-pause__step-upcoming-content">
            <div className="cancel-or-pause__heading-wrapper">
                <h1>Coming Soon</h1>
            </div>

            <div className="cancel-or-pause__divider" />

            <div className="cancel-or-pause__content-wrapper">
                <h3>Upcoming hits you don't want to miss</h3>

                <div className="step-upcoming-content__upcoming-content">
                    {itemsToShow.map((item, i) => (
                        <PortraitImageItem
                            key={i}
                            item={item}
                            isLoading={false}
                        />
                    ))}
                </div>

                <p className="step-upcoming-content__next-step">
                    Still not convinced?
                    <ButtonPill
                        onClick={() => handleCancelMembership()}
                        label={isCancelApiLoading ? "" : "Cancel Auto-Renew"}
                        className={isCancelApiLoading ? "is-loading" : "is-not-loading"}
                        link=""
                        icon={isCancelApiLoading ? IconSpinLoader() : null}
                    />
                </p>
            </div>
        </div>
    );
}

export default StepUpComingContent_CancelOrPause;
