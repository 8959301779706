import { useEffect, useRef, useCallback } from "react";
import { UpdateVideoStat, StartVideoSession } from "../../Api/VideoMetrics";
import { IPlayerPeakInfo } from "../../Models/IPlayerPeakInfo";
import Player from "video.js/dist/types/player";

interface TelemetryConfig {
    accessToken: string;
    video?: { Id?: number }; // adjust type as needed
    peak?: IPlayerPeakInfo | null;
}

const useTelemetry = (
    player: Player | null,
    { accessToken, video, peak }: TelemetryConfig
) => {
    // Ref for the telemetry interval timer
    const peakTimer = useRef<number | null>(null);
    // Flag to ensure telemetry is only initialized once
    const isFirstPlay = useRef(true);
    // Ref used in telemetry for tracking seeking
    const isSeekingRef = useRef(false);
    // Controller for aborting telemetry API calls if needed
    const controller = useRef(new AbortController());

    // Centralized telemetry initialization logic
    const initializeTelemetry = useCallback(() => {
        if (!player || !peak) {
            console.warn("Player is not available or peak data missing; telemetry not initialized.");
            return;
        }

        const videoId = video && video.Id ? video.Id : 0;

        // Start telemetry updates if not already started
        if (!peakTimer.current) {
            console.log("Starting the UpdateVideoStat interval");
            setTimeout(() => {
                peakTimer.current = window.setInterval(async () => {
                    try {
                        // Check if player is ready and peak data is still valid
                        if (!player || !peak || peak?.session === "" || player.readyState() < 1) {
                            console.log("Player not ready or peak data missing; skipping UpdateVideoStat.");
                            return;
                        }

                        const currentTime = Math.round(player.currentTime() || 0);
                        const playbackDuration = Math.round(player.duration() || 0);
                        const videoQuality = (player as any).qualityLevels?.()[0]?.height || "Unknown";
                        const isMuted = player.muted() ?? false;
                        const playbackRate = player.playbackRate() ?? 1;
                        const isPaused = player.paused();
                        const isSeeking = isSeekingRef.current;
                        const isEnded = player.ended();
                        const timestamp = new Date().toISOString();
                        const buffered = player.buffered();
                        const bufferingDuration = buffered.length ? buffered.end(0) : undefined;

                        let bitrate;
                        let bandwidth;
                        try {
                            const tech = player.tech();
                            const vhs = tech && (tech as any).vhs;
                            bitrate = vhs?.playlists?.media()?.attributes?.BANDWIDTH || undefined;
                            bandwidth = vhs?.bandwidth || undefined;
                        } catch (err) {
                            console.warn("Failed to retrieve bitrate or bandwidth:", err);
                        }

                        const data = {
                            currentTime,
                            playbackDuration,
                            videoQuality: videoQuality.toString(),
                            isMuted,
                            playbackRate,
                            isSeeking,
                            isPaused,
                            isEnded,
                            timestamp,
                            session: peak.session,
                            videoId,
                            bufferingDuration,
                            bitrate,
                            bandwidth,
                        };

                        console.log("Sending UpdateVideoStat data:", data);
                        const response = await UpdateVideoStat(data, accessToken, controller.current);
                        console.log("UpdateVideoStat response:", response);
                    } catch (error) {
                        console.error("Failed to update video stats:", error);
                    }
                }, 30000);
            }, 1000);
        }

        // Start the video session only once on the first play
        if (isFirstPlay.current) {
            isFirstPlay.current = false;
            const deviceType = /Mobile/.test(navigator.userAgent) ? "Mobile" : "Desktop";
            const browser = navigator.userAgent;
            const operatingSystem = navigator.platform;

            StartVideoSession(
                {
                    videoId,
                    userId: peak.userId,
                    session: peak.session,
                    deviceType,
                    browser,
                    operatingSystem,
                },
                accessToken,
                controller.current
            ).catch((error) => console.error("Failed to start video session:", error));
        }
    }, [player, peak, video, accessToken]);

    // onPlay callback attached to the player's "play" event
    const onPlay = useCallback(() => {
        initializeTelemetry();
    }, [initializeTelemetry]);

    // Attach onPlay to the player's "play" event
    useEffect(() => {
        if (!player) return;
        player.on("play", onPlay);
        return () => {
            player.off("play", onPlay);
            if (peakTimer.current) {
                clearInterval(peakTimer.current);
            }
        };
    }, [player, onPlay]);

    // NEW: Effect to re-attempt telemetry initialization when peak data becomes available
    useEffect(() => {
        if (player && peak && isFirstPlay.current) {
            console.log("Peak data is now available; re-attempting telemetry initialization.");
            onPlay();
        }
    }, [player, peak, onPlay]);
};

export default useTelemetry;
