import { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { AxiosError } from "axios";

// API function for fetching the peak data (assumed to return an object like { Peak: number })
import { GetVideoPeak } from "../../Api/Video";

// Models
import { IPlayerPeakInfo } from "../../Models/IPlayerPeakInfo";

// Context (to access the authenticated user data)
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

interface UseVideoPeakResult {
    peak: IPlayerPeakInfo | null;
    loading: boolean;
    error: string;
}

function useVideoPeak(videoId: number | undefined): UseVideoPeakResult {
    const { userData } = useContext(UserAuthenticationContext);
    const [peak, setPeak] = useState<IPlayerPeakInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    // Local state to track if the video should be blocked (e.g. for force-watch cases)
    const [blocked, setBlocked] = useState<boolean>(false);

    // Generate a unique session id for this video session
    const [session] = useState(() => uuidv4());

    useEffect(() => {
        // If no videoId is provided, do nothing.
        if (!videoId) {
            setLoading(false);
            return;
        }

        // If the user is not authenticated, you may want to set default peak info.
        if (!userData?.AspNetUserId) {
            setPeak({
                session,
                videoId,
                currentPeak: 0,
                userId: "",
                updateBlockedState: setBlocked,
                blocked: false,
            });
            setLoading(false);
            return;
        }

        const controller = new AbortController();
        setLoading(true);
        setError("");

        const fetchPeak = async () => {
            try {
                const result = await GetVideoPeak(userData.AspNetUserId, videoId, controller);
                let peakValue = 0;
                if (result && !(result instanceof AxiosError)) {
                    peakValue = result.Peak ?? 0;
                }
                setPeak({
                    session,
                    videoId,
                    currentPeak: peakValue,
                    userId: userData.AspNetUserId,
                    updateBlockedState: setBlocked,
                    blocked,
                });
            } catch (err) {
                console.error("Error fetching video peak:", err);
                setError("Failed to fetch video peak data.");
            } finally {
                setLoading(false);
            }
        };

        fetchPeak();

        return () => {
            controller.abort();
        };
    }, [videoId, userData?.AspNetUserId, session]);

    // If the "blocked" state changes, update the peak object accordingly.
    useEffect(() => {
        if (peak) {
            setPeak(prev => prev ? { ...prev, blocked } : prev);
        }
    }, [blocked]);

    return { peak, loading, error };
}

export default useVideoPeak;
