import axios, { AxiosError } from "axios";
import ISeriesDTO from "../Models/DTOs/ISeriesDTO";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.series;

export async function GetSeries(seriesId: number, abortController: AbortController) {
    const series = await axios
    .get(BACKEND_URL + seriesId + "/web", {
        signal: abortController.signal,
    })
    .then((result) => {
        let seriesResult = result.data as ISeriesDTO;
        return seriesResult;
    })
    .catch((error: AxiosError) => {
        console.log("GetSeries");
        throw error;
    })

    return series;
};

export async function GetNewsSeries( abortController: AbortController) {
    const series = await axios
    .get(BACKEND_URL + "GetNewsSeries" , {
        signal: abortController.signal,
    })
    .then((result) => {
        if (result == null) {
            return null;
        }

        let seriesResult = result.data as ISeriesDTO[];
        return seriesResult;
    })
    .catch((error: AxiosError) => {
        console.log("GetNewsSeries");
        return error;
    })

    return series;
};
