import React, { useState, ChangeEvent, forwardRef } from 'react';

interface TextAreaProps {
    name: string;
    value: string;
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus: (name: string) => void;
    onBlur: (name: string) => void;
    hasError: boolean;
    errorMessage: string;
    required?: boolean;
    className?: string;
}

const InputTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ name, value, placeholder, onChange, onFocus, onBlur, hasError, errorMessage, required, className }, ref) => {
        const
            [focused, setFocused] = useState<boolean>(false),
            handleFocus = () => {
                setFocused(true);
                onFocus(name);
            },
            handleBlur = () => {
                setFocused(false);
                onBlur(name);
            };

        return (
            <div className={`form__textarea ${hasError ? 'has-error' : 'has-no-error'} ${focused ? 'is-focused' : 'is-not-focused'} ${value !== '' ? 'has-value' : 'has-no-value'} ${className}`}>

                <textarea
                    placeholder={placeholder}
                    name={name}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    required={required}
                />

                {hasError && errorMessage && errorMessage !== "valid" &&
                    <span className="error-message">{errorMessage}</span>
                }
            </div>
        );
    }
);

export default InputTextArea;
