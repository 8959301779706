import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// API
import { GetCategoryArticles } from "../../../Api/Article";

// Models
import ICategoryArticlesDTO from "../../../Models/DTOs/ICategoryArticlesDTO";

function useFetchArticlesByCategory(
    categoryId: number,
    skip: number,
    take: number,
    displayedSinceLastAd: number
) {
    const
        [ categoryArticles, setCategory ] = useState<ICategoryArticlesDTO | null>(null),
        [ loading, setLoading ] = useState(false),
        [ error, setError ] = useState<string | null>(null),
        navigate = useNavigate();

    useEffect(() => {
        let isCancelled = false;
        const controller = new AbortController();

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await GetCategoryArticles(
                    categoryId,
                    skip,
                    take,
                    displayedSinceLastAd,
                    controller
                );

                if (!isCancelled && response && !(response as any).isAxiosError) {
                    console.log(response);
                    setCategory(response as ICategoryArticlesDTO);
                } else if (!isCancelled) {
                    setError("Failed to fetch category articles.");
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setError("Failed to fetch category articles.");
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isCancelled = true;
            controller.abort();
        };
    }, [categoryId, skip, take, displayedSinceLastAd, navigate]);

    return { categoryArticles, loading, error };
}

export default useFetchArticlesByCategory;
