export function SetTitle(title: string) {

    if (title === "") {
        document.title = "Ickonic";
        return;
    }

    let oldTitle = document.title;
    let newtitle = oldTitle.split("-")[0] + " - " + title;

    document.title = newtitle;
}
