import styled from "styled-components";
import ICarouselContent from "../../Models/DTOs/ICarouselContentDTO";
import Heading from "../UI/Text/Heading";
import { ContentType } from "../../Models/Enums/ContentType";
import Rating from "../UI/Rating";
import PinkButton from "../UI/Buttons/PinkButton";
import { FaRegPlayCircle, FaBookmark, FaRegBookmark } from "react-icons/fa";
import { AddOrDeleteFavourite } from "../../Api/Favourite";
import { useContext, useState } from "react";
import NavLinkPinkButton from "../UI/Buttons/NavLinkPinkButton";
import NavLinkWhiteButton from "../UI/Buttons/NavLinkWhiteButton";
import { RoutePaths } from "../../Constants/RoutePaths";
import { AxiosError } from "axios";
import { ResponsiveBreakpoints } from "../../Constants/ResponsiveBreakpoints";
import {Colours} from "../../Constants/Colours";
import PrimaryText from "../UI/Text/PrimaryText";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";
import {GetTrailerRoute} from "../../Helpers/Content";

const
    Container = styled.div`
        position: relative;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        .u-full_cover_absolute {
            opacity: 0.2;
        }
    `,
    ContentContainer = styled.div`
        position: relative;
        z-index: 1;
        padding: 2rem 2.5rem 4rem 2.5rem;
        max-width: calc(1652rem/16);
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .featured-slide__description {
            max-width: calc(900rem/16);
            margin: 0 auto 1rem 0;
        }

        h1 {
            margin: 1rem 0;
        }

        span {
            background: ${ Colours.IckonicPink };
            color: white;
            padding: calc(5rem/16) calc(13rem/16);
            margin: 0 0 1rem 0;
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }em/16)) {
            h1 {
                font-size: 30pt;
            }
        }
    `,
    ContentTypeContainer = styled.span`

    `,
    ButtonContainer = styled.div`
        margin: 1rem 0 0 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: calc(16rem/16);
        button,
        a {
            margin: 0;
            font-size: 12pt;
            &:last-child {
                margin: 0;
            }
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            justify-content: flex-start;
        }
    `;



function FeaturedCarouselItem(props: { Content: ICarouselContent, height?: number }) {
    const
        authCtx = useContext(UserAuthenticationContext),
        controller = new AbortController(),
        [ isFavourite, setIsFavourite ] = useState<boolean>(props.Content.IsFavourite),
        [ loading, setLoading ] = useState<boolean>(false),

        hasLatestEpisode =
            props.Content.LatestEpisode !== undefined &&
            props.Content.LatestEpisode > 0,

        favouriteText = isFavourite
            ? "Remove Favourite"
            : " Add To My Favourites",

        ToggleFavourite = async () => {
            if (authCtx?.userData) {
                setLoading(true);
                let isSeries;

                switch (props.Content.ContentType) {
                    case ContentType.Article:
                    case ContentType.Event:
                        setLoading(false);
                        return;

                    case ContentType.Series:
                        isSeries = true;
                        break;
                    default:
                    case ContentType.Episode:
                    case ContentType.Film:
                        isSeries = false;
                        break;
                }

                const result = await AddOrDeleteFavourite(
                    props.Content.Id,
                    authCtx?.userData?.AspNetUserId,
                    isSeries,
                    controller
                );

                if (result === "Error" || result instanceof AxiosError) {
                    setLoading(false);
                    return;
                }

                setIsFavourite(!isFavourite);
                setLoading(false);
            }
        };

    function GetButtonContainer() {
        return (
            <ButtonContainer>
                <NavLinkPinkButton
                    to={
                        hasLatestEpisode
                            ? RoutePaths.Watch(props.Content.LatestEpisode ?? props.Content.Id)
                            : RoutePaths.Watch(props.Content.Id)
                    }
                >
                    <FaRegPlayCircle />

                    { hasLatestEpisode ? "Play Latest Episode" : "Play Now" }
                </NavLinkPinkButton>

                {
                    props.Content.TrailerLinkDash.length > 0 || props.Content.TrailerLinkHLS.length > 0 ? (
                        <NavLinkWhiteButton
                            to={ GetTrailerRoute(props.Content.Id, props.Content.ContentType) }
                        >
                            <FaRegPlayCircle />

                            Watch Trailer
                        </NavLinkWhiteButton>
                    ) : null }

                <PinkButton onClick={ ToggleFavourite } disabled={ loading }>
                    { isFavourite ? <FaBookmark /> : <FaRegBookmark /> }
                    { favouriteText }
                </PinkButton>
            </ButtonContainer>
        );
    }

    return (
        <Container style={ { height: props.height !== undefined ? props.height : 'auto' } }>

            { /** BG Image **/ }
            <div className="u-full_cover_absolute" style={ { background: `url("${ props.Content.BackgroundImage }") center/cover no-repeat` } }> </div>

            <ContentContainer className="featured-slide__content">
                <ContentTypeContainer>
                    { ContentType[props.Content.ContentType] }
                </ContentTypeContainer>

                <Rating
                    iconSize={ 15 }
                    maxStars={ 5 }
                    value={ props.Content.Rating }
                />

                <Heading>
                    { props.Content.Title }
                </Heading>

                <div className="featured-slide__description">
                    <PrimaryText>
                        { props.Content.Text }
                    </PrimaryText>
                </div>

                { GetButtonContainer() }
            </ContentContainer>
        </Container>
    );
}

export default FeaturedCarouselItem;
