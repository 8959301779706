import React from "react";

// Assets
import IconTheatreMode from "../../Assets/SVGs/Icons/VideoPlayer/TheatreMode";
import IconTheatreModeRevert from "../../Assets/SVGs/Icons/VideoPlayer/TheatreModeRevert";

interface VideoTheatreModeProps {
    isTheatreMode: boolean;
    setIsTheatreMode: React.Dispatch<React.SetStateAction<boolean>>;
    showControls: boolean;
}

const VideoTheatreMode: React.FC<VideoTheatreModeProps> = ({ isTheatreMode, setIsTheatreMode, showControls }) => {
    const toggleTheatreMode = () => {
        setIsTheatreMode(!isTheatreMode);
    };

    return (
        <button
            className="video-controls__button video-controls--theatre-mode"
            onClick={toggleTheatreMode}
            aria-label={isTheatreMode ? "Exit Theatre Mode" : "Enter Theatre Mode"}
        >
            { isTheatreMode ? IconTheatreModeRevert() : IconTheatreMode() }
        </button>
    );
};

export default VideoTheatreMode;
