import React from "react";

const IconArrowSmall = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                style={{fill: props?.iconFill}}
                d="M13.789 7.155A.75.75 0 0115 7.747v8.501a.75.75 0 01-1.212.591L8.29 12.581a.746.746 0 01.001-1.183zm-.289 7.563V9.272l-3.522 2.719z"
            ></path>
        </svg>
    )
};

export default IconArrowSmall;
