import {PlanType} from "../Models/Enums/PlanType";
import {ContentAccessType} from "../Models/Enums/ContentAccessType";
import {PlanPeriod} from "../Models/Enums/PlanPeriod";
import {SubscriptionStatus} from "../Models/Enums/SubscriptionStatus";
import moment from "moment/moment";

export function GetPlanUniqueIdentifier(type: PlanType) {
    switch (type) {
        case PlanType.GameChanger:
            return <span>Best Value</span>;
        default:
            return null
    }
}

export function GetPlanDisplayedPrice(price: number, type: PlanType) {
    let displayPrice: string;
    let period: JSX.Element;
    period = (
        <span>{' / ' + GetPlanPeriod(type)}</span>
    );
    switch (type) {
        case PlanType.GameChanger:
            let newPrice = price / 12;
            displayPrice = newPrice.toFixed(2);
            period = (
                <span>{' / month'}</span>
            );
            break;
        default:
            displayPrice = price.toString();
            break;
    }

    return (
        <>
            {displayPrice}
            {period}
        </>
    );
}

export function PlanTypeText(accessLevel: number) {
    switch (accessLevel) {
        case 1:
            return "Standard";
        case 2:
            return "Basic";
        case 3:
            return "Premium";
        case 99:
            return "Admin";
        default:
            return "Just David Icke";
    }
}

export function PlanInterval(billingFrequency: number) {
    switch (billingFrequency) {
        case 10:
            return "One off";
        case 1:
            return "Year";
        case 2:
            return "Quarter";
        default:
            return "Month";
    }
}

export function DeviceLimit(title: string) {
    if (title === null || title.length === 0) {
        return 0;
    }

    switch (title.toLowerCase()) {
        case "premium":
            return 4;
        case "game changer":
        case "standard":
            return 2;
        case "truth seeker":
        case "basic":
            return 1;
        default:
            return 0;
    }
}

export function DeviceNumberToString(deviceNumber: number) {
    switch (deviceNumber) {
        default:
        case 1:
            return "One";
        case 2:
            return "Two";
        case 3:
            return "Three";
        case 4:
            return "Four";
        case 5:
            return "Five";
        case 6:
            return "Six";
    }
}

export function GetPlanSubText(
    subStatus: SubscriptionStatus,
    endDate: Date,
    billingFrequency: number
) {
    switch (subStatus) {
        case SubscriptionStatus.Trialing:
            return "Your trial ends " + moment(endDate).format("Do MMMM YYYY").toString();
        case SubscriptionStatus.Active:
            return (
                "Your next " +
                PlanInterval(billingFrequency).toLocaleLowerCase() +
                "ly renewal date will be " +
                moment(endDate).format("Do MMMM YYYY").toString()
            );
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Cancelled:
            return (
                "Your subscription will end on " + moment(endDate).format("Do MMMM YYYY").toString()
            );
        case SubscriptionStatus.Unpaid:
        case SubscriptionStatus.None:
        default:
            return "";
    }
}

export function GetPlanPeriod(type: PlanType) {
    switch (type) {
        case PlanType.MonthlyFour:
        case PlanType.MonthlyTwo:
        case PlanType.MonthlyOne:
        case PlanType.TruthSeeker:
            return "Month";
        case PlanType.YearlyFour:
        case PlanType.YearlyTwo:
        case PlanType.YearlyOne:
        case PlanType.GameChanger:
            return "Year";
        case PlanType.Quarterly:
            return "Quarter";
        default:
            return "";
    }
}

export function TrimPlanTitle(title: string) {
    if (title.toLowerCase().includes("monthly")) {
        return title.split("Monthly")[1].trim();
    }
    if (title.toLowerCase().includes("yearly")) {
        return title.split("Yearly")[1].trim();
    }
    if (title.toLowerCase().includes("quarterly")) {
        return title.split("quarterly")[1].trim();
    }

    return title;
}

export function GetPlanType(accessType: ContentAccessType, period: PlanPeriod) {
    switch (accessType) {
        case ContentAccessType.OneDevice:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyOne;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyOne;
                default:
                    return undefined;
            }
        case ContentAccessType.TwoDevices:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyTwo;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyTwo;
                default:
                    return undefined;
            }
        case ContentAccessType.FourDevices:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyFour;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyFour;
                default:
                    return undefined;
            }
        case ContentAccessType.GameChanger:
            return PlanType.GameChanger;
        case ContentAccessType.TruthSeeker:
            return PlanType.TruthSeeker;
        case ContentAccessType.Unlimited:
    }
}

export function GetPlanTitle(type: PlanType) {
    switch (type) {
        case PlanType.MonthlyOne:
        case PlanType.YearlyOne:
            return "Basic";
        case PlanType.MonthlyTwo:
        case PlanType.YearlyTwo:
            return "Standard";
        case PlanType.MonthlyFour:
        case PlanType.YearlyFour:
            return "Premium";
        case PlanType.TruthSeeker:
            return "Truth Seeker";
        case PlanType.GameChanger:
            return "Game Changer";
    }
}
