import React from "react";

const IconLinkedin = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path
                style={{fill: props?.iconFill}}
                d="M4.98 3.5C4.98 4.881 3.87 6 2.5 6S.02 4.881.02 3.5C.02 2.12 1.13 1 2.5 1s2.48 1.12 2.48 2.5M5 8H0v16h5zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V24H24V13.869c0-7.88-8.922-7.593-11.018-3.714z"></path>
        </svg>
    )
};

export default IconLinkedin;
