import React from "react";

const IconMugHot = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                style={{fill: props?.iconFill}}
                d="M14.884 10c-.439 5.493-1.803 9.418-3.087 12H7.205c-1.692-3.396-2.728-7.419-3.09-12h10.769zM17 8H2c.258 7.569 2.143 12.68 4 16h7c1.812-3.219 3.745-8.496 4-16zm1.088 2a44.864 44.864 0 01-.192 2h1.707c-.51 1.822-1.246 3.331-2.539 4.677a35.202 35.202 0 01-.939 3.229C19.386 17.739 21.681 13.517 22 10h-3.912zm-7.714-3.001c4.737-4.27-.98-4.044.117-6.999-3.783 3.817 1.409 3.902-.117 6.999zM7.594 7c3.154-2.825-.664-3.102.087-5.099C5.039 4.688 8.631 4.76 7.594 7z"></path>
        </svg>
    )
};

export default IconMugHot;
