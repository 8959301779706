import React, {useContext, useState} from "react";
import styled from 'styled-components'

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

// Components
import LikeDislikeButton from "../Buttons/LikeDislikeButton";

// Hooks
import useFetchVideoRating from "../../Hooks/VideoInfo/useFetchVideoRating";

// Constants
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Models
import {RatingButtonType} from "../../Models/Enums/RatingButtonType";

const RatingsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 1rem 0 0;
    padding: 0 18px 0 14px;
    height: 40px;
    width: auto;
    border-radius: 25px;
    background: #2e2e2e;
    min-width: 120px;
    p {
        min-width: 10px;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        //margin: 0 1rem 1rem 0;
    }
`;

interface VideoInfoProps {
    video: IVideoDTO;
    isVideoLoading: boolean;
}

const VideoLikeDislike: React.FC<VideoInfoProps> = ({ video, isVideoLoading }) => {
    const
        authCtx = useContext(UserAuthenticationContext),
        userId = authCtx.userData.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6",
        [refreshRating, setRefreshrating] = useState(0),
        { rating, loading, error } = useFetchVideoRating(video.Id, userId, refreshRating);

    return (
        <div className="watch-video__like-dislike">
            <RatingsContainer>
                <LikeDislikeButton
                    videoId={ video.Id }
                    IconSize={ 25 }
                    Rating={ rating }
                    aspNetUserId={ authCtx.userData.AspNetUserId }
                    RefreshRating={ () => {setRefreshrating(refreshRating + 1)} }
                    Type={ RatingButtonType.Like }
                    isDisabled={ false}
                    testId={"likeBtn"}
                >
                    {rating.LikeCount}
                </LikeDislikeButton>

                <LikeDislikeButton
                    videoId={video.Id}
                    IconSize={25}
                    Rating={rating}
                    aspNetUserId={ authCtx.userData.AspNetUserId }
                    RefreshRating={ () => {setRefreshrating(refreshRating + 1)} }
                    Type={RatingButtonType.Dislike}
                    isDisabled={false}
                    testId={"dislikeBtn"}
                >
                    {rating.DislikeCount}
                </LikeDislikeButton>
            </RatingsContainer>
        </div>
    );
};

export default VideoLikeDislike;
