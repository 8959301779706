import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {GetBrowsers, GetDevices, GetTechnicalIssues} from "../../../Api/Cancellation";
import InputCheckbox from "../../Inputs/InputCheckbox";
import SelectInput from "../../Inputs/SelectInput/SelectInput";
import IconSpinLoader from "../../../Assets/SVGs/Icons/Loading/SpinLoader";
import ButtonPill from "../../Buttons/ButtonPill";
import InputTextArea from "../../Inputs/InputTextArea";

interface StepTechnicalDifficultiesProps {
    selectedIssues: string[],
    setSelectedIssues: (arr: string[]) => void;
    selectedDevice: number;
    setSelectedDevice: Dispatch<SetStateAction<number>>;
    selectedBrowser: number;
    setSelectedBrowser: Dispatch<SetStateAction<number>>;
    feedback: string;
    setFeedback: (s: string) => void;
    handleCancelMembership: () => Promise<void>;
    isCancelApiLoading: boolean;
}

const StepTechnicalDifficulties_CancelOrPause = (
    {
        selectedIssues,
        setSelectedIssues,
        selectedDevice,
        setSelectedDevice,
        selectedBrowser,
        setSelectedBrowser,
        feedback,
        setFeedback,
        handleCancelMembership,
        isCancelApiLoading
    }: StepTechnicalDifficultiesProps) =>
{
    const
        [showOtherInput, setShowOtherInput] = useState(false),
        [deviceOptions, setDeviceOptions] = useState<{ value: string; label: string }[]>([]),
        [browserOptions, setBrowserOptions] = useState<{ value: string; label: string }[]>([]),
        [technicalIssuesOptions, setTechnicalIssuesOptions] = useState<{ value: string; label: string }[]>([]),
        [techIssuesValid, setTechIssuesValid] = useState(false),
        [techIssuesValidationText, setTechIssuesValidationText] = useState('valid'),
        [showValidationText, setShowValidationText] = useState(false),

        handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.name;

            //console.log("Checkbox clicked:", value); // Debugging log

            const updatedIssues = selectedIssues.includes(value)
                ? selectedIssues.filter((i) => i !== value) // Remove if already selected
                : [...selectedIssues, value]; // Add if not selected

            //console.log("Updated Issues:", updatedIssues); // Debugging log

            setSelectedIssues(updatedIssues);

            // Check for "Other" using its string representation
            if (value === "17") {
                //console.log("Is 'Other' included in updatedIssues?", updatedIssues.includes("17"));
                setShowOtherInput(updatedIssues.includes("17"));
            }
        },

        handleTechIssuesSubmit = () => {
            if (techIssuesValid) {
                handleCancelMembership();
            } else {
                setShowValidationText(true);
            }
        },

        validateTechnicalIssues = () => {
            if (selectedIssues.length === 0) {
                setTechIssuesValid(false);
                setTechIssuesValidationText("Please select at least one issue.");
                return false;
            }

            if (!selectedDevice || !selectedBrowser) {
                setTechIssuesValid(false);
                setTechIssuesValidationText("Please select a device and a browser.");
                return false;
            }

            setTechIssuesValid(true);
            setShowValidationText(false);
            setTechIssuesValidationText("valid");
            return true;
        };

    useEffect(() => {
        validateTechnicalIssues();
    }, [selectedIssues, selectedBrowser, selectedDevice]);

    useEffect(() => {
        async function fetchDevicesAndBrowsers() {
            try {
                const
                    devices = await GetDevices(),
                    browsers = await GetBrowsers(),
                    formattedDevices = devices.map((device) => ({
                        value: device.plKey,
                        label: device.plTitle,
                    })),
                    formattedBrowsers = browsers.map((browser) => ({
                        value: browser.plKey,
                        label: browser.plTitle,
                    }));

                //console.log("formattedDevices:", formattedDevices); // Debugging log
                //console.log("formattedBrowsers:", formattedBrowsers); // Debugging log

                setDeviceOptions(formattedDevices);
                setBrowserOptions(formattedBrowsers);
            } catch (error) {
                console.error("Error fetching devices or browsers:", error);
            }
        }

        fetchDevicesAndBrowsers();
    }, []);

    // Fetch technical issues
    useEffect(() => {
        async function fetchTechnicalIssues() {
            try {
                const
                    issues = await GetTechnicalIssues(),
                    formattedIssues = issues.map((issue) => ({
                        value: issue.plKey,
                        label: issue.plTitle,
                    }));

                //console.log("Technical Issues Options:", formattedIssues); // Debugging log

                setTechnicalIssuesOptions(formattedIssues);
            } catch (error) {
                console.error("Error fetching technical issues:", error);
            }
        }

        fetchTechnicalIssues();
    }, []);

    return (
        <div className="cancel-or-pause__step-technical-difficulties">
            <div className="step-provide-feedback__inner">
                <div className="cancel-or-pause__heading-wrapper">
                    <h1>
                        Technical Difficulties
                    </h1>
                </div>

                <div className="cancel-or-pause__divider" />

                <div className="cancel-or-pause__content-wrapper">
                    <p>
                        We are sorry for any technical issues! Please describe the issues you encountered.
                    </p>

                    {techIssuesValidationText && techIssuesValidationText !== 'valid' && showValidationText && (
                        <p className="tech-difficulties__error-text">{techIssuesValidationText}</p>
                    )}

                    <div className="tech-difficulties__checkboxes">
                        {technicalIssuesOptions.map((issue) => (
                            <InputCheckbox
                                name={issue.value.toString()}
                                label={issue.label}
                                onChange={(e) => handleCheckboxChange(e)}
                                checked={selectedIssues.includes(issue.value.toString())}
                            />
                        ))}
                    </div>

                    {showOtherInput && (
                        <InputTextArea
                            name="Other Issue"
                            onFocus={() => {}}
                            onBlur={() => {}}
                            hasError={false}
                            errorMessage={""}
                            placeholder="Please describe the issue"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                    )}

                    <SelectInput
                        name="Device Options"
                        label="Select a device"
                        value={selectedDevice.toString()}
                        onChange={(e) => setSelectedDevice(parseInt(e.target.value))}
                        options={deviceOptions}
                    />

                    <SelectInput
                        name="Browser Options"
                        label="Select a browser"
                        value={selectedBrowser.toString()}
                        onChange={(e) => setSelectedBrowser(parseInt(e.target.value))}
                        options={browserOptions}
                    />

                    <ButtonPill
                        onClick={() => handleTechIssuesSubmit()}
                        label={isCancelApiLoading ? "" : "Cancel Auto-Renew"}
                        className={`${techIssuesValid ? 'is-valid' : 'is-not-valid'} ${isCancelApiLoading ? "is-loading" : "is-not-loading"}`}
                        link=""
                        icon={isCancelApiLoading ? IconSpinLoader() : null}
                    />
                </div>
            </div>
        </div>
    );
}

export default StepTechnicalDifficulties_CancelOrPause;


const dummyTechIssues = [
    {
        "value": "13",
        "label": "Playback Issue"
    },
    {
        "value": "14",
        "label": "Buffering Issue"
    },
    {
        "value": "15",
        "label": "Login Issue"
    },
    {
        "value": "16",
        "label": "App Issue"
    },
    {
        "value": "17",
        "label": "Other"
    },
    {
        "value": "30",
        "label": "Website Issue"
    }
];

const dummyBrowserOptions = [
    {
        "value": "23",
        "label": "Google Chrome"
    },
    {
        "value": "24",
        "label": "Firefox"
    },
    {
        "value": "25",
        "label": "Brave"
    },
    {
        "value": "26",
        "label": "Explorer"
    },
    {
        "value": "27",
        "label": "Safari"
    },
    {
        "value": "28",
        "label": "MS Edge"
    },
    {
        "value": "29",
        "label": "Other"
    }
];

const dummyDeviceOptions = [
    {
        "value": "18",
        "label": "Phone - IOS"
    },
    {
        "value": "19",
        "label": "Phone - Android"
    },
    {
        "value": "20",
        "label": "Laptop IOS"
    },
    {
        "value": "21",
        "label": "Laptop Windows Based"
    },
    {
        "value": "22",
        "label": "TV"
    }
];
