import React, { useEffect, useState, useContext } from "react";
import { Table, Pagination, Button } from "antd";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// Import the API function to fetch user deletions.
import { getUserDeletions } from "../../Api/VideoMetrics";

interface UserDeletionsListProps {
  pageSize?: number;
}

// Updated DTO based on provided fields
export interface UserDeletionDTO {
  UserDeletionID: number;
  UserName: string;
  UserEmail: string;
  DataDeletionDate: string;
  ReasonForDeletion: string;
}

const UserDeletionsList: React.FC<UserDeletionsListProps> = ({ pageSize = 10 }) => {
  const [data, setData] = useState<UserDeletionDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { userData } = useContext(UserAuthenticationContext);
  const access_token = userData?.Access_Token || "";

  useEffect(() => {
    const abortController = new AbortController();

    const loadUserDeletions = async () => {
      try {
        // Call the API function using the access token and abort controller.
        const result = await getUserDeletions(access_token, abortController);
        setData(result);
      } catch (err: any) {
        if (err.name !== "AbortError") {
          console.error("❌ Failed to load user deletions", err);
          setError("Failed to load user deletions");
        }
      } finally {
        setLoading(false);
      }
    };

    loadUserDeletions();
    return () => abortController.abort();
  }, [access_token]);

  const exportToExcel = () => {
    // Convert the data array into a worksheet.
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet.
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UserDeletions");
    // Write the workbook to an array buffer.
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    // Create a Blob from the buffer and trigger a download.
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "UserDeletionsList.xlsx");
  };

  // Define columns for the table using the new fields.
  const columns = [
    { title: "User ID", dataIndex: "UserDeletionID", key: "UserDeletionID" },
    { title: "Username", dataIndex: "UserName", key: "UserName" },
    { 
      title: "Email", 
      dataIndex: "UserEmail", 
      key: "UserEmail", 
      render: (email: string) => email || "N/A" 
    },
    {
      title: "Deletion Date",
      dataIndex: "DataDeletionDate",
      key: "DataDeletionDate",
      render: (text: string) =>
        text ? new Date(text).toLocaleDateString() : "N/A",
    },
    {
      title: "Reason",
      dataIndex: "ReasonForDeletion",
      key: "ReasonForDeletion",
      render: (text: string) => text || "N/A",
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div style={{ padding: "20px", backgroundColor: "#e6e0f8", borderRadius: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ margin: 0, color: "#333", display: "inline-block", marginRight: "10px" }}>
            User Deletions List
          </h2>
          <span style={{ color: "#333", fontSize: "14px" }}>Total: {data.length}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" onClick={exportToExcel} style={{ marginRight: "20px" }}>
            Export to Excel
          </Button>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data.length}
            onChange={(page) => setCurrentPage(page)}
            showSizeChanger
          />
        </div>
      </div>
      <Table
        dataSource={data.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        pagination={false}
        rowKey="UserDeletionID"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default UserDeletionsList;