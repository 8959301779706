import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    LabelList,
} from "recharts";
import { format } from "date-fns";

import { fetchViewOnCategoryData } from "../../Api/VideoMetrics";

interface CategoryViewData {
    ViewDate: string;
    CategoryTitle: string;
    TotalViews: number;
}

const ViewOnCategoryReport: React.FC = () => {
    const [data, setData] = useState<CategoryViewData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const formatXAxis = (tickItem: string) => {
        // Parse the date string and format it to 'dd-MM-yyyy'
        return format(new Date(tickItem), "dd-MM-yyyy");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchViewOnCategoryData();
                console.log("API Response: ", response); // Debugging
                setData(Array.isArray(response) ? response : []);
            } catch (err) {
                setError("Failed to load category view data");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: "red" }}>{error}</p>;
    if (!data || data.length === 0) return <p>No data available</p>;

    // Transform data to have dates as keys and categories as sub-keys
    const transformedData = data.reduce(
        (
            acc: any[],
            { ViewDate, CategoryTitle, TotalViews }: CategoryViewData
        ) => {
            const existingDate = acc.find((item) => item.ViewDate === ViewDate);
            if (existingDate) {
                existingDate[CategoryTitle] = TotalViews;
            } else {
                acc.push({ ViewDate, [CategoryTitle]: TotalViews });
            }
            return acc;
        },
        []
    );

    // Extract unique category titles for dynamic Bar components
    const categoryTitles = Array.from(
        new Set(data.map((item) => item.CategoryTitle))
    );

    // Define a color palette
    const colors = [
        "#8884d8",
        "#82ca9d",
        "#ffc658",
        "#ff7f50",
        "#87ceeb",
        "#da70d6",
        "#32cd32",
        "#ff69b4",
        "#ba55d3",
        "#cd5c5c",
    ];

    return (
        <div
            style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
            }}
        >
            <h2 style={{ textAlign: "center", color: "#333" }}>
                Views by Category (Last 7 Days)
            </h2>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={transformedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis
                        dataKey="ViewDate"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        tickFormatter={formatXAxis}
                    />
                    <YAxis tick={{ fontSize: 12, fontWeight: "bold" }} />
                    <Tooltip />
                    <Legend />
                    {categoryTitles.map((category, index) => (
                        <Bar
                            key={category}
                            dataKey={category}
                            fill={colors[index % colors.length]}
                            name={category}
                        >
                            <LabelList dataKey={category} position="top" />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ViewOnCategoryReport;
