import React, { useState, useEffect, useRef } from "react";
import { FilterBy } from "../Models/Enums/SearchFilters/FilterBy";
import { GetSearch, GetSearchCount } from "../Api/Search";
import { ContentType } from "../Models/Enums/ContentType";
import { ISearchFilterDTO } from "../Models/DTOs/ISearchFilterDTO";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import ICarouselContentDTO from '../Models/DTOs/ICarouselContentDTO';
import Heading from "../Components/UI/Text/Heading";
import CarouselItem from "../Components/Carousels/CarouselItem";
import { Originals } from "../Models/Enums/SearchFilters/Originals";
import { SortBy } from "../Models/Enums/SearchFilters/SortBy";
import InfiniteScrollLoader from "../Components/UI/PageLoaders/InfiniteScrollLoader";
import { AxiosError } from "axios";
import { HeadingType } from "../Models/Enums/HeadingType";
import {ShouldSearch} from "../Helpers/Search";

const SearchResults = styled.div`
    .count-hide{
        display: none;
    }

    .count-show {
        opacity: 1;
        display: block;
        margin-left: 7px;
    }

    .infinite-scroll-component {
        display: flex;
        position: relative;
        align-items: flex-start;
        gap: 5px;
        flex-flow: row wrap;
    }

    // Each video item
    .infinite-scroll-component a {
        flex: 0 1 calc(20% - 4px);
    }

    h1 {
        flex-basis: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 1600px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(25.25% - 8px);
        }
    }

    @media only screen and (max-width: 1000px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(33.8% - 8px);
        }
    }

    @media only screen and (max-width: 800px) {
        .infinite-scroll-component a {
            flex: 0 1 calc(50.6% - 8px);
        }
    }
    @media only screen and (max-width: 600px) {
        .infinite-scroll-component a {
            flex: 0 1 100%;
        }
    }
`;

const Container = styled.div`
    padding-bottom: 4rem;
    h3 {
        &.count-hide {
            display: none;
        }

        &.count-show {
            display:block;
        }
    }
`;

const SearchScreen = (props: {
    searchText: string,
    searchFilters: ISearchFilterDTO,
    currentCountryCode: string,
    disablePopUp: () => void
}) => {
    const
        controller = new AbortController(),
        [searchResults, setSearchResults] = useState<ICarouselContentDTO[]>([]),
        [hasMore, setHasMore] = useState(true),
        videoSkip = useRef<number>(0),
        seriesSkip = useRef<number>(0),
        [ loading, setIsLoading ] = useState<boolean>(false),
        [ lastSearch, setLastSearch ] = useState<string>(""),
        [ searchResultCount, setSearchResultCount ] = useState<number>(),

        GetSearchResults = async () => {
            if (loading) {
                return ;
            }

            setIsLoading(true);

            // Result count
            const count = await GetSearchCount(props.searchText,
                props.searchFilters.FilterBy ?? FilterBy.FilmsAndSeries,
                props.searchFilters.Originals !== Originals.All ?? false,
                props.currentCountryCode,
                controller,
            );

            if(count instanceof AxiosError){
                setSearchResultCount(0);
                setHasMore(false);
                return;
            }

            const response = await GetSearch(props.searchText, // Text
                props.searchFilters.FilterBy ?? FilterBy.FilmsAndSeries, // Filter by
                props.searchFilters.Originals !== Originals.All ?? false, // Originals
                videoSkip.current, seriesSkip.current, // Skips
                props.currentCountryCode, // Country code
                controller, // Abort controller
                props.searchFilters.SortBy === SortBy.Newest ?? true // Sort by
            );

            if (response == null || response instanceof AxiosError || response.length === 0 ) {

                if (searchResults.length === 0) {
                    setSearchResultCount(0);
                }

                setHasMore(false);
                return;
            }
            else {
                setHasMore(true);
            }

            seriesSkip.current = seriesSkip.current + response.filter((x) => x.ContentType === ContentType.Series).length

            videoSkip.current = videoSkip.current + response.filter(
                (x) =>
                    x.ContentType === ContentType.Episode ||
                    x.ContentType === ContentType.Film
            ).length;

            setSearchResultCount(count);
            setSearchResults(prev => {
                return [...prev, ...response as ICarouselContentDTO[]]
            });

            setIsLoading(false);
        },

        ClearSearchResults = () => {
            videoSkip.current = 0;
            seriesSkip.current = 0;
            setHasMore(true);
            setSearchResults([]);
            setIsLoading(false);
            setSearchResultCount(undefined);
        },

        RenderCarouselItems = (items: ICarouselContentDTO[]) => {
            let elements: JSX.Element[] = [];
            items.map((content) =>
                elements.push(
                    <CarouselItem
                        key={content.Id}
                        Content={content}
                        onClickCallbackFunc={ () => props.disablePopUp() }
                    />
                )
            );

            return elements;
        };

    useEffect(() => {
        async function LoadData() {
            await GetSearchResults();
        }

        if (!ShouldSearch(props.searchText)) {
            return ;
        }

        ClearSearchResults();
        LoadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchFilters]);

    useEffect(() => {
        setSearchResultCount(0);
        if (!ShouldSearch(props.searchText)) {
            return ;
        }

        if (lastSearch !== props.searchText) {
            ClearSearchResults();
            setLastSearch(props.searchText);
        }

        const delayDebounceFn = setTimeout(async () => {
            GetSearchResults();
        }, 4000);

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchText]);

    if (!ShouldSearch(props.searchText)) {
        return null;
    }

    return (
        <Container>
            <Heading type={ HeadingType.H3 } className={searchResultCount === undefined ? "count-hide" : "count-show"}>{searchResultCount} Results Found</Heading>
            <SearchResults>
                <InfiniteScroll
                    dataLength={searchResults === undefined ? 0 : searchResults.length}
                    hasMore={hasMore}
                    next={GetSearchResults}
                    loader={ loading ? <InfiniteScrollLoader/> : null }
                >
                    {
                        <React.Fragment>
                            {RenderCarouselItems(searchResults)}
                        </React.Fragment>
                    }
                </InfiniteScroll>
            </SearchResults>
        </Container>
    );
};

export default SearchScreen;
