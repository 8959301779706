// VideoVolume.tsx
import React, {useRef, useContext, MouseEvent, useEffect} from "react";
import Player from "video.js/dist/types/player";
import { isMobile } from "react-device-detect";

// Assets (Replace these with your actual icon components)
import IconVolume from "../../Assets/SVGs/Icons/VideoPlayer/Volume";
import IconVolumeMuted from "../../Assets/SVGs/Icons/VideoPlayer/VolumeMuted";

// Import our new context
import { VideoPlayerSettingsContext } from "../../Context/VideoPlayerSettingsContext";

interface VideoVolumeProps {
    player: Player;
    isHovered: boolean;
    setIsHovered?: (isHovered: boolean) => void;
}

const VideoVolume: React.FC<VideoVolumeProps> = ({ player, isHovered, setIsHovered }) => {
    // Instead of local state for volume, use context.
    const { volume, setVolume, muted, setMuted } = useContext(VideoPlayerSettingsContext);

    const lastVolumeRef = useRef<number>(volume || 1);
    const trackRef = useRef<HTMLDivElement | null>(null);

    const handlePointer = (hovered: boolean) => {
        setIsHovered?.(hovered);
    };

    const updateVolumeFromPosition = (clientX: number) => {
        if (!trackRef.current) return;
        const rect = trackRef.current.getBoundingClientRect();
        let newVolume = (clientX - rect.left) / rect.width;
        newVolume = Math.max(0, Math.min(1, newVolume)); // Clamp between 0 and 1

        // Update the global volume (which also writes to a cookie)
        setVolume(newVolume);
        player.volume(newVolume);

        if (newVolume === 0) {
            setMuted(true);
            player.muted(true);
        } else {
            setMuted(false);
            player.muted(false);
            lastVolumeRef.current = newVolume;
        }
    };

    const handleTrackClick = (e: MouseEvent<HTMLDivElement>) => {
        updateVolumeFromPosition(e.clientX);
    };

    const handleThumbMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsHovered?.(true);

        const handleMouseMove = (e: MouseEvent) => {
            updateVolumeFromPosition(e.clientX);
            setIsHovered?.(true);
        };
        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove as any);
            document.removeEventListener("mouseup", handleMouseUp);
            setIsHovered?.(false);
        };
        document.addEventListener("mousemove", handleMouseMove as any);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const toggleMute = () => {
        if (!muted) {
            if (volume && volume > 0) {
                lastVolumeRef.current = volume;
            }
            setMuted(true);
            player.muted(true);
            setVolume(0);
            player.volume(0);
        } else {
            setMuted(false);
            player.muted(false);
            const restoredVolume = lastVolumeRef.current || 0.5;
            setVolume(restoredVolume);
            player.volume(restoredVolume);
        }
    };

    useEffect(() => {
        if (volume !== undefined) {
            player.volume(volume);
        }
    }, []);

    return (
        <div
            onPointerMove={() => handlePointer(true)}
            onPointerLeave={() => handlePointer(false)}
            style={{ display: isMobile ? "none" : "flex", alignItems: "center" }}
            className={`video-controls__button video-controls__volume ${
                isHovered ? "is-hovered" : "is-not-hovered"
            }`}
        >
            <button
                onClick={toggleMute}
                style={{ border: "none", background: "transparent", cursor: "pointer" }}
            >
                {muted ? IconVolumeMuted() : IconVolume()}
            </button>

            <div ref={trackRef} className="volume__track" onClick={handleTrackClick}>
                <div
                    className="volume__runner"
                    style={{ width: `${volume === undefined ? 0 : volume * 100}%` }}
                ></div>
                <div
                    className="volume__thumb"
                    style={{ left: `${volume === undefined ? 0 : volume * 100}%` }}
                    onMouseDown={handleThumbMouseDown}
                ></div>
            </div>
        </div>
    );
};

export default VideoVolume;
