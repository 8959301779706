import styled from "styled-components";
import React, { useContext, useRef, useState, useReducer } from 'react';
import { toast } from "react-toastify";

// Api
import { SendCode } from "../../../Api/TvAuth";

// Models
import { TvPlatform } from "../../../Models/Enums/TvPlatforms";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { InputState } from "../../../Models/Enums/InputState";

// Components
import Heading from "../../../Components/UI/Text/Heading";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import NavLinkPinkButton from "../../../Components/UI/Buttons/NavLinkPinkButton";
import PinkButton from "../../../Components/UI/Buttons/PinkButton";
import Input from "../../../Components/UI/Inputs/Input";

// Constants
import { RoutePaths } from "../../../Constants/RoutePaths";

// Assets
import { ReactComponent as IckonicLogoQuestionEverything } from '../../../Assets/Images/Logos/ickonic-question-everything-white.svg';
import IconTV from "../../../Assets/SVGs/Icons/Devices/TV";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";

// Helpers
import {GetTvPlatformName} from "../../../Helpers/TVApps";
import {IsActive, IsAuthenticated} from "../../../Helpers/Account";
import ButtonPill from "../../../Components/Buttons/ButtonPill";
import InputField from "../../../Components/Inputs/InputField";
import IconCheckMarkHollow from "../../../Assets/SVGs/Icons/YesOrNo/CheckMarkHollow";
import IconCheckMark from "../../../Assets/SVGs/Icons/YesOrNo/CheckMark";

const InlineButtonLayout = styled.div`

`;

const LargeButtonLayout = styled.div`

`;

function TvAuthenticationScreen(props: {platform: TvPlatform}) {
    const
        errorText = "We could not log you in, please check the code you entered is valid.",
        controller = new AbortController(),
        [success, setSuccess] = useState(false),
        [loading, setLoading] = useState(false),
        [codeError, setCodeError] = useState<string>(),
        authCtx = useContext(UserAuthenticationContext),
        codeInputRef = useRef<HTMLInputElement | null>(null),
        isAuthenticated = IsAuthenticated(authCtx.userData),
        isSubscribed = IsActive(authCtx.userData),

        GetCodeKeyUpState = (text: string) : InputIsValid => {
            if (codeError === '' || codeError === undefined || codeError === null) {
                return InputIsValid.Valid;
            }
            return GetCodeValidState(text);
        },

        GetCodeValidState = (text: string) : InputIsValid => {
            if (text.length <= 0 || text.trim().length <= 0) {
                setCodeError("The code cannot be empty.");
                return InputIsValid.Invalid;
            }

            setCodeError(undefined);
            return InputIsValid.Valid;
        },

        CodeReducer = (state: IInputDTO, action: IInputDTO) => {
            switch (action.Type) {
                case InputState.User_Input:
                    return {
                        Value: action.Value,
                        IsValid: GetCodeKeyUpState(state.Value),
                    } as IInputDTO;
                case InputState.Input_Blur:
                    return {
                        Value: state.Value,
                        IsValid: GetCodeValidState(state.Value),
                    } as IInputDTO;
                default:
                    return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
            }
        },

        [codeState, dispatchCode] = useReducer(CodeReducer, {
            Value: "",
            IsValid: InputIsValid.NotSet,
        } as IInputDTO),

        validateCodeHandler = () => {
            dispatchCode({ Type: InputState.Input_Blur } as IInputDTO);
        },

        codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatchCode({
                Type: InputState.User_Input,
                Value: event.target.value,
            } as IInputDTO);
        },
        codeIsValid =
            codeState.IsValid === InputIsValid.Valid ||
            codeState.IsValid === InputIsValid.NotSet,

        submitCode = async () => {
            if (loading) {
                return;
            }

            setLoading(true);

            dispatchCode({
                Type: InputState.Input_Blur,
                Value: codeState.Value,
            } as IInputDTO);

            if (codeState.IsValid === InputIsValid.Valid) {
                const success = await SendCode(codeState.Value, authCtx.userData.AspNetUserId,controller);

                if (success) {
                    toast.success("Success");
                    setSuccess(true);
                }
                else {
                    setCodeError(errorText);
                }
            }
            else {
                setCodeError(errorText);
            }

            setLoading(false);
        },

        codeForm = (
            <>
                <Input
                    ref={codeInputRef}
                    type={"text"}
                    required={true}
                    errorMessage={codeError}
                    isValid={(success && codeError === undefined) ||
                        (codeIsValid && !success && codeError === undefined)}
                    placeholder={"Enter your " + GetTvPlatformName(props.platform) + " authentication code here"}
                    onChange={codeChangeHandler}
                    onBlur={validateCodeHandler}
                />

                <div className="tv-apps-auth__button-wrapper">
                    <ButtonPill
                        label="Submit"
                        onClick={submitCode}
                        className={`button-wrapper__submit ${codeState.Value !== '' ? 'has-value' : 'has-no-value'}`}
                        link=""
                    />
                </div>
            </>
        ),

        unauthenticatedLayout = (
            <>
                <h3>You need an account to access content on your {GetTvPlatformName(props.platform)} App.</h3>

                <div className="tv-apps-auth__button-wrapper">
                    <ButtonPill
                        label="Sign Up"
                        className=""
                        link={RoutePaths.CreateYourAccount}
                    />

                    <p>Or</p>

                    <ButtonPill
                        label="Login"
                        className=""
                        link={RoutePaths.Login + `?redirectTo=${props.platform}`}
                    />
                </div>
            </>
        ),

        unsubscribedLayout = (
            <div className="tv-apps-auth__unsubscribed">
                <p>You must have an active subscription to access content on your {GetTvPlatformName(props.platform)} App.</p>

                <div className="tv-apps-auth__button-wrapper">
                    <ButtonPill
                        label="Subscribe Now"
                        className=""
                        link={RoutePaths.ChoosePlan}
                    />
                </div>
            </div>
        ),

        successLayout = (
            <div className="tv-apps-auth__success">
                {IconCheckMarkHollow()}
                Success!, your {GetTvPlatformName(props.platform)} will automatically log you in shortly.
            </div>
        ),

        DisplayLayout = () => {
            if (success) {
                return successLayout;
            } else if (isAuthenticated) {
                if (isSubscribed) {
                    return codeForm;
                }
                return unsubscribedLayout;
            }
            return unauthenticatedLayout;
        };

    return (
        <div className="page page--tv-app-auth">
            <div className="tv-apps-auth__logo">
                <IckonicLogoQuestionEverything />
            </div>

            <div className="tv-apps-auth__inner">
                {/*<div className="tv-apps-auth__tv-icon">{IconTV()}</div>*/}

                <h2>{props.platform} Authentication</h2>

                <div className="tv-apps-auth__divider" />

                <div className="tv-apps-auth__content">
                    {DisplayLayout()}
                </div>
            </div>
        </div>
    );
}

export default TvAuthenticationScreen;
