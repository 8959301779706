import React, { createContext, useState, useEffect, ReactNode } from "react";
import lscache from "lscache";

interface IVideoPlayerSettingsContext {
    isTheatreMode: boolean;
    setIsTheatreMode: React.Dispatch<React.SetStateAction<boolean>>;
    volume: number;
    setVolume: React.Dispatch<React.SetStateAction<number>>;
    muted: boolean;
    setMuted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VideoPlayerSettingsContext = createContext<IVideoPlayerSettingsContext>({} as IVideoPlayerSettingsContext);

interface Props {
    children: ReactNode;
}

export const VideoPlayerSettingsProvider: React.FC<Props> = ({ children }) => {
    // Set expiration to one year (525600 minutes)
    const expiration = 525600;

    // Initialize isTheatreMode from lscache (default: false)
    const cachedTheatreMode = lscache.get("isTheatreMode");
    const initialTheatreMode =
        typeof cachedTheatreMode !== "undefined" && cachedTheatreMode !== null
            ? cachedTheatreMode === true || cachedTheatreMode === "true"
            : false;
    const [isTheatreMode, setIsTheatreMode] = useState<boolean>(initialTheatreMode);

    // Initialize volume from lscache (default: 1)
    const cachedVolume = lscache.get("volume");
    let initialVolume = 1;
    if (typeof cachedVolume !== "undefined" && cachedVolume !== null) {
        const parsedVolume = parseFloat(String(cachedVolume));
        if (isFinite(parsedVolume)) {
            initialVolume = parsedVolume;
        }
    }
    const [volume, setVolume] = useState<number>(initialVolume);

    // Initialize muted from lscache (default: false)
    const cachedMuted = lscache.get("muted");
    const initialMuted =
        typeof cachedMuted !== "undefined" && cachedMuted !== null
            ? cachedMuted === true || cachedMuted === "true"
            : false;
    const [muted, setMuted] = useState<boolean>(initialMuted);

    // Update lscache when values change
    useEffect(() => {
        lscache.set("isTheatreMode", isTheatreMode, expiration);
    }, [isTheatreMode]);

    useEffect(() => {
        lscache.set("volume", volume, expiration);
    }, [volume]);

    useEffect(() => {
        lscache.set("muted", muted, expiration);
    }, [muted]);

    return (
        <VideoPlayerSettingsContext.Provider
            value={{
                isTheatreMode,
                setIsTheatreMode,
                volume,
                setVolume,
                muted,
                setMuted,
            }}
        >
            {children}
        </VideoPlayerSettingsContext.Provider>
    );
};
