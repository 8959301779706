import React, { useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import HeroWithPricingPlans from "../../Components/Layouts/Sections/HeroWithPricingPlans";
import DavidIckeWithContentTwoColumn from "../../Components/Layouts/Sections/DavidIckeWithContentTwoColumn";
import CarouselCardsWithContent from "../../Components/Carousels/CarouselCardsWithContent";
import CategoryFeaturedItems from "../../Components/Layouts/Sections/CategoryFeaturedItems";
import PresenterShowcase from "../../Components/Layouts/Sections/PresenterShowcase";
import AppGrid from "../../Components/Layouts/Sections/AppGrid";

// Context
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Hooks
import useFetchTopRated from "../../Hooks/Content/Watch/useFetchTopRated";
import useFetchSpecificContentItems from "../../Hooks/Content/Watch/useFetchSpecificContentItems";

// Assets
import UFOSpace from '../../Assets/Images/FrontPage/ufo-space.jpg';
import DNA from '../../Assets/Images/FrontPage/dna.jpg';
import Presenters from '../../Assets/Images/FrontPage/presenters.png';
import Node from '../../Assets/Images/FrontPage/node.jpg';
import David from '../../Assets/Images/FrontPage/david-icke-chair.png';
import Globe from '../../Assets/Images/FrontPage/globe.jpg';
import Pyramids from '../../Assets/Images/FrontPage/pyramids.png';
import Occult from '../../Assets/Images/FrontPage/occult.png';
import Smoke from '../../Assets/Images/FrontPage/decorative-smoke.jpg';
import SeriesBG from '../../Assets/Images/FrontPage/series-category-background.jpg';
import SmokeRed from '../../Assets/Images/FrontPage/smoke-red.jpg';
import SpaceClouds from '../../Assets/Images/FrontPage/space-clouds.jpg';
import HeroStarsDesktop from '../../Assets/Images/FrontPage/hero-stars-flashlight-desktop.jpg';
import HeroStarsMobile from '../../Assets/Images/FrontPage/hero-stars-flashlight-mobile.jpg';
import Sunset from '../../Assets/Images/FrontPage/sunset.jpg';

import {IckonicLogoIcon} from "../../Assets/SVGs/Logos";

// Constants
import {RoutePaths} from "../../Constants/RoutePaths";
import TripleColumnIcons from "../../Components/Layouts/Sections/TripleColumnIcons";
import TwoColumnWithLatestArticles from "../../Components/Layouts/Sections/TwoColumnWithLatestArticles";
import useFetchLatestArticles from "../../Hooks/Content/Read/useFetchLatestArticles";
import Accordion from "../../Components/Layouts/Accordion";

function PreSignUp() {
    const
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        authCtx = useContext(UserAuthenticationContext),

        // User data
        userId = authCtx.userData.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6",
        countryCode = authCtx.userData.CountryCode ?? "GB",

        navigate = useNavigate(),

        {articles, articlesLoading, articlesError} = useFetchLatestArticles(4, 0, false),

        topRatedIds = useMemo(
            () => [
                { type: "Film", id: 3070 },
                { type: "Film",  id: 1026 },
                { type: "Series",  id: 178 },
                { type: "Series", id: 181 }
            ],
            []
        ),
        {
            items: topRated,
            loading: topRatedLoading,
            error: topRatedError
        } = useFetchSpecificContentItems(topRatedIds, countryCode, userId),


        curatedFilmIds = useMemo(
            () => [
                { type: "Film", id: 1163 },
                { type: "Film",  id: 454 },
                { type: "Film",  id: 1542 },
                { type: "Film", id: 1591 }
            ],
            []
        ),
        {
            items: curatedFilms,
            loading: curatedFilmsLoading,
            error: curatedFilmsError
        } = useFetchSpecificContentItems(curatedFilmIds, countryCode, userId),

        curatedSeriesIds = useMemo(
            () => [
                { type: "Series", id: 106 },
                { type: "Series",  id: 128 },
                { type: "Series",  id: 99 },
                { type: "Series", id: 119 }
            ],
            []
        ),
        {
            items: curatedSeries,
            loading: curatedSeriesLoading,
            error: curatedSeriesError
        } = useFetchSpecificContentItems(curatedSeriesIds, countryCode, userId),

        curatedHealthIds = useMemo(
            () => [
                { type: "Series", id: 125 },
                { type: "Series",  id: 132 },
                { type: "Film",  id: 2088 },
                { type: "Series", id: 165 }
            ],
            []
        ),
        {
            items: curatedHealth,
            loading: curatedHealthLoading,
            error: curatedHealthError
        } = useFetchSpecificContentItems(curatedHealthIds, countryCode, userId),

        curatedConsciousnessIds = useMemo(
            () => [
                { type: "Series", id: 113 },
                { type: "Series",  id: 117 },
                { type: "Series",  id: 164 },
                { type: "Series", id: 108 }
            ],
            []
        ),
        {
            items: curatedConsciousness,
            loading: curatedConsciousnessLoading,
            error: curatedConsciousnessError
        } = useFetchSpecificContentItems(curatedConsciousnessIds, countryCode, userId),

        curatedDavidIds = useMemo(
            () => [
                { type: "Series", id: 122 },
                { type: "Film",  id: 2688 },
                { type: "Series",  id: 156 },
                { type: "Series", id: 154 }
            ],
            []
        ),
        {
            items: curatedDavid,
            loading: curatedDavidLoading,
            error: curatedDavidError
        } = useFetchSpecificContentItems(curatedDavidIds, countryCode, userId),

        curatedGlobalIds = useMemo(
            () => [
                { type: "Series", id: 148 },
                { type: "Series",  id: 121 },
                { type: "Series",  id: 182 },
                { type: "Series", id: 107 }
            ],
            []
        ),
        {
            items: curatedGlobal,
            loading: curatedGlobalLoading,
            error: curatedGlobalError
        } = useFetchSpecificContentItems(curatedGlobalIds, countryCode, userId),

        curatedDocsIds = useMemo(
            () => [
                { type: "Film", id: 2190 },
                { type: "Film",  id: 2582 },
                { type: "Film",  id: 2266 },
                { type: "Film", id: 1409 }
            ],
            []
        ),
        {
            items: curatedDocs,
            loading: curatedDocsLoading,
            error: curatedDocsError
        } = useFetchSpecificContentItems(curatedDocsIds, countryCode, userId),

        curatedOccultIds = useMemo(
            () => [
                { type: "Series", id: 150 },
                { type: "Film",  id: 2688 },
                { type: "Series",  id: 163 },
                { type: "Series", id: 123 }
            ],
            []
        ),
        {
            items: curatedOccult,
            loading: curatedOccultLoading,
            error: curatedOccultError
        } = useFetchSpecificContentItems(curatedOccultIds, countryCode, userId);

    if (authCtx.doesAuthTokenExist()) {
        navigate(RoutePaths.Browse);
    }

    return (
        <div className="page--pre-sign-up">
            <HeroWithPricingPlans
                mainMenuDesktopMode={globalInterfaceContext.mainMenuDesktopMode}
                heading={<h1 className="is-very-loud"><p>See the w{IckonicLogoIcon()}rld <span>Differently</span></p></h1>}
                tagline="Your gateway to alternative media, groundbreaking stories, and unique insights"
                backgroundDesktop={HeroStarsDesktop}
                backgroundMobile={HeroStarsMobile}
                planOneTitleBG="#423370"
                planTwoTitleBG="white"
                planInnerBG="rgba(66, 51, 112, 0.6)"
                requireTransition={true}
            />

            <DavidIckeWithContentTwoColumn/>

            <CarouselCardsWithContent
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading={<h1><span>Top Picks</span> This Month</h1>}
                body={<p>Explore the most-watched content that's sparking conversations</p>}
                carouselItems={topRated}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Original Films"
                body={<p>Uncompromising storytelling, challenges the mainstream</p>}
                contentLeftSide={true}
                buttonText="Explore All"
                buttonLink={RoutePaths.Browse}
                buttonTheme={true}
                buttonColor={`168,72,150`}
                buttonColorHover={`137,38,134`}
                focusImage={UFOSpace}
                showFocusImageOnMobile={true}
                bgColor="#010107"
                contentItems={curatedFilmsLoading ? undefined : curatedFilms}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Original Series"
                body={<p>Exclusive series exploring perspectives</p>}
                contentLeftSide={false}
                buttonText="Explore All"
                buttonLink={RoutePaths.Browse}
                buttonTheme={true}
                buttonColor={`168,72,150`}
                buttonColorHover={`137,38,134`}
                focusImage={Presenters}
                showFocusImageOnMobile={false}
                bgImage={SeriesBG}
                bgColor="#010107"
                contentItems={curatedSeriesLoading ? undefined : curatedSeries}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Health & Wellbeing"
                body={<p>Empowering insights for a balanced mind and body</p>}
                contentLeftSide={true}
                buttonText="Explore All"
                buttonLink={RoutePaths.Category(21)}
                buttonTheme={true}
                buttonColor={`9,133,119`}
                buttonColorHover={`9,104,93`}
                focusImage={DNA}
                showFocusImageOnMobile={true}
                bgColor="#010107"
                contentItems={curatedHealthLoading ? undefined : curatedHealth}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Consciousness"
                body={<p>Deep dives into the mysteries of the mind and spirit</p>}
                contentLeftSide={false}
                buttonText="Explore All"
                buttonLink={RoutePaths.Category(5)}
                buttonTheme={false}
                buttonColor={`232,187,39`}
                buttonColorHover={`229,174,44`}
                focusImage={Node}
                showFocusImageOnMobile={true}
                bgColor="#010107"
                contentItems={curatedConsciousnessLoading ? undefined : curatedConsciousness}
            />

            <PresenterShowcase isDarkMode={globalInterfaceContext.isDarkMode} />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="David Icke"
                body={<p>Exclusive content from the world's most fearless truth-seeker</p>}
                contentLeftSide={true}
                buttonText="Explore All"
                buttonLink={RoutePaths.DavidIcke}
                buttonTheme={true}
                buttonColor={`70,118,230`}
                buttonColorHover={`9,94,182`}
                focusImage={David}
                showFocusImageOnMobile={false}
                bgImage={SpaceClouds}
                bgColor="#010107"
                contentItems={curatedDavidLoading ? undefined : curatedDavid}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Global Current Events"
                body={<p>Unfiltered analysis of today's most pressing issues</p>}
                contentLeftSide={false}
                buttonText="Explore All"
                buttonLink={RoutePaths.Browse}
                buttonTheme={true}
                buttonColor={`70,118,230`}
                buttonColorHover={`9,94,182`}
                focusImage={Globe}
                showFocusImageOnMobile={true}
                bgColor="#010107"
                contentItems={curatedGlobalLoading ? undefined : curatedGlobal}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Documentaries"
                body={<p>Exclusive content from the world's most fearless truth-seeker</p>}
                contentLeftSide={true}
                buttonText="Explore All"
                buttonLink={RoutePaths.Category(3)}
                buttonTheme={true}
                buttonColor={`130,64,135`}
                buttonColorHover={`105,51,133`}
                focusImage={Pyramids}
                showFocusImageOnMobile={true}
                bgImage={Smoke}
                bgColor="#010107"
                contentItems={curatedDocsLoading ? undefined : curatedDocs}
            />

            <CategoryFeaturedItems
                isDarkMode={globalInterfaceContext.isDarkMode}
                heading="Occult & Hidden Knowledge"
                body={<p>Unfiltered analysis of today's most pressing issues</p>}
                contentLeftSide={false}
                buttonText="Explore All"
                buttonLink={RoutePaths.Category(29)}
                buttonTheme={true}
                buttonColor={`144,27,27`}
                buttonColorHover={`116,23,23`}
                focusImage={Occult}
                showFocusImageOnMobile={false}
                bgImage={SmokeRed}
                bgColor="#010107"
                contentItems={curatedOccultLoading ? undefined : curatedOccult}
            />

            <AppGrid />

            <TwoColumnWithLatestArticles
                articles={articlesLoading ? undefined : articles}
                heading={<h1>Dive into <span>Latest Insights</span></h1>}
                body={<p>The latest articles give you the tools to see the world through a different lens</p>}
            />

            <div
                className="pre-sign-up__finale u-relative_hidden"
                style={{
                    background: `url(${Sunset}) bottom center/cover no-repeat`,
                }}
            >
                <div className="u-full_cover_absolute pre-sign-up__gradient-top" style={{
                    background: 'linear-gradient(180deg, #010107 0%, rgba(32,32,32,0) 100%)',
                    height: 400
                }}/>

                <section className="pre-sign-up__faqs u-relative_hidden">
                    <h1><span>FAQs</span></h1>
                    <Accordion
                        heading="What Is Ickonic?"
                        body={<p>Ickonic is the home of news and current affairs with a difference, ground-breaking
                            original series and independent films on topics the mainstream won’t discuss. We are an
                            alternative media platform that brings you uncensored, unbiased and unique information – all
                            in one place.</p>}
                    />

                    <Accordion
                        heading="How much does a subscription cost?"
                        body={<p>Monthly subscriptions are just £1.99 for your first month and then renew at £7.99 per month. Annual subscriptions are charged at a time £60 for your first year and renew at £79 for your second year.</p>}
                    />

                    <Accordion
                        heading="What types of content are available on Ickonic?"
                        body={<p>Ickonic produces content that challenge the mainstream of everything. We have daily shows focussing on global current events, podcasts on health, wellness, spirituality and long-form series and documentaries exposing hidden knowledge and the nature of reality.</p>}
                    />

                    <Accordion
                        heading="How often is new content added?"
                        body={<p>New content is added daily.</p>}
                    />

                    <Accordion
                        heading="Can I watch Ickonic content on multiple devices?"
                        body={<p>On an annual plan you can access Ickonic on 2 devices. On a monthly plan only one device can be used at once.</p>}
                    />
                </section>

                <TripleColumnIcons/>

                <HeroWithPricingPlans
                    mainMenuDesktopMode={globalInterfaceContext.mainMenuDesktopMode}
                    heading={<h1><p>Choose A Plan That Works For You</p></h1>}
                    planOneTitleBG="rgba(255,255,255,0.2)"
                    planTwoTitleBG="white"
                    planInnerBG="rgba(255,255,255,0.2)"
                    requireTransition={false}
                />

                <div className="u-full_cover_absolute pre-sign-up__gradient-bottom" style={{
                    background: 'linear-gradient(0deg, #0f0f0f 0%, rgba(32,32,32,0) 100%)',
                    height: 400
                }}/>
            </div>
        </div>
    );
}

export default PreSignUp;
