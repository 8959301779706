// CustomInput.js
import React from 'react';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        className="custom-date-input"
        onClick={onClick}
        ref={ref}
        value={value}
        readOnly
    />
));

export default CustomInput;
