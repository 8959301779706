import React, { useState, useEffect } from 'react';
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Assets
import IconCheckMark from "../../Assets/SVGs/Icons/YesOrNo/CheckMark";

// Models
import IPlanDTO from "../../Models/DTOs/IPlanDTO";

// Api
import { GetPlansAPI } from "../../Api/Subscription";

interface PlansSelectorProps {
    onSelectPlan: (plan: IPlanDTO) => void;
    parentSelectedPlan?: IPlanDTO | null;
    hasError: boolean;
    isDarkMode: boolean;
    errorMessage: string;
    featuresStart: number;
    featuresEnd: number;
}

const PlansSelector: React.FC<PlansSelectorProps> = ({
        onSelectPlan,
        parentSelectedPlan,
        hasError,
        isDarkMode,
        errorMessage,
        featuresStart,
        featuresEnd
}) => {
    const
        [plans, setPlans] = useState<IPlanDTO[]>([]),
        [selectedPlan, setSelectedPlan] = useState<IPlanDTO | null>(null),

        { t } = useTranslation(),

        handleSelectPlan = (plan: IPlanDTO) => {
            setSelectedPlan(plan);
            onSelectPlan(plan);
        },

        abortController = new AbortController(),
        fetchPlans = async () => {
            try {
                const getPlansApiResponse = await GetPlansAPI(abortController);

                if (!(getPlansApiResponse instanceof AxiosError)) {
                    setPlans(getPlansApiResponse);
                }
            } catch (err) {
                throw new Error("fetchPlans failed");
            } finally {}
        };

    // Fetch plans on mount
    useEffect(() => {
        fetchPlans();
    }, []);

    // If a parent selected plan is provided, sync it with local state
    useEffect(() => {
        if (parentSelectedPlan) {
            setSelectedPlan(parentSelectedPlan);
        }
    }, [parentSelectedPlan]);

    // Once plans are fetched, check if a plan is specified in the query string
    useEffect(() => {
        if (plans.length > 0) {
            const searchParams = new URLSearchParams(window.location.search);
            const planParam = searchParams.get('plan');

            if (planParam) {
                // Convert hyphen-separated string into a space-separated string
                // e.g. "Truth-Seeker" -> "Truth Seeker"
                const planTitleFromUrl = planParam.replace(/-/g, ' ');

                const matchedPlan = plans.find(
                    p => p.Title.toLowerCase() === planTitleFromUrl.toLowerCase()
                );

                if (matchedPlan) {
                    setSelectedPlan(matchedPlan);
                    onSelectPlan(matchedPlan);

                    // Now remove `plan` from query string
                    searchParams.delete('plan');
                    window.history.replaceState(
                        {},
                        '',
                        `${window.location.pathname}?${searchParams.toString()}`
                    );
                }
            }
        }
    }, [plans, onSelectPlan]);

    return (
        <div
            className={`layout--plan-selector ${
                plans.length === 0 ? 'is-loading' : 'is-not-loading'
            } ${isDarkMode ? 'is-dark-mode' : 'is-light-mode'}`}
        >
            {plans && plans.length > 0 ? (
                plans.map((plan, i) => (
                    <div
                        key={i}
                        className={`form__plan-single ${
                            selectedPlan !== null ? 'plan-selected' : 'no-plan-selected'
                        } ${
                            selectedPlan?.PlanId === plan.PlanId ? 'is-selected' : 'is-not-selected'
                        }`}
                        onClick={() => handleSelectPlan(plan)}
                    >
                        {/** isSelected YesOrNo **/}
                        {selectedPlan?.PlanId === plan.PlanId && <IconCheckMark/>}

                        {/** Plan name / label **/}
                        <p className="plan-single__label u-relative_hidden">
                            {plan.Title}
                        </p>

                        {/** Billing Period **/}
                        <h2>{i === 0 ? t('generic-monthly-label') : t('generic-annual-label')}</h2>

                        {/** Price Information **/}
                        <h3>
                            <>
                                <span>£{plan.Phases[0].Price}</span>{' '}
                                {i === 0
                                    ? t('generic-monthly-label-first')
                                    : t('generic-annual-label-first')}
                                <span>
                                    Then £{plan.Price}{' '}
                                    {i === 0
                                        ? t('generic-monthly-label-billed')
                                        : t('generic-annual-label-billed')}
                                </span>
                            </>
                        </h3>

                        {/** Plan features **/}
                        {plan.Text && plan.Text.length > 0 && (
                            <ul>
                                {plan.Text.slice(featuresStart, featuresEnd).map((feature, j) => (
                                    <li className="plan-single__feature" key={i + j}>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        )}

                        {i !== 0 && (
                            <div className="plan-single__flag u-full_cover_absolute">
                                <span className="u-full_cover_absolute">Best Value</span>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <>
                    {/** Loading UI **/}
                    <div className="form__plan-single u-relative_hidden">
                        <div
                            className={`u-full_cover_absolute loader--side-to-side ${
                                isDarkMode ? 'is-dark-mode' : 'is-light-mode'
                            }`}
                        />
                    </div>

                    <div className="form__plan-single u-relative_hidden">
                        <div
                            className={`u-full_cover_absolute loader--side-to-side ${
                                isDarkMode ? 'is-dark-mode' : 'is-light-mode'
                            }`}
                        />
                    </div>
                </>
            )}

            {hasError && <label className="error-message">{errorMessage}</label>}
        </div>
    );
};

export default PlansSelector;
