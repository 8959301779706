import axios from "axios";
import config from "../Constants/Config";
import { VideoDetail, CancellationReason } from "../Screens/Reporting/types";  // Adjust the path as needed

if (!process.env.REACT_APP_API_URL) {
    throw new Error("REACT_APP_API_URL is not defined in the environment variables");
}

const BACKEND_URL = `${process.env.REACT_APP_API_URL}${config.videoMetrics}`;

async function getCountryInfo(): Promise<string> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.country_code || "Unknown";
    } catch (error) {
        console.error("Error fetching country information:", error);
        return "Unknown";
    }
}

export async function StartVideoSession(
    data: {
        videoId: number;
        userId: string | undefined;
        session: string;
        deviceType: string;
        browser: string;
        operatingSystem: string;
    },
    access_token: string,
    abortController: AbortController
): Promise<void> {
    const countryCode = await getCountryInfo();
    const enhancedData = { ...data, country: countryCode };

    axios
        .post(`${BACKEND_URL}StartVideoSession`, enhancedData, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then(() => {
            console.log("Video session successfully started.");
        })
        .catch((error: any) => {
            console.error("StartVideoSession Error: ", error);
            if (axios.isAxiosError(error) && error.response) {
                console.error("Server response: ", error.response.data);
            }
        });
}

export async function UpdateVideoStat(
    data: {
        currentTime: number;
        playbackDuration: number;
        videoQuality: string;
        isMuted: boolean;
        playbackRate: number;
        isSeeking: boolean;
        isPaused: boolean;
        isEnded?: boolean;
        timestamp: string;
        session: string;
        videoId: number;
        bufferingDuration?: number;
        bitrate?: number;
        bandwidth?: number;
    },
    access_token: string,
    abortController: AbortController
): Promise<boolean> {
    try {
        // Basic data validation
        if (!data.currentTime || !data.session || !data.videoId) {
            console.warn("Invalid data provided to UpdateVideoStat:", data);
            return false;
        }

        // Make the API call
        await axios.post(
            `${BACKEND_URL}UpdateVideoStat`,
            data,
            {
                signal: abortController.signal,
                headers: {
                    Authorization: "Bearer " + access_token,
                },
                timeout: 10000, // 10 seconds timeout
            }
        );

        return true;
    } catch (error: any) {
        console.error("UpdateVideoStat Error with data:", data);
        console.error("Error details:", error);

        if (axios.isAxiosError(error) && error.response) {
            console.error("Server response:", error.response.status, error.response.data);
        }

        return false;
    }
}

export async function fetchVideoMetricsData(
    access_token: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(`${BACKEND_URL}fetchVideoMetricsDataByCountryCode`, {
            signal: abortController.signal,
            headers: { Authorization: "Bearer " + access_token },
        });

        console.log("Raw API Response:", response.data);

        const groupedData: { [key: string]: any } = {};
        const countryTotals: { [key: string]: number } = {}; // Track total views per country

        response.data.forEach((item) => {
            const date = item.Date.split("T")[0]; // Remove time portion
            if (!groupedData[date]) {
                groupedData[date] = { Date: date };
            }

            if (item.CountryCode !== "Unknown" && item.CountryCode !== "Others") {
                groupedData[date][item.CountryCode] = (groupedData[date][item.CountryCode] || 0) + item.Count;
                countryTotals[item.CountryCode] = (countryTotals[item.CountryCode] || 0) + item.Count;
            }
        });

        // ✅ Find the top 8 most-viewed countries (excluding "Unknown" & "Others")
        const topCountries = Object.entries(countryTotals)
            .sort((a, b) => b[1] - a[1]) // Sort descending by total views
            .slice(0, 8) // Keep only top 8
            .map(([country]) => country);

        console.log("Top 8 Countries:", topCountries);

        // ✅ Filter data to keep only the top 8 countries
        const finalData = Object.values(groupedData).map((dayEntry) => {
            Object.keys(dayEntry).forEach((country) => {
                if (country !== "Date" && !topCountries.includes(country)) {
                    delete dayEntry[country]; // Remove non-top-8 countries
                }
            });
            return dayEntry;
        });

        console.log("Final Data for Chart:", finalData);

        return finalData;
    } catch (error: any) {
        console.error("fetchVideoMetricsData Error:", error);
        throw error;
    }
}

export async function fetchDailyVideoWatchDetail(
    date: string,
    access_token: string,
    abortController: AbortController
): Promise<VideoDetail[]> {
    try {
        const response = await axios.get<VideoDetail[]>(`${BACKEND_URL}fetchVideoMetricsDailyData/details?date=${date}`, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        });
        return response.data;
    } catch (error: any) {
        console.error("fetchDailyVideoWatchDetail Error: ", error);
        if (axios.isAxiosError(error) && error.response) {
            console.error("Server response: ", error.response.data);
        }
        throw error;
    }
}

export const fetchViewOnCategoryData = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/fetchViewOnCategory`);
        return response.data;
    } catch (error) {
        console.error("Error fetching category views data:", error);
        throw error;
    }
};

export async function fetchVideoMetricsByDateRange(
    startDate: string,
    endDate: string,
    accessToken: string
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(`${BACKEND_URL}TotalViewsByDateRange`, {
            params: { startDate, endDate },
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        console.log("📊 Raw API Response:", response.data);

        const groupedData: { [key: string]: any } = {};
        const countryTotals: { [key: string]: number } = {}; // Track total views per country

        response.data.forEach((item) => {
            const date = item.Date.split("T")[0]; // ✅ Remove time portion
            if (!groupedData[date]) {
                groupedData[date] = { Date: date };
            }

            if (item.CountryCode !== "Unknown" && item.CountryCode !== "Others") {
                groupedData[date][item.CountryCode] = (groupedData[date][item.CountryCode] || 0) + item.Count;
                countryTotals[item.CountryCode] = (countryTotals[item.CountryCode] || 0) + item.Count;
            }
        });

        // ✅ Find the top 8 most-viewed countries (excluding "Unknown" & "Others")
        const topCountries = Object.entries(countryTotals)
            .sort((a, b) => b[1] - a[1]) // Sort descending by total views
            .slice(0, 8) // Keep only top 8
            .map(([country]) => country);

        console.log("✅ Top 8 Countries:", topCountries);

        // ✅ Filter data to keep only the top 8 countries
        const finalData = Object.values(groupedData).map((dayEntry) => {
            Object.keys(dayEntry).forEach((country) => {
                if (country !== "Date" && !topCountries.includes(country)) {
                    delete dayEntry[country]; // ✅ Remove non-top-8 countries
                }
            });
            return dayEntry;
        });

        console.log("✅ Final Processed Data for Chart:", finalData);

        return finalData;
    } catch (error: any) {
        console.error("❌ fetchVideoMetricsByDateRange Error:", error);
        throw error;
    }
}

export async function fetchThirtyDayCancellations(days: number, accessToken: string): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(`${BACKEND_URL}ThirtyDayCancellations`, {
            params: { days }, // ✅ Correctly passes 'days' in the query string
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        console.log("📊 API Response (30-Day Cancellations):", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchThirtyDayCancellations Error:", error);
        return [];
    }
}

export async function fetchCancellationReasonAndContent(
    date: string,
    access_token: string,
    abortController: AbortController
): Promise<CancellationReason[]> {
    try {
        const response = await axios.get<CancellationReason[]>(`${BACKEND_URL}fetchCancellationReasons/details?date=${date}`, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        });
        return response.data;
    } catch (error: any) {
        console.error("fetchCancellationReasonAndContent Error: ", error);
        if (axios.isAxiosError(error) && error.response) {
            console.error("Server response: ", error.response.data);
        }
        throw error;
    }
}

/**
 * Fetches daily video completion details for a given date range.
 *
 * @param startDate A string representing the start of the date range (e.g., "2025-01-01")
 * @param endDate A string representing the end of the date range (e.g., "2025-01-31")
 * @param accessToken The user's access token for authorization
 * @returns A Promise resolving to an array of daily completion details
 */
export async function fetchVideoCompletionPercentagesByDateRange(
    startDate: string,
    endDate: string,
    accessToken: string
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}VideoCompletionPercentagesByDateRange`, // Adjust to your actual endpoint
            {
                params: { startDate, endDate },  // Pass both dates as query params
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        console.log("📊 Video Completion by Date Range Response:", response.data);

        // Example transformation (if necessary). Here, we return the data as-is.
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchDailyVideoCompletionDetail Error:", error);
        throw error;
    }
}

export async function fetchDailyVideoCompletionDetail(
    period: string,
    accessToken: string,
    abortController?: AbortController
): Promise<any[]> {
    console.log("Stub: fetchDailyVideoCompletionDetail called with:", { period, accessToken });

    // If you need to cancel requests, you'd check `abortController?.signal.aborted`
    // For the stub, we ignore it.

    return new Promise((resolve) => {
        // Simulate network delay
        setTimeout(() => {
            // Example mock data
            const mockData = [
                { VideoTitle: "Example Video A", Qty: 123 },
                { VideoTitle: "Another Video B", Qty: 456 },
            ];

            console.log("Stub: returning mock data:", mockData);
            resolve(mockData);
        }, 500); // Wait 0.5s then resolve
    });
}

export async function fetchVideoViewsByCountry(
    startDate: string,
    endDate: string,
    accessToken: string
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}VideoViewsByCountry`, // Calls the C# API endpoint
            {
                params: { startDate, endDate }, // Pass both dates as query params
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        console.log("📊 Video Views By Country Response:", response.data);

        // Return the data as-is (or transform if needed)
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchVideoViewsByCountry Error:", error);
        throw error;
    }
}

export async function fetchZeroViews(
    startDate: string,
    endDate: string,
    accessToken: string
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}ZeroViews`, // Calls the C# API endpoint for ZeroViews
            {
                params: { startDate, endDate }, // Pass both dates as query params
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        console.log("📊 ZeroViews Response:", response.data);

        // Return the data as-is (or transform if needed)
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchZeroViews Error:", error);
        throw error;
    }
}

export async function fetchWeeklyTopTenVideoContentViews(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}fetchVideoTopTenWeeklyMetrics`, // Calls the C# API endpoint for top ten video content
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal, // Pass the abort controller signal for cancellation
            }
        );

        console.log("📊 Top Ten Weekly Video Content Response:", response.data);

        // Return the data as-is (or transform if needed)
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchWeeklyTopTenVideoContentViews Error:", error);
        throw error;
    }
}

export async function fetchVideoEngagementReport(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}fetchVideoEngagementReport`, // Updated to match pattern
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal, // Supports request cancellation
            }
        );

        console.log("📊 Video Engagement Report Response:", response.data);

        return response.data;
    } catch (error: any) {
        console.error("❌ fetchVideoEngagementReport Error:", error);
        throw error;
    }
}

export async function fetchAverageViewTimeReport(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}AverageViewTime`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal, // Supports request cancellation
            }
        );

        console.log("📊 Average View Time Report Response:", response.data);

        return response.data;
    } catch (error: any) {
        console.error("❌ fetchAverageViewTimeReport Error:", error);
        throw error;
    }
}

interface LifetimeMember {
    SubscriptionStartDate: string;
    ID: number;
    AspNetUserID: string;
    BillingAmount: number;
    StripeCustomerID: string;
    StripePaymentID: string;
    HasStripeSubscriptionRecord: boolean;
    HasAspNetUsersRecord: boolean;
    HasAspNetUsersEmail: string;
}

export async function fetchLifetimeMembers(
    accessToken: string,
    abortController: AbortController
): Promise<LifetimeMember[]> {
    try {
        const response = await axios.get<LifetimeMember[]>(
            `${BACKEND_URL}LifetimeMembers`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Lifetime Members Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchLifetimeMembers Error:", error);
        throw error;
    }
}

interface FailedPayment {
    CustomerID: string;
    ChargeId: string;
    Status: string;
    Amount: number;
    Currency: string;
    PaymentDate: string;
    SubscriptionId: string;
    Email: string;
    PeriodStart: string;
    PeriodEnd: string;
}

export async function fetchFailedPayments(
    accessToken: string,
    abortController: AbortController
): Promise<FailedPayment[]> {
    try {
        const response = await axios.get<FailedPayment[]>(
            `${BACKEND_URL}/FailedPayments`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Failed Payments Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ getFailedPayments Error:", error);
        throw error;
    }
}

interface UnpaidSub {
    CustomerId: string;
    SubscriptionId: string;
    Email: string;
    StartDate: string;
    CurrentPeriodStart: string;
    CurrentPeriodEnd: string;
    BillingCycle: string;
    Amount: number;
    Country: string;
    Status: string;
}

export async function getUnpaidSubs(
    accessToken: string,
    abortController: AbortController
): Promise<UnpaidSub[]> {
    try {
        const response = await axios.get<UnpaidSub[]>(
            `${BACKEND_URL}/UnpaidSubs`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Unpaid Subscriptions Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ getUnpaidSubs Error:", error);
        throw error;
    }
}

interface SubsNoUser {
    Email: string;
    SubscriptionDate: string;
    SubscriptionId: string;
    CustomerId: string;
    SubscriptionType: string;
}

export async function fetchSubscriptionsWithoutUsers(
    accessToken: string,
    abortController: AbortController
): Promise<SubsNoUser[]> {
    try {
        const response = await axios.get<SubsNoUser[]>(
            `${BACKEND_URL}/SubsNoUser`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Subscriptions Without Users Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchSubscriptionsWithoutUsers Error:", error);
        throw error;
    }
}

interface SubsNoSubsInDTO {
    CustomerId: string;
    SubscriptionId: string;
    Email: string | null;
    StartDate: string;
    RenewalDate: string;
    ExpiryDate: string;
}

export async function fetchSubscriptionsWithoutSubscriptionIn(
    accessToken: string,
    abortController: AbortController
): Promise<SubsNoSubsInDTO[]> {
    try {
        const response = await axios.get<SubsNoSubsInDTO[]>(
            `${BACKEND_URL}/SubsNoSubsIn`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Subscriptions Without SubscriptionIn Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchSubscriptionsWithoutSubscriptionIn Error:", error);
        throw error;
    }
}

export interface DeviceStatsData {
    deviceType: string;
    operatingSystem: string;
    TotalViews: number;
    PercentageOfTotal: number;
}

export async function fetchDeviceStatsData(
    accessToken: string,
    abortController: AbortController
): Promise<DeviceStatsData[]> {
    try {
        const response = await axios.get<DeviceStatsData[]>(
            `${BACKEND_URL}/VideoViewDeviceStats`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );

        console.log("📊 Device Stats Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchDeviceStatsData Error:", error);
        throw error;
    }
}

export interface VideoCompletionDetail {
    VideoTitle: string;
    Qty: number;
    // Add any additional fields from the stored procedure response if needed
}

interface FetchVideosByCompletionBucketParams {
    date: string;
    minPercent: number;
    maxPercent: number;
    accessToken: string;
    abortController?: AbortController;
}

export async function fetchVideosByCompletionBucket({
                                                        date,
                                                        minPercent,
                                                        maxPercent,
                                                        accessToken,
                                                        abortController,
                                                    }: FetchVideosByCompletionBucketParams): Promise<VideoCompletionDetail[]> {
    try {
        const response = await axios.get<VideoCompletionDetail[]>(
            `${BACKEND_URL}/GetVideosByCompletionBucket`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                params: {
                    StartDate: date,
                    EndDate: date,
                    MinPercent: minPercent,
                    MaxPercent: maxPercent,
                },
                signal: abortController?.signal,
            }
        );

        console.log("📊 Videos by Completion Bucket Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchVideosByCompletionBucket Error:", error);
        throw error;
    }
}

export async function fetchOnboarded(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}/Onboarded`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );
        console.log("📊 Onboarded Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchOnboarded Error:", error);
        throw error;
    }
}

// Fetch Category Distribution Data
export async function fetchCategoryDistribution(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}/OnBoardDetailsByCategory`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );
        console.log("📊 Category Distribution Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchCategoryDistribution Error:", error);
        throw error;
    }
}

// Fetch Content Distribution Data
export async function fetchContentDistribution(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}/OnBoardDetailsByContentType`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );
        console.log("📊 Content Distribution Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchContentDistribution Error:", error);
        throw error;
    }
}

// Fetch HDYH Distribution Data
export async function fetchHDYHDistribution(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}/OnBoardDetailsByHowDidYouHear`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );
        console.log("📊 HDYH Distribution Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchHDYHDistribution Error:", error);
        throw error;
    }
}

export async function fetchSiteTrackingToDateForTracking(
    accessToken: string,
    abortController: AbortController
): Promise<any[]> {
    try {
        const response = await axios.get<any[]>(
            `${BACKEND_URL}/SiteTrackingToDateForTracking`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
                signal: abortController.signal,
            }
        );
        console.log("📊 SiteTrackingToDateForTracking Response:", response.data);
        return response.data;
    } catch (error: any) {
        console.error("❌ fetchSiteTrackingToDateForTracking Error:", error);
        throw error;
    }
}

export async function getUserDeletions(
  accessToken: string,
  abortController: AbortController
): Promise<any[]> {
  try {
    const response = await axios.get<any[]>(
      `${BACKEND_URL}/GetListOfUserDeletions`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: abortController.signal,
      }
    );
    console.log("📊 GetListOfUserDeletions Response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("❌ getUserDeletions Error:", error);
    throw error;
  }
}