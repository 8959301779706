import React, { useEffect, useState, useCallback, useContext } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    LabelList,
    ResponsiveContainer,
} from "recharts";
import { parse, format, parseISO } from "date-fns";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { fetchVideosByCompletionBucket } from "../../Api/VideoMetrics";

interface CompletionReportData {
    ViewDate: string; // e.g., "2025-02-18" or "10/02/2025 13:45:00"
    Qty: number; // numeric
    PercentageViewed: string; // e.g., "21 to 30"
}

/** Map the 10 possible ranges => distinct colors */
const colorMap: Record<string, string> = {
    range_1__10: "#8884d8",
    range_11__20: "#82ca9d",
    range_21__30: "#ffc658",
    range_31__40: "#d0ed57",
    range_41__50: "#ff8042",
    range_51__60: "#8dd1e1",
    range_61__70: "#a4de6c",
    range_71__80: "#d88884",
    range_81__90: "#9c82ca",
    range_91__100: "#82cafa",
};

interface VideoCompletionPercentageByDateRangeProps {
    reportData: CompletionReportData[];
}

/**
 * Convert e.g. "range_21__30" => "21 to 30%"
 */
function decodeRange(rangeKey: string): string {
    const noPrefix = rangeKey.replace(/^range_/, "");
    const replaced = noPrefix.replace(/__/g, " to ");
    return replaced + "%";
}

// Fallback color if colorMap doesn't have the key
function fallbackColor(index: number) {
    const fallbackColors = [
        "#8884d8",
        "#82ca9d",
        "#ffc658",
        "#d0ed57",
        "#a4de6c",
    ];
    return fallbackColors[index % fallbackColors.length];
}

const VideoCompletionPercentageByDateRange: React.FC<
    VideoCompletionPercentageByDateRangeProps
> = ({ reportData }) => {
    const [pivotedData, setPivotedData] = useState<any[]>([]);
    const [rangeKeys, setRangeKeys] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // For drill-down details
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedRange, setSelectedRange] = useState<string | null>(null);
    const [videoDetails, setVideoDetails] = useState<any[]>([]);
    const [loadingDetails, setLoadingDetails] = useState(false);

    const authCtx = useContext(UserAuthenticationContext);
    const access_token = authCtx.userData.Access_Token || "";

    // 1) Pivot data => each "PercentageViewed" becomes a separate bar field
    useEffect(() => {
        if (!reportData || reportData.length === 0) {
            setError("No data available for the selected date range.");
            setLoading(false);
            return;
        }

        const byDate: Record<string, any> = {};
        const allRanges = new Set<string>();

        for (const item of reportData) {
            // Extract the date part (assumes first 10 characters are in "dd/MM/yyyy")
            const datePart = item.ViewDate.slice(0, 10);

            if (!byDate[datePart]) {
                byDate[datePart] = { ViewDate: datePart };
            }
            // e.g. "21 to 30" => "range_21__30"
            const pivotKey = `range_${item.PercentageViewed.replace(
                " to ",
                "__"
            )}`;
            byDate[datePart][pivotKey] = item.Qty;
            allRanges.add(pivotKey);
        }

        const pivotArray = Object.values(byDate);
        pivotArray.sort((a: any, b: any) => {
            const dateA = parse(a.ViewDate, "dd/MM/yyyy", new Date());
            const dateB = parse(b.ViewDate, "dd/MM/yyyy", new Date());
            return dateA.getTime() - dateB.getTime();
        });

        setPivotedData(pivotArray as any[]);
        setRangeKeys(Array.from(allRanges));
        setLoading(false);
    }, [reportData]);

    // 2) Handle click on a specific bar (i.e. a date and a bucket)
    const handleBucketClick = useCallback(
        async (data: any, rangeKey: string) => {
            if (!data || !data.ViewDate) return;
            setSelectedDate(data.ViewDate);
            setSelectedRange(rangeKey);
            setLoadingDetails(true);

            // Format the date in ISO format ("yyyy-MM-dd") by parsing the original "dd/MM/yyyy"
            const parsedDate = parse(data.ViewDate, "dd/MM/yyyy", new Date());
            const formattedDate = format(parsedDate, "yyyy-MM-dd");

            // Expected format: "range_1__10"
            const rangeParts = rangeKey.replace("range_", "").split("__");
            const minPercent = parseInt(rangeParts[0], 10);
            const maxPercent = parseInt(rangeParts[1], 10);
            console.log(
                "Parsed percent:",
                minPercent,
                maxPercent,
                "Formatted Date:",
                formattedDate
            );

            try {
                // Pass the formattedDate (ISO format) to the API
                const details = await fetchVideosByCompletionBucket({
                    date: formattedDate, // e.g., "2025-02-13"
                    minPercent,
                    maxPercent,
                    accessToken: access_token,
                });
                setVideoDetails(details);
            } catch (err) {
                console.error("❌ Failed to fetch video details:", err);
            } finally {
                setLoadingDetails(false);
            }
        },
        [access_token]
    );

    if (loading) return <p>Loading completion report...</p>;
    if (error)
        return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;

    return (
        <div
            style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
            }}
        >
            {/* 1) Title */}
            <h2 style={{ textAlign: "center", color: "#333" }}>
                Video completion %
            </h2>

            {/* 2) Custom range legend near the title */}
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
                {rangeKeys.map((rangeKey, index) => {
                    const fillColor =
                        colorMap[rangeKey] || fallbackColor(index);
                    return (
                        <span key={rangeKey} style={{ marginRight: "12px" }}>
                            <span
                                style={{
                                    display: "inline-block",
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: fillColor,
                                    marginRight: "4px",
                                    borderRadius: "2px",
                                }}
                            />
                            <span style={{ color: "#333" }}>
                                {decodeRange(rangeKey)}
                            </span>
                        </span>
                    );
                })}
            </div>

            {/* 3) Chart Container */}
            <ResponsiveContainer width="100%" height={450}>
                <BarChart
                    data={pivotedData}
                    margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />

                    <XAxis
                        dataKey="ViewDate"
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        tickFormatter={(val: string) => {
                            try {
                                const datePart = val.slice(0, 10);
                                const parsed = parse(
                                    datePart,
                                    "dd/MM/yyyy",
                                    new Date()
                                );
                                return format(parsed, "dd-MM-yyyy");
                            } catch {
                                return val;
                            }
                        }}
                    />

                    <YAxis
                        tick={{ fontSize: 12, fontWeight: "bold" }}
                        allowDecimals={false}
                        domain={[0, "dataMax + 5"]}
                    />

                    {/* Create one Bar per range bucket */}
                    {rangeKeys.map((rangeKey, index) => (
                        <Bar
                            key={rangeKey}
                            dataKey={rangeKey}
                            fill={colorMap[rangeKey] || fallbackColor(index)}
                            cursor="pointer"
                            onClick={(data: any) =>
                                handleBucketClick(data, rangeKey)
                            }
                        >
                            <LabelList
                                position="top"
                                fill="#333"
                                fontSize={12}
                                fontWeight="bold"
                            />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>

            {/* 4) Detail table if user clicks a bar segment */}
            {selectedDate && (
                <div
                    style={{
                        marginTop: "20px",
                        padding: "20px",
                        backgroundColor: "#fff", // solid background
                        borderRadius: "10px",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        overflowX: "auto", // allow horizontal scroll if needed
                        position: "relative", // new stacking context
                        zIndex: 9999, // high z-index so it appears above the footer
                    }}
                >
                    <h3 style={{ textAlign: "center", color: "#333" }}>
                        Detailed View for{" "}
                        {new Date(selectedDate).toLocaleDateString("en-GB")} -{" "}
                        {selectedRange && decodeRange(selectedRange)}
                    </h3>

                    {loadingDetails ? (
                        <p style={{ textAlign: "center", fontStyle: "italic" }}>
                            Loading details...
                        </p>
                    ) : (
                        <table
                            style={{
                                width: "100%",
                                marginTop: "10px",
                                borderCollapse: "collapse",
                                border: "1px solid #ddd",
                                backgroundColor: "#fff",
                                minWidth: "600px",
                            }}
                        >
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "#8884d8",
                                        color: "#fff",
                                        textAlign: "left",
                                    }}
                                >
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Video Title
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            borderBottom: "2px solid #ddd",
                                        }}
                                    >
                                        Qty
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoDetails.map((detail, idx) => (
                                    <tr
                                        key={idx}
                                        style={{
                                            backgroundColor:
                                                idx % 2 === 0
                                                    ? "#f8f9fa"
                                                    : "#fff",
                                            color: "#333",
                                            transition: "background 0.3s",
                                        }}
                                    >
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.VideoTitle}
                                        </td>
                                        <td
                                            style={{
                                                padding: "12px",
                                                borderBottom: "1px solid #ddd",
                                                textAlign: "left",
                                            }}
                                        >
                                            {detail.Qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default VideoCompletionPercentageByDateRange;