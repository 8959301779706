import { useState, useEffect } from "react";
import { GetNotificationBar} from "../../Api/NotificationBar";
import { INotificationBarDTO, isNotificationBar } from "../../Models/DTOs/INotificationBarDTO";

function useFetchNotificationBar() {
    const [notificationBarData, setNotificationBarData] = useState<INotificationBarDTO | null>(null);
    const [notificationBarLoading, setNotificationBarLoading] = useState<boolean>(false);
    const [notificationBarError, setNotificationBarError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        setNotificationBarLoading(true);

        (async () => {
            try {
                const data = await GetNotificationBar();
                if (!isCancelled) {
                    // Validate the data if you have a type guard
                    if (isNotificationBar(data)) {
                        setNotificationBarData(data);
                    } else {
                        setNotificationBarError("Invalid notification bar data received.");
                    }
                }
            } catch (error) {
                if (!isCancelled) {
                    setNotificationBarError("Failed to fetch notification bar data.");
                    console.error("useFetchNotificationBar error:", error);
                }
            } finally {
                if (!isCancelled) {
                    setNotificationBarLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, []);

    return { notificationBarData, notificationBarLoading, notificationBarError };
}

export default useFetchNotificationBar;
