import React, {useEffect, useRef, useState} from "react";
import { NavLink } from "react-router-dom";

// Models
import ICarouselContentDTO from "../../Models/DTOs/ICarouselContentDTO";

type CarouselItemPresenterProps = {
    item: ICarouselContentDTO,
    isLoading: boolean,
};

const PortraitImageItem: React.FC<CarouselItemPresenterProps> = ({ item, isLoading }) => {
    const
        [ containerWidth, setContainerWidth ] = useState(0),
        containerRef = useRef<HTMLDivElement>(null),
        adjustAspectRatio = () => {
            if (containerRef.current !== null) {
                const width = containerRef.current.offsetWidth;
                setContainerWidth(width);
            }
        };

    useEffect(() => {
        adjustAspectRatio();
        window.addEventListener('resize', adjustAspectRatio);

        // Clean up on unmount
        return () => {
            window.removeEventListener('resize', adjustAspectRatio);
        }
    }, []);

    return (
        <div className="content--portrait-image-item" ref={ containerRef }>
            <div
                className="u-relative_hidden"
            >
                { isLoading ?
                    (
                        <div className="loader--side-to-side" />
                    )
                    :
                    (
                        <>
                            <img
                                alt={item.Title}
                                src={item.PortraitThumbnail}
                                style={{minHeight: `${ containerWidth * 9 / 16 }px`}}
                            />

                            {/* Show the label (Title) only if it's non-empty */}
                            {item.OptionalLabel && (
                                <label><span>{item.OptionalLabel}</span></label>
                            )}
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default PortraitImageItem;
