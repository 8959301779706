import React from "react";

const IconListBullet = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                style={{fill: props?.iconFill}}
                d="M3.3 15.4a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zM6 17.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM3.3 10.7a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zM6 12a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H6.75A.75.75 0 016 12zM3.3 6a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zm2.7.75A.75.75 0 016.75 6h14.5a.75.75 0 010 1.5H6.75A.75.75 0 016 6.75z"
            > </path>
        </svg>
    )
};

export default IconListBullet;
