import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

import { GetCategoryFilmsContent } from "../../../Api/Carousel";
import { GetCategory } from "../../../Api/Category";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import ICategoryDTO from "../../../Models/DTOs/ICategoryDTO";
import Heading from "../../../Components/UI/Text/Heading";
import NavLinkPinkButton from "../../../Components/UI/Buttons/NavLinkPinkButton";
import ListContainer from "../../../Components/UI/List/ListContainer";
import CarouselItem from "../../../Components/Carousels/CarouselItem";
import HeadingLoader from "../../../Components/UI/PageLoaders/HeadingLoader";
import VideoSingleLoader from "../../../Components/UI/PageLoaders/VideoSingleLoader";

import { RoutePaths } from "../../../Constants/RoutePaths";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";

// Context
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { AxiosError } from "axios";
import {SetTitle} from "../../../Helpers/PageMetadata";
import {isValidId} from "../../../Helpers/Numbers";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
        padding: 1.5rem;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    margin: 0 0 2rem 0;
    justify-content: space-between;
    align-items: center;
    h1 {
        margin: 0;
        font-size: 30px;
        line-height: 30px;
    }

    a {
        margin: 0;
    }
`;

const take = 30;

function CategoryFilmsScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();
    const controller = new AbortController();
    const loaded = useRef<boolean>(false);

    const params = useParams<{ id: string }>();
    const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
    const [backButtonRoutePath, setBackButtonRoutePath] = useState<string>(RoutePaths.Browse);

    const [categoryData, setCategoryData] = useState<ICategoryDTO | null>(null);
    const [categoryContent, setCategoryContent] = useState<ICarouselContentDTO[]>([]);
    const [initialContentFetched, setInitialContentFetched] = useState<ICarouselContentDTO[] | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        // Check user data
        if (!authCtx.userData) {
            // If user data not available yet, do nothing until it's loaded.
            return;
        }

        // Determine categoryId from params
        if (params.id) {
            if (!isValidId(params.id)) {
                navigate(RoutePaths.Browse);
                return;
            }
            const idNum = Number(params.id);
            // If david icke
            if (idNum === 17) {
                navigate(RoutePaths.DavidIckeFilms);
                return;
            }
            setCategoryId(idNum);
        } else {
            // If no categoryId is provided via params, just redirect to Browse or handle logic
            navigate(RoutePaths.Browse);
        }

    }, [authCtx.userData, params.id, navigate]);

    useEffect(() => {
        if (!categoryId) {
            return;
        }

        async function fetchInitialData() {
            if (typeof categoryId === "number") {
                setLoadingInitial(true);
                // Fetch category
                const catResult = await GetCategory(categoryId, controller);
                if (catResult !== null && categoryId === 17) {
                    catResult.Title = "Films and Specials";
                }
                // If category fetch fails or is invalid
                if (!catResult || catResult instanceof AxiosError) {
                    navigate(RoutePaths.Browse);
                    return;
                }
                setCategoryData(catResult);
                SetTitle(catResult.Title);

                // Fetch initial category content
                const contentResult = await GetCategoryFilmsContent(
                    categoryId,
                    authCtx.userData.CurrentCountryCode,
                    authCtx.userData.AspNetUserId,
                    take,
                    0,
                    controller
                );

                if (!contentResult || contentResult.length <= 0) {
                    toast.error("No films exists for this category.");

                    if (categoryId === 17) {
                        return navigate(RoutePaths.DavidIcke);
                    }

                    return navigate(RoutePaths.Category(categoryId));
                }

                setInitialContentFetched(contentResult);
                setLoadingInitial(false);
            }
        }

        fetchInitialData();

    }, [categoryId, authCtx.userData, navigate]);

    async function Categories(skip: number) {
        if (!categoryId) return;
        const result = await GetCategoryFilmsContent(
            categoryId,
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip,
            controller
        );

        if (!result || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setCategoryContent((prev) => [...prev, ...result]);
    }

    if (loadingInitial) {
        return (
            <Container>
                <HeaderContainer>
                    <HeadingLoader paddingTop="32" />
                </HeaderContainer>

                <ListContainer>
                    {[...Array(5)].map((e, i) => (
                        <VideoSingleLoader paddingTop="calc(16rem/16)" key={i} />
                    ))}
                </ListContainer>
            </Container>
        );
    }

    if (!initialContentFetched || !categoryData) {
        // If we have no initial content or category after loading, redirect or show empty state
        navigate(RoutePaths.Browse);
        return null;
    }

    const mergedLists = [...initialContentFetched, ...categoryContent];

    return (
        <Container>
            <HeaderContainer>
                <Heading>{categoryData.Title}</Heading>
                <NavLinkPinkButton to={backButtonRoutePath}>Back</NavLinkPinkButton>
            </HeaderContainer>

            <InfiniteScroll
                dataLength={mergedLists.length}
                hasMore={hasMore}
                next={() => Categories(mergedLists.length)}
                loader={
                    <ListContainer>
                        {[...Array(5)].map((e, i) => (
                            <VideoSingleLoader paddingTop="calc(16rem/16)" key={i} />
                        ))}
                    </ListContainer>
                }
            >
                <ListContainer>
                    {mergedLists.map((content, index) => {
                        return (
                            <CarouselItem
                                key={index.toString() + content.Id}
                                Content={content}
                                disableOverlay={true}
                            />
                        );
                    })}
                </ListContainer>
            </InfiniteScroll>
        </Container>
    );
}

export default CategoryFilmsScreen;

// The loader function is no longer needed, since data fetching is now done inside the component.
