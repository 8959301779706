import React from 'react';
import Select, { StylesConfig } from 'react-select';

// Components
import CustomClearIndicator from "./CustomClearIndicator";

interface OptionType {
    value: any;
    label: string;
}

interface SelectInputProps {
    name: string;
    value: any;
    label?: string;
    onChange: (event: { target: { name: string; value: any } }) => void;
    hasError?: boolean;
    errorMessage?: string;
    required?: boolean;
    className?: string;
    options: OptionType[];
    placeholder?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
    name,
    value,
    label,
    onChange,
    hasError = false,
    errorMessage,
    required = false,
    className = '',
    options,
    placeholder,
}) => {
    const customStyles: StylesConfig<OptionType, false> = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#333',
            padding: 'calc(10rem/16) calc(2rem/16)',
            borderColor: hasError ? 'red' : '#333',
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : '#444',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#333',
            marginTop: 0,
        }),
        menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#555'
                : state.isFocused
                    ? '#444'
                    : '#333',
            color: '#fff',
            '&:active': {
                backgroundColor: '#555',
            },
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff',
            margin: 0,
            padding: 0,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#aaa',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#555',
        }),
    };

    const handleSelectChange = (selectedOption: OptionType | null) => {
        const newValue = selectedOption ? selectedOption.value : '';
        onChange({
            target: {
                name,
                value: newValue !== '' ? newValue : null,
            },
        });
    };

    const selectedOption = options.find((option) => option.value === value);

    return (
        <div className={`form__input-select ${hasError ? 'has-error' : 'has-no-error'} ${className}`}>
            {label && <label>{label}</label>}

            <Select
                value={selectedOption || null}
                onChange={handleSelectChange}
                options={options}
                styles={customStyles}
                name={name}
                required={required}
                classNamePrefix="react-select"
                isClearable
                placeholder={placeholder}
                components={{ ClearIndicator: CustomClearIndicator }}
            />

            {hasError && errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    );
};

export default SelectInput;
