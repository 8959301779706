import {ISearchFilterDTO} from "../Models/DTOs/ISearchFilterDTO";
import {SortBy} from "../Models/Enums/SearchFilters/SortBy";
import {FilterBy} from "../Models/Enums/SearchFilters/FilterBy";
import {Originals} from "../Models/Enums/SearchFilters/Originals";

export function ShouldSearch(searchTerm: string) {
    return searchTerm.length >= 2;
}

export function GetDefaultSearchFilters(): ISearchFilterDTO {
    let searchFilters: ISearchFilterDTO = {
        SortBy: SortBy.Newest,
        FilterBy: FilterBy.FilmsAndSeries,
        Originals: Originals.All,
    };
    return searchFilters;
}
