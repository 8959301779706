import React, { useEffect, useState, useContext } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Label,
    Legend,
} from "recharts";
import { Table } from "antd";
import { fetchAverageViewTimeReport } from "../../Api/VideoMetrics";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// ✅ Custom Tooltip for full details
const CustomTooltip: React.FC<any> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload; // ✅ Get data of the hovered bar

        return (
            <div
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.95)",
                    padding: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                    width: "250px",
                }}
            >
                <p
                    style={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#333",
                    }}
                >
                    {data.FullTitle || "Unknown Title"}
                </p>
                <hr style={{ border: "0.5px solid #ddd", margin: "5px 0" }} />
                <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
                    <strong>Total Views:</strong>{" "}
                    {data.QtyViews.toLocaleString()}
                </p>
                <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
                    <strong>Avg View %:</strong>{" "}
                    {data.AverageViewPercentage.toFixed(2)}%
                </p>
                {data.DateRange && ( // ✅ Show date range if available
                    <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
                        <strong>Period:</strong> {data.DateRange}
                    </p>
                )}
            </div>
        );
    }
    return null;
};

const AverageViewTime: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [displayCount, setDisplayCount] = useState(20);
    const [sortBy, setSortBy] = useState<"views" | "percentage">("views");
    const [viewMode, setViewMode] = useState<"chart" | "table">("chart");
    const authCtx = useContext(UserAuthenticationContext);
    const access_token = authCtx.userData.Access_Token || "";

    useEffect(() => {
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                const response = await fetchAverageViewTimeReport(
                    access_token,
                    abortController
                );
                console.log("📊 Average View Time Response:", response);

                let sortedData = response.slice(0, displayCount);
                if (sortBy === "views") {
                    sortedData = sortedData.sort(
                        (a, b) => b.QtyViews - a.QtyViews
                    );
                } else {
                    sortedData = sortedData.sort(
                        (a, b) =>
                            b.AverageViewPercentage - a.AverageViewPercentage
                    );
                }

                const processedData = sortedData.map((video) => ({
                    ...video,
                    FullTitle: video.Title,
                    ShortTitle:
                        video.Title.length > 20
                            ? `${video.Title.substring(0, 20)}...`
                            : video.Title,
                }));

                setData(processedData);
            } catch (err) {
                console.error("❌ Failed to load data", err);
                setError("Failed to load data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        return () => abortController.abort();
    }, [access_token, displayCount, sortBy]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: "red" }}>{error}</p>;

    return (
        <div
            style={{
                width: "100%",
                height: "auto",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            <h2
                style={{ textAlign: "left", color: "#333", marginLeft: "20px" }}
            >
                Average View Time by Video (Last 7 Days)
            </h2>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "15px",
                    marginBottom: "15px",
                    marginLeft: "20px",
                }}
            >
                {/* Show Top Dropdown */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <label style={{ fontWeight: "bold", color: "#000" }}>
                        Show Top:
                    </label>
                    <select
                        value={displayCount}
                        onChange={(e) =>
                            setDisplayCount(Number(e.target.value))
                        }
                        style={{
                            padding: "10px 15px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            minWidth: "140px",
                            border: "2px solid #ccc", // ✅ Default grey border
                            backgroundColor: "#ffffff",
                            color: "#000",
                            cursor: "pointer",
                            height: "40px",
                            transition: "border-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.borderColor = "#6a0dad")
                        } // ✅ Turns purple on hover
                        onMouseOut={(e) =>
                            (e.currentTarget.style.borderColor = "#ccc")
                        } // ✅ Reverts back to grey
                    >
                        <option value="10">Top 10</option>
                        <option value="20">Top 20</option>
                        <option value="50">Top 50</option>
                        <option value="100">Top 100</option>
                    </select>
                </div>

                {/* Sort By Dropdown */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <label style={{ fontWeight: "bold", color: "#000" }}>
                        Sort By:
                    </label>
                    <select
                        value={sortBy}
                        onChange={(e) =>
                            setSortBy(e.target.value as "views" | "percentage")
                        }
                        style={{
                            padding: "10px 15px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            minWidth: "160px",
                            border: "2px solid #ccc", // ✅ Default grey border
                            backgroundColor: "#ffffff",
                            color: "#000",
                            cursor: "pointer",
                            height: "40px",
                            transition: "border-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.borderColor = "#6a0dad")
                        } // ✅ Turns purple on hover
                        onMouseOut={(e) =>
                            (e.currentTarget.style.borderColor = "#ccc")
                        } // ✅ Reverts back to grey
                    >
                        <option value="views">Total Views</option>
                        <option value="percentage">Avg View %</option>
                    </select>
                </div>

                {/* Switch View Button */}
                <button
                    onClick={() =>
                        setViewMode(viewMode === "chart" ? "table" : "chart")
                    }
                    style={{
                        padding: "10px 15px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        backgroundColor: "#6a0dad",
                        color: "white",
                        border: "2px solid #6a0dad",
                        borderRadius: "5px",
                        minWidth: "160px",
                        cursor: "pointer",
                        height: "40px",
                        transition: "background-color 0.3s ease",
                    }}
                    onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = "#570bbf")
                    }
                    onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = "#6a0dad")
                    }
                >
                    Switch to{" "}
                    {viewMode === "chart" ? "Table View" : "Chart View"}
                </button>
            </div>

            {/* ✅ Bar Chart View */}
            {viewMode === "chart" && (
                <ResponsiveContainer
                    width="95%"
                    height={data.length * 30 + 120}
                >
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{ top: 20, right: 50, left: 120, bottom: 40 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />

                        <YAxis
                            dataKey="ShortTitle"
                            type="category"
                            tick={{ fontSize: 12, fontWeight: "bold" }}
                            width={150}
                        />
                        <XAxis
                            type="number"
                            tick={{ fontSize: 12, fontWeight: "bold" }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ paddingBottom: "10px" }} />

                        <Bar
                            dataKey="QtyViews"
                            fill="#007bff"
                            name="Total Views"
                        >
                            <Label
                                value="Total Views"
                                position="top"
                                fill="#fff"
                                fontSize={14}
                                fontWeight="bold"
                            />
                        </Bar>
                        <Bar
                            dataKey="AverageViewPercentage"
                            fill="#ff7300"
                            name="Avg View %"
                        >
                            <Label
                                value="Avg View %"
                                position="top"
                                fill="#fff"
                                fontSize={14}
                                fontWeight="bold"
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}

            {/* ✅ Table View */}
            {viewMode === "table" && (
                <Table
                    dataSource={data}
                    columns={[
                        {
                            title: "Title",
                            dataIndex: "FullTitle",
                            key: "FullTitle",
                        },
                        {
                            title: "Views",
                            dataIndex: "QtyViews",
                            key: "QtyViews",
                            sorter: (a, b) => a.QtyViews - b.QtyViews,
                        },
                        {
                            title: "Avg View %",
                            dataIndex: "AverageViewPercentage",
                            key: "AverageViewPercentage",
                            sorter: (a, b) =>
                                a.AverageViewPercentage -
                                b.AverageViewPercentage,
                        },
                    ]}
                    pagination={{ pageSize: 10 }}
                    rowKey="FullTitle"
                />
            )}
        </div>
    );
};

export default AverageViewTime;
