export interface INotificationBarDTO {
    ButtonLink: string | null;
    ButtonText: string | null;
    Heading: string | null;
    Id: number;
}

export function isNotificationBar(obj: any): obj is INotificationBarDTO {
    if (obj === null || typeof obj !== 'object') {
        //console.log('Not an object:', obj);
        return false;
    }
    const requiredKeys = ['ButtonLink', 'ButtonText', 'Heading', 'Id'];
    for (const key of requiredKeys) {
        if (!(key in obj)) {
            //console.log(`Missing key: ${key}`, obj);
            return false;
        }
    }
    if (typeof obj.Id !== 'number') {
        //console.log(`'Id' is not a number:`, obj.Id);
        return false;
    }
    return true;
}

