import React from "react";

// Assets
import DavidIckeForTwoColumn from '../../../Assets/Images/FrontPage/david-icke-for-two-column.png';
import StarsEarthsRotation from '../../../Assets/Images/FrontPage/stars-earths-rotation.png';

// Components
import ButtonPillGradient from "../../Buttons/ButtonPillGradient";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";
import IconArrowSmall from "../../../Assets/SVGs/Icons/Arrows/ArrowSmall";
import IconArrowCarrot from "../../../Assets/SVGs/Icons/Arrows/ArrowCarrot";

const DavidIckeWithContentTwoColumn = () => {
    return (
        <section className="layout layout--david-icke-with-content-two-column u-relative_hidden">
            <div className="layout__gradient-transition-top"/>
            <img src={StarsEarthsRotation} className="column__stars" alt="Stars Behind David Icke"/>

            <div className="layout__column-wrapper">
                <div className="layout__column layout__column-one">
                    <div className="column__gradient-overlay"/>
                    <img src={DavidIckeForTwoColumn} className="column__portrait" alt="David Icke"/>

                </div>

                <div className="layout__column layout__column-two">
                    <h1>
                        Discover <span>David Icke's World</span>
                    </h1>

                    <p>
                        Go beyond the mainstream media. David Icke's thought-provoking series and talks are now
                        streaming exclusively on Ickonic.
                    </p>

                    <ButtonPillGradient
                        link={RoutePaths.DavidIcke}
                        color="white"
                        background="linear-gradient(0deg, rgba(187, 54, 184, 1) 0%, #b760b4 100%)"
                        backgroundHover="#b760b4"
                        text="Get Started"
                        icon={IconArrowCarrot({iconFill: 'white'})}
                    />
                </div>
            </div>

            <div className="layout__gradient-transition-bottom" />
        </section>
    );
};

export default DavidIckeWithContentTwoColumn;
