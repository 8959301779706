// src/reportsconfig.ts
export const ALLOWED_REPORTING_USERS = [
    "27de5319-2704-4c42-81cb-0dbd358ec505",
    "31d5df47-66db-4406-a746-870846e5d0c5",
    "3ba11092-7077-4d15-b7b0-7b496234d742",
    "0ee0cda1-5881-4a5b-b273-e2adb279589c",
    "00d3a0b3-4a70-4f60-aa65-3042e46789c1",
    "abc3acea-5cde-4f70-bfda-6ce6959edb9a",
    "d69f6fb1-89b7-4198-9b17-3a1491d1b240",
];

export const ALLOWED_VIDEO_REPORTS_USERS = [
    "27de5319-2704-4c42-81cb-0dbd358ec505",
    "31d5df47-66db-4406-a746-870846e5d0c5",
    "d69f6fb1-89b7-4198-9b17-3a1491d1b240",
    "3ba11092-7077-4d15-b7b0-7b496234d742",
    "00d3a0b3-4a70-4f60-aa65-3042e46789c1",
    "abc3acea-5cde-4f70-bfda-6ce6959edb9a",
    "0ee0cda1-5881-4a5b-b273-e2adb279589c",
];

export const ALLOWED_MANAGEMENT_REPORTS_USERS =[
    "27de5319-2704-4c42-81cb-0dbd358ec505",
    "31d5df47-66db-4406-a746-870846e5d0c5",
    "3ba11092-7077-4d15-b7b0-7b496234d742",
    "d69f6fb1-89b7-4198-9b17-3a1491d1b240",
    "0ee0cda1-5881-4a5b-b273-e2adb279589c",
];