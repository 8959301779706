import {HelpSubject} from "../Models/Enums/HelpSubject";

export function HelpTopicToString(topic: string) {
    switch (topic) {
        case HelpSubject.Careers:
            return "Careers";
        case HelpSubject.ContentSuggestions:
            return "Content Suggestion";
        case HelpSubject.CustomerService:
            return "Customer Service Request";
        case HelpSubject.Other:
            return "Customer Contact - Other";
        case HelpSubject.TechnicalSupport:
            return "Technical Support Request";
        default:
            return "Customer Support Request";
    }
}
