import { useState, useEffect } from "react";

// Api
import { GetCategories } from "../../../Api/Article";
import IArticleCategoryListDTO from "../../../Models/DTOs/IArticleCategoryListDTO";

function useFetchArticleCategories(skip: number, take: number, shouldAppendData: boolean) {
    const [categories, setCategories] = useState<IArticleCategoryListDTO[]>([]);
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
    const [categoriesError, setCategoriesError] = useState<string>("");

    useEffect(() => {
        const abortController = new AbortController();
        setCategoriesLoading(true);

        (async () => {
            try {
                const data = await GetCategories(skip, take, abortController);
                if (!abortController.signal.aborted && data) {
                    setCategories(prev =>
                        shouldAppendData ? [...prev, ...data] : data
                    );
                }
            } catch (err: any) {
                if (abortController.signal.aborted) {
                    // Optional: Silent log or ignore
                    // console.debug("Fetch cancelled");
                } else {
                    console.error(err);
                    setCategoriesError("Failed to fetch categories.");
                }
            } finally {
                if (!abortController.signal.aborted) {
                    setCategoriesLoading(false);
                }
            }
        })();

        return () => {
            abortController.abort(); // Cancel the request on cleanup
        };
    }, [skip, shouldAppendData, take]);

    return { categories, categoriesLoading, categoriesError };
}

export default useFetchArticleCategories;
