import { useContext, useState } from "react";
import styled from "styled-components";
import { AddOrDeleteFavourite } from "../../../Api/Favourite";
import { Colours } from "../../../Constants/Colours";
import useWindowDimensions from "../../../Helpers/WindowDimensions";
import ISeriesDTO from "../../../Models/DTOs/ISeriesDTO";
import { ContentType } from "../../../Models/Enums/ContentType";
import NavLinkPinkButton from "../Buttons/NavLinkPinkButton";
import NavLinkWhiteButton from "../Buttons/NavLinkWhiteButton";
import PinkButton from "../Buttons/PinkButton";
import ImageLazyLoad from "../ImageLazyLoad";
import Rating from "../Rating";
import ReadMoreText from "../ReadMoreText";
import Heading from "../Text/Heading";
import { FaRegPlayCircle, FaBookmark, FaRegBookmark } from "react-icons/fa";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { AxiosError } from "axios";
import { IsAuthenticated } from "../../../Helpers/Account";
import { Fonts } from "../../../Constants/Fonts";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

// Context
import {UserAuthenticationContext} from "../../../Context/UserAuthenticationContext";
import {GetPercentageOfNumber} from "../../../Helpers/Numbers";

const Container = styled.div`
    width: max-content;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: auto;
    max-height:400px;
    overflow: hidden;
    border-bottom: 4px solid ${Colours.IckonicPink};

    > div:first-child::before {
        content: "";
    }

    > div:first-child::after {
        display: block;
        position: absolute;
        top: 0;
        background-image: linear-gradient(
            90deg,
            rgba(29, 29, 29, 1) 5%,
            rgba(29, 29, 29, 0.95) 47%,
            rgba(255, 255, 255, 0) 100%
        );
        width: 100%;
        content: "";
        bottom: 0;
        right: 0;
    }
`;

const MobileContainer = styled.div`
    width: max-content;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 1rem;
    gap: 10px;
    > div:first-child {
        border-radius: 10px;
        overflow: hidden;
    }
`;

const MobileContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    h1 {
        margin: 0;
    }
`;

const OverlayContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 0 0 4%;
    gap: 10px;
    width: 50%;

    h1 {
        margin: 0;
        font-size: 30pt;
    }
`;

const ContentTypeContainer = styled.span`
    background: ${Colours.IckonicPink};
    color: white;
    text-decoration: none;
    font-family: ${ Fonts.Secondary };
    letter-spacing: 0.7px;
    font-weight: bold;
    padding: 5px;
    font-size: 15pt;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }em/16)) {
        flex-direction: row;
    }
`;

function SeriesHeader(props: {
    Content: ISeriesDTO;
    IsFavourite: boolean;
    SetIsFavourite: (value: boolean) => void;
    LatestEpisode?: number;
    rating: number;
}) {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const [loading, setLoading] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    const favouriteText = props.IsFavourite
        ? "Remove From My Favourites"
        : " Add To My Favourites";

    async function ToggleFavourite() {
        setLoading(true);

        const result = await AddOrDeleteFavourite(
            props.Content.Id,
            authCtx.userData.AspNetUserId,
            true,
            controller
        );

        if (result === "Error" || result instanceof AxiosError) {
            setLoading(false);

            return;
        }

        props.SetIsFavourite(!props.IsFavourite);
        setLoading(false);
    }

    function GetButtonContainer() {
        return (
            <ButtonContainer>
                {props.LatestEpisode !== undefined ? <NavLinkPinkButton to={RoutePaths.Watch(props.LatestEpisode)}>
                    <FaRegPlayCircle />
                    Latest Episode
                </NavLinkPinkButton> : null}


                {props.Content.TrailerLink !== null && props.Content.TrailerLink !== undefined && props.Content.TrailerLink.length > 0 ? (
                    <NavLinkWhiteButton to={RoutePaths.SeriesTrailer(props.Content.Id)}>
                        <FaRegPlayCircle />
                        Watch Trailer
                    </NavLinkWhiteButton>
                ) : null}

                {IsAuthenticated(authCtx.userData) ? (
                    <PinkButton onClick={ToggleFavourite} disabled={loading}>
                        {props.IsFavourite ? <FaBookmark /> : <FaRegBookmark />}
                        {favouriteText}
                    </PinkButton>
                ) : null}
            </ButtonContainer>
        );
    }

    if (width <= 1115) {
        return (
            <MobileContainer>
                <ImageLazyLoad source={props.Content.BackgroundImage} />
                <MobileContentContainer>
                    <ContentTypeContainer>
                        {ContentType[ContentType.Series]}
                    </ContentTypeContainer>
                    <Rating iconSize={15} maxStars={5} value={GetPercentageOfNumber(props.rating, 5)} />
                    <Heading>{props.Content.Title}</Heading>
                    {GetButtonContainer()}
                </MobileContentContainer>
            </MobileContainer>
        );
    }

    return (
        <Container>
            <ImageLazyLoad source={props.Content.BackgroundImage} />
            <OverlayContainer>
                <ContentTypeContainer>
                    {ContentType[ContentType.Series]}
                </ContentTypeContainer>
                <Rating iconSize={15} maxStars={5} value={GetPercentageOfNumber(props.rating, 5)} />
                <Heading>{props.Content.Title}</Heading>
                <ReadMoreText limit={240}>
                    {props.Content.Description}
                </ReadMoreText>
                {GetButtonContainer()}
            </OverlayContainer>
        </Container>
    );
}

export default SeriesHeader;
