import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";

// Context
import {VideoPlayerSettingsContext} from "../../../Context/VideoPlayerSettingsContext";

// Models
import IVideoDTO from "../../../Models/DTOs/IVideoDTO";

// Hooks
import useFetchVideo from "../../../Hooks/Content/Watch/useFetchVideoById";
import useFetchVideoPeakInfo from "../../../Hooks/VideoInfo/useFetchVideoPeakInfo";

// Components
import VideoPlayer from "../../../Components/VideoPlayer/VideoPlayer";
import VideoPrimaryInformation from "../../../Components/VideoInfo/VideoPrimaryInformation";
import ButtonPill from "../../../Components/Buttons/ButtonPill";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";

const TrailerFilm = () => {
    const

        // Parse the URL param into a number.
        {id} = useParams<string>(),
        videoId = id ? parseInt(id) : undefined,

        // Theatre mode forces the player to fill the width of the browser
        { isTheatreMode, setIsTheatreMode } = useContext(VideoPlayerSettingsContext),

        // Video object
        [videoData, setVideoData] = useState<IVideoDTO>({} as IVideoDTO),

        // Hooks
        {video} = useFetchVideo(videoId, "GB"),
        {peak} = useFetchVideoPeakInfo(videoData.Id);

    useEffect(() => {
        if (video) {
            if (videoData.Id === undefined || video.Id !== videoData.Id) {
                setVideoData(video);
            }
        }
    }, [video]);

    return (
        <div className="page--watch-video page--watch-trailer">
            <div className={`page__wrapper ${isTheatreMode ? 'is-theatre-mode' : 'is-not-theatre-mode'}`}>
                <VideoPlayer
                    key={videoData.Id}
                    video={videoData}
                    autoPlay={true}
                    playbackAllowed={true}
                    isTheatreMode={isTheatreMode}
                    setIsTheatreMode={setIsTheatreMode}
                    peak={peak}
                    isCommentInputFocused={false}
                    useTrailerLink={true}
                />

                <div className="watch__column watch__column-info">
                    <VideoPrimaryInformation
                        video={videoData}
                        isVideoLoading={videoData.Id === undefined}
                        disableRating={true}
                        disableDescription={true}
                        disableDateAndEpisodeInfo={true}
                        isTrailer={true}
                    />
                </div>

                {videoId && (
                    <div className="watch__button">
                        <ButtonPill
                            label="Watch Full Film"
                            className=""
                            link={RoutePaths.Watch(videoId)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default TrailerFilm;
