import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { IsDev } from "../../../Helpers/Admin";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import PinkButton from "../Buttons/PinkButton";
import Heading from "../Text/Heading";
import PrimaryText from "../Text/PrimaryText";
import Icon from "../../../Assets/Images/error-icon.png";
import {Encrypt} from "../../../Helpers/Encryption";

const ErrorContainer = styled.div`
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: -15px 26px 30px rgb(0 0 0 / 9%);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 850px;
    flex-basis: 70%;
    gap: 20px;
    width: 70%;
    height: fit-content;

    p {
        font-size: 1.05em;
    }

    @media only screen and (max-width: 800px) {
        margin: 10px 0;
    }
    @media only screen and (max-width: 600px) {
        margin: 5px 0;
        flex-basis: 80%;
        width: 80%;
    }
`;

const ErrorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 450px) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    img {
        height: 200px;
    }
`;

const TitleContainer = styled.div`
    h1 {
        color: ${Colours.Text};
        margin: 0;
        font-size: 5em;
    }

    h2 {
        color: ${Colours.Text};
        margin: 0;
        font-size: 3.4em;
    }
`;

const EncodedContainer = styled.div`
    text-align: justify;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 0.8em;
    color: #c1c1c1;
    flex-basis: 100%;
    background-color: #2c2c2c;
    border-radius: 5px;
    padding: 20px;
    width: -webkit-fill-available;
    min-height: 200px;
`;

function EncrytedError(props: { message: string; statusCode: number; statusText: string }) {
    const passPhrase = "KennedyIsRatherCute";
    const encryptedMessage = Encrypt(props.message, passPhrase);
    const isDev = IsDev();

    function CopyError() {
        navigator.clipboard.writeText(encryptedMessage);
    }

    return (
        <ErrorContainer>
            <ErrorHeader>
                <TitleContainer>
                    <Heading>{props.statusCode ?? "500"}</Heading>
                    <Heading type={HeadingType.H2}>{props.statusText ?? "Internal Error"}</Heading>
                </TitleContainer>

                <img src={Icon} alt={"logo"} />
            </ErrorHeader>
            <PrimaryText>
                Sorry, something went wrong on our end. We are currently trying to fix the problem.
            </PrimaryText>

            <PrimaryText>
                If you happen to find our technical team please copy this text and send it to them.
            </PrimaryText>

            <EncodedContainer>{isDev ? props.message : encryptedMessage}</EncodedContainer>

            <PinkButton onClick={CopyError}>Copy Error</PinkButton>
        </ErrorContainer>
    );
}

export default EncrytedError;
