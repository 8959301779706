import { useContext, useEffect, useState, ReactNode } from "react";
import {Outlet, useLocation} from "react-router-dom";
import lscache from "lscache";

// Components
import AcceptCookies from "../Components/GlobalUI/AcceptCookies";
import Footer from "../Components/UI/Navigation/Footer";
import Toast from "../Components/UI/Toasts/Toast";
import ScrollToTop from "../Components/UI/Navigation/ScrollToTop";
import MarketingScripts from "../Components/Headers/MarketingScripts";
import Header from "../Components/GlobalUI/Header";
import NotificationBar from "../Components/GlobalUI/NotificationBar";

// Context
import {UserAuthenticationContext} from "../Context/UserAuthenticationContext";
import {GlobalInterfaceContext} from "../Context/GlobalInterfaceContext";

// Constant
import {URLsWithoutUI, URLsWithNotifyBar} from "../Constants/RoutePaths";

// Utility
import {ShowWelcomeToast} from "../Helpers/Notifications";

// TEMP
import CancelOrPause from "../Components/Forms/CancelOrPause/CancelOrPause";
import PopUp from "../Components/Popups/PopUp";

const RootLayout = (props: {children?: ReactNode}) => {
    const
        {hasNotificationBar, isDarkMode, mainMenuDesktopMode} = useContext(GlobalInterfaceContext),
        authCtx = useContext(UserAuthenticationContext),
        location = useLocation(),
        [isLoaded, setIsLoaded] = useState<boolean>(false),
        currentLocation = useLocation(),
        shouldDisplayGlobalUI = !URLsWithoutUI.some((path) => path.test(currentLocation.pathname)),
        shouldDisplayNotifyBar = URLsWithNotifyBar.some((path) => path.test(currentLocation.pathname)),

        // TEMP
        [popupOpen, setPopUpOpen] = useState(false);

    useEffect(() => {
        if (isLoaded) {
            ShowWelcomeToast(authCtx?.userData.Person?.FirstName, location);
            return;
        }

        setTimeout(() => {
            setIsLoaded(true);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx?.userData]);

    useEffect(() => {

        // TEMP
        setPopUpOpen(true);

        if (authCtx && authCtx.doesAuthTokenExist()) {
            const token = lscache.get('authToken');
            if (token && token !== "") {
                authCtx.fetchUserData(token);
            }
        }
    }, []);

    return (
        <main role="main" className={shouldDisplayNotifyBar && hasNotificationBar ? 'has-notification-bar' : 'notification-bar-hidden'}>

            {/** TEMP **/}
            {/*<PopUp*/}
            {/*    canBeDismissed={true}*/}
            {/*    isFullScreen={true}*/}
            {/*    zIndex={9999}*/}
            {/*    isOpen={popupOpen}*/}
            {/*    onClose={() => setPopUpOpen(false)}*/}
            {/*>*/}
            {/*    <CancelOrPause*/}
            {/*        userData={{*/}
            {/*            Last4: "3009",*/}
            {/*            LatestInvoiceStatus: 3,*/}
            {/*            Subscription: {*/}
            {/*                Id: "12345",*/}
            {/*                AspNetUserId: "asp-net-user-id",*/}
            {/*                AccessLevel: 99,*/}
            {/*                SubscriptionStartDate: "2021-10-05T14:48:00.000Z",*/}
            {/*                SubscriptionEndDate: "2025-10-05T14:48:00.000Z",*/}
            {/*                TransactionId: "transaction-id",*/}
            {/*                BillingFrequency: 3,*/}
            {/*                BillingAmount: 7.99,*/}
            {/*                PaymentMeth: "3009",*/}
            {/*                SubNo: "sub-number",*/}
            {/*                StripeId: "stripe-id",*/}
            {/*                Currency: null*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</PopUp>*/}

            <MarketingScripts/>

            { /** Tracking Consent Banner **/}
            <AcceptCookies/>

            { /** AlertAndInfo and top notification bar **/ }
            { shouldDisplayGlobalUI && shouldDisplayNotifyBar && hasNotificationBar && <NotificationBar isDarkMode={isDarkMode} /> }

            { /** Main Header w/ Primary Nav & Profile / Search / Alerts **/ }
            { shouldDisplayGlobalUI && <Header shouldDisplayNotifyBar={shouldDisplayNotifyBar} /> }

            <div
                className={`container
                ${shouldDisplayGlobalUI ? 'has-header' : 'has-no-header'}
                ${isDarkMode ? 'is-dark-mode' : 'is-light-mode'}
                ${mainMenuDesktopMode ? "menu-is-desktop" : "menu-is-mobile"}
                ${authCtx.userData.AspNetUserId ? 'user-is-authenticated' : 'user-not-authenticated'}
                `}>

                { /**
                 Currently only used to compose the <Error> component...
                 React-Router expects a single comp for the errorElement prop,
                 but I still want to include the rest of the UI without duplicating
                 code in that file, so I pass <Error /> as a child to Root.
                 **/}
                {props?.children}

                { /** React Router Body **/}
                <Outlet/>
            </div>

            { shouldDisplayGlobalUI && (
                <>
                    <Footer/>

                    <Toast/>

                    <ScrollToTop/>
                </>
            ) }
        </main>
    );
}

export default RootLayout;
