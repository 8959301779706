import { AxiosError } from "axios";
import moment from "moment";
import { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CheckPaymentIntentStatus, GetRenewDetails, PayInvoice } from "../../../Api/Stripe";
import SelectPaymentMethodExternal from "../../../Components/UI/Subscription/SelectPaymentMethodExternal";
import PinkButton from "../../../Components/UI/Buttons/PinkButton";
import Modal from "../../../Components/UI/Modals/Modal";
import Spinner from "../../../Components/UI/Spinner";
import Heading from "../../../Components/UI/Text/Heading";
import ErrorText from "../../../Components/UI/Text/ErrorText";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { RoutePaths } from "../../../Constants/RoutePaths";
import IPayInvoiceDTO from "../../../Models/DTOs/IPayInvoiceDTO";
import IPaymentIntentResultDTO from "../../../Models/DTOs/IPaymentIntentResultDTO";
import IUnpaidDTO from "../../../Models/DTOs/IUnpaidDTO";
import { PaymentIntentResult } from "../../../Models/Enums/PaymentIntentResult";
import { Fonts } from "../../../Constants/Fonts";
import { PaymentMethod } from "@stripe/stripe-js";

// Context
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import {SetTitle} from "../../../Helpers/PageMetadata";
import {SymbolFromCurrency} from "../../../Helpers/Currency";
import {GetAuthCompleteLink} from "../../../Helpers/Routing";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    padding: 2% 0;
    max-height: 65%;

    > * {
        width: 100%;
    }

    > button {
        margin-top: auto;
    }

    h1 {
        font-size: 2.5rem;
        margin: 0;
    }

    @media only screen and (min-width: 1200px) {
        max-width: ${ResponsiveBreakpoints.ContainerDesktopWidth + "px"};
    }

    @media only screen and (max-width: 1115px) {
        width: 95%;
        max-height: 45%;
    }
`;

const IFrameContainer = styled.div`
    width: 100%;
    min-width: 300px;
    height: 640px;
`;

const Table = styled.table`
    width: 100%;
    background: ${Colours.SecondaryHighlight};
    font-family: ${Fonts.Primary};
    color: ${Colours.Text};
    font-size: 12pt;
    margin: 0;
    line-height: 1.5;
    border-radius: 5px;

    th{
        color: white;
        text-align: start;
        padding: 5px;
    }

    td {
        text-align: start;
        padding: 5px;
    }
`;

function RenewScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();
    const navigate = useNavigate();
    const [formValid, setFormValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [needsBankAuth, setNeedsBankAuth] = useState<boolean>(false);
    const [iFrameUrl, setIFrameUrl] = useState<string>("");
    const formRef = useRef<HTMLFormElement | null>(null);
    const renewDetails = useRef<IUnpaidDTO>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<IUnpaidDTO | null>(null);

    useEffect(() => {
        SetTitle("Renew Your Subscription");
    }, []);

    useEffect(() => {
        // If user is not authenticated, redirect
        if (!authCtx.userData || !authCtx.userData.AspNetUserId) {
            navigate(RoutePaths.Root);
            return;
        }

        async function fetchInvoices() {
            const result = await GetRenewDetails(
                authCtx.userData.AspNetUserId,
                authCtx.userData.PaymentMethodId,
                controller
            );

            if (!result || result instanceof AxiosError) {
                navigate(RoutePaths.Root);
                return;
            }

            setInvoices(result);
        }

        fetchInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.userData]);

    useEffect(() => {
        async function on3DSComplete() {
            const result = await CheckPaymentIntentStatus(authCtx.userData.AspNetUserId, controller);

            if (result === true) {
                await PaymentSucessful();
            } else {
                setErrorMessage(
                    "Error: Failed to verify details with your bank. Please try again or use a different card."
                );
            }
            setNeedsBankAuth(false);
            setIFrameUrl("");
        }

        if (!pageLoaded) {
            window.addEventListener('message', function (ev) {
                if (ev.data === '3DS-authentication-complete') {
                    on3DSComplete();
                }
            }, false);
            setPageLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLoaded]);

    async function OnSubmit() {
        if (formRef.current) {
            formRef.current.requestSubmit();
        } else {
            setLoading(true);
            await SubmitPayInvoice();
            setLoading(false);
        }
    }

    async function SubmitPayInvoice(newPaymentmethod?: PaymentMethod) {
        setErrorMessage(undefined);

        if (!renewDetails.current) {
            return;
        }

        let returnUrl = GetAuthCompleteLink();

        const payInvoiceData: IPayInvoiceDTO = {
            InvoiceId: renewDetails.current.LatestUnpaidInvoice.id,
            PaymentMethodId: newPaymentmethod?.id ?? authCtx.userData.PaymentMethodId,
            ReturnUrl: returnUrl,
            StripeId: authCtx.userData.StripeId,
            UserId: authCtx.userData.AspNetUserId,
        };

        const result = await PayInvoice(payInvoiceData, controller);

        if (!result || result instanceof AxiosError || result.PaymentIntentResult === PaymentIntentResult.Failure) {
            setErrorMessage(
                "Error: Failed to pay invoice. Please ensure you have enough funds in your account or try a new card."
            );
            return;
        }

        if (result.PaymentIntentResult === PaymentIntentResult.NeedsAuth) {
            DisplayAuthForm(result);
            return;
        }

        await PaymentSucessful();
    }

    async function PaymentSucessful() {
        toast.success("Your subscription has been successfully renewed.");
        await authCtx.refreshUserData(authCtx.userData.Access_Token);
        navigate(RoutePaths.Browse);
    }

    function SetFormValid(value: boolean) {
        setFormValid(value);
    }

    function DisplayAuthForm(result: IPaymentIntentResultDTO) {
        setNeedsBankAuth(true);
        if (result.AuthReturnUrl !== undefined && result.AuthReturnUrl !== null) {
            setIFrameUrl(result.AuthUrl as string);
        }
    }

    // If invoices not loaded yet or user not authenticated, show spinner
    if (!invoices || !authCtx.userData || !authCtx.userData.AspNetUserId) {
        return (
            <Container>
                <Spinner fontSize={50} />
            </Container>
        );
    }

    renewDetails.current = invoices;

    return (
        <Container>
            <Heading>Your payment was unsuccessful</Heading>
            <PrimaryText>
                It seems we are having trouble collecting your payment from your card
                ending in {invoices.CardLast4}. This could be due your bank requiring
                a security check to proceed, an unauthorised payment, insufficient funds,
                or incorrect payment details. Please try again by entering your payment details.
            </PrimaryText>
            <Table>
                <thead>
                <tr>
                    <th>Due</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {moment(invoices.LatestUnpaidInvoice.period_end * 1000)
                            .format("Do MMMM YYYY")
                            .toString()}
                    </td>
                    <td>
                        {SymbolFromCurrency(invoices.LatestUnpaidInvoice.currency) + (invoices.LatestUnpaidInvoice.amount_due / 100)}
                    </td>
                </tr>
                </tbody>
            </Table>
            <SelectPaymentMethodExternal
                ref={formRef}
                setIsValid={SetFormValid}
                onSubmit={SubmitPayInvoice}
                setLoading={setLoading}
                CardLast4={invoices?.CardLast4}
                hideExisting={
                    !authCtx.userData.PaymentMethodId ||
                    authCtx.userData.PaymentMethodId.length <= 0
                }
            />

            {loading ? (
                <Spinner fontSize={50} />
            ) : (
                <PinkButton disabled={!formValid} onClick={OnSubmit}>
                    Renew Subscription
                </PinkButton>
            )}

            {errorMessage && errorMessage.length > 0 ? (
                <ErrorText>{errorMessage}</ErrorText>
            ) : null}

            <Modal
                title=""
                closeOnClickOutside={false}
                displayClosebutton={false}
                display={needsBankAuth}
                setDisplay={setNeedsBankAuth}
            >
                <IFrameContainer>
                    <iframe title={"card"} src={iFrameUrl} width="100%" height="640px" />
                </IFrameContainer>
            </Modal>
        </Container>
    );
}

export default RenewScreen;

export async function Loader() {
    SetTitle("Renew Your Subscription");
    // No UserSnapshot or RenewAuth calls here.
    // Authentication checks handled inside the component.
    return null;
}
