import { forwardRef } from "react";
import Select from "react-select";
import styled from "styled-components";

import { Colours } from "Constants/Colours";
import ISelecterDTO from "../../../Models/DTOs/ISelecterDTO";
import ErrorText from "../Text/ErrorText";
import { Fonts } from "Constants/Fonts";

const Container = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;

    .css-b62m3t-container.invalid {
        box-shadow: 0 0 0 1px ${Colours.Error};
        border-radius: 5px;
    }

    @media (min-width: 768px) {
        align-items: center;
        flex-direction: row;
    }
`;

const SelecterItem = styled(Select)<{backgroundColour: string, highlightColour: string}>`
    width: 100%;

    * {
        font-family: ${ Fonts.Primary };
    }

    > div {
        background: ${p => p.backgroundColour};
        color: ${Colours.Text};
        border: 0;
    }

    .css-t3ipsp-control,
    .css-t3ipsp-control:hover {
        box-shadow: 0 0 0 1px ${p => p.highlightColour};
    }

    > div:focus {
        border: 0;
    }

    .css-1jqq78o-placeholder,
    .css-1dimb5e-singleValue {
        color: ${Colours.Text};
    }
`;

const ErrorContainer = styled.div`
    flex-basis: 100%;
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease-in-out;

    p {
        font-size: 12pt;
    }

    //Container styles when active
    &.active {
        height: 100%;
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
        height: 0;
    }
`;

const Selecter = forwardRef(
    (
        props: {
            options: ISelecterDTO[];
            isValid: boolean;
            placeholder?: string;
            onChange: (option: ISelecterDTO) => void;
            onBlur: (
                event: React.FocusEvent<HTMLInputElement, Element>
            ) => void;
            errorMessage?: string;
            backgroundColour?: string,
            highlightColour?: string,
            containerClasses?: string,
            defaultValue?: ISelecterDTO,
            styles?: any,
        },
        ref
    ) => {
        function onChange(option: ISelecterDTO) {
            props.onChange(option);
        }


        return (
            <Container className={props?.containerClasses}>
                <SelecterItem
                    className={`${props.isValid === false ? "invalid" : ""}`}
                    options={props.options}
                    isSearchable={false}
                    onChange={(selectedValue) =>
                        onChange(selectedValue as ISelecterDTO)
                    }
                    defaultValue={props?.defaultValue}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder}
                    backgroundColour={props.backgroundColour ?? "#444444"}
                    highlightColour={props.highlightColour ?? "#6d6d6d"}
                    styles={{
                        option: (base, state) => ({
                            ...base,
                            background: state.isFocused ? (props.highlightColour ?? "#444444") : (props.backgroundColour ?? "#6d6d6d"),
                            height: "100%",
                        }),
                        ...(props?.styles ?? {})
                    }}
                />

                <ErrorContainer
                    className={`${props.isValid === false ? "active" : ""}`}
                >
                    {props.errorMessage !== undefined ? (
                        <ErrorText>{props.errorMessage}</ErrorText>
                    ) : null}
                </ErrorContainer>
            </Container>
        );
    }
);

export default Selecter;
