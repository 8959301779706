import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useSwipeable } from "react-swipeable";

// Models
import ICarouselContentDTO, { createPlaceholderICarouselContentDTO } from "../../Models/DTOs/ICarouselContentDTO";

// Components
import ButtonsPrevNext from "../Buttons/ButtonsPrevNext";
import ButtonPill from "../Buttons/ButtonPill";
import LandscapeVideoItem from "../Content/LandscapeVideoItem";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

type CarouselLandscapeProps = {
    isDarkMode: boolean;
    heading: string;
    accentColor?: string;
    carouselItems?: ICarouselContentDTO[];
    buttonText?: string;
    buttonLink?: string;
};

const CarouselLandscape: React.FC<CarouselLandscapeProps> = (
    {
        isDarkMode,
        heading,
        accentColor,
        carouselItems,
        buttonText,
        buttonLink
    }
) => {
    // Determine if data is loading; if so, we'll show placeholder items
    const
        isLoading = !carouselItems,
        placeholderItems = createPlaceholderICarouselContentDTO(10),

        authCtx = useContext(UserAuthenticationContext),

        // Once we have data, use it; otherwise use placeholders
        items = isLoading || carouselItems.length === 0 ? placeholderItems : carouselItems,

        containerRef = useRef<HTMLDivElement>(null),

        [ currentIndex, setCurrentIndex ] = useState(0),
        [ itemsPerView, setItemsPerView ] = useState(2),
        [ containerWidth, setContainerWidth ] = useState(0),

        updateItemsPerView = useCallback(() => {
            const width = window.innerWidth;
            if (width < 760) {
                return 2;
            } else if (width < 1008) {
                return 3;
            } else if (width < 1272) {
                return 4;
            } else {
                return 5;
            }
        }, []),

        // Compute how many items we actually have
        itemCount = items?.length ?? 0,

        // Calculate maxIndex based on itemCount & itemsPerView
        maxIndex = Math.max(0, itemCount - itemsPerView),

        goToPrev = () => {
            setCurrentIndex((prev) => Math.max(prev - 1, 0));
        },

        goToNext = () => {
            setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
        },

        handlers = useSwipeable({
            onSwipedLeft: goToNext,
            onSwipedRight: goToPrev,
            trackMouse: true,
        }),

        // Calculate pixel-based layout
        itemWidth = containerWidth && itemsPerView ? containerWidth / itemsPerView : 0,
        trackWidth = itemWidth * itemCount,
        translateX = -currentIndex * itemWidth;

    // On mount & window resize, recalc container width & items per view
    useEffect(() => {
        const handleResize = () => {
            const newItemsPerView = updateItemsPerView();
            setItemsPerView(newItemsPerView);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        // Initial measure
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateItemsPerView]);

    // If itemsPerView or items array changes, clamp currentIndex
    useEffect(() => {
        const newMaxIndex = Math.max(0, itemCount - itemsPerView);
        setCurrentIndex((prevIndex) => Math.min(prevIndex, newMaxIndex));
    }, [itemsPerView, itemCount]);

    return (
        <div
            className={`layout--carousel-landscape ${
                isDarkMode ? "is-dark-mode" : "has-light-mode"
            }`}
            {...handlers}
        >
            <div className={`carousel-landscape__heading ${accentColor ? "has-accent-color" : "no-accent-color"}`}>
                <h2>
                    {accentColor && <span style={{background: accentColor}}/>}
                    {heading}
                </h2>

                {buttonText && buttonLink && (
                    <ButtonPill
                        label={buttonText}
                        className=""
                        link={buttonLink}
                    />
                )}
            </div>

            <div className="carousel-landscape__wrapper">
                <div ref={containerRef} className="carousel-landscape__inner">
                    <div
                        className="carousel-landscape__track"
                        style={{
                            width: trackWidth ? `${trackWidth}px` : "auto",
                            transform: `translateX(${translateX}px)`,
                        }}
                    >
                        {items.map((item, i) => (
                            <LandscapeVideoItem
                                key={i}
                                item={item}
                                userData={authCtx.userData}
                                authTokenExists={authCtx.doesAuthTokenExist()}
                                itemWidth={itemWidth}
                                isLoading={isLoading}
                            />
                        ))}
                    </div>
                </div>

                <ButtonsPrevNext
                    onPrev={goToPrev}
                    onNext={goToNext}
                    currentIndex={currentIndex}
                    maxIndex={maxIndex}
                    parentClass="carousel-landscape__controls"
                />
            </div>
        </div>
    );
};

export default CarouselLandscape;
