import { Colours } from "../../../Constants/Colours";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { BsTwitter, BsInstagram, BsFacebook, BsYoutube, BsTelegram, BsTiktok } from "react-icons/bs";
import { ReactComponent as Gab } from '../../../Assets/Images/Logos/logo-gab.svg';
import { ReactComponent as Minds } from '../../../Assets/Images/Logos/logo-minds.svg';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as IckonicLogoQuestionEverything } from '../../../Assets/Images/Logos/ickonic-question-everything-white.svg';
import SocialMediaButton from "../Buttons/SocialMediaButton";

import styled from "styled-components";
import PrimaryText from "../Text/PrimaryText";
import { RoutePaths } from "../../../Constants/RoutePaths";
import {Ease} from "../../../Constants/EasingCurves";
import {Fonts} from "../../../Constants/Fonts";
import {ShouldHideFooter} from "../../../Helpers/UI";

const sectionBaseStyles = `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px auto;
`;

const FooterContainer = styled.footer`
    background: #0f0f0f;
    padding: 16px;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {
        padding: 16px 32px;
    }
`;

const ContentContainer = styled.div`
    max-width: 1750px;
    margin: 0 auto;
    position: relative;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {

    }
`;

const CopyrightContainer = styled.div`
    ${ sectionBaseStyles }
    p {
        text-align: center;
        font-size: 9pt;
        color: #888;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {
        margin-top: 64px;
    }
`;

const FooterNav = styled(NavLink)`
    display: block;
    width: 100%;
    color: #aaa;
    text-decoration: none;
    font-family: ${ Fonts.Primary };
    text-align: center;
    margin: 5px 0;
    transition: color .25s ${ Ease.Smooth };
    &:hover,
    &:active,
    &:visited {
        color: white;
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {
        text-align: left;
    }
`;

const Section = styled.div`
    ${ sectionBaseStyles }
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 16px auto 16px 0;
}
`;

const SocialSection = styled.div`
    ${ sectionBaseStyles }
    max-width: 160px;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.FooterDesktopWidth }em/16)) {
        position: absolute;
        top: -16px;
        right: -7px;

    }
`;

const StyledLogo = styled.div`
    width: 106px;
    height: 47px;
    svg {
        * {
            fill: #ccc;
        }
    }
`;

const styledIcon = `
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    transition: background .25s ${ Ease.Smooth };
    svg {
        width: 20px;
        height: 20px;
        * {
            fill: #bcbcbc;
            transition: fill .15s ${ Ease.Smooth };
        }
    }

    &:hover,
    &:active {
        background: rgba(255,255,255,0.05);
        svg {
            * {
                fill: ${ Colours.IckonicPinkHighlight };
            }
        }
    }
`;

const SocialIcon = styled.div`
    ${ styledIcon }
`;

const SocialIconException = styled.div`
    ${ styledIcon }
    padding: 7px;
    svg {
        width: 25px;
        height: 25px;
    }
`;


function Footer() {

    const location = useLocation();

    if (ShouldHideFooter(location.pathname)) {
        return (
            <></>
        );
    }

    return (
        <FooterContainer>
            <ContentContainer>
                <Section>
                    <StyledLogo>
                        <IckonicLogoQuestionEverything />
                    </StyledLogo>
                </Section>

                <Section>
                    <FooterNav to={RoutePaths.HelpCentre}>Help Centre</FooterNav>
                    <FooterNav to={RoutePaths.TermsOfUse}>Terms of Use</FooterNav>
                    <FooterNav to="/Careers">Careers</FooterNav>
                </Section>

                <SocialSection>
                    <SocialIcon>
                        <SocialMediaButton link="https://www.twitter.com/ickonic/"><BsTwitter/></SocialMediaButton>
                    </SocialIcon>

                    <SocialIcon>
                        <SocialMediaButton link="https://www.instagram.com/ickonicmedia/"><BsInstagram/></SocialMediaButton>
                    </SocialIcon>

                    <SocialIcon>
                        <SocialMediaButton link="https://www.facebook.com/ickonicmedia/"><BsFacebook/></SocialMediaButton>
                    </SocialIcon>

                    <SocialIcon>
                        <SocialMediaButton link="https://www.youtube.com/@weareickonic"><BsYoutube/></SocialMediaButton>
                    </SocialIcon>

                    <SocialIconException>
                        <SocialMediaButton link="https://www.tiktok.com/@weareickonic"><BsTiktok/></SocialMediaButton>
                    </SocialIconException>

                    <SocialIconException>
                        <SocialMediaButton link="https://www.minds.com/ickonicmediagroup/"><Minds/></SocialMediaButton>
                    </SocialIconException>

                    <SocialIconException>
                        <SocialMediaButton link="https://gab.com/ickonicmediagroup"><Gab/></SocialMediaButton>
                    </SocialIconException>

                    <SocialIcon>
                        <SocialMediaButton link="https://t.me/ickonicmediagroup"><BsTelegram/></SocialMediaButton>
                    </SocialIcon>
                </SocialSection>

            </ContentContainer>
            <CopyrightContainer>
                <PrimaryText>Copyright © {new Date().getUTCFullYear()} Ickonic Media Group. All Rights Reserved.</PrimaryText>
            </CopyrightContainer>
        </FooterContainer>
    );
}

export default Footer;
