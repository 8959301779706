import styled, { keyframes} from "styled-components";
import { ISearchFilterDTO } from "../../../Models/DTOs/ISearchFilterDTO";
import { useRef, useEffect, useState } from "react";

// Models
import { Originals } from "../../../Models/Enums/SearchFilters/Originals";
import { FilterBy } from "../../../Models/Enums/SearchFilters/FilterBy";
import { SortBy } from "../../../Models/Enums/SearchFilters/SortBy";
import { HeadingType } from "../../../Models/Enums/HeadingType";

// Components
import Heading from "../Text/Heading";
import RadioButton from "../RadioButtons/RadioButton";
import PrimaryText from "../Text/PrimaryText";
import GreyBorderThinButton from "../Buttons/GreyBorderThinButton";
import Input from "../Inputs/Input";

// Helpers

// Assets
import IconFilters from "../../../Assets/SVGs/Icons/UI/Filters";
import IconClose from "../../../Assets/SVGs/Icons/UI/Close";

// Constants
import { Ease } from "../../../Constants/EasingCurves";
import { Fonts } from "../../../Constants/Fonts";
import { Colours } from "../../../Constants/Colours";
import {GetDefaultSearchFilters} from "../../../Helpers/Search";

const ProgressionMoveUp = keyframes`
    to {
        transform: translateY(0px);
        opacity: 1;
    }
`;

const ProgressionMoveOut = keyframes`
    from {
        visibility: visible;
        opacity: 1;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
`;

const HeaderContainer = styled.div `
    display:flex;
`;

const Container = styled.div`
    top: -1px;
    left: 0;
    margin-top: 1px;
    position: fixed;
    width: 100%;
    height: calc(100vh + 1px);
    background: ${Colours.Secondary};
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    box-shadow: 0px 15px 20px rgb(0 0 0 / 5%);
    line-height: 1.6;
    transform: translateX(-500);
    flex-wrap: wrap;
    z-index: 25;
    padding: 177px 30px 20px 30px;
    &.mobile {
        padding:10px;
        top: 120px;
        width: 95vw;

        h1 {
            margin-bottom: 2px;
            margin-top: 5px;
        }
    }

    & > div {
        &:last-chlid {
            height: calc(100vh - 172px);
        }
    }

    opacity: 0;
    visibility: hidden;

    &.active{
        opacity: 1;
        visibility: visible;

        transform: translateY(-20px);
        animation-duration: 125ms;
        animation-name: ${ProgressionMoveUp};
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    &.loaded {
        animation-duration: 200ms;
        animation-name: ${ProgressionMoveOut};
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    button {
        align-self: center;
        margin: 20px 0 0 0;
    }
`;

const FilterOptionsContainer = styled.div`
    width: calc(100% - 12px);
    margin: 0 5px;
    padding: 16px;
    background: ${ Colours.SecondaryDarker };
    z-index: 50;
    position: absolute;
    left: 0;
    top: -2000px;
    transform: translateY(30px);
    opacity: 0;
    transition: top 0s linear .25s, opacity .25s ${ Ease.Smooth }, transform .25s ${ Ease.Smooth };
    &:before {
        content: '';
        position: absolute;
        top: -15px;
        left: 0;
        height: 15px;
        width: 35px;
        background: ${ Colours.SecondaryDarker };
    }

    &.active {
           opacity: 1;
           transform: translateY(0);
           top: 50px;
           transition: top 0s linear, opacity .25s ${ Ease.Smooth }, transform .25s ${ Ease.Smooth };
    }

    &.mobile {
        flex-direction: column;
    }
`;

const FilterBox = styled.div`
    flex:1;
    &:first-child {
        h1 {
            margin-top: 0;
        }
    }
`;

const SearchInput = styled.div`
    margin: 0 auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    position: fixed;
    top: 15px;
    left: 0;
    padding: 0 30px;
    z-index: 2;
    input {
        background: ${Colours.SecondaryHighlight} !important;
        border: 1px solid ${Colours.SecondaryHighlight} !important;
        width: 100% !important;
        padding: 12px 80px 11px 50px !important;
        border-radius: 6px !important;
        font-size: 12pt !important;
    }

    p {
        box-shadow: 0 0 0 1px ${ Colours.ErrorDark };
        color: #ccc;
        padding: 5px 10px;
        font-size: 10pt;
        position: absolute;
        text-transform: uppercase;
        border-radius: 3px;
        letter-spacing: 1px;
        top: 9px;
        right: 11px;
        cursor: pointer;
        transition: background .15s ${ Ease.Smooth }, box-shadow .15s ${ Ease.Smooth }, color .15s ${ Ease.Smooth };
        &:hover,
        &:active {
            color: white;
            background: ${ Colours.Error };
            box-shadow: 0 0 0 1px ${ Colours.ErrorDark };
        }
    }

    input:focus {
        background: ${Colours.SecondaryHighlight};
        border: 1px solid ${Colours.SecondaryHighlight};
    }

    @media screen and (min-width: calc(660em/16)) {
        left: calc(50% - 300px);
    }
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchPopUpHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    div {
        position: relative;
    }

    & > div {
        background: rgba(255,255,255,0.05);
        border-radius: 6px;
     }

    h3 {
        font-family: ${ Fonts.Primary };
        font-size: 18pt;
        font-weight: 400;
    }

`;

const styledIcon = `
  cursor: pointer;
  width: 50px;

  height: 50px;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    * {
      fill: #bcbcbc;
      transition: fill .15s ${ Ease.Smooth };
    }
  }

  &:hover,
  &:active {
      svg {
        * {
          fill: white;
        }
      }
  }
`;

const CloseModal = styled.div`
  ${ styledIcon }
  padding: 10px;
`;

const ToggleFilters = styled.div`
    ${ styledIcon }
    top: 5px;
    padding: 4px;
    right: auto;
    width: 35px;
    height: 40px;
    left: 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${ Colours.SecondaryDarker };
    transform: translateY(0);
    svg {
        //height: 21px;
    }

    @media screen and (min-width: calc(660em/16)) {

    }
`;



const ResultsContainer = styled.div`
    height: calc(100vh - 197px);
    overflow-y: auto;
    position: relative;
    z-index: 0;
`;


function SearchFilters(props : {
    Header: JSX.Element | JSX.Element[];
    isActive: boolean,
    mobileLayout: boolean,
    isLoaded: boolean,
    setIsActive: (value: boolean) => void,
    searchText: string,
    onSearchUpdate: (text: string) => void,
    searchFilter: ISearchFilterDTO,
    onSearchFilterUpdate: (filters : ISearchFilterDTO) => void,
    children: string | string[] | number | React.ReactNode
}) {

    const [selectedSortBy, setSelectedSortBy] = useState<SortBy>(props.searchFilter.SortBy ?? SortBy.Newest);
    const [selectedFilterBy, setSelectedFilterBy] = useState<FilterBy>(props.searchFilter.FilterBy ?? FilterBy.FilmsAndSeries);
    const [selectedOriginals, setSelectedOriginals] = useState<Originals>(props.searchFilter.Originals ?? Originals.All);
    const [filtersPanelActive, setFiltersPanelActive] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.mobileLayout) {
            return () => {};
        }

        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                props.setIsActive(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);


    useEffect(() => {
        if (inputRef.current && props.isActive) {
            inputRef.current.focus();
        }
    }, [props.isActive]);

    function getLoadedClass(): string {

        let className = "";
        if (props.mobileLayout) {
            className += "mobile ";
        }

        if (props.isActive) {
            className += "active";
        }
        else if (props.isLoaded)
        {
            className += "loaded";
        }
        return className;
    }

    function onSortByRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value as SortBy;
        setSelectedSortBy(value);
        const filters = props.searchFilter;
        filters.SortBy = value;
        props.onSearchFilterUpdate(filters);
    }

    function onFilterByRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value as FilterBy;
        setSelectedFilterBy(value);
        const filters = props.searchFilter;
        filters.FilterBy = value;
        props.onSearchFilterUpdate(filters);
    }

    function onOriginalsRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value as Originals;
        setSelectedOriginals(value);
        const filters = props.searchFilter;
        filters.Originals = value;
        props.onSearchFilterUpdate(filters);
    }

    function onResetClicked() {
        const filters: ISearchFilterDTO = GetDefaultSearchFilters();
        setSelectedFilterBy(filters.FilterBy ?? FilterBy.FilmsAndSeries);
        setSelectedSortBy(filters.SortBy ?? SortBy.Newest);
        setSelectedOriginals(filters.Originals ?? Originals.All);
        props.onSearchFilterUpdate(filters);
    }

    function focusSearchInput() {
        if (inputRef.current && props.isActive) {
            inputRef.current.focus();
        }
    }

    function onHandleClearInput() {
        props.onSearchUpdate("");

        focusSearchInput();

        // Only makes sense to deactivate the filters panel if
        // you're also going to set the filters back to default.
        // if (filtersPanelActive) {
        //     setFiltersPanelActive(false);
        // }
    }


    function onHandleFiltersPanelSwitch() {

        // If filters panel is currently open, then it will
        // be closed momentarily. In this scenario, a good UX
        // might be to focus the search input again in case
        // the user wants to type.
        if (filtersPanelActive) {
            focusSearchInput();
        }

        // Swap the filters panel active state
        setFiltersPanelActive(!filtersPanelActive);
    }

    function searchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.onSearchUpdate(event.target.value);
    }

    return (
        <div ref={ref}>
            <HeaderContainer>
                {props.Header}
            </HeaderContainer>

            <Container ref={ref} className={ getLoadedClass() }>
                <SearchInput>
                    <SearchPopUpHeader>
                        <Heading type={ HeadingType.H3 }>
                            Search
                        </Heading>

                        <CloseModal onClick={ () => props.setIsActive(false) }>
                            <IconClose />
                        </CloseModal>
                    </SearchPopUpHeader>

                    <SearchContainer>
                        <Input
                            isValid={true}
                            onChange={searchTextChange}
                            onBlur={() => {}}
                            type={"text"}
                            value={props.searchText}
                            placeholder={"Start typing..."}
                            disabled={false}
                            removeMargin={true}
                            ref={ inputRef }
                        >
                            <ToggleFilters
                                onClick={ () => onHandleFiltersPanelSwitch() }
                                className={ filtersPanelActive ? "active" : "not-active" }
                            >
                                {
                                    filtersPanelActive ?
                                        <IconClose />
                                        :
                                        <IconFilters/>
                                }
                            </ToggleFilters>
                        </Input>

                        <FilterOptionsContainer className={ `${ props.mobileLayout ? "mobile" : "desktop" } ${ filtersPanelActive ? "active" : "not-active" }` }>
                            <FilterBox>
                                <Heading type={ HeadingType.H4 }>Filter By:</Heading>
                                <RadioButton
                                    id="1"
                                    label="Films & Series"
                                    onChange={onFilterByRadioChange}
                                    value={FilterBy.FilmsAndSeries}
                                    checked={selectedFilterBy === FilterBy.FilmsAndSeries}
                                    name="filterBy"
                                />
                                <RadioButton
                                    id="2"
                                    label="Films"
                                    onChange={onFilterByRadioChange}
                                    value={FilterBy.Films}
                                    checked={selectedFilterBy === FilterBy.Films}
                                    name="filterBy"
                                />
                                <RadioButton
                                    id="3"
                                    label="Series"
                                    onChange={onFilterByRadioChange}
                                    value={FilterBy.Series}
                                    checked={selectedFilterBy === FilterBy.Series}
                                    name="filterBy"
                                />
                                <RadioButton
                                    id="4"
                                    label="Films & Episodes"
                                    onChange={onFilterByRadioChange}
                                    value={FilterBy.FilmsAndEpisodes}
                                    checked={selectedFilterBy === FilterBy.FilmsAndEpisodes}
                                    name="filterBy"
                                />
                            </FilterBox>
                            <FilterBox>
                                <Heading type={ HeadingType.H4 }>Originals:</Heading>
                                <RadioButton
                                    id="1"
                                    label="All"
                                    onChange={onOriginalsRadioChange}
                                    value={Originals.All}
                                    checked={selectedOriginals === Originals.All}
                                    name="originals"
                                />
                                <RadioButton
                                    id="2"
                                    label="Only Ickonic Originals"
                                    onChange={onOriginalsRadioChange}
                                    value={Originals.Originals}
                                    checked={selectedOriginals === Originals.Originals}
                                    name="originals"
                                />
                            </FilterBox>
                            <FilterBox>
                                <Heading type={ HeadingType.H4 }>Sort By:</Heading>
                                <RadioButton
                                    id="1"
                                    label="Newest"
                                    onChange={onSortByRadioChange}
                                    value={SortBy.Newest}
                                    checked={selectedSortBy === SortBy.Newest}
                                    name="sortBy"
                                />
                                <RadioButton
                                    id="2"
                                    label="Oldest"
                                    onChange={onSortByRadioChange}
                                    value={SortBy.Oldest}
                                    checked={selectedSortBy === SortBy.Oldest}
                                    name="sortBy"
                                />
                            </FilterBox>

                            <GreyBorderThinButton onClick={onResetClicked} disabled={ selectedFilterBy === 'filmsandseries' && selectedSortBy === 'newest' && selectedOriginals === 'all' }>
                                Reset Filters
                            </GreyBorderThinButton>
                        </FilterOptionsContainer>

                        { props.searchText !== '' && (
                            <PrimaryText onClick={ () => onHandleClearInput() }>
                                Clear
                            </PrimaryText>
                        ) }
                    </SearchContainer>
                </SearchInput>

                <ResultsContainer>
                    { props.children }
                </ResultsContainer>
            </Container>
        </div>
    );
}

export default SearchFilters;
