import {ContentType} from "../Models/Enums/ContentType";
import {RoutePaths} from "../Constants/RoutePaths";

export function ContentTypeToString(contentType: ContentType) {
    switch (contentType) {
        case ContentType.Article:
            return "article";
        case ContentType.Episode:
            return "episode";
        case ContentType.Category:
            return "category";
        case ContentType.Event:
            return "event";
        case ContentType.Film:
            return "films";
        case ContentType.Series:
            return "series";
        default:
            return "content";
    }
}

export function GetContentRedirect(contentType: ContentType, id: number) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.Watch(id);
        case ContentType.Series:
            return RoutePaths.SeriesEpisodes(id);
        case ContentType.Event:
            return RoutePaths.Event(id);
        case ContentType.Category:
            return RoutePaths.Category(id);
        default:
            return null;
    }
}

export function GetTrailerRoute(contentId: number, contentType: ContentType) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.WatchTrailer(contentId);
        case ContentType.Series:
            return RoutePaths.SeriesTrailer(contentId);
        default:
            console.log('src/Helpers GetTrailerRoute');
            return RoutePaths.Browse;
    }
}

